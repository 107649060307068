<ion-header translucent>
  <ion-toolbar mode="ios">
    <ion-title>{{ 'CONTACT_US_title_Tasafy' | translate }}</ion-title>
    <img class="title-logo" *ngIf="isTasafy" src="/assets/icon/logo-tasafy-amarillo.png" />
    <img class="title-logo" *ngIf="!isTasafy" src="/assets/icon/icon-app.png" />
    <ion-buttons slot="end">
      <ion-button class="button-icon-clear-rounded" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <p class="medium">{{ 'CONTACT_US_description' | translate }}</p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <a [href]="'tel:' + phoneNumber">
          <p>{{ displayNumber }}</p>
        </a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button id="AT_Contact_Us_Call" [href]="'tel:' + phoneNumber">{{ 'CONTACT_US_call' | translate }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
