<!--control should be present before p-dropdown declaration because throws an error-->
<span *ngIf="control" class="md-inputfield p-float-label my-4" [ngClass]="{ readonly: readonly }">
  <!-- TODO: NgClass ñapa -->
  <p-dropdown
    [id]="componentId"
    [ngClass]="{ filled: control?.value === 0 || control?.value === false, disabled: control?.disabled || disabled }"
    appendTo="body"
    [autoDisplayFirst]="false"
    [options]="optionList"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [formControl]="control"
    [style]="{ width: '100%' }"
    [filter]="activeFilter"
    [filterBy]="filterBy"
    [virtualScroll]="activeVirtualScroll"
    [virtualScrollItemSize]="30"
    [showClear]="showClear"
    [readonly]="readonly"
    (onChange)="selectedValueChange.next($event)"
  >
    <ng-template let-item pTemplate="selectedItem">
      <span class="dropdown-selectedItem">{{ item?.translatedLabel | translate }}</span>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="p-helper-clearfix dropdown-item-position">
        <div class="d-flex align-items-center dropdown-item" [ngClass]="{ 'disabled-item': item?.disabled }" *ngIf="!item.expertOnly">
          {{ item?.translatedLabel | translate }}
        </div>
        <div class="d-flex align-items-center dropdown-item" *ngIf="item.expertOnly">
          <span class="disabled-item">{{ item?.translatedLabel | translate }}</span>
          <span class="badge">Comfort/Premium</span>
        </div>
      </div>
    </ng-template>
  </p-dropdown>
  <label *ngIf="label">{{ label | translate }}</label>
  <ui-form-validation-messages *ngIf="control?.errors" [control]="control"></ui-form-validation-messages>
</span>
