import { AppId, DevelopEnvironment, Environment, XPlatform } from '@foxeet/domain';
import { VERSION } from './version';

export const environment: Environment = {
  app: AppId.TASAFY,
  appName: 'TASAFY',
  wikiURL: 'https://wiki.tasafy.app',
  platform: XPlatform.MOBILE,
  production: false,

  // defaultEndpoint: 'https://192.168.16.10:5001', // Javi
  // defaultEndpoint: 'https://192.168.16.25:5001', // Gilbert
  // defaultEndpoint: 'https://192.168.16.24:5001', // Alfredo
  // defaultEndpoint: 'https://192.168.16.50:5001', // Alberto
  // defaultEndpoint: 'https://192.168.16.37:5001', // David
  appVersion: VERSION,
  version: VERSION,
  envName: DevelopEnvironment.dev,
  defaultEndpoint: 'https://api-dev.tasafy.app',
  devEndpoint: 'https://api-dev.tasafy.app',
  qaEndpoint: 'https://api-qa.tasafy.app',
  prodEndpoint: 'https://api.tasafy.app',

  mapbox: {
    accessToken: 'pk.eyJ1IjoiaW5zdGl0dXRvIiwiYSI6ImNrNmF1bHc5aTAxbHUzbXNicmdpYWFuMGcifQ.oLGQ854cghe8ypDxZ1-3HQ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
