<form class="form" [formGroup]="formArray" class="no-padding">
  <ion-row class="ion-justify-content-end">
    <ion-col size="12" class="right" *ngIf="!readonly">
      <a (click)="setVisitProposalForm()" class="link">
        <ion-icon name="add-outline"></ion-icon>
        {{ 'DATES.addProposalDate' | translate }}</a
      >
    </ion-col>
  </ion-row>
  <ion-row
    class="ion-align-items-end date-lines"
    *ngFor="let formGroup of formArray?.controls; let i = index"
    [ngClass]="{ selected: clickable && selected === formGroup }"
    (click)="clickable ? dateSelected(formGroup) : $event.stopPropagation()"
  >
    <ion-col class="no-padding" size-xs="12" size-md="6">
      <ion-row class="ion-align-items-start">
        <ion-col size-xs="6" [sizeSm]="formGroup?.get(proposedDatesControls.allDay)?.value ? '6' : '4'" [sizeMd]="formGroup?.get(proposedDatesControls.allDay)?.value ? '8' : '6'">
          <ui-mobile-form-calendar
            [label]="'DATES.date'"
            [placeholder]="'DATES.date'"
            [subLabel]="formGroup?.get(proposedDatesControls.allDay)?.value ? '' : 'DATES.from'"
            [form]="formGroup"
            [controlName]="proposedDatesControls.from"
            [displayFormat]="formGroup?.get(proposedDatesControls.allDay)?.value ? 'dd/MM/yyyy' : 'dd/MM/yyyyHH:mm'"
            [pickerFormat]="formGroup?.get(proposedDatesControls.allDay)?.value ? 'date' : 'date-time'"
            [readonly]="readonly"
            [icon]="FormCalendarIcons.CALENDAR"
          >
          </ui-mobile-form-calendar>
        </ion-col>

        <ion-col
          size-xs="6"
          [sizeSm]="formGroup?.get(proposedDatesControls.allDay)?.value ? '6' : '4'"
          [sizeMd]="formGroup?.get(proposedDatesControls.allDay)?.value ? '6' : '6'"
          *ngIf="!formGroup?.get(proposedDatesControls.allDay).value"
        >
          <ui-mobile-form-calendar
            [label]="'DATES.date'"
            [placeholder]="'DATES.date'"
            [subLabel]="'DATES.to'"
            [form]="formGroup"
            [controlName]="proposedDatesControls.until"
            [displayFormat]="formGroup?.get(proposedDatesControls.allDay)?.value ? 'dd/MM/yyyy' : 'dd/MM/yyyyHH:mm'"
            [pickerFormat]="formGroup?.get(proposedDatesControls.allDay)?.value ? 'date' : 'date-time'"
            [readonly]="readonly"
            [icon]="FormCalendarIcons.CALENDAR"
          >
          </ui-mobile-form-calendar>
        </ion-col>
      </ion-row>
    </ion-col>

    <ion-col size-xs="11" size-md="5">
      <ui-mobile-form-toggle
        (valueChange)="toggleValueChange($event, formGroup)"
        [label]="'DATES.allDay'"
        [controlName]="proposedDatesControls.allDay"
        [form]="formGroup"
        [readonly]="readonly"
      >
      </ui-mobile-form-toggle>
    </ion-col>
    <ion-col size="1" class="no-padding remove-date" *ngIf="!readonly">
      <ion-icon name="trash-outline" color="danger" (click)="removeDateProposed(i)"></ion-icon>
    </ion-col>
  </ion-row>
</form>
