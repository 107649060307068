<ion-card>
  <ion-card-header *ngIf="title || subtitle">
    <ion-card-title [color]="titleColor ? titleColor : 'primary'" *ngIf="title">{{ title | translate: internationalizationType }}</ion-card-title>
    <ion-card-subtitle *ngIf="subtitle">{{ subtitle | translate: internationalizationType }}</ion-card-subtitle>
  </ion-card-header>

  <ion-card-content [ngClass]="{ 'no-padding': noPadding }">
    <ng-content></ng-content>
  </ion-card-content>
</ion-card>
