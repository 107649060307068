import { MapConfig } from '@foxeet/domain';

export const COLOR_MARKER = '#fec526';
export const COLOR_NEIGHBOR_MARKERS = '#c1a00f';

export const mapConfig: MapConfig = {
  colors: {
    marker: '#fec526',
    neighborMarkers: '#c1a00f',
  },
};
