import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccountCanAccess, AccountCanLoad, ChangeTemporalPasswordGuardService } from '@foxeet/feature/auth';
import { ChangeTemporalPasswordComponent } from '@foxeet/mcore';
import { M_PATHS as PATHS } from '@foxeet/n-core';

const routes: Routes = [
  {
    path: `${PATHS.LOGIN}`,
    loadChildren: () => import('@foxeet/mfeature/login').then((m) => m.MfeatureTasafyLoginModule),
  },
  {
    path: `${PATHS.LOGIN}/${PATHS.RESTORE}`,
    loadChildren: () => import('@foxeet/mfeature/login').then((m) => m.MfeatureTasafyLoginModule),
  },
  {
    path: `${PATHS.CHANGE_TEMPORAL_PASSWORD}`,
    component: ChangeTemporalPasswordComponent,
    canActivate: [ChangeTemporalPasswordGuardService],
  },
  {
    path: '',
    loadChildren: () => import('./modules/modules.module').then((m) => m.ModulesPageModule),
    canLoad: [AccountCanLoad, AccountCanAccess],
    data: { platform: 'tasafy' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
