import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mcore-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
})
export class MenuHeaderComponent {
  @Input() logo: string;
  @Input() appVersion: string;
}
