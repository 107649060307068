import { UnsuscriptionHandler } from '@foxeet/utils/components';
import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DynamicConfigLoaderService } from '@foxeet/utils-modules';
import { AppId } from '@foxeet/domain';

@Component({
  selector: 'mcore-contact-us-modal',
  templateUrl: 'contact-us-modal.component.html',
  styleUrls: ['contact-us-modal.component.scss'],
})
export class ContactUsModalComponent extends UnsuscriptionHandler implements OnInit {
  public phoneNumber: string;
  public displayNumber: string;
  public isTasafy: boolean;
  constructor(private nav: NavParams, private _modalController: ModalController, private _dynamicConfigLoaderService: DynamicConfigLoaderService) {
    super();
    this.isTasafy = [AppId.TASAFY].includes(this._dynamicConfigLoaderService.Options.environment.app);
  }
  ngOnInit() {
    this.phoneNumber = this.nav?.data?.phoneNumber || '';
    this.displayNumber = this.nav?.data?.displayNumber || '';
    console.log(this.phoneNumber);
  }
  close() {
    this._modalController.dismiss();
  }
}
