import { Component, Input } from '@angular/core';
import { InternationalizationTypes } from '@foxeet/domain';

@Component({
  selector: 'ui-mobile-basic-container',
  templateUrl: './basic-container.component.html',
  styleUrls: ['./basic-container.component.scss'],
})
export class BasicContainerComponent {
  @Input() title?: string;
  @Input() titleColor?: string;
  @Input() subtitle?: string;
  @Input() internationalizationType?: InternationalizationTypes;

  @Input() noPadding = false;
}
