<ion-footer>
  <ion-row class="ion-justify-content-center">
    <!-- Comentado por la tarea: IVF-14232 -->
    <!-- <ion-col size="6" class="no-padding">
      <ion-button class="block-button conctact" color="foreground" (click)="contactUs()">
        <ion-icon name="call-outline"></ion-icon>
        {{ 'MENU_contactUs' | translate | uppercase }}
      </ion-button>
    </ion-col> -->

    <ion-col size="12" class="no-padding">
      <ion-button class="block-button logout" color="foreground" (click)="logOut()"> {{ 'MENU_logOut' | translate | uppercase }} </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
