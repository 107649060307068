import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { isNil } from '@foxeet/utils/functions';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { AccountService, EnumsService } from '@foxeet/data-access';

@Injectable({ providedIn: 'root' })
export class AccountCanAccess implements CanLoad {
  constructor(private _authService: AuthService, private _accountService: AccountService, private _enumsService: EnumsService, private _router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return forkJoin([this._enumsService.getAuthorities(), this._accountService.getLicenses()]).pipe(
      take(1),
      map((): boolean => {
        const urlParams = new URLSearchParams(location.search);
        const licenseIdParam = urlParams.get('licenseId');

        if (this._authService.userLicenses$.value.map((el) => el.id).includes(Number(licenseIdParam))) {
          const appraisalIdParam = urlParams.get('appraisalId');

          if (!isNil(licenseIdParam) && +licenseIdParam !== this._authService.getCurrentLicense()?.id) {
            this._authService.setCurrentLicenseByLicenseId(+licenseIdParam, true);
          }

          if (appraisalIdParam) {
            this._router.navigate([`/orders/${appraisalIdParam}/info`]);
          }
        }

        return true;
      }),
    );
  }
}
