import { ObjectTS } from '@foxeet/utils/functions/javascript.functions';

export enum LicenseeUserRoleAppraiser {
  Basic = 'Basic_LicenseeUserRole_Appraiser',
  Comfort = 'Comfort_LicenseeUserRole_Appraiser',
  Premium = 'Premium_LicenseeUserRole_Appraiser',
  Trial = 'Trial_LicenseeUserRole_Appraiser',
}

export enum ModuleAccess {
  Billing = 'BillingModule',
}

export const ALL_APPRAISER_ROLES = ObjectTS.values(LicenseeUserRoleAppraiser);

export const ALL_MODULE_ACCESS = ObjectTS.values(ModuleAccess);

export const ALL_ROLES = [...ALL_APPRAISER_ROLES, ...ALL_MODULE_ACCESS];

export const authoritiesDefinition = {
  orders: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    pages: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      list: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        actions: {
          canFilter: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canShowLastOrders: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canShowVisitPending: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canShowPendingToSend: [
            'Basic_LicenseeUserRole_Appraiser',
            'Trial_LicenseeUserRole_Appraiser',
            'Comfort_LicenseeUserRole_Appraiser',
            'Premium_LicenseeUserRole_Appraiser',
          ],
          canShowSentToRevision: [
            'Basic_LicenseeUserRole_Appraiser',
            'Trial_LicenseeUserRole_Appraiser',
            'Comfort_LicenseeUserRole_Appraiser',
            'Premium_LicenseeUserRole_Appraiser',
          ],
          canShowFinished: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canShowRevisions: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canShowIncidents: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        },
      },
      detail: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        actions: {
          canScheduleVisit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canStartVisit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canFinishVisit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canSentToRevision: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canAddComment: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canAddIncident: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canImportReport: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canShowDocuments: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        },
        info: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          actions: {
            canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          },
        },
        incidents: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        },
        board: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          actions: {
            canAdd: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canReply: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canDelete: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          },
        },
        reports: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        },
      },
      documents: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        actions: {
          canAdd: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canDelete: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canSetAsPrintable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canDownload: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        },
      },
      reports: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        request: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          actions: {
            canImportStructure: [
              'Basic_LicenseeUserRole_Appraiser',
              'Trial_LicenseeUserRole_Appraiser',
              'Comfort_LicenseeUserRole_Appraiser',
              'Premium_LicenseeUserRole_Appraiser',
            ],
          },
        },
        addAsset: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          createAsset: {
            access: [],
            enable: [],
            showExternSourceFilter: {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            showLocationFilter: {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            showCadastralReferenceFilter: {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
        },
        commonAssets: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          'locality-environment': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canGetAutomaticData: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
          },
          'exploitation-description': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'rustic-estate-features': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-environment': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'building-terrain': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'building-features': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          terrainFeatures: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'urbanistic-situation': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'market-studies': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            detail: {
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
            },
          },
          'urbanistic-situation-terrain': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          checks: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          conditioningFactors: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-building-info': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          buildingState: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'valuation-list': {
            access: [],
            enable: [],
            actions: {
              canEdit: [],
            },
          },
          valuations: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canAdd: [],
              canRecalculate: [],
            },
            'comparison-method': {
              access: [],
              enable: [],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
            },
            'cost-method': {
              access: [],
              enable: [],
            },
            'discount-cashflow-method': {
              access: [],
              enable: [],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
            },
            'contract-cashflow-method': {
              access: [],
              enable: [],
            },
            'legal-maximum-method': {
              access: [],
              enable: [],
            },
            'other-method': {
              access: [],
              enable: [],
            },
            'static-residual-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
            },
            'dynamic-residual-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
            },
            'dynamic-residual': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              createComparable: {
                access: [],
                enable: [],
                showExternSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showLocationFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
                showCadastralReferenceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
              ListComparable: {
                access: [],
                enable: [],
                showSourceFilter: {
                  access: ['Premium_LicenseeUserRole_Appraiser'],
                },
              },
            },
            'economic-exploitation-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
            'rustic-estate-exploitation-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
          },
        },
        assets: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          actions: {
            canAddCluster: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canAddTerrainCluster: [
              'Basic_LicenseeUserRole_Appraiser',
              'Trial_LicenseeUserRole_Appraiser',
              'Comfort_LicenseeUserRole_Appraiser',
              'Premium_LicenseeUserRole_Appraiser',
            ],
            canAddAsset: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canAddAnnex: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canDelete: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canDuplicate: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            canMakeItMain: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          },
          location: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          identification: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          typology: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-typology': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-surface-facilities-stays': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-building-info': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-environment': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-terrain-surface': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'visit-terrain-features': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          surfaces: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'locality-environment': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canGetAutomaticData: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
          },
          terrainFeatures: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'exploitation-description': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'rustic-estate-features': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'building-terrain': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'building-features': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'property-features': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'urbanistic-situation': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'urbanistic-situation-terrain': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          holding: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          checks: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          'valuation-list': {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          valuations: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canAdd: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canRecalculate: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'comparison-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              actions: {
                canAddExternSource: ['Premium_LicenseeUserRole_Appraiser'],
                canFilterByExternSource: ['Premium_LicenseeUserRole_Appraiser'],
              },
            },
            'cost-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'discount-cashflow-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'contract-cashflow-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'legal-maximum-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'other-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'static-residual': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
            'dynamic-residual': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
            'economic-exploitation-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
            'rustic-estate-exploitation-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
          },
          conditioningFactors: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          photos: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canDownload: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canSetAsPrintable: [
                'Basic_LicenseeUserRole_Appraiser',
                'Trial_LicenseeUserRole_Appraiser',
                'Comfort_LicenseeUserRole_Appraiser',
                'Premium_LicenseeUserRole_Appraiser',
              ],
              canDelete: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canMakeItMain: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canAdd: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          documentation: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canSetAsPrintable: [
                'Basic_LicenseeUserRole_Appraiser',
                'Trial_LicenseeUserRole_Appraiser',
                'Comfort_LicenseeUserRole_Appraiser',
                'Premium_LicenseeUserRole_Appraiser',
              ],
              canDownload: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canAdd: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canDelete: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          possession: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          buildingState: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
        },
        clusters: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          actions: {
            canDelete: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          },
          detail: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          valuations: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canAdd: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              canRecalculate: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'comparison-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'cost-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'discount-cashflow-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'contract-cashflow-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'legal-maximum-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'other-method': {
              access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
              enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
            'static-residual': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
            'dynamic-residual': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
            'economic-exploitation-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
            'rustic-estate-exploitation-method': {
              access: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
              enable: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
            },
          },
        },
        terrains: {
          access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          actions: {},
          detail: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
          calculations: {
            access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            actions: {
              canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
            },
          },
        },
      },
    },
  },
  'connections-network': {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    pages: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      'connection-history': {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
      'license-requests': {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
      'user-requests': {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
    },
  },
  user: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    pages: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      profile: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        actions: {
          canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        },
      },
      preferences: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
      incompatibilities: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        actions: {
          canAdd: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canDelete: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
          canEdit: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        },
      },
    },
  },
  invoices: {
    access: ['BillingModule'],
    enable: ['BillingModule'],
    pages: {
      access: ['BillingModule'],
      enable: ['BillingModule'],
      list: {
        access: ['BillingModule'],
        enable: ['BillingModule'],
      },
      detail: {
        access: ['BillingModule'],
        enable: ['BillingModule'],
      },
    },
  },
  documents: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    pages: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      list: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
      detail: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
    },
  },
  absences: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    pages: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      list: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
      detail: {
        access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
        enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      },
    },
  },
  schedule: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
  },
  'how-it-works': {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
  },
  opportunities: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    list: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    },
    detail: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    },
  },
  collaborations: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    add: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    },
    list: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    },
    detail: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    },
  },
  ClusterTypes: {
    FinishedElements: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    Terrains: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
    EconomicExploitation: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
    RusticEstate: [],
  },
  AssetUses: {
    Residential: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    Commercial: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    Tertiary: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    Industrial: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    Annexed: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    RusticEstate: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
    Terrain: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
    Dotational: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
  },
  AssetStates: {
    Finished: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    Unfinished: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
  },
  comparables: {
    access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
    list: {
      access: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      enable: ['Basic_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser', 'Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser'],
      actions: {
        canFilterBySource: ['Premium_LicenseeUserRole_Appraiser'],
      },
    },
    createComparable: {
      access: [],
      enable: [],
      showExternSourceFilter: {
        access: ['Premium_LicenseeUserRole_Appraiser'],
      },
      showLocationFilter: {
        access: ['Premium_LicenseeUserRole_Appraiser'],
      },
      showCadastralReferenceFilter: {
        access: ['Premium_LicenseeUserRole_Appraiser'],
      },
    },
  },
  isEconomicExploitationCheckbox: ['Comfort_LicenseeUserRole_Appraiser', 'Premium_LicenseeUserRole_Appraiser', 'Trial_LicenseeUserRole_Appraiser'],
};
