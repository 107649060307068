import { Component, Input } from '@angular/core';
import { ToastrWrapperService } from '@foxeet/utils/services';
import { UserProfileModel } from './domain/user-profile.model';

@Component({
  selector: 'mcore-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  @Input() user: UserProfileModel;

  constructor(private _toastWrapper: ToastrWrapperService) {}

  copyCode() {
    this._toastWrapper.success('NETWORK.codeCopied');
  }
}
