export const esLang = {
  languageKey: 'es',
  translates: {
    primeng: {
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Termina con',
      equals: 'Es igual a',
      notEquals: 'No es igual a ',
      noFilter: 'No Filter',
      lt: 'Menor que',
      lte: 'Menor o igual que',
      gt: 'Mayor que',
      gte: 'Mayor o igual que',
      is: 'Es',
      isNot: 'No es',
      before: 'Antes',
      after: 'Después',
      clear: 'Borrar',
      apply: 'Aplicar',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Añadir regla',
      removeRule: 'Eliminar regla',
      accept: 'Yes',
      reject: 'No',
      choose: 'Elegir',
      upload: 'Subir',
      cancel: 'Cancelar',
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      weekHeader: 'Sem',
      weak: 'Débil',
      medium: 'Medio',
      strong: 'Fuerte',
      passwordPrompt: 'Introducir contraseña',
      emptyMessage: 'No hay resultados',
      emptyFilterMessage: 'No hay resultados',
    },
    aggrid: {
      // Set Filter
      selectAll: '(Select All)',
      selectAllSearchResults: '(Select All Search Results)',
      searchOoo: 'Buscar...',
      blanks: '(Blanks)',
      noMatches: 'No matches',

      // Number Filter & Text Filter
      filterOoo: 'Filter...',
      equals: 'Equals',
      notEqual: 'Not equal',
      blank: 'Blank',
      notBlank: 'Not blank',
      empty: 'Choose One',

      // Number Filter
      lessThan: 'Less than',
      greaterThan: 'Greater than',
      lessThanOrEqual: 'Less than or equal',
      greaterThanOrEqual: 'Greater than or equal',
      inRange: 'In range',
      inRangeStart: 'from',
      inRangeEnd: 'to',

      // Text Filter
      contains: 'Contains',
      notContains: 'Not contains',
      startsWith: 'Starts with',
      endsWith: 'Ends with',

      // Date Filter
      dateFormatOoo: 'yyyy-mm-dd',

      // Filter Conditions
      andCondition: 'AND',
      orCondition: 'OR',

      // Filter Buttons
      applyFilter: 'Apply',
      resetFilter: 'Reset',
      clearFilter: 'Clear',
      cancelFilter: 'Cancel',

      // Filter Titles
      textFilter: 'Text Filter',
      numberFilter: 'Number Filter',
      dateFilter: 'Date Filter',
      setFilter: 'Set Filter',

      // Side Bar
      columns: 'Columns',
      filters: 'Filters',

      // columns tool panel
      pivotMode: 'Pivot Mode',
      groups: 'Row Groups',
      rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
      values: 'Values',
      valueColumnsEmptyMessage: 'Drag here to aggregate',
      pivots: 'Column Labels',
      pivotColumnsEmptyMessage: 'Drag here to set column labels',

      // Header of the Default Group Column
      group: 'Group',

      // Row Drag
      rowDragRows: 'rows',

      // Other
      loadingOoo: 'Loading...',
      noRowsToShow: 'No Rows To Show',
      enabled: 'Enabled',

      // Menu
      pinColumn: 'Fijar columna',
      pinLeft: 'Fijar columna a la izquierda',
      pinRight: 'Fijar columna a la derecha',
      noPin: 'No fijar',
      valueAggregation: 'Value Aggregation',
      autosizeThiscolumn: 'Ajustar tamaño de esta columna',
      autosizeAllColumns: 'Ajustar tamaño de todas las columnas',
      groupBy: 'Group by',
      ungroupBy: 'Un-Group by',
      addToValues: 'Add ${variable} to values',
      removeFromValues: 'Remove ${variable} from values',
      addToLabels: 'Add ${variable} to labels',
      removeFromLabels: 'Remove ${variable} from labels',
      resetColumns: 'Reiniciar columnas',
      expandAll: 'Expand All',
      collapseAll: 'Close All',
      copy: 'Copy',
      ctrlC: 'Ctrl+C',
      copyWithHeaders: 'Copy With Headers',
      copyWithGroupHeaders: 'Copy with Group Headers',
      paste: 'Paste',
      ctrlV: 'Ctrl+V',
      export: 'Export',
      csvExport: 'CSV Export',
      excelExport: 'Excel Export',

      // Enterprise Menu Aggregation and Status Bar
      sum: 'Sum',
      min: 'Min',
      max: 'Max',
      none: 'None',
      count: 'Count',
      avg: 'Average',
      filteredRows: 'Filtered',
      selectedRows: 'Selected',
      totalRows: 'Total Rows',
      totalAndFilteredRows: 'Rows',
      more: 'More',
      to: 'to',
      of: 'of',
      page: 'Page',
      nextPage: 'Next Page',
      lastPage: 'Last Page',
      firstPage: 'First Page',
      previousPage: 'Previous Page',

      // Pivoting
      pivotColumnGroupTotals: 'Total',

      // Enterprise Menu (Charts)
      pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
      pivotChart: 'Pivot Chart',
      chartRange: 'Chart Range',

      columnChart: 'Column',
      groupedColumn: 'Grouped',
      stackedColumn: 'Stacked',
      normalizedColumn: '100% Stacked',

      barChart: 'Bar',
      groupedBar: 'Grouped',
      stackedBar: 'Stacked',
      normalizedBar: '100% Stacked',

      pieChart: 'Pie',
      pie: 'Pie',
      doughnut: 'Doughnut',

      line: 'Line',

      xyChart: 'X Y (Scatter)',
      scatter: 'Scatter',
      bubble: 'Bubble',

      areaChart: 'Area',
      area: 'Area',
      stackedArea: 'Stacked',
      normalizedArea: '100% Stacked',

      histogramChart: 'Histogram',

      combinationChart: 'Combination',
      columnLineCombo: 'Column & Line',
      AreaColumnCombo: 'Area & Column',

      // Charts
      pivotChartTitle: 'Pivot Chart',
      rangeChartTitle: 'Range Chart',
      settings: 'Settings',
      data: 'Data',
      format: 'Format',
      categories: 'Categories',
      defaultCategory: '(None)',
      series: 'Series',
      xyValues: 'X Y Values',
      paired: 'Paired Mode',
      axis: 'Axis',
      navigator: 'Navigator',
      color: 'Color',
      thickness: 'Thickness',
      xType: 'X Type',
      automatic: 'Automatic',
      category: 'Category',
      number: 'Number',
      time: 'Time',
      autoRotate: 'Auto Rotate',
      xRotation: 'X Rotation',
      yRotation: 'Y Rotation',
      ticks: 'Ticks',
      width: 'Width',
      height: 'Height',
      length: 'Length',
      padding: 'Padding',
      spacing: 'Spacing',
      chart: 'Chart',
      title: 'Title',
      titlePlaceholder: 'Chart title - double click to edit',
      background: 'Background',
      font: 'Font',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      left: 'Left',
      labels: 'Labels',
      size: 'Size',
      minSize: 'Minimum Size',
      maxSize: 'Maximum Size',
      legend: 'Legend',
      position: 'Position',
      markerSize: 'Marker Size',
      markerStroke: 'Marker Stroke',
      markerPadding: 'Marker Padding',
      itemSpacing: 'Item Spacing',
      itemPaddingX: 'Item Padding X',
      itemPaddingY: 'Item Padding Y',
      layoutHorizontalSpacing: 'Horizontal Spacing',
      layoutVerticalSpacing: 'Vertical Spacing',
      strokeWidth: 'Stroke Width',
      lineDash: 'Line Dash',
      offset: 'Offset',
      offsets: 'Offsets',
      tooltips: 'Tooltips',
      callout: 'Callout',
      markers: 'Markers',
      shadow: 'Shadow',
      blur: 'Blur',
      xOffset: 'X Offset',
      yOffset: 'Y Offset',
      lineWidth: 'Line Width',
      normal: 'Normal',
      bold: 'Bold',
      italic: 'Italic',
      boldItalic: 'Bold Italic',
      predefined: 'Predefined',
      fillOpacity: 'Fill Opacity',
      strokeOpacity: 'Line Opacity',
      histogramBinCount: 'Bin count',
      columnGroup: 'Column',
      barGroup: 'Bar',
      pieGroup: 'Pie',
      lineGroup: 'Line',
      scatterGroup: 'X Y (Scatter)',
      areaGroup: 'Area',
      histogramGroup: 'Histogram',
      combinationGroup: 'Combination',
      groupedColumnTooltip: 'Grouped',
      stackedColumnTooltip: 'Stacked',
      normalizedColumnTooltip: '100% Stacked',
      groupedBarTooltip: 'Grouped',
      stackedBarTooltip: 'Stacked',
      normalizedBarTooltip: '100% Stacked',
      pieTooltip: 'Pie',
      doughnutTooltip: 'Doughnut',
      lineTooltip: 'Line',
      groupedAreaTooltip: 'Area',
      stackedAreaTooltip: 'Stacked',
      normalizedAreaTooltip: '100% Stacked',
      scatterTooltip: 'Scatter',
      bubbleTooltip: 'Bubble',
      histogramTooltip: 'Histogram',
      columnLineComboTooltip: 'Column & Line',
      areaColumnComboTooltip: 'Area & Column',
      customComboTooltip: 'Custom Combination',
      noDataToChart: 'No data available to be charted.',
      pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
      chartSettingsToolbarTooltip: 'Menu',
      chartLinkToolbarTooltip: 'Linked to Grid',
      chartUnlinkToolbarTooltip: 'Unlinked from Grid',
      chartDownloadToolbarTooltip: 'Download Chart',
      seriesChartType: 'Series Chart Type',
      seriesType: 'Series Type',
      secondaryAxis: 'Secondary Axis',

      // ARIA
      ariaChecked: 'checked',
      ariaColumn: 'Column',
      ariaColumnGroup: 'Column Group',
      ariaColumnList: 'Column List',
      ariaColumnSelectAll: 'Toggle Select All Columns',
      ariaDateFilterInput: 'Date Filter Input',
      ariaDefaultListName: 'List',
      ariaFilterColumnsInput: 'Filter Columns Input',
      ariaFilterFromValue: 'Filter from value',
      ariaFilterInput: 'Filter Input',
      ariaFilterList: 'Filter List',
      ariaFilterToValue: 'Filter to value',
      ariaFilterValue: 'Filter Value',
      ariaFilteringOperator: 'Filtering Operator',
      ariaHidden: 'hidden',
      ariaIndeterminate: 'indeterminate',
      ariaInputEditor: 'Input Editor',
      ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
      ariaRowDeselect: 'Press SPACE to deselect this row',
      ariaRowSelectAll: 'Press Space to toggle all rows selection',
      ariaRowToggleSelection: 'Press Space to toggle row selection',
      ariaRowSelect: 'Press SPACE to select this row',
      ariaSearch: 'Buscar',
      ariaSortableColumn: 'Press ENTER to sort',
      ariaToggleVisibility: 'Press SPACE to toggle visibility',
      ariaUnchecked: 'unchecked',
      ariaVisible: 'visible',
      ariaSearchFilterValues: 'Search filter values',

      // ARIA Labels for Drop Zones

      ariaRowGroupDropZonePanelLabel: 'Row Groups',
      ariaValuesDropZonePanelLabel: 'Values',
      ariaPivotDropZonePanelLabel: 'Column Labels',
      ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
      ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
      ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
      // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
      ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
      ariaDropZoneColumnComponentSortAscending: 'ascending',
      ariaDropZoneColumnComponentSortDescending: 'descending',

      // ARIA Labels for Dialogs
      ariaLabelColumnMenu: 'Column Menu',
      ariaLabelCellEditor: 'Cell Editor',
      ariaLabelDialog: 'Dialog',
      ariaLabelSelectField: 'Select Field',
      ariaLabelTooltip: 'Tooltip',
      ariaLabelContextMenu: 'Context Menu',
      ariaLabelSubMenu: 'SubMenu',
      ariaLabelAggregationFunction: 'Aggregation Function',

      // Number Format (Status Bar, Pagination Panel)
      thousandSeparator: ',',
      decimalSeparator: '.',
    },
    /**
     * ORDER ASCENDING FROM HERE
     */
    'bde-filter': 'Bde',
    'billing-info': 'Datos de facturación',
    'code-management': 'Gestión de códigos',
    'commercial-manager-user': 'Usuario gestor comercial',
    'delivered-to-client': 'Entregadas al cliente',
    'payment-summary': 'Resumen de pagos',
    'process-manager-user': 'Usuario gestor de procesos',
    'quality-control': 'Control de calidad',
    'recived-from-register': 'Recibidas del registro de propiedades',
    'requester-companies': 'Prescriptores',
    'sent-to-register': 'Enviadas al registro de propiedades',
    'simple-notes': 'Notas simples',
    'other-orders': 'Otros encargos',
    avm: 'AVM',
    'supplier-companies': 'Sociedades de tasación',
    'technical-manager-user': 'Usuario gestor de técnicos',
    'users-credentials': 'Usuarios con credenciales',
    'validation-finished': 'Validación finalizada',
    academy: 'Academy',
    acceptancePending: 'Pendiente de aceptación',
    AccessFloor: 'Accesos al edificio',
    ACCOUNT_MANAGEMENT_accountManagement: 'Configuración de la licencia',
    ACCOUNT_MANAGEMENT_active: 'Activo',
    ACCOUNT_MANAGEMENT_activePlan: 'Plan activo',
    ACCOUNT_MANAGEMENT_availableContractPlans: '¿Quieres contratar un plan? ¡Consulta nuestros planes disponibles!',
    ACCOUNT_MANAGEMENT_availablePlans: '¿Quieres cambiar de plan? ¡Consulta nuestros planes disponibles!',
    ACCOUNT_MANAGEMENT_billingData: 'Detalles de facturación',
    ACCOUNT_MANAGEMENT_buyLicense: 'Contratar',
    ACCOUNT_MANAGEMENT_cancelLicense: 'Cancelar licencia',
    ACCOUNT_MANAGEMENT_cancelLicenseDowngrade: 'Anular actualización',
    ACCOUNT_MANAGEMENT_changePlanMessage: 'Se va a proceder a actualizar tu licencia. Se realizará el cargo en el método de pago introducido en tu perfil.',
    ACCOUNT_MANAGEMENT_connectionsNetwork: 'Mi red de conexiones',
    ACCOUNT_MANAGEMENT_contractPlanMessage: '¿Está seguro que desea contratar este plan?',
    ACCOUNT_MANAGEMENT_data: 'Datos',
    ACCOUNT_MANAGEMENT_expired: 'Caducado',
    ACCOUNT_MANAGEMENT_finished: 'Caducó el',
    ACCOUNT_MANAGEMENT_invoices: 'Historial de facturación',
    ACCOUNT_MANAGEMENT_invoicesHistory: 'Historial de facturas',
    ACCOUNT_MANAGEMENT_licenseDetail: 'Detalle de la licencia',
    ACCOUNT_MANAGEMENT_monthActiveUsers: 'usuarios activos este mes',
    ACCOUNT_MANAGEMENT_moreInfo: 'Más información',
    ACCOUNT_MANAGEMENT_nextBilling: 'Próxima facturación el',
    ACCOUNT_MANAGEMENT_nextBillingEstimation: 'Estimación próximo cobro',
    ACCOUNT_MANAGEMENT_paymentHistory: 'Historial de pagos',
    ACCOUNT_MANAGEMENT_paymentMethods: 'Método de pago',
    ACCOUNT_MANAGEMENT_paymentPlan: 'Plan de pago',
    ACCOUNT_MANAGEMENT_payMethodToAddAppraisalMessage: 'Recuerda que para dar de alta una nueva valoración debes haber introducido un método de pago.',
    ACCOUNT_MANAGEMENT_priceNotIncludeVAT: '*El precio no incluye IVA.',
    ACCOUNT_MANAGEMENT_profile: 'Configuración de Perfil',
    ACCOUNT_MANAGEMENT_reportCustomization: 'Configuración del informe',
    ACCOUNT_MANAGEMENT_rollbackPlanMessage: '¿Está seguro de que desea cancelar la actualización de plan?',
    ACCOUNT_MANAGEMENT_totalWithoutTaxes: 'Total sin impuestos',
    ACCOUNT_MANAGEMENT_totalWithTaxes: 'Total con impuestos',
    ACCOUNT_MANAGEMENT_trialPeriod: 'Período de prueba',
    ACCOUNT_MANAGEMENT_trialPeriodIsActive: 'El período de prueba de su licencia está activo.',
    ACCOUNT_MANAGEMENT_trialPeriodIsExpired: 'Tu licencia de prueba ha caducado, introduce un método de pago valido y contrata uno de nuestros planes para seguir disfrutando de Tasafy.',
    ACCOUNT_MANAGEMENT_willEnd: 'Caducará el',
    ACCOUNT_MANAGEMENT_willNotLongerStayActive: 'Dejará de estar activo el',
    ACCOUNT_MANAGEMENT_willStartActive: 'Entrará en vigor el',
    account_number_for_payments: 'Número de cuenta bancaria para el pago',
    ActiveLicensePlan: 'Plan actual',
    actives: 'Activos',
    ADD_ASSET_addAsset: 'Añadir activo',
    ADD_ASSET_addNewAssetToRepositoryOption: 'Seleccione esta opción si quiere añadir un elemento no existente en el repositorio de activos',
    ADD_ASSET_impossibleToMap: 'No se ha podido mapear la información de catastro, debe rellenarse manualmente algunos datos',
    ADD_ASSET_modifyStructure: 'Modificar estructura del objeto de tasación',
    ADD_ASSET_searchAssetInRepositoryOption: 'Seleccione esta opción para buscar un elemento en el repositorio de activos',
    ADD_ASSET_selectAsset: 'Seleccionar activo',
    add_backtesting: 'Añadir Backtesting',
    add_batch: 'Añadir lote',
    ADD_NEW_ASSET_FORM_add: 'Añadir',
    ADD_NEW_ASSET_FORM_addByCadastralRefError: 'No se ha podido encontrar ningun activo',
    ADD_NEW_ASSET_FORM_addByCadastralRefSuccess: 'Se ha obtenido la información de catastro satisfactoriamente',
    ADD_NEW_ASSET_FORM_addManualy: 'Añadir manualmente',
    ADD_NEW_ASSET_FORM_externalSource: 'Fuente externa',
    ADD_NEW_ASSET_FORM_Block: 'Bloque',
    ADD_NEW_ASSET_FORM_book: 'Libro',
    ADD_NEW_ASSET_FORM_cadastralReference: 'Referencia catastral',
    ADD_NEW_ASSET_FORM_ConstructionYear: 'Antigüedad',
    ADD_NEW_ASSET_FORM_coordinatesOption: 'COORDENADAS',
    ADD_NEW_ASSET_FORM_Floor: 'Piso',
    ADD_NEW_ASSET_FORM_Gate: 'Puerta',
    ADD_NEW_ASSET_FORM_Identification: 'Identificación',
    ADD_NEW_ASSET_FORM_idufir: 'C.R.U (IDUFIR)',
    ADD_NEW_ASSET_FORM_industrialEstate: 'Polígono',
    ADD_NEW_ASSET_FORM_inscription: 'Inscripción',
    ADD_NEW_ASSET_FORM_location: 'Localización',
    ADD_NEW_ASSET_FORM_municipality: 'Municipio',
    ADD_NEW_ASSET_FORM_number: 'Número',
    ADD_NEW_ASSET_FORM_paper: 'Folio',
    ADD_NEW_ASSET_FORM_place: 'Paraje',
    ADD_NEW_ASSET_FORM_plot: 'Parcela',
    ADD_NEW_ASSET_FORM_polygonPlotOption: 'POLÍGONO/PARCELA',
    ADD_NEW_ASSET_FORM_postalCode: 'Código postal',
    ADD_NEW_ASSET_FORM_propertyRegister: 'Registro propiedad',
    ADD_NEW_ASSET_FORM_propertyRegisterNumber: 'Numero de finca registral',
    ADD_NEW_ASSET_FORM_province: 'Provincia',
    ADD_NEW_ASSET_FORM_realState: 'Finca',
    ADD_NEW_ASSET_FORM_region: 'Comarca',
    ADD_NEW_ASSET_FORM_Stairs: 'Escalera',
    ADD_NEW_ASSET_FORM_streeDoseNotExist: 'No está en el callejero',
    ADD_NEW_ASSET_FORM_streetName: 'Nombre vía',
    ADD_NEW_ASSET_FORM_streetNumberOption: 'CALLE/NÚMERO',
    ADD_NEW_ASSET_FORM_Surface: 'Superficie',
    ADD_NEW_ASSET_FORM_SurfaceAndAntiquity: 'Surperficie y antigüedad',
    ADD_NEW_ASSET_FORM_Typologies: 'Tipología',
    ADD_NEW_ASSET_FORM_Use: 'Uso',
    ADD_NEW_ASSET_FORM_volume: 'Tomo',
    add_new_prescriptor: '+ Añadir nuevo prescriptor',
    add_new_professional: '+ Añadir nuevo profesional',
    add_new: 'Añadir nuevo',
    add_prescriber: 'Añadir prescriptor',
    add: 'Añadir',
    addAppraisalToInvoiceWarningAutoGlobal: 'El importe de la factura será el indicado después la selección de las valoraciones.',
    addAppraisalToInvoiceWarningAutoIndividual: 'El importe de la factura será el sumatorio de los importes confirmados de las valoraciones seleccionadas.',
    addAppraisalToInvoiceWarningManual: 'Los datos de facturación serán incluidos de forma manual después de la selección de las valoraciones.',
    addCustomer: 'Añadir cliente',
    addedDateTime: 'Fecha de creación',
    AddedDateTime: 'Fecha de creación',
    addedUserFullName: 'Creado por',
    addIncompatibilities: 'Añadir incompatibilidades',
    address: 'Dirección',
    Address: 'Dirección',
    AddressString: 'Direccion',
    addValorationToRequestInvoice: 'Añadir valoración a factura',
    ADMIN_DASHBOARD_activeLicensees: 'Licencias activas',
    ADMIN_DASHBOARD_dataReport: 'Informe de datos',
    ADMIN_DASHBOARD_finishedAppraisals: 'Tasaciones finalizadas',
    ADMIN_DASHBOARD_inactiveLicensees: 'Licencias inactivas',
    ADMIN_DASHBOARD_licensees: 'Licencias totales',
    ADMIN_DASHBOARD_licensesPerLicense: 'Licencias por plan contratado',
    ADMIN_DASHBOARD_numberOfActiveLicensees: 'Nº licencias activas',
    ADMIN_DASHBOARD_numberOfInactiveLicensees: 'Nº licencias inactivas',
    ADMIN_DASHBOARD_numberOfLicensees: 'Nº licencias totales',
    ADMIN_DASHBOARD_recordsNumber: 'Nº de registros',
    ADMIN_DASHBOARD_reportsPerLicense: 'Informes por plan y licencia',
    ADMIN_DASHBOARD_startedAppraisals: 'Tasaciones comenzadas',
    ADMIN_signInDate: 'Fecha de registro',
    admin: 'Administrador',
    airConditioning: 'Aire acondicionado',
    alive: 'Altas vivas',
    all: 'Todas',
    All: 'Todos',
    allInvoiced: 'Facturado totalmente',
    allowed_roles: 'Roles permitidos',
    allTranslatesEditedCorrectly: 'Todas las traducciones editadas correctamente',
    amount: 'Total',
    amountsSummary: 'Resumen de importes',
    an_error_has_ocurred: 'Ha ocurrido un error',
    APPRAISAL_addNewPortfolio: 'Añadir cartera',
    APPRAISAL_allAssets: 'Todos los activos',
    APPRAISAL_appraisalCancelled: 'Encargo anulado',
    APPRAISAL_appraisalData: 'Datos de la tasación',
    APPRAISAL_DASHBOARD_advancedFilters: 'Filtros',
    APPRAISAL_DASHBOARD_claimsInAppraisalToBeValidated: 'Reclamaciones en informes por validar',
    APPRAISAL_DASHBOARD_claimsInAppraisalToRecive: 'Reclamaciones en informes por recibir',
    APPRAISAL_DASHBOARD_filesToModifie: 'Expedientes por validar',
    APPRAISAL_DASHBOARD_validationTime: 'Tiempo de validación',
    APPRAISAL_DASHBOARD_filesToRecive: 'Expedientes por recibir',
    APPRAISAL_DASHBOARD_managementTime: 'Tiempo de gestión',
    APPRAISAL_DASHBOARD_FILTER_advancedFilters: 'Filtros',
    APPRAISAL_DASHBOARD_filterBy: 'Filtrar por: ',
    APPRAISAL_DASHBOARD_goToClaims: 'Ir a reclamaciones',
    APPRAISAL_DASHBOARD_goToIncidents: 'Ir a incidentes',
    APPRAISAL_DASHBOARD_goToPendingToDeliverReport: 'Ir a pendiente de entrega de informe',
    APPRAISAL_DASHBOARD_goToPendingToSchedule: 'Ir a pendiente de agendar',
    APPRAISAL_DASHBOARD_greeting: 'Buenos días, ',
    APPRAISAL_DASHBOARD_incidenceInReportsToBeValidated: 'Incidencias en informes por validar',
    APPRAISAL_DASHBOARD_lateReportDelivery: 'Informe pendiente de entrega fuera de plazo',
    APPRAISAL_DASHBOARD_lateSchedulePending: 'Encargos pendientes de agendar visita fuera de plazo',
    APPRAISAL_DASHBOARD_recordState: 'Estado actual de los expedientes',
    APPRAISAL_DASHBOARD_reportDeliverPending: 'Pendiente de entrega de informe',
    APPRAISAL_DASHBOARD_resolvedByAppraiser: 'resueltas por tasador',
    APPRAISAL_DASHBOARD_reviewsInAppraisalToBeValidated: 'Revisiones en informes por validar',
    APPRAISAL_DASHBOARD_reviewsInAppraisalToRecive: 'Revisiones en informes por recibir',
    APPRAISAL_DASHBOARD_reviewSummary: 'Informes con revisiones',
    APPRAISAL_DASHBOARD_schedulePending: 'Pendiente de agendar',
    APPRAISAL_DASHBOARD_summaryOfClaims: 'Informes con reclamaciones',
    APPRAISAL_DASHBOARD_summaryOfFinishedFiles: 'Resumen de expedientes finalizados',
    APPRAISAL_DASHBOARD_summaryOfIncidence: 'Informes con incidencias',
    APPRAISAL_DASHBOARD_summaryOfUnmodifiedFiles: 'Resumen de expedientes sin finalizar',
    APPRAISAL_DASHBOARD_ToRecive: 'Por recibir',
    APPRAISAL_DASHBOARD_toResolve: 'Por resolver',
    APPRAISAL_DASHBOARD_totalOrders: 'Total de encargos: ',
    APPRAISAL_DASHBOARD_toValidate: 'Por validar',
    APPRAISAL_DETAILS_orderCanceled: 'Encargo anulado',
    APPRAISAL_disclaimer: 'Te recordamos que como solicitante de la tasación, eres responsable de haber informado a tu cliente de que sus datos serán cedidos a Instituto de Valoraciones S.A. con la finalidad de ejecutar el servicio contratado.',
    APPRAISAL_lastNotificationError: 'Último envío KO',
    APPRAISAL_lastNotificationSuccesful: 'Último envío OK',
    APPRAISAL_REPORT_createTerrainCluster: 'Crear agrupación de terrenos',
    APPRAISAL_REPORT_dateAndTime: 'Fecha y hora',
    APPRAISAL_REPORT_fullName: 'Nombre y apellidos',
    APPRAISAL_REPORT_infoData: 'Datos del informe',
    APPRAISAL_VALUATION_addWitness: 'Añadir testigo',
    APPRAISAL_VALUATION_createWitness: 'Crear testigo',
    APPRAISAL_VALUATION_addMarketStudy: 'Añadir estudio de mercado',
    APPRAISAL_VALUATION_NotMarketStudyList: 'No hay ningún estudio de mercado para poder añadir en este método',
    APPRAISAL_VALUATION_removeWitnessList: 'Quitar del listado',
    APPRAISAL_VALUATION_totalInfoMessage: 'este valor proviene del método de máximo legal',
    APPRAISAL_VALUATION_marketStudy: 'Estudio de mercado',
    APPRAISAL_VALUATION_editMarketStudyTitle: 'Modificación estudio mercado',
    APPRAISAL_VALUATION_editMarketStudySubtitle: 'Las modificaciones realizadas en el método dan lugar a un nuevo estudio de mercado. ¿Desea que estas modificaciones se apliquen al estudio de mercado existente o den lugar a uno nuevo?',
    APPRAISAL_VALUATION_editMarketStudySubtitleOnlyCreate: 'Las modificaciones realizadas en el método dan lugar a un nuevo estudio de mercado.',
    APPRAISAL_VALUATION_editMarketStudyConfirm: 'Modificar existente',
    APPRAISAL_VALUATION_editMarketStudyNew: 'Crear nuevo',
    APPRAISAL_VALUATION_saveMethodInfo: 'Para poder guardar el método debe añadir al menos un testigo.',
    APPRAISAL_VALUATION_infoMigration: '* Este método ha sido generado desde una migración y no puede ser editado',
    APPRAISAL_value: 'Valor tasación',
    APPRAISAL_valueWithSuffix: 'Valor tasación ({{currency}})',
    appraisalAverageTimeDashboardTitle: 'Tiempo medio entre etapas en expedientes finalizados',
    appraisalInExchange: 'Esta valoración se encuentra actualmente en la bolsa de encargos.',
    APPRAISALS_add_new_appraisal: 'Añadir nuevo encargo',
    APPRAISALS_addDocument: 'Añadir documento',
    APPRAISALS_addedMaxDateTime: 'Añadido fecha fin',
    APPRAISALS_addedMinDateTime: 'Añadido fecha inicio',
    APPRAISALS_addInvoiceToProfessional: 'Añadir factura al técnico',
    APPRAISALS_addMessage: 'Añadir mensaje',
    APPRAISALS_addNewAsset: '+ Añadir nuevo activo',
    APPRAISALS_addNewCustomer: '+ Añadir nuevo cliente',
    APPRAISALS_addNewPrescriber: '+ Añadir nuevo prescriptor',
    APPRAISALS_addProcessManager: 'Asignar responsable del proceso',
    APPRAISALS_address: 'Dirección',
    APPRAISALS_addTechnicalManager: 'Asignar Responsable del Dpto. Técnico',
    APPRAISALS_addVisitDate: 'Añadir fecha de visita',
    APPRAISALS_allFields: 'Todos los campos rellenos al',
    APPRAISALS_amount: 'Importe',
    APPRAISALS_appraisalDocuments: 'Documentos de la tasación',
    APPRAISALS_appraisalIsInExchange: 'Esta valoración se encuentra actualmente en la bolsa de encargos.',
    APPRAISALS_appraisalStays: 'Estancias de la valoración',
    APPRAISALS_appraiser: 'Tasador',
    APPRAISALS_appraiserAllDataSentDate: 'Fecha de entrega',
    APPRAISALS_appraiserDatasent: 'Informe entregado',
    APPRAISALS_appraiserDataSent: 'Informe entregado',
    APPRAISALS_appraiserDataSentDeadline: 'Límite para entregar el informe',
    APPRAISALS_appraiserUserFullName: 'Técnico',
    APPRAISALS_asset: 'Activo',
    APPRAISALS_assetAssign: 'Asignar activo',
    APPRAISALS_assetDocuments: 'Documentos del activo',
    APPRAISALS_importDocuments: 'Seleccionar toda la documentación',
    APPRAISALS_dataDocuments: 'Fecha de emisión',
    APPRAISALS_orderDocuments: '*No será necesario subir los documentos de forma ordenada, ya que éstos llevarán un orden preestablecido por el sistema en el informe según el Tipo de documento elegido.',
    APPRAISALS_assetStays: 'Estancias de activo',
    APPRAISALS_assignedUser: 'Usuario asignado',
    APPRAISALS_assignmentBag: 'Bolsa de asignación',
    APPRAISALS_assignmentBagStatus: 'Estado en la bolsa',
    APPRAISALS_assignmentBagTimeLeft: 'Tiempo restante en la bolsa',
    APPRAISALS_assignmentBagTimeLeftInMin: 'Tiempo restante en bolsa (minutos)',
    APPRAISALS_attachDocument: '+ Adjuntar',
    APPRAISALS_attaching: 'Adjuntando...',
    APPRAISALS_basicFilters: 'Filtros básicos',
    APPRAISALS_billedAt: 'Facturado en: ',
    APPRAISALS_billFuture: 'Será facturado en: ',
    APPRAISALS_budgetNumber: 'Número de presupuesto',
    APPRAISALS_by: 'por',
    APPRAISALS_cancel: 'Cancelar',
    APPRAISALS_cancelAppraisal: 'Anular encargo',
    APPRAISALS_cancelationReason: 'Motivo de la anulación',
    APPRAISALS_cancelled: 'Anuladas',
    APPRAISALS_cancelledDate: 'Fecha de anulación',
    APPRAISALS_changeUser: 'Cambiar usuario',
    APPRAISALS_chooseAppraiser: 'Seleccionar profesional',
    APPRAISALS_chooseCustomer: 'Seleccionar un cliente',
    APPRAISALS_chooseExecutionMode: 'Modo de ejecución de la valoración',
    APPRAISALS_chooseProcessManager: 'Seleccionar usuario gestor de procesos',
    APPRAISALS_chooseTechnicalManager: 'Seleccionar usuario gestor de técnicos',
    APPRAISALS_claimDate: 'Fecha de reclamación',
    APPRAISALS_claims: 'Reclamaciones',
    APPRAISALS_clientComplaint: 'Reclamación Cliente',
    APPRAISALS_coincidence: 'Coincidencia',
    APPRAISALS_comesFromRequest: '¿Viene de una solicitud?',
    APPRAISALS_commercialTechnicalsFee: 'Honorarios suplidos',
    APPRAISALS_confirm_expenses: 'Confirmar los gastos',
    APPRAISALS_confirmCoeficientChange: 'Si modifica el tipo de homogenización, perderá los datos relativos a esta incluidos hasta el momento ¿desea continuar?',
    APPRAISALS_confirmed_amount: 'Importes/Gastos confirmados',
    APPRAISALS_contactVisitData: 'Datos de contacto para la visita',
    APPRAISALS_copyAppraisal: 'Copiar encargo',
    APPRAISALS_createdAt: 'Creada a las',
    APPRAISALS_createIncident: 'Crear incidencia',
    APPRAISALS_creationDate: 'Fecha de creación',
    APPRAISALS_pointerTerm: 'Nota plazo',
    APPRAISALS_executionNote: 'Nota ejecución',
    APPRAISALS_customer: 'Cliente',
    APPRAISALS_customerDeliverables: 'Entregables a cliente',
    APPRAISALS_customerName: 'Nombre / Razón social',
    APPRAISALS_date: 'Fecha',
    APPRAISALS_dateDataSent: 'Informe entregado',
    APPRAISALS_dateOfIssue: 'Fecha de emisión',
    APPRAISALS_dateSendDataDeadline: 'Límite para entregar el informe',
    APPRAISALS_dateVisitScheduleDeadline: 'Límite para agendar',
    APPRAISALS_deadlineDate: 'Fecha de cierre',
    APPRAISALS_delete: 'Eliminar',
    APPRAISALS_deleteMessage: 'Va a eliminar el mensaje, ¿Está seguro?',
    APPRAISALS_deliverDeadlineToCustomer: 'Fecha límite de entrega al cliente',
    APPRAISALS_deliveredToClient: 'Entregada a cliente',
    APPRAISALS_deliverToCustomer: 'Entregar a cliente',
    APPRAISALS_description: 'Descripción',
    APPRAISALS_detail: 'Detalle',
    APPRAISALS_documentAddedTime: 'Añadido en fecha',
    APPRAISALS_documents: 'Documentos',
    APPRAISALS_documentType: 'Tipo de documento',
    APPRAISALS_documentTypes: 'Tipos de documento',
    APPRAISALS_download: 'Descargar',
    APPRAISALS_downloadAll: 'Descargar todo',
    APPRAISALS_downloading: 'Descargando',
    APPRAISALS_downloadFileCadastral: 'Descargar ficha catastral',
    APPRAISALS_downloadFileCadastralSuccess: 'Se ha descargado y adjuntado a la documentacion del activo la ficha catastral',
    APPRAISALS_downloadFileCadastralError: 'No se ha podido descargar y adjuntar el documento',
    APPRAISALS_eco8052003: 'Cumple Eco 805/2003',
    APPRAISALS_edit: 'Editar',
    APPRAISALS_editMessage: 'Editar el mensaje',
    APPRAISALS_email: 'Email',
    APPRAISALS_executed_with_tasafy: 'Seleccione esta opción para crear la valoración con ejecución por Tasafy',
    APPRAISALS_expected_billing_date: 'Fecha prevista facturación',
    APPRAISALS_externalReference: 'Nº referencia externa',
    APPRAISALS_externalTech: 'Profesional',
    APPRAISALS_fieldName: 'Nombre del campo',
    APPRAISALS_fileName: 'Documento',
    APPRAISALS_fileNumber: 'Nº de Expediente',
    APPRAISALS_finished: 'Finalizadas',
    APPRAISALS_finishedDate: 'Fecha de finalización',
    APPRAISALS_hasPrescriptor: 'Tiene prescriptor',
    APPRAISALS_hasRelationshipWithAppraisal: '¿Existe una vinculación con otra valoración?',
    APPRAISALS_hasRelationshipWithAppraisalAdditionalOrders: '¿Existe una vinculación con otro trabajo?',
    APPRAISALS_hasRelationship: '¿Existe vinculación?',
    APPRAISALS_homogeneousCoefficientType: 'Típo de homogenización',
    APPRAISALS_importReportFrom: 'Importar informe desde...',
    APPRAISALS_importReportFromSubTitle: 'Solo se puede seleccionar un expediente. Se copiará la estructura de activos y todos los datos introducidos en el informe, excepto documentos y fotografías.',
    APPRAISALS_importFormDataPick: 'Importar desde toma de datos',
    APPRAISALS_infoButtonDataPick: '*El botón Importar desde toma de datos se habilitará cuando la toma de datos se encuentre validada o finalizada.',
    APPRAISALS_importStateDataPick: '*Cuando se importe la documentación el estado del expediente pasará automáticamente a Visita finalizada.',
    APPRAISALS_importStateInfo: '*Los expedientes vinculados de toma de datos no se encuentran validados o finalizados.',
    APPRAISALS_infoNotDocument: '“En la toma de datos no hay ninguna documentación anexa.”',
    APPRAISALS_incidenceCreationDate: 'Fecha de creación de la incidencia',
    APPRAISALS_incidentCode: 'Código de incidencia',
    APPRAISALS_incidentDate: 'Fecha de incidencia ',
    APPRAISALS_incidentId: 'ID de incidencia',
    APPRAISALS_incidents: 'Incidencias',
    APPRAISALS_includeAppraisalInExchange: 'Incluir la solicitud en la bolsa de encargos',
    APPRAISALS_internalReference: 'Nº referencia interna',
    APPRAISALS_internalReferenceShort: 'Nº ref. interna',
    APPRAISALS_INVOICE_commercialTechnicalFee: 'Honorarios Comerciales',
    APPRAISALS_INVOICE_edit: 'Editar datos de facturación',
    APPRAISALS_INVOICE_error: 'Hubo un problema accediendo a los datos de facturación. Por favor refresca la página e inténtalo de nuevo.',
    APPRAISALS_INVOICE_generate: 'Generar o añadir a la factura',
    APPRAISALS_INVOICE_generateWarning: '¿Estás seguro de que quieres generar la factura?',
    APPRAISALS_INVOICE_see: 'Ver factura',
    APPRAISALS_INVOICE_technicalFee: 'Honorarios técnicos',
    APPRAISALS_invoice: 'Factura',
    APPRAISALS_invoicing: 'Facturación',
    APPRAISALS_isAppraiserAllDataSentDeadlineExpired: 'Entrega del informe fuera de plazo',
    APPRAISALS_isCustomerDeadlineExpired: 'Entrega al cliente fuera de plazo',
    APPRAISALS_isExecutedByIvForce: 'Es ejecutado con Tasafy',
    APPRAISALS_isInConfirmedInvoice: 'Tiene la factura confirmada',
    APPRAISALS_IsInvoicedInOrderRequest: 'Facturado',
    APPRAISALS_isSolved: 'Está resuelta',
    APPRAISALS_lastAppraisalDatetime: 'Fecha última tasación',
    APPRAISALS_lastCompanyName: 'Sociedad tasadora',
    APPRAISALS_lastRecordId: 'Nº de expediente anterior ',
    APPRAISALS_lastTotalSurface: 'Superficie adoptada ',
    APPRAISALS_lastUpdatedUser: 'Último editor',
    APPRAISALS_lastValuation: 'Tasación anterior',
    APPRAISALS_lastValuationValue: 'Valor última tasación',
    APPRAISALS_locality: 'Localidad',
    APPRAISALS_markAsDelivered: 'Marcar como entregada',
    APPRAISALS_markAsDeliveredConfirmation: '¿Está seguro de que desea marcar esta tasación como entregada?',
    APPRAISALS_markAsResolved: 'Marcar como resuelta',
    APPRAISALS_MASSIVE_clear: 'Eliminar',
    APPRAISALS_MASSIVE_create: 'Crear nuevo encargo masivo',
    APPRAISALS_MASSIVE_createNew: 'Nueva carga masiva',
    APPRAISALS_MASSIVE_createNewValoration: 'Carga masiva de valoraciones de la solicitud',
    APPRAISALS_MASSIVE_description: 'Por favor, asegurese de haber ',
    APPRAISALS_MASSIVE_drag: 'Arrastre aquí el fichero o ',
    APPRAISALS_MASSIVE_fileInfo: 'Información del fichero procesado',
    APPRAISALS_MASSIVE_goToList: 'Ir a la lista',
    APPRAISALS_MASSIVE_massiveLoadBatches: 'Lotes de carga masiva',
    APPRAISALS_MASSIVE_massiveListBatches: 'Carga de tasaciones',
    APPRAISALS_MASSIVE_process: 'Procesar',
    APPRAISALS_MASSIVE_selectHere: 'Seleccionalo aquí',
    APPRAISALS_MASSIVE_templateDownload: 'descargado la última versión del fichero modelo',
    APPRAISALS_MASSIVE_uploadErrorMsg: 'Hay problemas con la subida del fichero. Consultar el apartado "Información del fichero procesado".',
    APPRAISALS_message: 'Mensaje',
    APPRAISALS_messageBoard: 'Tablón',
    APPRAISALS_noClaims: 'No hay reclamaciones',
    APPRAISALS_noDiferenceWithTheOriginalAsset: 'No hay diferencias con el activo original.',
    APPRAISALS_noIncidents: 'No hay incidencias',
    APPRAISALS_noMessages: 'No hay mensajes',
    APPRAISALS_noRecords: 'No hay registros',
    APPRAISALS_noRevisions: 'No hay revisiones',
    APPRAISALS_noStais: 'Sin estancias',
    APPRAISALS_notGenerateInvoice: 'Generar factura individual de la tasación',
    APPRAISALS_setDailyInvoiced: 'Incluir en el pago automático al profesional a final del día',
    APPRAISALS_notPrint: 'No imprimir',
    APPRAISALS_noValidate: 'No validar',
    APPRAISALS_numberBathrooms: 'Número de baños',
    APPRAISALS_numberRooms: 'Número de habitaciones',
    APPRAISALS_observations: 'Observaciones',
    APPRAISALS_justifyObservationsSurface: 'Justificar origen de la superficie utilizada y del módulo considerado',
    APPRAISALS_observationsMarketValue: 'Observaciones del cálculo del valor de mercado',
    APPRAISALS_of: 'de',
    APPRAISALS_openingDate: 'Fecha de apertura',
    APPRAISALS_orderFeatures: 'Características del encargo',
    APPRAISALS_orderType: 'Tipo de encargo',
    APPRAISALS_Type: 'Tipo de valoración',
    APPRAISALS_informType: 'Tipo informe',
    APPRAISALS_eco: '¿Cumple ECO?',
    APPRAISALS_appraisal: 'Valor de tasación',
    APPRAISALS_visitDate: 'Fecha de visita',
    APPRAISALS_antecedents: '¿Existen antecedentes?',
    APPRAISALS_antecedentsInfo: `Se muestra el listado de tasaciones en el sistema que contienen activos con rasgos en común con los del presente expediente. En función de qué datos se compartan distinguimos tres tipos de antecedentes:
    <ol>
      <li class="mt-2">
        Mismo activo: Si coinciden el IDUFIR, la referencia catastral o la finca y el registro.
      </li>
      <li class="mt-2">
        Mismo edificio: Si coinciden la calle y el N.º o la referencia catastral del edificio..
      </li>
      <li class="mt-2">
        Misma zona: Si coinciden el Uso del inmueble y la distancia entre las coordenadas es menor a 1 km.
      </li>
    </ol>`,
    APPRAISALS_antecedentsInfoAssetCommon: 'Los siguientes expedientes, validados en los últimos 6 meses, contiene activos en común con éste. Revíselos.',
    APPRAISALS_emit: 'Fecha Emisión',
    APPRAISALS_client: 'Cliente',
    APPRAISALS_normativeReference: 'Editar texto finalidades y referencia normativa',
    APPRAISALS_originData: 'Dato origen',
    APPRAISALS_otherExpensesAsociated: 'Gastos asociados',
    APPRAISALS_pendingClaims: 'Reclamaciones pendientes',
    APPRAISALS_pendingIncidents: 'Incidencias pendientes',
    APPRAISALS_phone: 'Teléfono',
    APPRAISALS_portfolios: 'Carteras',
    APPRAISALS_prescribers: 'Prescriptores',
    APPRAISALS_prescriptor: 'Prescriptor',
    APPRAISALS_prescriptorOffice: 'Oficina',
    APPRAISALS_print: 'Imprimir',
    APPRAISALS_processManager: 'Gestor de procesos',
    APPRAISALS_propertyData: 'Datos de la solicitud',
    APPRAISALS_province: 'Provincia',
    APPRAISALS_purpose: 'Finalidad',
    APPRAISALS_purposeBaseValue: 'Finalidad / Base valor',
    APPRAISALS_purposeAppraisal: 'Propósito',
    APPRAISALS_reason: 'Razón',
    APPRAISALS_reasonsComplaint: 'Motivo de la reclamación',
    APPRAISALS_reasonToChooseTech: 'Dinos el motivo de la elección de este tasador:',
    APPRAISALS_recalculateWorkPlan: 'Es necesario recalcular el estado de la obra',
    APPRAISALS_related_expenses: 'Gastos conexos',
    APPRAISALS_relationship: 'Tasaciones vinculadas',
    APPRAISALS_relationshipAppraisalId: 'Valoración relacionada',
    APPRAISALS_relationshipType: 'Tipo de vinculación',
    APPRAISALS_reply: 'Responder',
    APPRAISALS_replyMessage: 'Responder el mensaje',
    APPRAISALS_REPORT_add: '+ Añadir',
    APPRAISALS_REPORT_addAnex: 'Añadir anejo',
    APPRAISALS_REPORT_addChapter: 'Añadir capítulo',
    APPRAISALS_REPORT_addConstruction: 'Añadir construcción',
    APPRAISALS_REPORT_addExpense: 'Añadir gasto',
    APPRAISALS_REPORT_addIncome: 'Añadir ingreso',
    APPRAISALS_REPORT_addNewExpense: 'Añadir nuevo gasto',
    APPRAISALS_REPORT_addNewIncome: 'Añadir nuevo ingreso',
    APPRAISALS_REPORT_addPhoto: 'Añadir foto',
    APPRAISALS_REPORT_addPossession: 'Añadir posesión',
    APPRAISALS_REPORT_address: 'Dirección',
    APPRAISALS_REPORT_addValuation: 'Añadir valoración',
    APPRAISALS_REPORT_adoptedSurface: 'Superficie adoptada ({{surfaceUnit}})',
    APPRAISALS_REPORT_adoptedSurfaceType: 'Tipo superficie adoptada',
    APPRAISALS_REPORT_adoptedSurfaceHeaderType: 'Tipo superficie adoptada global',
    APPRAISALS_REPORT_application: 'Aplicación',
    APPRAISALS_REPORT_appraisalAssetBuildingState: 'Estado de la obra',
    APPRAISALS_REPORT_appraisalAssetLogChanges: 'Registro de cambios',
    APPRAISALS_REPORT_appraisalCommonData: 'Datos comunes del informe',
    APPRAISALS_REPORT_appraisalObject: 'Objecto de valoración',
    APPRAISALS_REPORT_appraise: 'Valorar',
    APPRAISALS_REPORT_assetDeleteWarning: 'Está seguro de querer eliminar este elemento de la agrupación ?',
    APPRAISALS_REPORT_assigned: 'Asignado ({{currency}})',
    APPRAISALS_REPORT_assignedValue: 'Valor asignado ({{currency}})',
    APPRAISALS_REPORT_automaticInfoWarning: 'Al guardar el formulario se recuperarán los datos resaltados en naranja en base a la localización, perdiendo los datos editados manualmente.',
    APPRAISALS_REPORT_buildability: 'Edificabilidad',
    APPRAISALS_REPORT_buildabilityM2: 'Edificabilidad ({{surfaceUnit}})',
    APPRAISALS_REPORT_buildable: 'Edificabilidad',
    APPRAISALS_REPORT_building_caracteristics_economic_message: 'El inmueble ha agotado su vida útil, por lo tanto, no es susceptible de generar ninguna actividad económica y el método de Actualización ILEE se eliminará.',
    APPRAISALS_REPORT_buildingCharacteristics: 'Características del edificio',
    APPRAISALS_REPORT_buildingElementWarning: 'Se van a recalcular los parámetros estimados del elemento a construir dentro del cálculo del valor de mercado. Es necesario revisar.',
    APPRAISALS_REPORT_builtWithCommonAreas: 'Construida con zonas comunes',
    APPRAISALS_REPORT_builtWithoutCommonAreas: 'Construida sin zonas comunes',
    APPRAISALS_REPORT_cadastralReference: 'Referencia catastral',
    APPRAISALS_REPORT_calculateMarketValue: 'Calculo de valor de mercado',
    APPRAISALS_REPORT_calculationMethod: 'Método de cálculo',
    APPRAISALS_REPORT_calculations: 'Cálculos',
    APPRAISALS_REPORT_cancel: 'Cancelar',
    APPRAISALS_REPORT_capitalGain: 'Plusvalía/minusvalía del suelo',
    APPRAISALS_REPORT_cashflows_observations: '* Únicamente será necesario hacer el reparto de los Ingresos/Gastos que se hayan añadido con la Tipología No Periódica.',
    APPRAISALS_REPORT_cashflows_tooltip: 'Los Flujos de Caja muestran las cuantías totales de Ingresos y Gastos generados por la Explotación Económica sin aplicación del porcentaje relativo a la Renta imputada al inmueble.',
    APPRAISALS_REPORT_cashflows: 'Flujos de caja',
    APPRAISALS_REPORT_checkValidations: 'Comprobaciones',
    APPRAISALS_REPORT_cluster: 'Agrupación',
    APPRAISALS_REPORT_clusterItems: 'Unidades de la agrupación',
    APPRAISALS_REPORT_clusters: 'Agrupaciones',
    APPRAISALS_REPORT_comparisonCalculation: 'Cálculo de comparación',
    APPRAISALS_REPORT_comparisonForConstructionHypotheses: 'Comparación para hipotesis de construcción',
    APPRAISALS_REPORT_conditioningLabel: 'Condicionantes, advertencias y observaciones',
    APPRAISALS_REPORT_constructionCosts: 'Costes de construcción',
    APPRAISALS_REPORT_constructionCostsWithUnits: 'Costes de construcción ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_constructionHypothesis: 'Hipótesis de construcción',
    APPRAISALS_REPORT_contractDocumentation: 'Documentación que acredita el estado de ocupación',
    APPRAISALS_REPORT_country: 'País',
    APPRAISALS_REPORT_createdBy: 'Creado por',
    APPRAISALS_REPORT_createResidualDynamicMethod: 'Crear método residual dinámico',
    APPRAISALS_REPORT_criteria: 'Criterio',
    APPRAISALS_REPORT_currentValuationTotalValue: 'valoración total actual',
    APPRAISALS_REPORT_dataSourse: 'Origen de los datos',
    APPRAISALS_REPORT_dateRange: 'Plazo',
    APPRAISALS_REPORT_delete: 'Eliminar',
    APPRAISALS_REPORT_description: 'Descripción',
    APPRAISALS_REPORT_dinamicResidualMethodValue: 'Valor método residual dinámico',
    APPRAISALS_REPORT_dni: 'DNI',
    APPRAISALS_REPORT_documents: 'Documentación',
    APPRAISALS_REPORT_doMain: 'Hacer imagen principal de la valoración',
    APPRAISALS_REPORT_doMainElement: 'Hacer elemento principal',
    APPRAISALS_REPORT_door: 'Puerta',
    APPRAISALS_REPORT_duplicate: 'Duplicar',
    APPRAISALS_REPORT_economicData: 'Datos económicos',
    APPRAISALS_REPORT_economicExploitationData: 'Datos de explotación económica',
    APPRAISALS_REPORT_economicExploitationUpdateDatesMessage: 'Cualquier modificación de la fechas, una vez añadidos ingresos y gastos, implicará un reajuste de estos.',
    APPRAISALS_REPORT_economicParameters: 'Parámetros económicos',
    APPRAISALS_REPORT_edit: 'Editar',
    APPRAISALS_REPORT_editAutomaticInfo: 'Editar información automática',
    APPRAISALS_REPORT_editFields: 'Editar campos',
    APPRAISALS_REPORT_editFinisDateTime: 'Editar fecha fin',
    APPRAISALS_REPORT_editOrDelete: 'Puedes valorar o borrar esta agrupación',
    APPRAISALS_REPORT_editPhoto: 'Editar foto',
    APPRAISALS_REPORT_editStartDateTime: 'Editar fecha de inicio',
    APPRAISALS_REPORT_elementSurface: 'Superficie estimada del elemento ({{surfaceUnit}})',
    APPRAISALS_REPORT_entrance: 'Portal',
    APPRAISALS_REPORT_euroValue: 'Valor {{currency}}',
    APPRAISALS_REPORT_everyAssetMustHaveAValidSurface: 'Comprobar que los siguientes activos tienen al menos una superficie válida',
    APPRAISALS_REPORT_expense: 'Gasto',
    APPRAISALS_REPORT_expenses_obserservations: 'Observaciones sobre gastos',
    APPRAISALS_REPORT_expenses: 'Gastos',
    APPRAISALS_REPORT_exploitationType: 'Tipo de explotación',
    APPRAISALS_REPORT_extract: 'Extraer',
    APPRAISALS_REPORT_finish: 'Terminar',
    APPRAISALS_REPORT_finishDateTime: 'Fecha fin',
    APPRAISALS_REPORT_floor: 'Planta',
    APPRAISALS_REPORT_futureValuationTotalValue: 'valoración total H.E.T.',
    APPRAISALS_REPORT_identification: 'Identificación',
    APPRAISALS_REPORT_idufir: 'C.R.U.(IDUFIR)',
    APPRAISALS_REPORT_imputedIncome: 'Renta imputada',
    APPRAISALS_REPORT_imputedIncomePercentage: '% Renta imputada al inmueble',
    APPRAISALS_REPORT_income: 'Ingresos',
    APPRAISALS_REPORT_incomes_obserservations: 'Observaciones sobre ingresos',
    APPRAISALS_REPORT_incomes: 'Ingresos',
    APPRAISALS_REPORT_incomesExpensesDifference: 'Ingresos - Gastos',
    APPRAISALS_REPORT_incomeType: 'Tipo de ingreso',
    APPRAISALS_REPORT_initialParameters: 'Parámetros iniciales',
    APPRAISALS_REPORT_insurance: 'Cálculo de valor del seguro respecto a:',
    APPRAISALS_REPORT_insuranceByEco: 'seguro según ECO',
    APPRAISALS_REPORT_insuranceByRoyalDecree: 'seguro según R.D.',
    APPRAISALS_REPORT_isCapitalGainPercentEdited: 'Editar plusvalía/minusvalía del suelo',
    APPRAISALS_REPORT_isIncomeImputedToPropertyPercentEdited: 'Editar % Renta imputada al inmueble',
    APPRAISALS_REPORT_isRecurrent: '¿Es periódico?',
    APPRAISALS_REPORT_isReversionValueEdited: 'Editar valor de reversion',
    APPRAISALS_REPORT_landDescription: 'Descripción y superficie del terreno',
    APPRAISALS_REPORT_location: 'Localización',
    APPRAISALS_REPORT_locationAndSurroundings: 'Localidad, entorno y mercado',
    APPRAISALS_REPORT_marketValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_multipleSelection: 'Selección multiple',
    APPRAISALS_REPORT_municipality: 'Municipio',
    APPRAISALS_REPORT_name: 'Nombre',
    APPRAISALS_REPORT_netExpenses: 'Gastos netos',
    APPRAISALS_REPORT_netExpensesAssigned: 'Gastos netos asignados',
    APPRAISALS_REPORT_netIncomensExpensesDiff: 'Ingresos - Gastos',
    APPRAISALS_REPORT_netIncomensExpensesDiffAssigned: 'Ingresos netos - Gastos netos asignados',
    APPRAISALS_REPORT_netIncomes: 'Ingresos netos',
    APPRAISALS_REPORT_netIncomesAssigned: 'Ingresos netos asignados',
    APPRAISALS_REPORT_noDocumentSelected: 'Ningún documento seleccionado',
    APPRAISALS_REPORT_non_lease_document: 'Documento inexistencia de arrendamientos',
    APPRAISALS_REPORT_noPhotos: 'No hay fotos disponibles para este activo',
    APPRAISALS_REPORT_noValorationClusteredAssets: 'Este activo no se puede valorar ya que pertenece a una agrupación',
    APPRAISALS_REPORT_numOfElementsToBuild: 'Nº de elementos a construir',
    APPRAISALS_REPORT_objectOfValuation: 'Objeto de valoración',
    APPRAISALS_REPORT_occupationDniNif: 'DNI/NIF arrendatario',
    APPRAISALS_REPORT_occupationState: 'Estado de ocupación',
    APPRAISALS_REPORT_occupationType: 'Tipo de ocupación',
    APPRAISALS_REPORT_otherMethod: 'Otro método',
    APPRAISALS_REPORT_parameters: 'Parámetros',
    APPRAISALS_REPORT_percentage: 'Porcentaje',
    APPRAISALS_REPORT_periodicity: 'Periodicidad',
    APPRAISALS_REPORT_photos: 'Fotos',
    APPRAISALS_REPORT_plotSurfaces: 'Superficies de parcela',
    APPRAISALS_REPORT_possesionSituation: 'Posesión',
    APPRAISALS_REPORT_possessionType: 'Tipo de posesión',
    APPRAISALS_REPORT_postalCode: 'Código postal',
    APPRAISALS_REPORT_print: 'Incluir en el informe',
    APPRAISALS_REPORT_promoterBenefitsPercent: 'Beneficios del promotor (%)',
    APPRAISALS_REPORT_propertyCaracteristics: 'Características del inmueble',
    APPRAISALS_REPORT_propertyFeatures: 'Características del inmueble',
    APPRAISALS_REPORT_province: 'Provincia',
    APPRAISALS_REPORT_recalculateMethod: 'Recalcular métodos',
    APPRAISALS_REPORT_recalculate: 'Recalcular',
    APPRAISALS_REPORT_recalculating: 'Recalculando',
    APPRAISALS_REPORT_recurrent: 'Recurrente',
    APPRAISALS_REPORT_registryNumber: 'Finca registral',
    APPRAISALS_REPORT_remove: 'Borrar',
    APPRAISALS_REPORT_reorderPhotos: 'Reordenar',
    APPRAISALS_REPORT_repercusion: 'Repercusión ({{currency_per_surfaceUnit}})',
    APPRAISALS_REPORT_requestData: 'Datos de solicitud',
    APPRAISALS_REPORT_reversalValue: 'Valor de reversión',
    APPRAISALS_REPORT_reversionValue: 'Valor de reversión',
    APPRAISALS_REPORT_reversionValueUpdated: 'Valor Reversión actualizado',
    APPRAISALS_REPORT_riskPremium: 'Prima de riesgo',
    APPRAISALS_REPORT_selectAppraisalMethod: 'Seleccione el método de valoración',
    APPRAISALS_REPORT_selectValorationType: 'Seleccionar método de valoración',
    APPRAISALS_REPORT_stairs: 'Escalera',
    APPRAISALS_REPORT_startDateTime: 'Fecha de inicio',
    APPRAISALS_REPORT_staticResidualMethodValue: 'Valor método residual estático',
    APPRAISALS_REPORT_status: 'Estado',
    APPRAISALS_REPORT_streetName: 'Nombre vía',
    APPRAISALS_REPORT_streetNumber: 'Número vía',
    APPRAISALS_REPORT_subType: 'Subtipología',
    APPRAISALS_REPORT_sureDeleteCluster: '¿Está seguro de querer eliminar esta agrupación?',
    APPRAISALS_REPORT_sureRemoveElement: '¿Estás seguro que quiere eliminar el elemento?',
    APPRAISALS_REPORT_surfaces: 'Superficies',
    APPRAISALS_REPORT_surfaceType: 'Tipo de superficie',
    APPRAISALS_REPORT_terrainFeatures: 'Características del terreno',
    APPRAISALS_REPORT_terrainValuation: 'Valoración terrenos',
    APPRAISALS_REPORT_totalAndUpdatedValues: 'Valores Totales y de Actualización',
    APPRAISALS_REPORT_totalAssigned: 'Asignado total ({{currency}})',
    APPRAISALS_REPORT_totalExpenses: 'Gastos Totales',
    APPRAISALS_REPORT_totalIncomes: 'Ingresos Totales',
    APPRAISALS_REPORT_totalSummary: 'Resumen totales',
    APPRAISALS_REPORT_typology: 'Tipología',
    APPRAISALS_REPORT_units: 'Unidades',
    APPRAISALS_REPORT_updatedRents: 'Renta actualizada',
    APPRAISALS_REPORT_updatedRentsImputed: 'Renta actualizada imputada al inmueble',
    APPRAISALS_REPORT_updatedValue: 'Actualizado ({{currency}})',
    APPRAISALS_REPORT_updatedValueEx: 'Valor actualización',
    APPRAISALS_REPORT_urbanisticSituation: 'Situación urbanística',
    APPRAISALS_REPORT_use: 'Uso',
    APPRAISALS_REPORT_useful: 'Útil',
    APPRAISALS_REPORT_valorationMethodChangeWarning: 'Si cambia el método de valoración perderá los cálculos actuales',
    APPRAISALS_REPORT_valorations: 'Valoraciones',
    APPRAISALS_REPORT_valuation: 'Valoración',
    APPRAISALS_REPORT_valuationInfoMessage: 'Sólo se permite un método de cada tipo por valoración',
    APPRAISALS_REPORT_valuationMethods: 'Métodos de valoración',
    APPRAISALS_REPORT_valuations: 'Valoraciones',
    APPRAISALS_REPORT_valuationTotalValue: 'valoración total',
    APPRAISALS_REPORT_valueByResidualStatic: 'Valor por método residual estático',
    APPRAISALS_REPORT_view: 'Ver detalle',
    APPRAISALS_REPORT_weight: 'Peso (%)',
    APPRAISALS_REPORT_workPlanCreateRDMethodMessage: 'Cuando se cree el residual dinámico, se generará para las unidades que forman parte del mismo "Estado de la obra". El valor total del método residual dinámico se repartirá entre los distintos elementos.',
    APPRAISALS_REPORT_workPlanCreateRDMethosAssetsMessage: 'En este caso, las unidades para las que se generará conjuntamente el método residual dinámico serán las siguientes:',
    APPRAISALS_REPORT_workPlanMessageToRecalculate: 'El recálculo del Residual Dinámico implica que se actualizarán todos aquellos Residuales que estuviesen sujetos al mismo presupuesto dentro de Estado de la Obra. Si confirmas, será necesario recalcular posteriormente las valoraciones de cada activo. ¿Quieres realizar el recálculo?',
    APPRAISALS_REPORT_MARKET_STUDY_CREATE: 'Crear estudio de mercado',
    APPRAISALS_REPORT_MARKET_STUDY_CREATION: 'Creación del estudio de mercado',
    APPRAISALS_REPORT_MARKET_STUDY_NOT_CREATE: 'No se han creado estudios de mercado',
    APPRAISALS_REPORT_MARKET_STUDY_ASSET_TYPE: 'Tipo de activo',
    APPRAISALS_report: 'Informe',
    APPRAISALS_request: 'Solicitud',
    APPRAISALS_requestNumber: 'Nº de solicitud',
    APPRAISALS_requiredFields: 'Los campos requeridos están relleno al',
    APPRAISALS_resolutionAverageTime: 'Tiempo medio de resolución',
    APPRAISALS_resolveClaim: 'Resolver reclamación',
    APPRAISALS_resolvedAt: 'Resuelta a las',
    APPRAISALS_resolveIncidence: 'Resolver incidencia',
    APPRAISALS_revalorationIsNeeded: 'Es necesario revalorar el activo',
    APPRAISALS_review: 'Revisión',
    APPRAISALS_revisionReason: 'Motivo de revisión',
    APPRAISALS_scheduledVisitDate: 'Fecha de visita agendada',
    APPRAISALS_seeRelatedAppraisal: 'Ver valoración vinculada',
    APPRAISALS_selectAppraisal: 'Seleccionar valoración',
    APPRAISALS_selectOrder: 'Seleccionar trabajo',
    APPRAISALS_selectPrescriptor: 'Seleccionar un prescriptor',
    APPRAISALS_selectRelationshipAppraisal: 'Seleccionar valoración',
    APPRAISALS_selectRequest: 'Seleccionar solicitud',
    APPRAISALS_selectRequestElements: 'Elegir elementos de la solicitud',
    APPRAISALS_simpleNote: 'Nota simple',
    APPRAISALS_solvedDate: 'Fecha de resolución',
    APPRAISALS_solvedMaxDateTime: 'Fecha de resolución fin',
    APPRAISALS_solvedMinDateTime: 'Fecha de resolución inicio',
    APPRAISALS_startValidation: 'Empezar validación',
    APPRAISALS_startValidationConfirmation: '¿Está seguro de que desea empezar la validación de esta tasación?',
    APPRAISALS_status: 'Estado',
    APPRAISALS_stay: 'Estancia',
    APPRAISALS_target: 'Propósito',
    APPRAISALS_techManager: 'Gestor de técnicos',
    APPRAISALS_technicalsFee: 'Honorarios técnicos',
    APPRAISALS_thereAreNoItems: '* No hay elementos',
    APPRAISALS_totalClaims: 'Reclamaciones totales',
    APPRAISALS_totalIncidents: 'Incidencias totales',
    APPRAISALS_history: 'Historial',
    APPRAISALS_useAssetRepository: 'Seleccione esta opción para crear la valoración con ejecución por Tasafy',
    APPRAISALS_userType: 'Tipo de usuario',
    APPRAISALS_validate: 'Validar',
    APPRAISALS_validateJustification: 'Justificación',
    APPRAISALS_validateJustificationInfo: 'Ya hay una tasación VIGENTE con estos datos registrales. Si no da el mismo valor justifíquelo.',
    APPRAISALS_startValidationJustificationInfo: 'Ya hay una tasación VIGENTE con estos datos registrales. Puede consultarla en antecedentes.',
    APPRAISALS_validateAppraisalConfirmation: '¿Está seguro de que desea validar esta tasación?',
    APPRAISALS_validateAssetsOfAnAppraisal: 'Validar activos de una tasación',
    APPRAISALS_resumeValidate: 'Resumen de datos de validación',
    APPRAISALS_validateExpenses: 'Validar gastos',
    APPRAISALS_validateExpensesConfirmation: 'Atención una vez validados los gastos estos no se podrán editar ¿Está seguro de que desea validar los gastos?',
    APPRAISALS_validatingExpenses: 'Validando gastos',
    APPRAISALS_validation: 'Validación',
    APPRAISALS_validationDatetime: 'Fecha de validación',
    APPRAISALS_validationFinishDatetime: 'Fecha supervisión',
    APPRAISALS_validationFinished: 'Validación finalizada',
    APPRAISALS_validationStarted: 'Validación empezada',
    APPRAISALS_valorationTotalValue: 'Valor tasación',
    APPRAISALS_valorationValue: 'Valor',
    APPRAISALS_VALUATION_adoptedTotalSurfaceArea: 'Superficie adoptada ({{surfaceUnit}})',
    APPRAISALS_VALUATION_atLeastOneSurfaceWithValue: 'Al menos una superficie útil',
    APPRAISALS_VALUATION_back: 'Volver',
    APPRAISALS_VALUATION_save_selection: 'Guardar selección',
    APPRAISALS_VALUATION_buildingPhysicDeprecation: 'Dep. física del edificio',
    APPRAISALS_VALUATION_buildingUseRequired: 'Características del edificio: uso del edificio',
    APPRAISALS_VALUATION_buildingYearRequired: 'Características del edificio: año de construcción',
    APPRAISALS_VALUATION_calculationDocument: 'Documento de cálculo',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Gastos necesarios adoptados %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanConstructionCostCurrent: 'Coste de construcción - Actual',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanConstructionCostTotal: 'Coste de construcción total',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanExpensesProgressPercentage: 'Avance de los gastos %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanFormMessage: 'Los valores de construcción se calculan en base al porcentaje de avance de la obra. El valor de Coste proviene del repartido en la pestaña de Estado de obra.',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanLink: 'Cálculo en función avance obra',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanManualEditing: 'Editar porcentaje de avance en los gastos',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanMessage: '* Los gastos necesarios se calculan respecto a los costes de construcción totales de la unidad (tabla "en estado de la obra") considerando el % aplicado por el grado de avance de la obra. Ver detalle en "Cálculo en función avance de obra".',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanNecessaryExpenses: 'Gastos necesarios %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Gastos necesarios - Actual',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanProgressPercentage: 'Avance de la obra %',
    APPRAISALS_VALUATION_calculationsInProgressWorkPlanTitle: 'Construcción - Costes y gastos',
    APPRAISALS_VALUATION_construction: 'Construcción',
    APPRAISALS_VALUATION_constructionCost: 'Coste de construcción',
    APPRAISALS_VALUATION_constructionCostsTitle: 'Costes de construcción',
    APPRAISALS_VALUATION_constructionCostTotalValue: 'Coste de construcción ({{currency}})',
    APPRAISALS_VALUATION_constructionCostUnitValue: 'Coste de construcción ({{currency_per_surfaceUnit}})',
    APPRAISALS_VALUATION_constructionExpenses: 'Gastos de construcción',
    APPRAISALS_VALUATION_direction: 'Dirección',
    APPRAISALS_VALUATION_distance: 'Distancia',
    APPRAISALS_VALUATION_elementPhysicDeprecation: 'Dep. física del elemento',
    APPRAISALS_VALUATION_financialExpenses: 'Gastos financieros',
    APPRAISALS_VALUATION_functionalDeprecation: 'Dep. funcional',
    APPRAISALS_VALUATION_ground: 'Suelo',
    APPRAISALS_VALUATION_KCoefficient: 'K (Coeficiente para pasar a nuevo)',
    APPRAISALS_VALUATION_KeInfo: 'Ke: coeficiente de homogeneización para unidades concretas que son mejores o peores para la homogeneización general',
    APPRAISALS_VALUATION_KhInfo: 'Kh: coeficiente de ajuste entre el valor de mercado y el valor hipotecario. Si concurren los requisitos del artículo 12.3 de la orden ECO, aplicar el coeficiente Kh y además incluir las advertencias MER1 o MER2',
    APPRAISALS_VALUATION_marketValue: 'Valor de mercado',
    APPRAISALS_VALUATION_marketValueEuroM2: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    APPRAISALS_VALUATION_marketValueEuroM2Month: 'Valor de mercado ({{currency_per_surfaceUnit}}/mes)',
    APPRAISALS_VALUATION_euroM2Month: '({{currency_per_surfaceUnit}}/mes)',
    APPRAISALS_VALUATION_monthly: 'Mensual',
    APPRAISALS_VALUATION_moreInfo: 'Más información',
    APPRAISALS_VALUATION_necessaryConstructionExpensesPercentageRequired: 'Completar "Cálculo en función avance obra"',
    APPRAISALS_VALUATION_necessaryExpenses: 'Gastos necesarios',
    APPRAISALS_VALUATION_observations: 'Observaciones',
    APPRAISALS_VALUATION_promoterBenefits: 'Beneficios del promotor',
    APPRAISALS_VALUATION_proposalValue: 'Propuesta de valor',
    APPRAISALS_VALUATION_recalulateWorkPlan: 'Es necesario recalcular el estado de la obra y asignarle una superficie a este activo.',
    APPRAISALS_VALUATION_repercussion: 'Repercusión',
    APPRAISALS_VALUATION_method: 'Método',
    APPRAISALS_VALUATION_requiredErrorHeader: 'Faltan campos requeridos para este método',
    APPRAISALS_VALUATION_requiredErrorMessage: 'Falta alguno de los siguientes datos:',
    APPRAISALS_VALUATION_requiredSurface: 'Para continuar es necesario seleccionar una superficie',
    APPRAISALS_VALUATION_selectSurfaceForValuation: 'Seleccione una superficie para usar en esta valoración:',
    APPRAISALS_VALUATION_staticResidual: 'Residual estático simplificado',
    APPRAISALS_VALUATION_total: 'Total',
    APPRAISALS_VALUATION_totalAdoptedSurface: 'Es necesario definir las superficies adoptadas en el capítulo de Estado de la Obra.',
    APPRAISALS_VALUATION_totalLegalMaximumValue: 'Valor total máximo legal',
    APPRAISALS_VALUATION_totalMarketValue: 'Valor total mercado',
    APPRAISALS_VALUATION_totalValueEuro: 'Valor total ({{currency}})',
    APPRAISALS_VALUATION_suitableType: 'Control de calidad',
    APPRAISALS_VALUATION_totalValueEuroMonth: 'Valor total ({{currency}}/mes)',
    APPRAISALS_VALUATION_tradingExpenses: 'Gastos de comercialización',
    APPRAISALS_VALUATION_typology: 'Tipología',
    APPRAISALS_VALUATION_valueProposalChanges: 'Cambios en la propuesta de valor',
    APPRAISALS_VALUATION_valueProposalChangesObservations: 'Observaciones sobre la propuesta de valor',
    APPRAISALS_VALUATION_yearly: 'Anual',
    APPRAISALS_VALUTION_comparableList: 'Lista de comparables',
    APPRAISALS_version: 'Versión',
    APPRAISALS_viewRequest: 'Ver solicitud',
    APPRAISALS_vinculation: 'Tipo relación',
    APPRAISALS_visit: 'Visita',
    APPRAISALS_limit: 'Fecha límite',
    APPRAISALS_absent: 'Ausente',
    APPRAISALS_visitDateEnd: 'Agendada fecha fin',
    APPRAISALS_visitDates: 'Fechas de visita',
    APPRAISALS_visitDateStart: 'Agendada fecha inicio',
    APPRAISALS_visitFinished: 'Visita finalizada',
    APPRAISALS_visitName: 'adsasd',
    APPRAISALS_visitScheduled: 'Agendada',
    APPRAISALS_visitScheduleDeadline: 'Límite para agendar',
    APPRAISALS_visitStarted: 'Visita empezada',
    APPRAISALS_visitType: 'Tipo de visita',
    APPRAISALS_watchCustomerDetail: 'Ver ficha de cliente',
    APPRAISALS_watchPrescriptorDetail: 'Ver ficha de prescriptor',
    APPRAISALS_watchProfile: 'Ver perfil',
    APPRAISALS_withClaims: 'Con reclamaciones',
    APPRAISALS_withClaimsMaxDateTime: 'Fecha de incidencia fin',
    APPRAISALS_withClaimsNotResolved: 'Con reclamaciones no resueltas',
    APPRAISALS_withIncidents: 'Con incidencias',
    APPRAISALS_withIncidentsMinDateTime: 'Fecha de incidencia inicio',
    APPRAISALS_withIncidentsNotResolve: 'Con incidencias no resueltas',
    APPRAISALS_withIncidentsActive: 'Con incidencias activas',
    appraisals: 'Tasaciones',
    AppraisalStatus_1: '1 - ALTA',
    AppraisalStatus_10: '10 - VALIDACIÓN CORRECTA',
    AppraisalStatus_11: '11 - ENTREGADA A CLIENTE ',
    AppraisalStatus_2: '2 - RESPONSABLE ASIGNADO',
    AppraisalStatus_3: '3 - TÉCNICO ASIGNADO',
    AppraisalStatus_4: '4 - VISITA AGENDADA',
    AppraisalStatus_5: '5 - VISITA EMPEZADA',
    AppraisalStatus_6: '6 - VISITA FINALIZADA',
    AppraisalStatus_7: '7 - INFORME ENTREGADO',
    AppraisalStatus_8: '8 - VALIDADOR ASIGNADO',
    AppraisalStatus_9: '9 - VALIDACIÓN EMPEZADA',
    appraisalsWithInvoice: 'Valoraciones con factura',
    Appraiser: 'Profesionales',
    appraiser: 'Tasador',
    appraiserFee: 'Honorarios técnico',
    Ascensor: 'Ascensores',
    assessments: 'Valoraciones',
    ASSET_BUILDING_addDynamicResidual: 'Añadir Método residual dinámico',
    ASSET_BUILDING_addingMethod: 'Añadiendo ...',
    ASSET_BUILDING_basicProject: 'Proyecto Básico',
    ASSET_BUILDING_basicProjectDatetime: 'Fecha de redacción Proyecto Básico',
    ASSET_BUILDING_basicProjectVisaDatetime: 'Fecha visado Proyecto Básico',
    ASSET_BUILDING_buildingAverageConstructionCost: 'Coste de construcción medio de la obra ({{ currency_per_surfaceUnit }})',
    ASSET_BUILDING_buildingEstimatedDate: 'Fecha estimada fin de obra',
    ASSET_BUILDING_buildingEstimatedDuration: 'Duración estimada de la obra (meses)',
    ASSET_BUILDING_buildingLicense: 'Licencia de Obras',
    ASSET_BUILDING_buildingLicenseDatetime: 'Fecha de Licencia de obras',
    ASSET_BUILDING_buildingLicenseExpirationDatetime: 'Caducidad de la Licencia de obras',
    ASSET_BUILDING_buildingProgressGrade: 'Grado de avance de la obra (%)        ',
    ASSET_BUILDING_buildingStateObservations: 'Observaciones sobre el estado de la obra',
    ASSET_BUILDING_buildingTotalSurface: 'Superficie total de la obra ({{ surfaceUnit }})',
    ASSET_BUILDING_builingStateInitDatetime: 'Fecha de inicio de obra',
    ASSET_BUILDING_chapter: 'Capítulo',
    ASSET_BUILDING_chapterAmount: 'Importe ({{ currency }})',
    ASSET_BUILDING_chapterDescription: 'Descripción del capítulo',
    ASSET_BUILDING_chapterOverTotal: 'Capítulo sobre el total (%)',
    ASSET_BUILDING_chapterProgress: 'Avance capítulo (%)',
    ASSET_BUILDING_currentBuildingStateObservations: 'Estado actual de la obra',
    ASSET_BUILDING_documentation: 'Documentación',
    ASSET_BUILDING_dynamicResidualSummary: 'Resumen de valores del Método residual dinámico',
    ASSET_BUILDING_executionBuildingBudget: 'Presupuesto de ejecución de la obra',
    ASSET_BUILDING_executionProject: 'Proyecto de Ejecución',
    ASSET_BUILDING_executionProjectDatetime: 'Fecha de redacción Proyecto de Ejecución',
    ASSET_BUILDING_executionProjectVisaDatetime: 'Fecha de visado Proyecto de Ejecución',
    ASSET_BUILDING_needToReviewCashflow: '* Debido a las modificaciones que has realizado es necesario volver a realizar el reparto de los flujo de caja',
    ASSET_BUILDING_recalculateMethod: 'Recalcular método',
    ASSET_BUILDING_notRecalculateMethod: '* No es posible recalcular debido a que el expediente vinculado procede de Borsan. Se debe eliminar el método y crearlo de nuevo.',
    ASSET_BUILDING_removeDynamicMethodConfirmation: 'Se va a eliminar el método residual estático que afecta a los siguientes activos: \n\n {{assets}}',
    ASSET_BUILDING_totalBudget: 'Total del presupuesto ({{ currency }})',
    ASSET_BUILDING_totalProgress: 'Avance Global (%)',
    ASSET_BUILDING_workPlanConstructionCost: 'Coste de construcción de la obra',
    ASSET_CLUSTER_DETAIL_adoptedSurface: 'Superficie adoptada',
    ASSET_CLUSTER_DETAIL_buildingData: 'Datos del edificio',
    ASSET_CLUSTER_DETAIL_buildingUse: 'Uso de edificio',
    ASSET_CLUSTER_DETAIL_censusSection: 'Sección censal',
    ASSET_CLUSTER_DETAIL_clusterDetails: 'Datos de la agrupación',
    ASSET_CLUSTER_DETAIL_clusterUnits: 'Unidades de la agrupación',
    ASSET_CLUSTER_DETAIL_constructionYearWightedObservations: 'Observaciones relativas a la ponderación del año de construcción',
    ASSET_CLUSTER_DETAIL_name: 'Nombre',
    ASSET_CLUSTER_DETAIL_observations: 'Observaciones relativas a la agrupación',
    ASSET_CLUSTER_DETAIL_reformYear: 'Año de reforma',
    ASSET_exploitingAboveGround: 'Aprovechamiento sobre rasante ({{surfaceUnit}})',
    ASSET_isEconomicExploit: '¿Explotación económica?',
    ASSET_TREE_childrenWithIncompatibilities: 'Hijos con características incompatibles',
    ASSET_TREE_unfinishedAssetWarning: 'No se pueden agrupar elementos no terminados',
    assetConstructiveElementsTotalBuiltSurfaceWithCommonElements: 'Superficie construida Calculado',
    assetRepartitionErrorMessage: 'La suma de la distribución supera el total del coste de la obra',
    ASSETS_accesibility: 'Accesibilidad',
    ASSETS_actualProductionsInLastThreeYears: 'Producciones reales en los últimos 3 años',
    ASSETS_add_new_asset: 'Añadir nuevo activo',
    ASSETS_address: 'Dirección',
    ASSETS_addRoom: '+ Añadir estancia',
    ASSETS_agrologicalClasses: 'Clases agrológicas',
    ASSETS_alarmSystem: 'Sistema de alarma',
    ASSETS_animalHusbrandy: 'Ganadería',
    ASSETS_architecturalHomogeneity: 'Homogeneidad arquitectónica',
    ASSETS_assets: 'Activos',
    ASSETS_assetState: 'Estado del activo',
    ASSETS_atLeastOneBathAndOneBedroomWarning: 'Al menos debe haber un baño y un dormitorio.',
    ASSETS_averageAltitude: 'Altitud media',
    ASSETS_averageAnnualIncome: 'Renta disponible media anual',
    ASSETS_basementSurface: 'Superficie sótano',
    ASSETS_basicFacilities: 'Instalaciones básicas',
    ASSETS_book: 'Libro',
    ASSETS_borrowersHabitualResidence: 'Vivienda habitual del prestatario',
    ASSETS_buildability: 'Edificabilidad',
    ASSETS_buildingData: 'Datos del edificio',
    ASSETS_buildingDevelopment: 'Desarrollo edificatorio',
    ASSETS_buildingsLinkedToExploitation: 'Edificaciones ligadas a la explotación',
    ASSETS_buildingsOwnEconomicActivity: 'Edificaciones actividad económica propia',
    ASSETS_buildingUse: 'Uso',
    ASSETS_builtInWardrobes: 'Armarios empotrados',
    ASSETS_builtWithCommonSurfaceM2: 'Superficie construida con comunes ({{surfaceUnit}})',
    ASSETS_builtWithoutCommonSurfaceM2: 'Superficie construida sin comunes ({{surfaceUnit}})',
    ASSETS_cadastralInformation: 'Información catastral',
    ASSETS_cadastralReference: 'Referencia catastral',
    ASSETS_cadastralReferenceContainerBuilding: 'Referencia catastral del edificio',
    ASSETS_cadastralSurface: 'Catastral',
    ASSETS_cadastralSurfaceM2: 'Superficie catastral ({{surfaceUnit}})',
    ASSETS_caracteristicUse: 'Uso característico',
    ASSETS_ceilingType: 'Tipo de techo',
    ASSETS_characteristicAntiquity: 'Antigüedad característica',
    ASSETS_checkedSurface: 'Comprobada',
    ASSETS_checkedSurfaceM2: 'Superficie comprobada ({{surfaceUnit}})',
    ASSETS_clearanceHeight: 'Altura libre',
    ASSETS_closedSecurityCircuit: 'Circuito cerrado de seguridad',
    ASSETS_clothesLine: 'Tendedero',
    ASSETS_coefTableBackground: 'Coeficiente Tabla Fondo',
    ASSETS_coldHoursSunnyHours: 'Horas de frío / Horas de sol',
    ASSETS_commonFacilitiesSurface: 'Superficie instalaciones comunes',
    ASSETS_communications: 'Comunicaciones',
    ASSETS_compatibleUses: 'Usos compatibles',
    ASSETS_compositionAndSize: 'Composición y tamaño',
    ASSETS_conditioning: 'Acondicionamiento',
    ASSETS_conservationState: 'Estado de conservación',
    ASSETS_constructionYear: 'Año de construcción',
    ASSETS_coreType: 'Tipo de núcleo',
    ASSETS_country: 'País',
    ASSETS_createdBy: 'Creado por',
    ASSETS_creationDate: 'Fecha de creación',
    ASSETS_criticalJudgement: 'Juicio crítico',
    ASSETS_cultivationAge: 'Antigüedad del cultivo',
    ASSETS_cultivationPlantingFramework: 'Marco de plantación del cultivo',
    ASSETS_cultivesAndUses: 'Cultivos y aprovechamientos',
    ASSETS_currentInfraestructure: 'Infraestructura existente',
    ASSETS_currentPlanning: 'Planeamiento vigente',
    ASSETS_currentPricSaleRental: 'Intervalos de precios actuales venta/alquiler (indicar valores máximos y mínimos de los métodos de valoración empleados)',
    ASSETS_DefinitiveQualificationDateTime: 'Fecha calificación definitiva',
    ASSETS_delete: 'Eliminar',
    ASSETS_demand: 'Demanda',
    ASSETS_demandRevaluationExpectations: 'Expectativas oferta-demanda y revalorización',
    ASSETS_depth: 'Fondo',
    ASSETS_description: 'Descripción',
    ASSETS_distribution: 'Distribución',
    ASSETS_divisibility: 'Divisibilidad',
    ASSETS_door: 'Puerta',
    ASSETS_dragBluePin: '* Debe arrastrar el pin azul a la posición correcta o bien introducir la latitud y la longitud manualmente',
    ASSETS_duplicate: 'Duplicar',
    ASSETS_economicExploitationObservations: 'Descripción de la explotación',
    ASSETS_editActualProductionsInLastThreeYears: 'Editar información',
    ASSETS_energeticCertificateGrade: 'Calificación energética actual (emisiones)',
    ASSETS_energeticCertificateGradeConsumo: 'Calificación energética actual (consumo)',
    ASSETS_environmentConsolidationDegreePercentage: 'Porcentaje de grado de consolidación del entorno',
    ASSETS_environmentDescription: 'Descripción del entorno',
    ASSETS_environmentSignificance: 'Significación del entorno',
    ASSETS_equipmentAndServices: 'Equipamiento y servicios',
    ASSETS_equippedKitchen: 'Cocina equipada',
    ASSETS_executionDeadlinesAndConsequences: 'Plazos de ejecución previstos para el desarrollo urbanístico del terreno. Consecuencias en caso de incumplimiento',
    ASSETS_existingEdifications: 'Edificaciones existentes',
    ASSETS_Expedient: 'Expediente',
    ASSETS_exploitation: 'Aprovechamiento',
    ASSETS_exploitationDescription: 'Descripción de la explotación',
    ASSETS_expropiation: 'Expropiación. Estado del procedimiento expropiatorio. Criterios legales aplicables a su valoración',
    ASSETS_externalWoodWork: 'Carpintería exterior',
    ASSETS_extractionAndDistributionSystem: 'Sistema de extracción y distribución',
    ASSETS_facade: 'Fachada',
    ASSETS_facilities: 'Instalaciones',
    ASSETS_featuresMarket: 'Características mercado inmobiliario comparable',
    ASSETS_floor: 'Planta',
    ASSETS_fundamentalBuildingElements: 'Elementos fundamentales de la edificación',
    ASSETS_generate: 'Generar',
    ASSETS_geolocate: 'Geolocalizar',
    ASSETS_groundFloorSurface: 'Superficie planta baja',
    ASSETS_groundLevel: 'Nivel de suelo (Normativa ECO/805/2003)',
    ASSETS_groundQualification: 'Calificación del suelo',
    ASSETS_hasActiveCrop: 'Cultivo activo',
    ASSETS_hasActiveCultivation: '¿Tiene cultivo activo?',
    ASSETS_hasAirConditioning: 'Climatización',
    ASSETS_hasCommonFacilities: 'Instalaciones comunes',
    ASSETS_hasElevator: 'Ascensor',
    ASSETS_hasEnergeticCertificate: 'Tiene certificado energético',
    ASSETS_hasFlatTerrain: 'Terreno llano',
    ASSETS_hasGas: 'Gas',
    ASSETS_hasGreenArea: 'Zonas verdes',
    ASSETS_hasHeating: 'Calefacción',
    ASSETS_hasHotWater: 'Agua caliente',
    ASSETS_hasIntercom: 'Portero automático',
    ASSETS_hasPaving: 'Pavimentación',
    ASSETS_hasPhone: 'Teléfono',
    ASSETS_hasPipedGasNetwork: 'Red de gas canalizado',
    ASSETS_hasPlumbingAndSanitaryEquipment: 'Fontanería y aparatos sanitarios',
    ASSETS_hasPool: 'Piscina',
    ASSETS_hasPublicLighting: 'Alumbrado público',
    ASSETS_hasRolledAccess: 'Acceso rodado',
    ASSETS_hasSanitation: 'Saneamientos',
    ASSETS_hasSanitationNetwork: 'Red de saneamiento',
    ASSETS_hasSecurity: 'Seguridad',
    ASSETS_hasSportsZone: 'Zona deportiva',
    ASSETS_hasTelephoneNetwork: 'Red de teléfono',
    ASSETS_hasTennisOrPaddle: 'Tenis/Pádel',
    ASSETS_hasWarehouse: 'Almacén',
    ASSETS_hasWaterDistribution: 'Distribución de agua',
    ASSETS_hasWaterSupply: 'Abastecimiento de agua',
    ASSETS_heatingType: 'Tipo de calefacción',
    ASSETS_hotWaterType: 'Tipo de agua caliente',
    ASSETS_humidityRegime: 'Régimen de humedad',
    ASSETS_hydrology: 'Hidrología',
    ASSETS_idufir: 'IDUFIR',
    ASSETS_industrialBuildingCharacteristics: 'Características de la nave industrial',
    ASSETS_infrastructures: 'Infraestructuras',
    ASSETS_inscription: 'Inscripción',
    ASSETS_internalWoodType: 'Carpintería interior',
    ASSETS_isAdequatedToCurrentPlanning: '¿Adecuación al planeamiento vigente?',
    ASSETS_isEconomicExploitation: '¿Es una Explotación Económica?',
    ASSETS_isPlot: '¿Es un solar?',
    ASSETS_jobOccupation: 'Ocupación laboral',
    ASSETS_lastRehabilitationYear: 'Año de la rehabilitación',
    ASSETS_legalSituation: 'Situación legal',
    ASSETS_license: 'Licencia',
    ASSETS_lngLatWarning: 'No se han podido obtener los datos de la localidad y entorno',
    ASSETS_localCharacteristics: 'Características del local',
    ASSETS_characteristics: 'Características',
    ASSETS_locality: 'Localidad',
    ASSETS_localityMainFeatures: 'Principales características de la localidad',
    ASSETS_locationComparedToSameBuildingElements: 'Ubicación del inmueble dentro del edificio en comparación con otros elementos del mismo edificio',
    ASSETS_locationComparedToSameMunicipality: 'Ubicación del inmueble en comparación con otros del mismo municipio',
    ASSETS_locationComparedToSameZone: 'Ubicación del inmueble en comparación con otros de la misma zona',
    ASSETS_locationIndustrialShip: 'Ubicación nave',
    ASSETS_locationStreetType: 'Ubicación - Tipo de calle',
    ASSETS_loftSurface: 'Superficie altillo',
    ASSETS_LOG_currentValue: 'Valor actual',
    ASSETS_LOG_elementWithId: 'El elemento',
    ASSETS_LOG_previusValue: 'Valor anterior',
    ASSETS_LOG_was: 'se ha',
    ASSETS_mainElements_Deck: 'Cubierta',
    ASSETS_mainElements_DeckDescription: 'Descripción de la cubierta',
    ASSETS_mainElements_Enclosure: 'Cerramientos',
    ASSETS_mainElements_EnclosureDescription: 'Descripción de los cerramientos',
    ASSETS_mainElements_Foundation: 'Cimentación',
    ASSETS_mainElements_FoundationDescription: 'Descripción de la cimentación',
    ASSETS_mainElements_Structure: 'Estructura',
    ASSETS_mainElements_StructureDescription: 'Descripción de la estructura',
    ASSETS_managementPhase: 'Fase de gestión',
    ASSETS_managementSystem: 'Sistema de gestión',
    ASSETS_managementTypology: 'Tipología de manejo',
    ASSETS_manuallySelection: 'Elegir ubicación manualmente',
    ASSETS_changeUrbanSituationMonths: 'Plazo previsto de cambio de la situación urbanística del terreno (meses)',
    ASSETS_expectedObtainingLicenseMonths: 'Plazo  previsto  para  la obtención  de  licencia (meses)',
    ASSETS_market: 'Mercado',
    ASSETS_marketMortgageValueDifferences: 'Diferencias valor mercado y valor hipotecario',
    ASSETS_maxHeight: 'Altura máxima',
    ASSETS_mezzanineSurface: 'Superficie entre-planta',
    ASSETS_microclimate: 'Microclima',
    ASSETS_minimunPlot: 'Parcela mínima',
    ASSETS_modeAndManagementSystem: 'Sistema y modo de gestión',
    ASSETS_morphology: 'Morfología',
    ASSETS_notCorrectLocation: '* Si la ubicación mostrada no es correcta puede seleccionarla manualmente',
    ASSETS_numberOfBuildingAccesses: 'Accesos al edificio',
    ASSETS_observations: 'Observaciones',
    ASSETS_ocupation: 'Ocupación',
    ASSETS_offer: 'Oferta',
    ASSETS_officeCharacteristics: 'Características de la oficina',
    ASSETS_openSpace: 'Espacio diáfano',
    ASSETS_otherBuildings: 'Otras edificaciones',
    ASSETS_otherSurface: 'Otra superficie',
    ASSETS_paper: 'Folio',
    ASSETS_parkings: 'Aparcamientos',
    ASSETS_pavementType: 'Tipo de pavimento',
    ASSETS_pendingInfrastructure: 'Infraestructura pendiente',
    ASSETS_pendingInfrastructuresPercentage: 'Porcentaje de infraestructuras pendientes',
    ASSETS_pendingProcedures: 'Trámites pendientes para suelo finalista',
    ASSETS_percentageOfValuedArea: 'Porcentaje del ámbito valorado',
    ASSETS_percentOfWorkDone: 'Porcentaje de urbanización ejecutado',
    ASSETS_placeName: 'Paraje',
    ASSETS_planningDevelopment: 'Desarrollo de planteamiento',
    ASSETS_plotCode: 'Parcela',
    ASSETS_pluviometry: 'Pluviometría',
    ASSETS_podeAndManagementSystem: 'Sistema y modo de gestión',
    ASSETS_polivalentUse: 'Uso polivalente',
    ASSETS_polygonCode: 'Polígono',
    ASSETS_population: 'Población Nº habitantes',
    ASSETS_populationEvolutionPercent: 'Evolución reciente de la población',
    ASSETS_portal: 'Portal',
    ASSETS_postalCode: 'Código postal',
    ASSETS_predominantOfficeUse: '¿Predomina uso oficina?',
    ASSETS_productionDestination: 'Destino de la producción',
    ASSETS_productToDevelopTypes: 'Producto que se prevé desarrollar',
    ASSETS_prohibitedUses: 'Usos prohibidos',
    ASSETS_propertyRegistrationName: 'Nombre del registro de la propiedad',
    ASSETS_propertySize: 'Tamaño del inmueble',
    ASSETS_ProtectionTimeLimit: 'Plazo protección (años)',
    ASSETS_province: 'Provincia',
    ASSETS_ProvisionalQualificationDateTime: 'Fecha calificación provisional',
    ASSETS_pubjectCorrespondingManagementInitiative: 'Sujeto a quien corresponde la iniciativa de la gestión',
    ASSETS_qualityOfBuilding: 'Calidad de las edificaciones',
    ASSETS_raisedFloorOrFalseCeiling: 'Suelo técnico / Falso techo',
    ASSETS_reformLastYear: 'Año de reforma',
    ASSETS_regionName: 'Comarca',
    ASSETS_registeredPropertyNumber: 'Finca registral',
    ASSETS_registeredSurfaceM2: 'Superficie registral ({{surfaceUnit}})',
    ASSETS_registralSurface: 'Registral',
    ASSETS_registryInfo: 'Información Registral',
    ASSETS_remarkableMicroclimates: 'Microclimas destacables',
    ASSETS_renovation: 'Renovación',
    ASSETS_residenceType: '1ª / 2ª de residencia',
    ASSETS_roomType: 'Tipo de estancia',
    ASSETS_scopeSurface: 'Superficie del ámbito',
    ASSETS_section: 'Sección',
    ASSETS_securityDoor: 'Puerta de seguridad',
    ASSETS_setBacks: 'Retranqueos',
    ASSETS_setOff: 'Relieve',
    ASSETS_sourceOfWaterAndFlow: 'Procedencia del agua y caudal',
    ASSETS_species: 'Especies',
    ASSETS_stairs: 'Escalera',
    ASSETS_status: 'Estado',
    ASSETS_stays: 'Estancias',
    ASSETS_streetCategory: 'Categoría de la calle',
    ASSETS_streetName: 'Nombre de la vía',
    ASSETS_streetNotExist: 'La vía no existe en el callejero',
    ASSETS_streetNumber: 'Número',
    ASSETS_streetType: 'Tipo de vía',
    ASSETS_structureLight: 'Luz de la estructura',
    ASSETS_subjectCorrespondingManagementInitiative: 'Sujeto a quien corresponde la iniciativa de la gestión',
    ASSETS_subTypology: 'Subtipología',
    ASSETS_surface: 'Superficie',
    ASSETS_surfaceM2: 'Superficie ({{surfaceUnit}})',
    ASSETS_surfaceToDemolish: 'Superficie a demoler ({{surfaceUnit}})',
    ASSETS_surveillance: 'Vigilancia',
    ASSETS_temperatures: 'Temperaturas',
    ASSETS_terrainDescription: 'Descripción del terreno',
    ASSETS_terrainFeatures: 'Características del terreno',
    ASSETS_terrainInfraestructures: 'Infraestructuras del terreno',
    ASSETS_terrainSurface: 'Superficie del terreno',
    ASSETS_terrainTexture: 'Textura del terreno',
    ASSETS_topography: 'Topografía',
    ASSETS_topographyAndAgrologicalCharacteristics: 'Topografía y características agrológicas',
    ASSETS_topologyAndSlope: 'Topología y relieve',
    ASSETS_totalFloorsAboveGround: 'Plantas sobre rasante',
    ASSETS_totalFloorsBellowGround: 'Plantas bajo rasante',
    ASSETS_totalPlotSurface: 'Superficie parcela ({{surfaceUnit}})',
    ASSETS_typology: 'Tipología',
    ASSETS_unemploymentRatePercent: 'Tasa de paro',
    ASSETS_urbanBurdens: 'Cargas urbanísticas (cesiones, costes de urbanización, obligaciones adquiridas, etc)',
    ASSETS_urbanCoreProximity: 'Proximidad respecto el núcleo urbano',
    ASSETS_urbanisticManagment: 'Gestión urbanística',
    ASSETS_urbanisticParameters: 'Parámetros urbanísticos',
    ASSETS_urbanisticParams: 'Parámetros urbanísticos',
    ASSETS_urbanisticQualificationAndManagement: 'Calificación y gestión urbanística',
    ASSETS_urbanisticQualification: 'Calificación urbanística',
    ASSETS_urbanizationCostsAndPendingCharges: 'Costes de urbanización y cargas pendientes',
    ASSETS_urbanizationState: 'Estado de la urbanización',
    ASSETS_use: 'Uso',
    ASSETS_usefulSurfaceM2: 'Superficie útil ({{surfaceUnit}})',
    ASSETS_vegetativeState: 'Estado vegetativo',
    ASSETS_volume: 'Tomo',
    ASSETS_wallType: 'Tipo de paredes',
    ASSETS_withPaving: 'Pavimentación',
    ASSETS_withPipedGasNetwork: 'Red de gas canalizado',
    ASSETS_withSanitationNetwork: 'Red de saneamiento',
    ASSETS_withStreetLighting: 'Alumbrado público',
    ASSETS_withTelephoneNetwork: 'Red de teléfono',
    ASSETS_withWaterSupply: 'Abastecimiento de agua',
    ASSETS_marketStudy: 'Recuerda que esta modificación implicará cambios en los métodos donde este estudio de mercado se esté usando.',
    ASSETS_marketStudyInformation: '*Recuerda que la homogeneización debe ser desglosada para tasaciones ECO sí.',
    ASSETS_marketStudyRemove: '¿Está seguro de querer eliminar el estudio de mercado?',
    AssetState: 'Estado',
    assetSubTypology: 'Subtipología',
    AssetSubTypology: 'Subtipología',
    AssetTypology: 'Tipología',
    AssetUse: 'Uso',
    assignRoles: 'Asignar roles',
    Assistance: 'Asistencial',
    automaticInvoicing: 'Facturación automática',
    avm_add_valuation: 'Añadir Valoración',
    avm_additionalInfo: 'Información adicional',
    avm_alerts: 'Alertas',
    avm_assets: 'Activos',
    avm_back: 'Volver',
    avm_backtesting_message_2: 'Sólo se podrá generar un backtesting por pedido. Elegir uno de los lotes valorados y pulsar sobre el botón “Crear backtesting”.',
    avm_backtesting_message_3: 'Elegir valoración que modificará los activos usados en el backtesting.',
    avm_backtesting_message: 'Se esta completando el proceso de backtesting. Se ha solicitado el {{ dateValue }}. Si tarda más de 30 minutos contactar con el servicio técnico.',
    avm_backtestings_files: 'Ficheros backtesting',
    avm_backtestings: 'Backtestings',
    avm_backtestingSelectedBatchValorationIdis: 'Id del lote',
    avm_batchAlertType: 'Tipo',
    avm_batchId: 'ID del lote',
    avm_batchValorationId: 'ID Valoratión',
    avm_cadastralApiBatchEstimatedFinishDatetime: 'Hora de fin estimada',
    avm_cadastralApiBatchFinishedDatetime: 'Hora de fin',
    avm_cadastralApiBatchStartDatetime: 'Hora de inicio',
    avm_cadastralApiBatchStartUserFullName: 'Nomdre de usuario',
    avm_cadastralApiBatchStartUserId: 'Id del Usuario',
    avm_cadastralData: 'API Catastro',
    avm_calculated: 'Calculado',
    avm_checkValue_tooltip: 'AVM (A) asistidos por tasador según la Guía supervisora de BDE. El algoritmo de valoración tendrá en cuenta las comprobaciones adicionales y el juicio experto realizado por un tasador en cuanto a los parámetros utilizados en la valoración del activo.',
    avm_checkValue: 'Revisión asistida – AVM (A)',
    avm_choose_backtesting: 'Seleccionar Backtesting',
    avm_column_preselection: 'Preselección de columnas',
    avm_confidenceIndexPercent: 'Indice de confianza (%)',
    avm_create_batch_headerRowsCount_tooltip: 'Filas de cabecera que tiene la tabla. Normalmente 1.',
    avm_create_batch_initialCell_tooltip: 'La celda donde empieza la tabla a leer en el Excel.<br><br>Por ejemplo: B2',
    avm_creationDate: 'Fecha de creación',
    avm_databaseVersion: 'Version Base de Datos',
    avm_dbVersion: 'Versión Base de Datos',
    avm_deliveredDatetime: 'Fecha de entrega',
    avm_deliveredSolveBacktestingValorationId: 'Id de la valoracion',
    avm_deliveredUserFullName: 'Nombre completo',
    avm_deliveredUserId: 'Id del usuario',
    avm_deliverToClient: 'Entregar a cliente',
    avm_deliverToClientData: 'Datos de la entrega a cliente',
    avm_detail: 'Detalle',
    avm_discrepancies_filter_ids_tooltip: 'Introduzca los IDS de los activos separados por ; para buscarlos.<br><br>Ejemplo: 1;2;3;4;5 ...',
    avm_discrepancies: 'Discrepancias',
    avm_edit_translation: 'Editar traducción',
    avm_enrichment_criteria: 'Criterios de enriquecimiento',
    avm_errorPercent: 'Error (%)',
    avm_establish_discrepancies: 'Establecer criterios',
    avm_estimatedFinishDatetime: 'Fecha Fin Estimada',
    avm_failureProbability: 'Probabilidad de fallo %',
    avm_file: 'Fichero',
    avm_generate_backtesting_file: 'Generar fichero backtesting',
    avm_generated_backtesting_files: 'Ficheros backtesting generados',
    avm_go_to_order_detail: 'Ver detalle pedido',
    avm_hasMoreThanOneConstructiveElement: 'Con más de un elemento constructivo (origen)',
    avm_headerRowsCount: 'Número de filas de encabezado',
    avm_hittingProbability: 'Probabilidad de acierto %',
    avm_ids_to_review_value_tooltip: 'Por defecto serán a todos los activos, a no ser que se especifiquen ids',
    avm_initialCell: 'Celda inicial',
    avm_isDelivered: 'Esta entregado',
    avm_isDiscrepancySolved: 'Discrepancia resuelta',
    avm_keepDiscrepancies: 'Mantener discrepancias',
    avm_login_information: 'Info. de inicio de sesión',
    avm_logins_log: 'Logins log',
    avm_orderCustomerFullName: 'Nombre del cliente del pedido',
    avm_orderDescription: 'Descripción del pedido',
    avm_orderOrderDateTime: 'Fecha del pedido',
    avm_orderType: 'Tipo de pedido',
    avm_origin: 'Origen',
    avm_pq: 'PQ',
    avm_providerAssetId: 'Id del activo',
    avm_providerAssetIds: 'IDs de activos del proveedor',
    avm_relatedAssetHasMoreThanOneConstructiveElement: 'Con mas de un elemento constructivo (calculado)',
    avm_resolve_discrepancies: 'Confirmar criterios',
    avm_scriptVersion: 'Version Script',
    avm_see_batch: 'Ver el lote',
    avm_see_valuation: 'Ver la Valoracion',
    avm_solve_incidents: 'Resolver incidencias',
    avm_solving: 'Resolviendo...',
    avm_this_batch_has_been_duplicated_from: 'Este lote ha sido duplicado del lote con ID:',
    avm_this_is_a_duplicated_batch: 'Este es un Lote duplicado',
    avm_useInValorationBatchAssetSurfaceData: 'Discrepancia Superficie',
    avm_useInValorationBatchAssetTypologyData: 'Discrepancia Tipología',
    avm_useReferenceUnitValue_tooltip: 'AVM (A) asistidos por tasador según la Guía supervisora de BDE. El algoritmo de valoración tendrá en cuenta las comprobaciones adicionales y el juicio experto realizado por un tasador. El valor de referencia se obtiene a partir del fichero de entrada, de aquella columna que se haya marcado de tipo “Valor unitario de referencia del elemento principal”. En aquellos que no tengan valor, se aplicará el algoritmo por defecto.',
    avm_useReferenceUnitValue: 'Valoración asistida – AVM (A)',
    avm_useWitnessesAverageSurfaceInBatchAssetsDontHaveAnySurface_tooltip: 'Seleccionando esta opción, el algoritmo de valoración aportará una superficie a los activos que no tengan, calculada a partir de la base de datos de testigos (los de ultima versión disponible) del clúster',
    avm_useWitnessesAverageSurfaceInBatchAssetsDontHaveAnySurface: 'Superficie estimada testigos',
    avm_valorations: 'Valoraciones',
    avm_valorationState: 'Estado de valoración',
    avm_value_revision_similarity_index_tooltip: 'Este índice limita la diferencia de los testigos que van a entrar nuevos, con respecto a los ya seleccionados. Siendo 1 la máxima similitud, se deberá aumentar este valor para ir aceptando más diferencia entre los testigos. Por lo que, 1,15 sería como decir que tenemos una desviación del 15% en similitud con respecto a los que ya se han seleccionado. No se recomienda pasar de 1,5.',
    avm_witnessesLimitToCalculateAverageSurfaceInBatchAssetsDontHaveAnySurface_tooltip: 'Número de testigos mínimo en el clúster para calcular la superficie sobre aquellos activos que no tengan. En aquellos que no llegue a los testigos mínimos, se dejarán sin superficie y por tanto no se valorarán.',
    avm_witnessesLimitToCalculateAverageSurfaceInBatchAssetsDontHaveAnySurface: 'Limite minimo de testigos para la media',
    backtesting: 'Backtesting',
    bank_account_number_for_payment: 'Numero de Cuenta Bancaria para el pago',
    basicFilters: 'Filtros básicos',
    batchAssetConstructiveElementsTotalBuiltSurfaceWithCommonElements: 'Superficie construida Origen',
    batchDateTime: 'Fecha del Lote',
    BATCHES_addedUserName: 'Usuario',
    BATCHES_batchId: 'Id',
    BATCHES_batchState: 'Estado',
    BATCHES_creationDate: 'Fecha de creación',
    BATCHES_creationMaxDate: 'Hasta',
    BATCHES_creationMinDate: 'Desde',
    BATCHES_fileName: 'Nombre archivo',
    BATCHES_processError: 'Error de proceso',
    BatchStatus_0: 'Pendiente',
    BatchStatus_1: 'En proceso',
    BatchStatus_2: 'Procesado correctamente',
    BatchStatus_3: 'Error',
    batchWithId: 'Lote {{massiveBatchId}}',
    requestWithId: 'Solicitud {{requestId}}',
    bathroomNumber: 'Número de baños',
    BDE_FILTER_IsReportedToBde: 'Reportado a Bde',
    BDE_FILTER_Trimester: 'Trimestre',
    BDE_FILTER_YearReportedToBdE: 'Año reportado a Bde',
    bedroomNumber: 'Número de dormitorios',
    BILL_LIST_invoiceNumber: 'Nº de factura',
    billing_email: 'Email de facturación',
    billing_info: 'Datos de Facturación',
    BILLING_INFORMATION_addAsDefaultMethod: 'Añadir como método predeterminado',
    BILLING_INFORMATION_addPaymentMethods: 'Añadir métodos de pago',
    BILLING_INFORMATION_billingData: 'Datos de facturación',
    BILLING_INFORMATION_card: 'Tarjeta',
    BILLING_INFORMATION_cardNumber: 'Número de tarjeta',
    BILLING_INFORMATION_creditCardExpiryDateMonth: 'Mes de caducidad',
    BILLING_INFORMATION_creditCardExpiryDateYear: 'Año de caducidad',
    BILLING_INFORMATION_cvv: 'CVV',
    BILLING_INFORMATION_defaultPayment: 'Opción de pago predeterminada',
    BILLING_INFORMATION_expirationDate: 'Fecha de caducidad',
    BILLING_INFORMATION_paymentMethods: 'Métodos de pago',
    BILLING_INFORMATION_payments: 'Pagos',
    BILLING_INFORMATION_redirect: 'Redirigiendo...',
    BILLING_INFORMATION_redirectToSafeLink: 'Redirigiendo a un sitio seguro.',
    BILLING_INFORMATION_setAssDefault: 'Establecer como opción de pago predeterminada',
    BILLING_INFORMATION_sureRemoveElement: '¿Estás seguro de que quieres eliminar este método de pago?',
    BILLING_INFORMATION_warningDescription: 'Para el pago de los expedientes de tasación realizados, almacenamos los datos de tu tarjeta con total seguridad bajo el sistema de Redsys. Para ello, introdúcelos en la ventana emergente de la entidad bancaria que se abrirá al clicar sobre “Añadir métodos de pago”, proceso que no conlleva ningún cargo en tu tarjeta.',
    BillingDataCopyOptions_0: 'Copiar datos del titular',
    BillingDataCopyOptions_1: 'Copiar datos del prescriptor',
    BillingDataCopyOptions_2: 'Copiar datos de la cuenta',
    billingFileName: 'Factura',
    billingStatus: 'Estado facturación',
    billingTaxType: 'Tipo de impuesto',
    BillingTaxType: 'Tipo de impuesto',
    billingType: 'Tipo de facturación',
    Book: 'Libro',
    BooleanQuestionValues_false: 'No',
    BooleanQuestionValues_true: 'Sí',
    BUILDING_ELEMENT_COMPARISON_observations: 'Observaciones relativas a la hipótesis de construcción',
    BUILDING_ELEMENT_valuationOtherMethod: 'Manual',
    BuildingInformation: 'Datos del edificio',
    buildingLastRehabilitationYear: 'Año de rehabilitación',
    Buildings: 'Edificios',
    buildingUse: 'Uso del edificio',
    BuildingUse: 'Uso',
    buildingYear: 'Año de construcción',
    builtConstructionYear: 'Año de construcción',
    BusinessPremises: 'Local comercial',
    BUTTON_ACTION_accept: 'Aceptar',
    BUTTON_ACTION_cancel: 'Cancelar',
    BUTTON_ACTION_reject: 'Rechazar',
    BUTTON_ACTION_resend: 'Reenviar',
    cadastral: 'Catastral',
    cadastralData: 'Datos catastrales',
    cadastralReference: 'Referencia catastral',
    CadastralReference: 'Referencia catastral',
    CadastralReferenceContainerBuilding: 'Referencia catastral del edificio',
    CadastralSurfaces: 'Superficie catastral',
    calculated: 'Calculado',
    CAN_CREATE_APPRAISAL_addPaymentMethod: 'Añadir método de pago',
    CAN_CREATE_APPRAISAL_selectPlan: 'Selecciona un plan',
    cancel: 'Cancelar',
    cancelled: 'Anuladas',
    cancelledDate: 'Fecha de anulación',
    CeilingType: 'Tipo de techo',
    change_asset_status: 'Si cambia el estado del activo a terminado perderá los datos relativos a las valoraciones actuales introducidas. ¿Desea continuar?',
    change_asset_typology: 'Las modificaciones relizadas implican que se eliminen aquellos métodos que no apliquen a la tipología escogida. ¿Desea continuar? ',
    change_password: 'Cambiar contraseña',
    change_picture: 'Cambiar imagen',
    CHANGE_TEMPORAL_PASSWORD_change: 'Cambiar',
    CHANGE_TEMPORAL_PASSWORD_changeTemporalPassword: 'Cambiar contraseña temporal',
    CHANGE_TEMPORAL_PASSWORD_newPassword: 'Nueva contraseña',
    CHANGE_TEMPORAL_PASSWORD_passwordsNotMatch: 'Las contraseñas no coinciden',
    CHANGE_TEMPORAL_PASSWORD_repeatPassword: 'Repita la contraseña',
    CHANGE_TEMPORAL_PASSWORD_yourPasswordIsTemporal: 'Su contraseña actual es temporal, por motivos de seguridad ha de cambiarla ',
    checked: 'Comprobada',
    CheckedSurfaces: 'Superficie comprobada',
    checkLicenseData: 'Por favor, revise los datos de su licencia',
    ChecksRelatedToAttachedDocumentation: 'Comprobaciones relativas a la documentación anexa',
    cif_nif: 'CIF/NIF',
    cif: 'CIF',
    city: 'Ciudad',
    claims: 'Reclamaciones',
    claimsResolutionAverageTime: 'Tiempo medio resolución de reclamaciones',
    client: 'Cliente',
    close: 'Cerrar',
    closed: 'Cerrado',
    CLUSTER_addAssetToCluster: 'Añadir activo',
    CLUSTER_addUnit: 'Añadir unidad',
    CLUSTER_buildingUse: 'Uso del edificio',
    CLUSTER_chooseClusterAssets: 'Elegir activos',
    CLUSTER_chooseClusterType: 'Elegir tipo de agrupación',
    CLUSTER_clusterType: 'Tipo de agrupación',
    CLUSTER_completeClusterData: 'Completar datos de la agrupación',
    CLUSTER_constructionYear: 'Año de construcción',
    CLUSTER_createCluster: 'Crear nueva agrupación',
    CLUSTER_createClusterMessage: 'Los elementos a agrupar deberán ser siempre del mismo tipo conforme al desplegable superior. Los inmuebles no terminado no son susceptibles de agrupación.',
    CLUSTER_details: 'Datos de la agrupación',
    CLUSTER_next: 'Siguiente',
    CLUSTER_noSelectableAssets: 'Activos no seleccionables',
    CLUSTER_propertyRegistry: 'Finca registral',
    CLUSTER_selectableAssets: 'Activos seleccionables',
    CLUSTER_sureDelete: '¿ Estas seguro de querrer borar esta agrupación ?',
    CLUSTER_TERRAIN_clusterTerrain: 'Agrupación de terrenos',
    CLUSTER_TERRAIN_typologyInfo: 'Este activo pertenece a una agrupación de terrenos y no es posible editar su Uso. Para su edición debe extraerlo de la agrupación.',
    CLUSTER_typologyInfo: 'Este activo pertenece a una agrupación y no es posible editar su estado o cambiar su uso a terrenos/fincas rústicas o a explotaciones económicas. Para su edición debe extraerlo de la agrupación.',
    CLUSTER_unit: 'unidad',
    CLUSTER_valuationSurfaceSelectedMessage: 'La superficie seleccionada se puede modificar en Unidades',
    CLUSTERS_canNotChangeUseToTerrain: 'Este activo pertenece a una agrupación. Si quiere convertirlo en terreno es necesario sacarlo de dicha agrupación',
    CLUSTERS_surfaces_error: 'Es necesario añadir las superficies en el apartado de Superficies de cada activo y seleccionarlas en el apartado de Unidades.',
    CLUSTERS_valorations_summary: 'Desglose valoraciones',
    clusterSurface: 'Superficie agrupación',
    ClusterTerrainSurfaces_0: 'Útil',
    ClusterTerrainSurfaces_12: 'Construida sin zonas comunes',
    ClusterTerrainSurfaces_6: 'Construida con zonas comunes',
    CLUTER_reformLastYear: 'Año de reforma',
    CODE_MANAGEMENT_addNewCode: 'Añadir código',
    CODE_MANAGEMENT_code_management: 'Gestión de códigos',
    CODE_MANAGEMENT_discount_percentage: 'Porcentaje de descuento',
    CODE_MANAGEMENT_discountPercent: 'Porcentaje',
    CODE_MANAGEMENT_ending_date: 'Fecha de fin',
    CODE_MANAGEMENT_identifier: 'Identificador',
    CODE_MANAGEMENT_initial_date: 'Fecha de inicio',
    CODE_MANAGEMENT_is_active: 'Activo',
    CODE_MANAGEMENT_isActive: 'Activo',
    CODE_MANAGEMENT_isActiveFromDateTime: 'Fecha inicio',
    CODE_MANAGEMENT_isActiveUntilDateTime: 'Fecha fin',
    CODE_MANAGEMENT_payment_summary: 'Resumen de pagos',
    CODE_MANAGEMENT_plus_add_new: '+ Añadir nuevo',
    CODE_MANAGEMENT_promotionalCode: 'Identificador',
    CODE_MANAGEMENT_user: 'Usuario',
    code: 'Código',
    Code: 'Código',
    codeManagement: 'Gestión de códigos',
    collaborator: 'Colaborador',
    collaboratorCheckbox: '¿Colaborador?',
    COMMERCIAL_MANAGER_paymentOption: 'Método de pago',
    COMMERCIAL_MANAGER_USERS_add_commercial_manager: 'Añadir gestor comercial',
    COMMERCIAL_MANAGER_USERS_appraisals_in_process_count: 'Tasaciones en proceso',
    COMMERCIAL_MANAGER_USERS_email: 'Email',
    COMMERCIAL_MANAGER_USERS_first_name: 'Nombre',
    COMMERCIAL_MANAGER_USERS_is_active: 'Usuario activo',
    COMMERCIAL_MANAGER_USERS_lastname: 'Apellidos',
    COMMERCIAL_MANAGER_USERS_national_identity_document: 'DNI',
    COMMERCIAL_MANAGER_USERS_plus_add_user: '+ Añadir usuario',
    COMMERCIAL_MANAGER_USERS_position: 'Cargo',
    COMMERCIAL_MANAGER_USERS: 'Usuarios Gestores Comerciales',
    commercial_managers: 'Gestores comerciales',
    commercialFee: 'Honorarios suplidos',
    CommercialManager: 'Gestores comercial',
    CommercialMedian: 'Mediana comercial',
    COMPARISON_METHOD_comparison: 'Comparación',
    COMPARISON_METHOD_depreciation: 'Dep. Funcional ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_depreciationTotal: 'Dep. Funcional ({{currency}})',
    COMPARISON_METHOD_direction: 'Dirección',
    COMPARISON_METHOD_finalValue: 'Valor final ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_finalValueMonthly: 'Valor final ({{currency_per_surfaceUnit}}/mes)',
    COMPARISON_METHOD_homogCoef: 'Coef. Homog.',
    COMPARISON_METHOD_homogeneizedValue: 'Valor homogeneizado',
    COMPARISON_METHOD_homogeneizedValueDepreciated: 'Valor homogeneizado depreciado ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_initialValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_initialValueMonthly: 'Valor de mercado ({{currency_per_surfaceUnit}}/mes)',
    COMPARISON_METHOD_moreInfo: 'Más información',
    COMPARISON_METHOD_normalizedComparison: 'comparación ajustada',
    COMPARISON_METHOD_observations: 'Observaciones relativas al estudio de mercado',
    COMPARISON_METHOD_reduc: 'Reduc. / Negociación',
    COMPARISON_METHOD_surface: 'Superficie ({{surfaceUnit}})',
    COMPARISON_METHOD_typology: 'Tipología y subtipología',
    COMPARISON_METHOD_UnitValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
    COMPARISON_METHOD_weight: 'Peso',
    COMPARISON_METHOD_witnessInformation: 'Información del testigo',
    COMPARISON_METHOD_witnessComplete: 'Completa tu testigo',
    COMPARISON_METHOD_SearchWitness: 'Encuentra tu testigo',
    complete: 'Completar',
    completeRequiredFields: 'Campos requeridos completos',
    completing: 'Completando',
    COND_WARN_OBS_obsCode: 'OBS',
    conditions: 'Condicionantes',
    config_report: 'Configura tu informe',
    config: 'Configuración',
    confirm_and_get_assets: 'Confirmar y obtener activos',
    confirm: 'Confirmar',
    skip: 'Omitir',
    confirming: 'Confirmando',
    confirmInvoice: 'Confirmar factura',
    confirmPassword: 'Repite la contraseña',
    Connection_label: 'Tipo de conexión',
    connections: 'Conexiones',
    ConservationState: 'Estado de conservación',
    ConstructionYear: 'Año de construcción',
    contact_number: 'Contáctanos',
    contactLicenseeAdmin: 'Por favor, contacte con el administrador de su licencia',
    CONTRACT_METHOD_adoptedValueType: 'Tipo de valor adoptado',
    CONTRACT_METHOD_adoptedValueTypes: 'Tipos de valor adoptado',
    CONTRACT_METHOD_calculationDate: 'Fecha de cálculo',
    CONTRACT_METHOD_constructionCost: 'Coste de construcción',
    CONTRACT_METHOD_contractId: 'Identificador de contrato',
    CONTRACT_METHOD_contractInitialDate: 'Fecha de inicio contrato',
    CONTRACT_METHOD_contractualData: 'Datos contractuales',
    CONTRACT_METHOD_economicData: 'Datos económicos',
    CONTRACT_METHOD_monthlyNetIncome: 'Renta neta mensual',
    CONTRACT_METHOD_necessaryExpenses: 'Gastos necesarios',
    CONTRACT_METHOD_nextChargeDate: 'Fecha del siguiente cobro',
    CONTRACT_METHOD_observationsNetIncome: 'Observaciones relativas a la renta neta /  Información de estimación de los pagos',
    CONTRACT_METHOD_pendingContractDuration: 'Duración pendiente contrato',
    CONTRACT_METHOD_technicalValues: 'Valores técnicos edificación',
    CONTRACT_METHOD_tenantsFreeValue: 'Valor libre de inquilinos',
    CONTRACT_METHOD_updateType: 'Tipo de actualización',
    cost_group_valuation_message_information: '* El coste de construcción que se indicará corresponderá con el ponderado de todos los elementos que conforman la agrupación.',
    COST_METHOD_comeFromWorkInProgress: 'Este valor proviene del valor repartido en la pestaña de Estado de la Obra',
    COST_METHOD_futureMessage: 'El resto de campos sólo se pueden editar desde la pestaña de Valoración Actual',
    COST_METHOD_isUnfinishedMessage: 'La superficie adoptada solo podrá ser modificada en el capitulo de Estado de la Obra.',
    COST_METHOD_observations: 'Observaciones',
    COST_METHOD_justification_rent: 'Justificación de % de rentas imputables al inmueble',
    COST_METHOD_observationsGround: 'Justificación de los cálculos del valor de suelo',
    COST_METHOD_justification_construction_expenses: 'Justificación de los costes de construcción',
    COST_METHOD_justification_construction_expenses_text: 'El coste de construcción adoptado se ha obtenido de estadísticas internas elaboradas a partir de revistas especializadas contrastadas con presupuestos de construcción reales',
    costMethodRepercussionCalculateMessage: 'Si modifica el modo de introducir la repercusión, perderá los datos incluidos hasta el momento, ¿desea continuar?',
    country: 'País',
    CountryThreeLetterISORegionName: 'País',
    CREATE_CRAWLEDASSET_annexes: 'Anejos',
    CREATE_CRAWLEDASSET_annexesInfoMessage: 'Si fuera necesario, habrá que descontar del valor de oferta del comparable el valor de estos anejos',
    CREATE_CRAWLEDASSET_buildeableSurface: 'Superficie edificable',
    CREATE_CRAWLEDASSET_builtSurface: 'Superficie edificada',
    CREATE_CRAWLEDASSET_contactNumber: 'Número de contacto',
    CREATE_CRAWLEDASSET_coverTerraceSurface: 'Sup. terraza cubierta ({{surfaceUnit}})',
    CREATE_CRAWLEDASSET_crawledDateTime: 'Fecha de creación del testigo: {{date}}',
    CREATE_CRAWLEDASSET_crawledSource: 'Origen del testigo',
    CREATE_CRAWLEDASSET_numberOfGarages: 'Nº de plazas de garaje',
    CREATE_CRAWLEDASSET_observations: 'Observaciones',
    CREATE_CRAWLEDASSET_offerType: 'Tipo de oferta',
    CREATE_CRAWLEDASSET_offerValue: 'Valor de oferta',
    CREATE_CRAWLEDASSET_providerCreateDate: 'Fecha de creación del testigo:',
    CREATE_CRAWLEDASSET_providerData: 'Información del proveedor',
    CREATE_CRAWLEDASSET_providerId: 'ID del proveedor',
    CREATE_CRAWLEDASSET_providerUrl: 'URL del proveedor',
    CREATE_CRAWLEDASSET_sourceType: 'Tipo de origen',
    CREATE_CRAWLEDASSET_storageRoom: 'Trastero',
    CREATE_CRAWLEDASSET_uncoverTerraceSurface: 'Sup. terraza descubierta ({{surfaceUnit}})',
    create: 'Crear',
    createBacktesting: 'Crear Backtesting',
    created: 'Creadas',
    creating: 'Creando',
    CriteriaTypes_0: '% sobre ingresos',
    CriteriaTypes_1: '% sobre total de costes de construcción',
    CriteriaTypes_2: 'Manual ({{currency}})',
    Cultural: 'Cultural',
    currency_per_month: '{{currency}}/mes',
    currency_per_year: '{{currency}}/año',
    currency: 'Moneda',
    CurrentWorkflowStep: 'Estado',
    Customer: 'Cliente',
    CUSTOMERS_add_new_customer: '+ Añadir nuevo cliente',
    CUSTOMERS_billing_information: 'Datos de Facturación',
    CUSTOMERS_cif_nif: 'CIF/NIF',
    CUSTOMERS_cif: 'CIF',
    CUSTOMERS_contactEmail: 'Email',
    CUSTOMERS_contactPhone: 'Teléfono',
    CUSTOMERS_creation_date: 'Fecha de creación',
    CUSTOMERS_customers: 'Clientes',
    CUSTOMERS_email: 'Email',
    CUSTOMERS_firstName: 'Nombre',
    CUSTOMERS_its_a_company: 'Es una empresa',
    CUSTOMERS_lastName: 'Apellidos',
    CUSTOMERS_name: 'Nombre',
    CUSTOMERS_NEW_add_customer: 'Añadir nuevo cliente',
    CUSTOMERS_nif: 'NIF',
    CUSTOMERS_observations: 'Observaciones',
    CUSTOMERS_profile: 'Perfil',
    customers: 'Clientes',
    customerTranslationType: 'Tipo de traducción del cliente',
    customerValue: 'Valor del cliente',
    dashboard: 'Panel de control',
    DATE_OF_VISIT_addVisitDate: '+ Añadir fecha de visita',
    DATE_OF_VISIT_allDay: 'Cualquier momento del día',
    DATE_OF_VISIT_date: 'Fecha',
    DATE_OF_VISIT_from: 'Desde',
    DATE_OF_VISIT_until: 'Hasta',
    DATES_addedDateTime: 'añadido',
    DATES_appraiserAllDataSentDeadlineDateTime: 'límite para enviar informe',
    DATES_exchangeFinishDateTime: 'Límite envío informe',
    DATES_obtainedDatetime: 'Fecha de obtención',
    DATES_solvedDateTime: 'resuelto',
    DATES_visitScheduleDateTime: 'fecha visita',
    DATES_visitScheduleDeadlineDateTime: 'límite para agendar',
    degree: 'Titulación',
    delete: 'Borrar',
    DELIVERABLE_MODAL_deliverable: 'Entregable',
    DELIVERABLE_MODAL_documentName: 'Nombre de documento',
    DELIVERABLE_MODAL_documentVersion: 'Version documento',
    DELIVERABLE_MODAL_downloadAll: 'Descargar todos',
    DELIVERABLE_MODAL_downloading: 'Descargando',
    DELIVERABLE_MODAL_emissionDate: 'Fecha de emisión',
    DELIVERABLE_MODAL_expirationDate: 'Fecha de caducidad',
    DELIVERABLE_MODAL_external: 'Externo',
    DELIVERABLE_MODAL_generationDate: 'Fecha de generación',
    DELIVERABLE_MODAL_internal: 'Interno',
    DELIVERABLE_REPOSITORY_addRow: '+ Añadir nuevo entregable',
    DELIVERABLE_REPOSITORY_deliverable: 'Entregable',
    DELIVERABLE_REPOSITORY_orderType: 'Tipo de encargo',
    DELIVERABLE_REPOSITORY_prupose: 'Finalidad',
    DELIVERABLE_REPOSITORY_source: 'Origen',
    deliverables: 'Entregables',
    deliverablesConfiguration: 'Configuración de entregables',
    deliveredByAppraiser: 'Entregadas por técnico',
    deliveredToClient: 'Finalizadas',
    description: 'Descripción',
    Description: 'Descripción',
    detail: 'Detalle',
    Developable: 'Urbanizable',
    DgcCodVia: 'Código vía',
    dni: 'DNI',
    document: 'Documento',
    documents: 'Documentos',
    download: 'Descargar',
    downloadBatchFile: 'Descargar fichero del lote',
    downloadCsv: 'Descargar CSV',
    duplicate: 'Duplicar',
    duplicateBatch: 'Duplicar lote',
    ECONOMIC_EXPLOITATION_buildingOrRehabilitationYear: 'Año de construcción o rehabilitación',
    ECONOMIC_EXPLOITATION_costMethod: 'Método de coste',
    edit_cost_method_percentages: 'Editar porcentajes',
    edit: 'Editar',
    elevator: 'Ascensor',
    email: 'Email',
    Email: 'Email',
    EnergeticCertificateGrade: 'Certificado energético',
    english: 'Inglés',
    ENTITIES_add_entity: 'Añadir entidad',
    ENTITIES_auto_billing: 'Facturación Automática',
    ENTITIES_cif: 'CIF',
    ENTITIES_companyName: 'Nombre/Razón social',
    ENTITIES_contactEmail: 'Email',
    ENTITIES_contactPhone: 'Teléfono',
    ENTITIES_createEntity: 'Crear cuenta',
    ENTITIES_creationDateTime: 'Fecha de creación',
    ENTITIES_email: 'Email',
    ENTITIES_entities: 'Entidades',
    ENTITIES_entityType: 'Tipo de entidad',
    ENTITIES_sectorType: 'Sector',
    ENTITIES_hasCustomer: '¿Es cliente?',
    ENTITIES_hasPrescriptor: '¿Es prescriptor?',
    ENTITIES_hasUsers: '¿Tiene usuarios?',
    ENTITIES_its_a_company: 'Es una empresa',
    ENTITIES_isAppraiserSociety: 'Es una sociedad de tasación homologada',
    ENTITIES_lastName: 'Apellidos',
    ENTITIES_name: 'Nombre',
    ENTITIES_nationalIdentityDocument: 'DNI/CIF',
    ENTITIES_nif: 'NIF',
    ENTITIES_observations: 'Observaciones',
    ENTITIES_plus_add_new: '+ Añadir nueva',
    ENTITIES_profile: 'Perfil',
    entitiesLabel: 'Cuentas',
    entrance: 'Portal',
    EnvironmentPersonalOpinion: 'Opinión',
    error: 'Error',
    errors: 'Errores',
    EX_each: 'Cada',
    EX_economicDataInfoModalContent: '<ul><li><span>Descripción</span> - Descripción del Ingreso/Gasto.</li><li><span>Valor Asignado</span> - Cuantía del Ingreso/Gasto ({{currency}}).</li><li><span>¿Es periódico?</span> - Tipología de Ingreso/Gasto. Se indicará si es recurrente (periódico) o puntual (no periódico).<ul class="inside"><li>El Valor Asignado al Ingreso/Gasto es Periódico, se repartirá de forma automática y equitativa entre el periodo que se haya selección en los campos de Fecha inicio/fin del Ingreso/Gasto.</li><li>El Valor Asignado al Ingreso/Gasto no es Periódico, se repartirá la cuantía de forma manual en los flujos de caja del método.</li></ul></li><li><span>Periodicidad</span> - Periodicidad del Ingreso/Gasto. Podrá ser Anual o Mensual.</li><li><span>Fecha inicio</span> - Fecha a partir de la cual se producirá el Ingreso/Gasto.</li><li><span>Fecha fin</span> - Fecha hasta la cual se producirá el Ingreso/Gasto.</li></ul>',
    EX_economicDataInfoModalTitle: 'Información',
    EX_expensesObservations: 'Observaciones sobre gastos. En caso de emplear ratios medios especificar las fuentes',
    EX_incomesObservations: 'Observaciones sobre ingresos. En caso de emplear ratios medios especificar las fuentes',
    EX_month_months: 'mes/meses',
    EX_repeatEach: 'Repetir cada:',
    EX_year_years: 'año/años',
    existFormChanges: 'Hay cambios en el formulario',
    externalCode: 'Código Externo',
    ExternalRecordId: 'Nº de referencia externa',
    externalTech: 'Técnico externo',
    ExternalWoodWork: 'Carpintería exterior',
    extract: 'Extracto',
    Facilities: 'Instalaciones',
    FacilityObservations: 'Observaciones',
    familiarRelationShip: 'Parentesco',
    features: 'Características',
    Fee: 'Honorarios',
    fileInfoFileName: 'info_archivo_procesado.txt',
    fileName: 'Nombre de archivo',
    filesAverageTime: 'Tiempos medios de',
    filesGlobalAverageTime: 'Tiempo medio global de expedientes',
    filesVolume: 'Volumen de',
    filter: 'Filtrar',
    financial: 'Financiera',
    finished: 'Finalizadas',
    FinishedAppraisals: 'Tasaciones finalizadas',
    finishedAppraisals: 'Valoraciones finalizadas',
    finishedDate: 'Fecha de finalización',
    FirstName: 'Nombre',
    floor: 'Planta',
    Floor: 'Planta',
    FORM_VALIDATION_ALPHANUMERIC: 'Solo se permiten caracteres alfanuméricos',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'El formato debe ser: 999999-999999',
    FORM_VALIDATION_CUSTOMER_ALREADY_EXISTS: 'Dicho usuario ya existe',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'El formato de email no es válido',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'El formato de email no es válido',
    FORM_VALIDATION_MAX_ADDITION: 'La suma supera: ',
    FORM_VALIDATION_MAX_LENGTH: 'La longitud máxima del campo es de: ',
    FORM_VALIDATION_MAX: 'El valor máximo es: ',
    FORM_VALIDATION_MIN_LENGTH: 'La longitud mínima del campo es de: ',
    FORM_VALIDATION_MIN: 'El valor mínimo es: ',
    FORM_VALIDATION_NOT_SAME: 'Los campos no coinciden',
    FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH: 'Su contraseña debe tener al menos 8 caracteres y contener una combinación de letras minúsculas y mayúsculas, números y caracteres especiales (por ejemplo,! # $).',
    FORM_VALIDATION_PATTERN: 'El formato es incorrecto',
    FORM_VALIDATION_REQUIRED: 'Este campo es obligatorio',
    free: 'Libre',
    from: 'Desde',
    FuelStation: 'Estación de servicio',
    Garage: 'Garaje',
    gate: 'Puerta',
    Gate: 'Puerta',
    generalData: 'Datos generales',
    generate_password: 'Generar contraseña',
    GestasaId: 'Nº Gestasa',
    GlobalToOrder: 'Global',
    greenZones: 'Zonas verdes',
    group_valuation_message_information: '* El año de construcción se corresponderá con el ponderado de todos los elementos que conforman la agrupación.',
    had_recalculate_valoration: 'Tiene que recalcular para poder agregar una valoracion',
    HasAirConditioning: 'Climatización',
    HasArquitectonicProtectionRegime: 'Régimen de protección arquitectónica',
    HasaSportsZone: 'Zona deportiva',
    HasAssetSketch: 'Croquis del inmueble',
    HasCadastralCertification: 'Certificado catastral',
    HasCadastralGoodsCertificate: 'Certificado de bienes catastrales',
    HasCadastralProperty: 'Propiedad catastral',
    HasCadastralRecord: 'Ficha catastral',
    HasConservationState: 'Estado de conservación',
    HasContract: 'Documentación que acredita el estado de la ocupación',
    HasCorrespondanceWithRegisteredProperty: 'Correspondencia con el registro de la propiedad',
    HasCurrentPlanAdequation: 'Adecuación al plan actual',
    HasDeck: 'Cubierta',
    HasElevator: 'Ascensor',
    HasGarden: 'Jardín',
    HasParking: 'Aparcamiento',
    HasEnergeticCertificate: '¿Certificado energético?',
    HasExpropiation: 'Expropiación',
    HasExternalEnclosure: 'Cerramiento exterior',
    HasGas: 'Gas',
    HasGreenArea: 'Zonas verdes',
    HasHeating: 'Calefación',
    HasHotWater: 'Agua caliente',
    HasIBIBill: 'Recibo IBI',
    HasIntercom: 'Portero automático',
    HasNonexistenceLeasesDocument: 'Documento inexistencia arrendamiento',
    HasOccupationStateUseAndExploitation: 'Estado de ocupación, uso y explotación',
    HasPaymentMethod: 'Método de pago',
    HasPhone: 'Telefono',
    HasPhotos: 'Fotografias',
    HasPhysicalAndRegisteredIdenficication: 'Identificación física y registral',
    HasPlumbingAndSanitaryEquipment: 'Fontanería y aparatos sanitarios',
    HasPool: 'Piscina',
    HasProperyRegisterBookCopy: 'Fotocopia del libro de la propiedad',
    HasPublicProtectionRegime: 'Régimen de protección pública',
    HasRegisteredCertificationUpdated: 'Certificado registral actualizado',
    HasRegisteredPropertyDeed: 'Escritura de la propiedad registrada',
    HasSanitation: 'Saneamientos',
    HasSecurity: 'Seguridad',
    HasSimpleNoteOutdated: 'Nota simpre desactualizada',
    HasSimpleNoteUpdated: 'Nota simple actualizada',
    HasSituation: 'Situación',
    HasSwimmingPool: 'Piscina',
    HasTenisField: 'Tenis',
    HasTownHallCertificate: 'Certificado del ayuntamiento',
    HasUnregisteredPropertyDeed: 'Escritura de la propiedad sin registrar',
    HasUrbanism: 'Urbanismo',
    HasUrbanisticIdentification: 'Cédula urbanística',
    HasUrbanisticNormative: 'Normativa urbanística',
    HasVisibleEasements: 'Servidumbres visibles',
    heating: 'Calefación',
    hide_filters: 'Ocultar filtros',
    HOMOG_COEF_MODAL_accessibility: 'Coef. Accesibilidad',
    HOMOG_COEF_MODAL_antiquity: 'Coef. Antiguedad',
    HOMOG_COEF_MODAL_calculateOmogCoef: 'Calcular coeficiente de homogeneización',
    HOMOG_COEF_MODAL_direction: 'Dirección',
    HOMOG_COEF_MODAL_height: 'Coef. Altura',
    HOMOG_COEF_MODAL_infoTooltip: 'Las observaciones serán obligatorias si tiene valor el coeficiente “otros”',
    HOMOG_COEF_MODAL_marketPrice: 'Valor de mercado ({{ currency_per_surfaceUnit }})',
    HOMOG_COEF_MODAL_morphology: 'Coef. Morfología',
    HOMOG_COEF_MODAL_observationPLaceholder: 'Observaciones',
    HOMOG_COEF_MODAL_orientation: 'Coef. Orientación',
    HOMOG_COEF_MODAL_others: 'Coef. Otros',
    HOMOG_COEF_MODAL_slope: 'Coef. Pendiente',
    HOMOG_COEF_MODAL_polyvalence: 'Coef. Polivalencia',
    HOMOG_COEF_MODAL_requiredFields: 'Deben completarse todos los coeficientes de homogeneización. En caso de no aplicar algún concepto dejar en 1,00',
    HOMOG_COEF_MODAL_situation: 'Coef. Situación',
    HOMOG_COEF_MODAL_state: 'Coef. Estado',
    HOMOG_COEF_MODAL_surface: 'Coef. Superficie',
    HOMOG_COEF_MODAL_typologieAndSubtypologies: 'Tipología y subtipología',
    HOMOG_COEF_MODAL_witnessSurface: 'Superficie ({{ surfaceUnit }})',
    HOMOG_COEF_MODAL_elevators: 'Nº de ascensores',
    HOMOG_COEF_MODAL_constructionRehabilitation: 'Año construcción/ rehabilitación',
    Hotel: 'Hotel',
    identification: 'Identificación',
    Identification: 'Identificación',
    Idufir: 'C.R.U (IDUFIR)',
    incidents: 'Incidencias',
    incidentsResolutionAverageTime: 'Tiempo medio resolución de incidencias',
    incomeOrExpensesInvoiced: 'Facturación Ingresos/Gastos',
    incomeOrExpensesProvision: 'Provisión Ingresos/Gastos',
    incomesExpensesPeriodicityMessage: ' El Valor asignado introducido se repetirá según la periodicidad indicada (cuantía anual o mensual).',
    INCOMPATIBILITIES_userName: 'Nombre de usuario',
    incompatibilities: 'Incompatibilidades',
    IndividualByAsset: 'Particular por inmuebles',
    IneCodLocality: 'Código de localidad',
    inProcess: 'En proceso',
    inProgress: 'En proceso',
    inputFileWebPath: 'Ruta del fichero',
    Inscription: 'Inscripción',
    InternalWoodWork: 'Carpintería interior',
    invoice: 'Factura',
    INVOICES_ALL_INVOICES: 'Todas las facturas',
    INVOICES_askForConfirm: 'Pedir Confirmación',
    INVOICES_askForConfirmation: 'Solicitar confirmación',
    INVOICES_askForConfirmationModal: 'Va a solicitar confirmación de la factura. ¿Estás seguro?',
    INVOICES_billingFlow: 'Flujo de facturación',
    INVOICES_cancelationReason: 'Mótivo de la cancelación',
    INVOICES_cif_nif: 'CIF/NIF',
    INVOICES_code_management: 'Gestión de códigos',
    INVOICES_confirmation_date: 'Fecha de confirmación',
    INVOICES_CONFIRMATION_SENT: 'Confirmación enviada',
    INVOICES_CONFIRMED: 'Confirmada',
    INVOICES_dayFlow: 'Día/Flujo',
    INVOICES_DETAIL_resetInvoiceConfirmation: 'Se va a reinciar la factura actual. Esto puede llevar unos segundos.',
    INVOICES_downloadBills: 'Descargar facturas',
    INVOICES_downloadDraft: 'Descargar borrador',
    INVOICES_downloadInvoicesModal: 'Se van a descargar todas las facturas del año y mes elegidos. Esto puede llevar unos minutos',
    INVOICES_downloadInvoicesSummaryMonth: 'Descargar resumen mensual Excel',
    INVOICES_downloadInvoicesSummaryYear: 'Descargar resumen anual Excel',
    INVOICES_downloadInvoicesZip: 'Descargar ZIP de facturas',
    INVOICES_file_number: 'Nº de expediente',
    INVOICES_fullInvoiceReset: 'Reiniciar por completo',
    INVOICES_incomeTax: 'Cuota del',
    INVOICES_invoiceConfirmationDate: 'Fecha confirmacion de la factura',
    INVOICES_invoiceGenerationDate: 'Fecha generación de la factura',
    INVOICES_internal_reference_number: 'Nº de referencia interno',
    INVOICES_massConfirmation: 'Va a solicitar confirmación de todas las facturas del año y mes elegidos. Esto puede llevar unos minutos ',
    INVOICES_month: 'Mes',
    INVOICES_invoiceMonth: 'Mes factura',
    INVOICES_name: 'Nombre',
    INVOICES_payment_summary: 'Resumen de pagos',
    INVOICES_PENDING_REQUEST_CONFIRMATION: 'Pendiente de solicitar confirmación',
    INVOICES_REJECTED: 'Rechazada',
    INVOICES_rejection_date: 'Fecha de rechazo',
    INVOICES_reseting: 'Reiniciando factura',
    INVOICES_resetInvoice: 'Reiniciar factura',
    INVOICES_resetInvoiceKeepState: 'Reiniciar manteniendo el estado',
    INVOICES_resetInvoiceKeepStatus: 'Mantener estado',
    INVOICES_resetInvoices: 'Reiniciar facturas',
    INVOICES_resetInvoicesConfirmation: 'Se van a reiniciar todas las facturas del año y mes elegidos. Esto puede llevar unos minutos',
    INVOICES_sent_confirmation_date: 'Fecha de envío de la confirmación',
    INVOICES_status: 'Estado',
    INVOICES_subtotal: 'Subtotal',
    INVOICES_taxBase: 'Base imponible al',
    INVOICES_technical_mannager_users: 'Usuarios gestores de técnicos',
    INVOICES_total_with_taxes_included: 'Importe (impuestos incluidos)',
    INVOICES_total: 'Total',
    INVOICES_year: 'Año factura',
    INVOICES_youSure: '¿Está seguro?',
    invoices: 'Facturas',
    InvoiceStatus_0: 'Pendiente de solicitar confirmación',
    InvoiceStatus_1: 'Confirmación enviada',
    InvoiceStatus_2: 'Confirmada',
    InvoiceStatus_3: 'Rechazada',
    irpfPercentage: 'Porcentaje de Retención de IRPF (%)',
    IrpfPercentage: 'Porcentaje de Retención de IRPF (%)',
    IsActive: 'Usuario activo',
    isActiveLicense: 'Licencia Activa',
    IsAdequatedToCurrentPlanning: 'Planeamiento vigente',
    IsOkStatusValues_false: 'KO',
    IsOkStatusValues_true: 'OK',
    isUserActive: 'Usuario activo',
    isVPO: '¿Es VPO?',
    language: 'Idioma',
    LastName: 'Apellidos',
    LastRehabilitationYear: 'Año de reforma',
    lastUpdate: 'Última actualización',
    latitude: 'Latitud',
    Latitude: 'Longitud',
    LEGAL_MAX_value: 'Valor',
    LegalSituation: 'Situación legal',
    LICENSE_individual: 'Individual',
    LICENSE_teams: 'Equipos',
    LicenseExpirationDatetime: 'Fecha fin',
    licenseExpired: 'Su licencia ha caducado',
    LicensePlanChange: 'Histórico',
    LicenseRegistryDatetime: 'Fecha de registo',
    list: 'Listado',
    locality: 'Localidad',
    Locality: 'Localidad',
    LocalityEnvironmentInformation: 'Localización y Entorno',
    location: 'Localización',
    Location: 'Localización',
    LocationConfirmedType: 'Tipo de localización',
    LocationObservations: 'Observaciones',
    log_in: 'Iniciar sesión',
    log_out: 'Cerrar sesión',
    logging_in: 'Iniciando sesión...',
    LOGIN_forgotPassword: '¿Olvidó su contraseña?',
    LOGIN_password: 'Contraseña*',
    LOGIN_resetYourPassword: 'Introduzca su correo electrónico, se le enviará un correo con las instruciones para restaurar su contraseña',
    LOGIN_signin: 'Iniciar sesión',
    LOGIN_userName: 'Nombre de usuario/Email*',
    logout: 'Cerrar sesión',
    Longitude: 'Latitud',
    longitude: 'Longitud',
    mainAssetTipology: 'Tipología Calculado',
    mainAssetUse: 'Uso Calculado',
    mainBatchAssetTipology: 'Tipología Origen',
    mainBatchAssetUse: 'Uso Origen',
    mainConstructiveSubtypology: 'Subtipología',
    mainConstructiveTotalBuiltSurfaceWithCommonElements: 'Superficie construida',
    mainConstructiveTypology: 'Tipología',
    mainConstructiveUse: 'Uso',
    MainElements_Deck: 'Cubierta',
    MainElements_Enclosure: 'Cubierta',
    MainElements_Structure: 'Estructura',
    mainUse: 'Uso principal',
    manager: 'Responsable',
    massive: 'Carga masiva',
    MAXIMUM_LEGAL_mainAssetPercentage: 'Porcentaje de unidad principal',
    MAXIMUM_LEGAL_totalValue: 'Valor total',
    MAXIMUM_LEGAL_unitaryValue: 'Valor unitario',
    MAXLEGAL_METHOD_costMethodRequired: 'Valoración por método de coste',
    MAXLEGAL_METHOD_groundLimitationValue: 'Limitación de valor del suelo',
    MAXLEGAL_METHOD_groundMaximumLegal: 'Valor máximo legal del suelo',
    MAXLEGAL_METHOD_groundTotalValue: 'Valor total del suelo',
    MAXLEGAL_METHOD_isNotChildElement: 'Este elemento no tiene activo principal',
    MAXLEGAL_METHOD_maximumLegalValue: 'Valor máximo legal',
    MAXLEGAL_METHOD_parentHasNotMaximumLegalValue: 'El elemento padre no dispone de valor en el método de máximo legal',
    MAXLEGAL_METHOD_parentLegalMaxValue: 'El valor total del máximo legal del padre es ',
    MAXLEGAL_METHOD_qualifiedSurface: 'Superficie calificada',
    MAXLEGAL_METHOD_REAMaximumLegal: 'REA (máximo legal)',
    MAXLEGAL_METHOD_REAMethodCost: 'REA (método de coste)',
    MAXLEGAL_METHOD_residualStaticAbr: 'Método residual estático en el método de valoración coste',
    MAXLEGAL_METHOD_totalValue: 'Valor total máximo legal',
    MAXLEGAL_METHOD_unitValue: 'Valor unitario máximo legal',
    MAXLEGAL_METHOD_updatedNetIncome: 'Rentas actualizadas',
    MAXLEGAL_METHOD_updatedReversionValue: 'Reversión actualizado',
    MAXLEGAL_METHOD_updatedTotalValue: 'Total actualizado',
    MAXLEGAL_METHOD_updatedValues: 'Valores actualizados',
    MAXLEGAL_METHOD_usefulCheckedSurface: 'Superficie útil comprobada',
    MAXLEGAL_METHOD_usefulQualifiedSurface: 'Superficie útil cédula calif',
    MAXLEGAL_METHOD_usefulRegistralSurface: 'Superficie útil registral',
    MAXLEGAL_METHOD_valueTypes: 'Tipos de valor',
    MENU_ASSESSMENTS_add: 'Añadir Valoración',
    MENU_ASSESSMENTS_claims: 'Claims',
    MENU_ASSESSMENTS_incidents: 'Incidents',
    MENU_ASSESSMENTS_massive_load: 'Carga masiva',
    MENU_ASSESSMENTS_orders_alive: 'Appraisals Actives',
    MENU_ASSESSMENTS_orders_all: 'Todas',
    MENU_ASSESSMENTS_orders_cancelled: 'Cancelled',
    MENU_ASSESSMENTS_orders_deliveredByAppraiser: 'Delivered by technician',
    MENU_ASSESSMENTS_orders_deliveredToClient: 'Delivered to Client',
    MENU_ASSESSMENTS_orders_inProcess: 'In process',
    MENU_ASSESSMENTS_orders_pendingToAssignManager: 'Pendientes de Asignar Responsable',
    MENU_ASSESSMENTS_orders_pendingToSchedule: 'Pendientes de Agendar',
    MENU_ASSESSMENTS_orders_scheduledByTec: 'Agendadas por Técnico',
    MENU_ASSESSMENTS_orders_toAssign: 'Pendientes de Asignar Técnico',
    MENU_ASSESSMENTS_orders_validated: 'Validated',
    MENU_ASSESSMENTS_orders_validationProcess: 'Validation in Progress',
    MENU_ASSESSMENTS_orders: 'Encargos',
    MENU_ASSESSMENTS_portfolios: 'Carteras',
    MENU_ASSESSMENTS: 'Valoraciones',
    MENU_ASSETS_add: 'Añadir',
    MENU_ASSETS_assets: 'Activos',
    MENU_ASSETS_list: 'Listado',
    MENU_CLAIMS: 'Claims',
    MENU_CLIENTS: 'Clients',
    MENU_dashboard: 'Panel de control',
    MENU_ENTITIES_appraisal_companies: 'Sociedades de tasación',
    MENU_ENTITIES_customers: 'Clientes',
    MENU_ENTITIES_prescribers: 'Prescriptores',
    MENU_ENTITIES_users: 'Usuarios',
    MENU_ENTITIES: 'Entidades',
    MENU_FINANCIAL_paymentSummary_allInvoices: 'Todas las facturas',
    MENU_FINANCIAL_paymentSummary_confirmedByInvoicer: 'Confirmed',
    MENU_FINANCIAL_paymentSummary_pendingToSendToInvoicer: 'Pending confirmation request',
    MENU_FINANCIAL_paymentSummary_rejectedByInvoicer: 'Rejected',
    MENU_FINANCIAL_paymentSummary_sendToRevisionToInvoicer: 'Confirmation sent',
    MENU_FINANCIAL_paymentSummary: 'Payment Summary',
    MENU_FINANCIAL: 'Financial',
    MENU_ORDERS_list: 'Listado',
    MENU_ORDERS_requests: 'Solicitudes',
    MENU_QUALITYCONTROL_createOrder: 'Create order',
    MENU_QUALITYCONTROL_orders_alive: 'Appraisals Actives',
    MENU_QUALITYCONTROL_orders_all: 'Todas',
    MENU_QUALITYCONTROL_orders_cancelled: 'Cancelled',
    MENU_QUALITYCONTROL_orders_deliveredToClient: 'Delivered to Client',
    MENU_QUALITYCONTROL_orders_pending2AssignTechnical: 'Pending to Assign Technical',
    MENU_QUALITYCONTROL_orders_pendingToAssignProcessManager: 'Pending to Assign Process Manager',
    MENU_QUALITYCONTROL_orders_pendingToAssignTechnicalManager: 'Pending to Assign Technical Manager',
    MENU_QUALITYCONTROL_orders_pendingToStartValidation: 'Pending to start Validation',
    MENU_QUALITYCONTROL_orders_validating: 'Validation in progress',
    MENU_QUALITYCONTROL_orders_validationFinished: 'Validation finished',
    MENU_QUALITYCONTROL_orders: 'Encargos',
    MENU_QUALITYCONTROL: 'Quality control',
    MENU_USERS_processManagers_list: 'Listado',
    MENU_USERS_processManagers_statistics: 'Statistics',
    MENU_USERS_processManagers: 'Usuarios Gestores de Procesos',
    MENU_USERS_professionals: 'Profesionales',
    MENU_USERS_techManagers_list: 'Listado',
    MENU_USERS_techManagers: 'Usuario Gestores de Técnicos',
    MENU_USERS: 'Usuarios',
    MinimumChecks: 'Comprobaciones mínimas',
    minlength: 'Longitud mínima {{value}}',
    moreFilters: 'Más filtros',
    month: 'Mes',
    MonthsEnum_1: 'Enero',
    MonthsEnum_10: 'Octubre',
    MonthsEnum_11: 'Noviembre',
    MonthsEnum_12: 'Diciembre',
    MonthsEnum_2: 'Febrero',
    MonthsEnum_3: 'Marzo',
    MonthsEnum_4: 'Abril',
    MonthsEnum_5: 'Mayo',
    MonthsEnum_6: 'Junio',
    MonthsEnum_7: 'Julio',
    MonthsEnum_8: 'Agosto',
    MonthsEnum_9: 'Septiembre',
    municipality: 'Municipio',
    my_network: 'Mi red',
    MY_PROFILE_MODAL_cancel: 'Cancelar',
    MY_PROFILE_MODAL_cancelling: 'Cancelando',
    MY_PROFILE_MODAL_confirm: 'Confirmar',
    MY_PROFILE_MODAL_confirming: 'Confirmando',
    MY_PROFILE_MODAL_my_profile: 'Mi perfil',
    MY_PROFILE_MODAL_save: 'Guardar',
    MY_PROFILE_myProfile: 'Mi perfil',
    my_profile: 'Mi perfil',
    my_work_modal_body: 'Al trabajar como <strong>profesional</strong> en esta licencia no podrás acceder a ella a través de la versión web. Si quieres ver o trabajar sobre tus expedientes podrás hacerlo a través de la aplicación que tienes disponible en las tiendas (Google Play o App Store) o desde la versión móvil.',
    my_work_modal_btn: 'Ir a la versión móvil',
    my_work_modal_title: 'AVISO',
    MY_WORK_mywork: 'Mi Trabajo',
    myComparables: 'Comparables Valum',
    externalComparables: 'Comparables externos',
    mywork_finalized_expedients: 'Expedientes finalizados',
    mywork_job_summary: 'Resumen de trabajo',
    mywork_my_assignments: 'Mis asignaciones',
    mywork_my_pending_job: 'Mi trabajo pendiente',
    mywork_pending_expedients: 'Expedientes pendientes',
    mywork_total_expedients: 'Expedientes totales',
    mywork_totals: 'Totales',
    myData_info_displacement: 'Con el fin de calcular nuestra aportación a la reducción de la huella de carbono, necesitaríamos nos indicaras el modo en el que realizas los desplazamientos para las visitas',
    myData_label_displacement: 'Modo de desplazamiento más habitual',
    myData_preferences_typology: 'Tipologías',
    MULTIPLE_RECALCULATION_title: 'Recálculo múltiple de activos',
    MULTIPLE_RECALCULATION_subtitle: '*Se efectúa el recálculo de todos los activos pendientes de ser recalculados.',
    MULTIPLE_RECALCULATION_result: 'Resultado del recálculo',
    name_or_business_name: 'Nombre o razón social',
    name: 'Nombre',
    Name: 'Nombre',
    nationalBankSpainCutomerType: 'Tipo de cliente de BDE',
    NationalIdentityDocument: 'DNI',
    nationalIdentityDocument: 'Documento nacional de identidad',
    necessaryRecalculate: 'Es necesario recalcular antes de poder editar.',
    necessaryRecalculateToNavigate: 'Es necesario recalcular antes de poder acceder.',
    NETWORK_acceptCancelation: 'Aceptar cancelación',
    NETWORK_acceptRequest: 'Aceptar solicitud',
    NETWORK_acceptRequestQuestion: '¿Aceptar solicitud?',
    NETWORK_alreadyRelatedMessage: 'Esta persona ya pertenece a tu red de contactos.Revisa que los datos que has introducido son correctos.',
    NETWORK_cancelConfirmation: '¿Está seguro de querer cancelar esta conexión?',
    NETWORK_cancelConnection: 'Cancelar conexión',
    NETWORK_code: 'Código',
    NETWORK_codeCopied: '¡Código copiado!',
    NETWORK_colaborateWithLicenses: 'Colabora con otras licencias',
    NETWORK_conectionDate: 'Fecha de conexión',
    NETWORK_confirmAndSend: 'Confirmar y enviar',
    NETWORK_connectionCode: 'Código de conexión',
    NETWORK_connectionHistory: 'Histórico de conexiones',
    NETWORK_connectionType: 'Tipo de conexión',
    NETWORK_copyCode: 'Copiar código',
    NETWORK_insertPersonalDataToConnect: 'Introduce los datos de persona con la que quieres conectar',
    NETWORK_invalidSharingIdMessage: 'El código que has introducido no coincide con ningún usuario de Tasafy, comprueba este código y realiza de nuevo la solicitud',
    NETWORK_joinYourLicense: 'Unirse a tu licencia',
    NETWORK_licenseeCodeContent: 'Este es el código de licencia que otros profesionales deben usar para buscarte y conectar contigo. De esta forma, pasan a ser parte de tu red y puedes empezar a asignarles todo el trabajo que necesites.',
    NETWORK_licenseeCodeTitle: '¡Permite que otros profesionales se unan a tu licencia!',
    NETWORK_licenseMessage: 'Quiero colaborar contigo para hacer tasaciones en...',
    NETWORK_message: 'Mensaje',
    NETWORK_myIdentification: 'Mi identificación',
    NETWORK_name: 'Nombre',
    NETWORK_newConnection: 'Nueva conexión',
    NETWORK_pendingConnectionRequestMessage: 'Ya has intentado conectar con esta persona pero todavía no ha aceptado la invitación. Puedes reenviarle de nuevo la solicitud de conexión',
    NETWORK_professionalCodeContent: 'Este es el código de profesional con el que otras licencias pueden buscarte y colaborar contigo. De esta forma pasas a ser parte de su red y pueden empezar a asignarte trabajo.',
    NETWORK_professionalCodeTitle: '{{userName}}, ¿quieres colaborar con otras licencias como profesional?',
    NETWORK_reassignAppraisals: 'Reasignar todos los expedientes a: ',
    NETWORK_received: 'Recibida',
    NETWORK_rejectRequest: 'Rechazar solicitud',
    NETWORK_requestDate: 'Fecha de solicitud',
    NETWORK_requestMessageWorkFor: '¡{{name}} quiere conectar contigo!',
    NETWORK_requestMessageWorkWith: '¡{{name}} quiere trabajar contigo!',
    NETWORK_requests: 'Solicitudes',
    NETWORK_resendConfirmation: '¿Está seguro de querer reenviar la solicitud?',
    NETWORK_resendRequest: 'Reenviar solicitud',
    NETWORK_sended: 'Enviada',
    NETWORK_state: 'Estado',
    NETWORK_successfulCancelation: 'La cancelación de conexión con {{name}} se ha realizado correctamente',
    NETWORK_userMessage: 'Quiero trabajar contigo para aumentar mi red de técnicos en...',
    NETWORK_wantWorkFor: '¿Quieres trabajar para...?',
    NETWORK_wantWorkWith: '¿Quieres trabajar con...?',
    NETWORK_workWithMe: '¡Trabaja conmigo!',
    NETWORK_workWithSomeone: '¡Únete a alguien!',
    newCustomer: 'Nuevo cliente',
    newProfessional: 'Nuevo profesional',
    nif: 'NIF',
    no_documents: 'No hay documentos',
    not_valid_email: 'Email no válido',
    not: 'no',
    notAutomaticInvoicing: 'Facturación no automática',
    NotDevelopable: 'No urbanizable',
    notHavePrescriber: 'No tiene prescriptor',
    notInvoiced: 'No facturado',
    number: 'Número',
    NumberOfBuildingAccesses: 'Número de accesos',
    NumberOfLicensees: 'Nº usuarios activos',
    Observación: 'Observaciones',
    Observations: 'Observación',
    observations: 'Observaciones',
    justificationPercentageNegotiation: 'Justificación del % de negociación',
    residualObservations: 'Observaciones de las hipótesis de construcción y costes de construcción',
    OcupationState: 'Estado de ocupación',
    OcupationType: 'Tipo de ocupación',
    of: 'de',
    Office: 'Oficina',
    ORDER_REQUEST_DETAIL_addNewAsset: '+ Añadir nuevo activo',
    ORDER_REQUEST_DETAIL_available: 'Disponible',
    ORDER_REQUEST_DETAIL_cancel: '- Cancelar',
    ORDER_REQUEST_DETAIL_euro: 'EUROS(sin tasas).',
    ORDER_REQUEST_DETAIL_hasAppraisals: 'En una valoración',
    ORDER_REQUEST_DETAIL_idufir: 'C.R.U(IDUFIR)',
    ORDER_REQUEST_DETAIL_propertyRegister: 'Registro de la propiedad',
    ORDER_REQUEST_DETAIL_propertyRegisterNumber: 'Nº registro',
    ORDER_REQUEST_DETAIL_realEstate: 'Finca',
    ORDER_REQUEST_DETAIL_registerData: 'Datos registrales',
    ORDER_REQUEST_DETAIL_showDetail: 'Ver detalle',
    ORDER_REQUEST_requestSimpleNote: 'Solicitar nota simple',
    ORDER_REQUESTS_customerWillReceiveAnEmailSoon: 'En breve, el cliente recibirá un email con el presupuesto y los datos de la solicitud.',
    order: 'Pedido',
    orderDateTime: 'Fecha de pedido',
    orderDescription: 'Descripción de pedido',
    orderId: 'ID del pedido',
    orderInputFileId: 'orderInputFileId',
    orderInputFileInputFileName: 'orderInputFileInputFileName',
    orders: 'Encargos',
    origin: 'Origen',
    otherExpenses: 'Otros gastos',
    otherExpensesRappel: 'Gastos conexos',
    feesAndPublicDocuments: 'Tasas, impuestos y documentos oficiales',
    Others: 'Otros',
    outOfDate: 'Fuera de plazo',
    Paper: 'Folio',
    parametersOfValoration: 'Parámetros de Valoración',
    partialInvoiced: 'Facturado parcialmente',
    PARTICIPANTS_hasUsers: '¿Tiene usuarios?',
    participants: 'Participantes',
    partnerCheckWarningMessage: `Cuando se marca el check de colaborador, se está marcando automáticamente la generación de facturas para los encargos en los que actúa como prescriptor. Para modificarlo acceda al apartadode "Datos de facturación".`,
    password: 'Contraseña',
    pattern: 'Formato erróneo',
    PavementType: 'Tipo de pavimento',
    pending: 'Pendiente',
    pendingToAssignManager: 'Pendientes de asignar Responsable',
    pendingToAssignProcessManager: 'Pendientes de asignar responsable de procesos',
    pendingToAssignTechnicalManager: 'Pendientes de asignar responsable ténico',
    pendingTOAssignTechnician: 'Pendientes de asignar técnico',
    pendingToStartValidation: 'Pendientes de empezar validación',
    PERIOD_percentage: 'Porcentaje',
    PERIOD_unitary: 'Unitario',
    PersonalOpinion: 'Observaciones',
    Phone: 'Móvil',
    phone: 'Teléfono',
    PHOTOS_mainImageWarning: 'La foto principal será única por encargo y aparecerá en la portada del documento ¿Estás seguro de que quieres continuar?',
    pool: 'Piscina',
    population: 'habitantes',
    Portal: 'Portal',
    portuguese: 'Portugués',
    position: 'Cargo',
    postalCode: 'Código Postal',
    PostalCode: 'Código postal',
    preferences: 'Preferencias del profesional',
    prescribers: 'Prescriptores',
    PrescriptorType: 'Tipo de prescriptor',
    PROCESS_MANAGER_USER: 'Gestor de procesos',
    PROCESS_MANAGER_USERS_add_process_manager_user: 'Añadir gestor de procesos',
    PROCESS_MANAGER_USERS_addNew: 'Añadir nuevo',
    PROCESS_MANAGER_USERS_appraisals_in_process_count: 'Tasaciones en proceso',
    PROCESS_MANAGER_USERS_email: 'Email',
    PROCESS_MANAGER_USERS_first_name: 'Nombre',
    PROCESS_MANAGER_USERS_is_active: 'Usuario activo',
    PROCESS_MANAGER_USERS_lastname: 'Apellidos',
    PROCESS_MANAGER_USERS_national_identity_document: 'DNI',
    PROCESS_MANAGER_USERS_plus_add_user: '+ Añadir usuario',
    PROCESS_MANAGER_USERS_position: 'Cargo',
    PROCESS_MANAGER_USERS: 'Usuarios Gestores de Procesos',
    process_managers: 'Gestores de Procesos',
    processDept: 'Departamento de Procesos',
    ProcessManager: 'Gestores de procesos',
    ProcessManagerUser: 'Gestor de procesos',
    processState: 'Estado del proceso',
    processStates: 'Estados del proceso',
    PROFESSIONAL_LIST_isPartner: 'Es colaborador',
    PROFESSIONAL_LIST_isAbsent: 'Ausente',
    PROFESSIONAL_LIST_isVerifiedAppraiser: 'Profesional validado',
    PROFESSIONAL_LIST_inProgressTooltip: 'Para los profesionales sólo se tienen en cuenta los expedientes en estado del 3 al 6.',
    PROFESSIONALS_addNewDocument: 'Añadir nuevo documento',
    PROFESSIONALS_addSignature: 'añadir firma',
    PROFESSIONALS_appraisalsInProcessCount: 'En proceso',
    PROFESSIONALS_appraisers: 'Tasadores',
    PROFESSIONALS_changeSignature: 'cambiar firma',
    PROFESSIONALS_commercialPercentageFee: '% Honorarios suplidos',
    PROFESSIONALS_distance: 'Distancia',
    PROFESSIONALS_executionPercentageFee: '% Ejecución',
    Professionals_internalObservations: 'Observaciones internas',
    PROFESSIONALS_maxDistance: 'Distancia máxima',
    PROFESSIONALS_month: 'Mes',
    PROFESSIONALS_NEW_add_professional: 'Añadir tasador',
    PROFESSIONALS_notFileSelected: 'No hay archivos seleccionados',
    PROFESSIONALS_notifications: 'Notificaciones',
    PROFESSIONALS_paymentOption: 'Modo de facturación a clientes:',
    PROFESSIONALS_qualification: 'Titulación',
    PROFESSIONALS_recieveAppNotifications: 'Recibe notificaciones en la app',
    PROFESSIONALS_recieveEmailNotifications: 'Recibe notificaciones e-mail',
    PROFESSIONALS_selectFile: 'Selecciona un archivo',
    PROFESSIONALS_signature: 'firma',
    PROFESSIONALS_status: 'Estado',
    PROFESSIONALS_totalTaxesIncluded: 'Importe (impuestos incluidos)',
    PROFESSIONALS_typologiesAppraisable: 'Tipologías',
    PROFESSIONALS_year: 'Año',
    professionals: 'Profesionales',
    PROFFESIONALS_aditionalInfo: 'Información adicional',
    PROFFESIONALS_aditionalInfoTooltip: 'El código externo se utiliza para conservar alguna numeración propia. Las observaciones podrán ser leídas por el tasador desde la app. Mientras que las observaciones internas sólo podrán ser leídas desde este panel de administración, el tasador no podrá leerlo desde la app.',
    PROFFESIONALS_observationTooltip: 'Las observaciones podrán ser leídas por el tasador desde la app. Mientras que las observaciones internas sólo podrán ser leídas desde este panel de administración, el tasador no podrá leerlo desde la app.',
    profile: 'Perfil',
    PROPERTY_DATA_TABLE_adress: 'Dirección',
    PROPERTY_DATA_TABLE_antiquity: 'Antigüedad',
    PROPERTY_DATA_TABLE_assetRregistryInf: 'Información registral del inmueble',
    PROPERTY_DATA_TABLE_CadastralReference: 'Referencia catastral',
    PROPERTY_DATA_TABLE_estimatedValueBand: 'Franja de valor estimado',
    PROPERTY_DATA_TABLE_isUsedInAppraisal: 'En una valoración',
    PROPERTY_DATA_TABLE_pruposedProperty: 'Tarifa propuesta',
    PROPERTY_DATA_TABLE_refCatastral: 'Ref. Catastral',
    PROPERTY_DATA_TABLE_RegistryInfoBook: 'Libro',
    PROPERTY_DATA_TABLE_RegistryInfoIdufir: 'C.R.U. (IDUFIR)',
    PROPERTY_DATA_TABLE_RegistryInfoInscription: 'Inscripción',
    PROPERTY_DATA_TABLE_RegistryInfoRealEstateNumber: 'Finca',
    PROPERTY_DATA_TABLE_RegistryInfoRegister: 'Registro de la propiedad',
    PROPERTY_DATA_TABLE_RegistryInfoRegisterNumber: 'Número de finca registral',
    PROPERTY_DATA_TABLE_RegistryInfoSection: 'Folio',
    PROPERTY_DATA_TABLE_RegistryInfoVolume: 'Tomo',
    PROPERTY_DATA_TABLE_surface: 'Superficie',
    PROPERTY_DATA_TABLE_tipologyAndUse: 'Uso y tipología',
    PROPERTY_DATA_TABLE_total: 'Total:',
    PropertyRegistrationCode: 'Número de registro de la propiedad',
    PropertyRegistrationName: 'Registro de la propiedad',
    protected: 'Protegida',
    providerAssetId: 'ID Activo Proveedor',
    providerAssetIds: 'ID de origen',
    province: 'Provincia',
    Province: 'Provincia',
    PROVISION_EXPENSES_alert: 'Advertencia!',
    PROVISION_EXPENSES_alertMessage: 'Los datos que vienen a continuación son de Provision de Ingresos/Gastos, se requiere que confirme los Ingresos/Gastos definitivos.',
    QUALITY_add_massive_control_appraisal: 'Crear nueva carga masiva de control de validación',
    QUALITY_add_new_appraisal: 'Crear encargo',
    QUALITY_add_new_control_appraisal: 'Crear nuevo encargo de control de validación',
    QUALITY_addedDate: 'Fecha de creación',
    QUALITY_addKo: 'Añadir KO',
    QUALITY_addMessage: 'Añadir un mensaje',
    QUALITY_appraiser: 'Tasador',
    QUALITY_attachDocument: 'Adjuntar',
    QUALITY_cancelAppraisal: 'Anular encargo',
    QUALITY_cancelled: 'Anulada',
    QUALITY_cancelledDate: 'Fecha de anulación',
    QUALITY_claimDate: 'Fecha de reclamación',
    QUALITY_appraiserName: 'Nombre del tasador',
    QUALITY_select_prescriber: 'Selecciona un prescriptor',
    QUALITY_valorationMethodAdopted: 'Método adoptado',
    QUALITY_CONTROL_detail: 'Detalle',
    QUALITY_CONTROL_documents: 'Documentación',
    QUALITY_CONTROL_finishValidationConfirm: 'Va a finalizar la validación ¿Está seguro?',
    QUALITY_CONTROL_massiveControlAppraisal: 'Nueva carga masiva de control de validación',
    QUALITY_CONTROL_messageBoard: 'Tablón',
    QUALITY_CONTROL_pressingCtrlKey: 'Seleccionarlos unitariamente mantiendo pulsada la tecla control (Ctrl).',
    QUALITY_CONTROL_pressingShiftKey: 'Seleccionar rangos manteniendo pulsada la tecla shift (mayús)',
    QUALITY_CONTROL_registralPlat: 'Fincas registrales',
    QUALITY_CONTROL_selectMultipleFilesOptions: 'Para seleccionar múltiples ficheros es posible :',
    QUALITY_CONTROL_startValidationConfirmation: '¿Está seguro de que desea empezar la validación?',
    QUALITY_CONTROL_status: 'Resultado de la validación',
    QUALITY_creationDate: 'Fecha de creación',
    QUALITY_customer: 'Cliente',
    QUALITY_customerName: 'Nombre y apellidos/Razón social',
    QUALITY_customerReferenceId: 'Referencia del cliente',
    QUALITY_documentType: 'Tipo de documento',
    QUALITY_estimatedValue: 'Valor estimado',
    QUALITY_estimatedValueWithSuffix: 'Valor estimado ({{currency}})',
    QUALITY_externalTech: 'Técnico externo',
    QUALITY_fileName: 'Documento',
    QUALITY_fileNumber: 'Nº de expediente',
    QUALITY_finished: 'Finalizadas',
    QUALITY_finishedDate: 'Fecha de finalización',
    QUALITY_finishValidation: 'Finalizar validación',
    QUALITY_from: 'Desde',
    QUALITY_incidenceCode: 'Código de incidecia',
    QUALITY_incidentInStatus: 'Estado de la incidencia',
    QUALITY_isCustomerDeadlineExpired: 'Entrega al cliente fuera de plazo',
    QUALITY_isSolved: 'Está resuelta',
    QUALITY_noValidate: 'No validar',
    QUALITY_pdf: 'PDF',
    QUALITY_pendingValidation: 'Pendiente de validación',
    QUALITY_portfolios: 'Carteras',
    QUALITY_processManager: 'Gestor de procesos',
    QUALITY_processManagers: 'Gestores de proceso',
    QUALITY_purpose: 'Finalidad',
    QUALITY_recordId: 'Nº de expediente',
    QUALITY_resultValidation: 'Resultado de la validación',
    QUALITY_select_client: 'Selecciona un cliente',
    QUALITY_solvedDate: 'Fecha de resolución',
    QUALITY_startValidation: 'Empezar validación',
    QUALITY_status: 'Estado',
    QUALITY_techManager: 'Gestor de técnicos',
    QUALITY_toDate: 'Hasta',
    QUALITY_validate: 'Validar',
    QUALITY_validationFinished: 'Validación finalizada',
    QUALITY_validationStarted: 'Validación iniciada',
    QUALITY_validationState: 'Est. Val.',
    QUALITY_viewPDF: 'Ver PDF',
    QUALITY_withClaims: 'Con reclamaciones',
    QUALITY_withClaimsNotResolved: 'Con reclamaciones no resueltas',
    QUALITY_xml: 'XML',
    QualityOfBuilding: 'Calidad de las edificaciones',
    REA_costMethodSurface: 'Superficie del método de coste:',
    REA_groundValueMaximumLegal: 'Valor del suelo según máximo legal',
    REA_total: 'Total',
    REA_valueMaximumLegalDividedSurface: 'Este valor viene dado del valor máximo legal dividido entre la superficie adoptada en el método de coste',
    recalculationNeeded: 'Es necesario recalcular',
    recalculation: 'Recálculo',
    recalculation_success: 'Recálculo realizado con éxito',
    RecordId: 'Nº expediente',
    ReformLastYear: 'Año de reforma',
    registered: 'Registral',
    RegisteredPropertyNumber: 'Finca registral',
    registryName: 'Registro',
    relatedAppraisals: 'Valoraciones relacionadas',
    Religious: 'Religioso',
    remainder: 'Resto',
    remove_cluster_asset_modal_1: '¿Eliminar unidad?',
    remove_cluster_asset_modal_2: 'Al eliminar esta unidad, se eliminarán las unidades vinculadas. ¿Eliminar?',
    RENTAL_METHOD_activeNetIncome: 'Renta neta activa',
    RENTAL_METHOD_amountMonthlyExpenses: 'Importe gastos mensuales ({{currency}})',
    RENTAL_METHOD_annualNetIncome: 'Renta neta anual',
    RENTAL_METHOD_annualNetIncomeSuffix: '{{currency}}/año',
    RENTAL_METHOD_capex: 'Capex anual',
    RENTAL_METHOD_capexPercent: '% capex anual',
    RENTAL_METHOD_capexValue: 'Importe capex anual',
    RENTAL_METHOD_capitalGain: 'Plusvalía/minusvalía del suelo',
    RENTAL_METHOD_chooseDate: 'Seleccionar fecha',
    RENTAL_METHOD_currentIncome: 'Renta actual',
    RENTAL_METHOD_defaulting: 'Morosidad',
    RENTAL_METHOD_defaultingPercent: '% morosidad',
    RENTAL_METHOD_defaultingValue: 'Meses de morosidad',
    RENTAL_METHOD_economicMarketIncomeData: 'Datos económicos de renta de mercado',
    RENTAL_METHOD_euroMonthSuffix: '({{currency}}/mes)',
    RENTAL_METHOD_euroYearSuffix: '({{currency}}/año)',
    RENTAL_METHOD_extinctionContractDate: 'Fecha de extinción de contrato',
    RENTAL_METHOD_finalValue: 'Valor final ({{currency_per_surfaceUnit}}/mes)',
    RENTAL_METHOD_generalEconomicData: 'Datos generales y económicos contractuales',
    RENTAL_METHOD_groundRepercussion: 'Repercusión del suelo',
    RENTAL_METHOD_groundRepercussionError: 'Método de coste',
    RENTAL_METHOD_homogeneizedValue: 'Valor homogeneizado ({{currency_per_surfaceUnit}}/mes)',
    RENTAL_METHOD_incomeUpdated: 'Rentas actualizadas',
    RENTAL_METHOD_initialValue: 'Valor inicial ({{currency_per_surfaceUnit}}/mes)',
    RENTAL_METHOD_IPC: 'IPC',
    RENTAL_METHOD_leisure: 'Desocupación',
    RENTAL_METHOD_leisurePercent: '% desocupación',
    RENTAL_METHOD_leisureValue: 'Meses de desocupación',
    RENTAL_METHOD_marketNetIncome: 'Renta neta mercado',
    RENTAL_METHOD_MonthlyEquivalentUpdateRate: 'Tasa de actualización mensual equivalente',
    RENTAL_METHOD_monthlyExpenses: 'Gastos mensuales',
    RENTAL_METHOD_monthlyGrossIncome: 'Renta bruta mensual',
    RENTAL_METHOD_monthlyNetIncome: 'Renta neta mensual',
    RENTAL_METHOD_monthlyNetIncomeSuffix: '{{currency}}/mes',
    RENTAL_METHOD_observationsOcupation: 'Observaciones relativas a la ocupación',
    RENTAL_METHOD_residualUsefulLife: 'Vida útil residual',
    RENTAL_METHOD_rollbackUpdated: 'Reversión actualizado',
    RENTAL_METHOD_rollbackValue: 'Valor de reversión',
    RENTAL_METHOD_unitNetIncome: 'Renta neta unitaria',
    RENTAL_METHOD_unitNetIncomeSuffix: '{{currency_per_surfaceUnit}}/mes',
    RENTAL_METHOD_unitCurrencySurfaceSuffix: '{{currency_per_surfaceUnit}}',
    RENTAL_METHOD_updateRatePercent: 'Tasa de actualización',
    RENTAL_METHOD_updateValue: 'Valor actualización',
    RENTAL_METHOD_valuesUpdated: 'Valores actualizados',
    REPORT_actualValuation: 'Valoración Actual',
    REPORT_ASSET_hide_assets_to_group: 'Ocultar activos a agrupar',
    REPORT_ASSET_only_finished: '* No se podrán agrupar elementos no terminados.',
    REPORT_ASSET_only_group_same_types: '* No podrás agrupar elementos mezclados, suelos solo se puede agrupar con suelos, elementos terminados, con elementos terminados y explotaciones económicas con explotaciones económicas.',
    REPORT_ASSET_report_structure_childrenHasErrors_error: 'Es necesario revisar los errores pertenecientes a los anejos',
    REPORT_ASSET_report_structure_hasValorations_error: 'No puedes agrupar este activo ya que tiene métodos de valoración asociados.',
    REPORT_ASSET_report_structure_isClustered_error: 'No puedes agrupar este activo ya que pertenece a otra agrupación',
    REPORT_ASSET_report_structure_stateNotDefined_error: 'Tienes que seleccionar el Estado del activo en Tipología para poder agrupar este activo.',
    REPORT_ASSET_show_assets_to_group: 'Mostrar activos a agrupar',
    REPORT_CUSTOMIZATION_footerLine1: 'Pie de página (línea 1)',
    REPORT_CUSTOMIZATION_footerLine2: 'Pie de página (línea 2)',
    REPORT_CUSTOMIZATION_footerLine3: 'Pie de página (línea 3)',
    REPORT_CUSTOMIZATION_includeCustomImageHeader: 'Incluir cabecera personalizada',
    REPORT_CUSTOMIZATION_includeCustomLogo: 'Incluir el logo portada',
    REPORT_CUSTOMIZATION_includeSignature: 'Incluir la firma del representante en todos los informes',
    REPORT_CUSTOMIZATION: 'Personaliza tu informe de valoración',
    REPORT_futureValuation: 'Valoración H.E.T',
    REPORT_LIST_deliverablesAccess: 'Acceso a entregables',
    REPORT_LIST_generateDraft: 'Generar borrador',
    REPORT_reportVersionSelected: 'Versión del informe seleccionada',
    REPORT_valuation_summary_adoptedMethod: 'Método Adoptado',
    REPORT_valuation_summary_adoptedSurface: 'Superficie Adoptada',
    REPORT_valuation_summary_cadastralReference: 'Referencia Catastral',
    REPORT_valuation_summary_element: 'Elemento',
    REPORT_valuation_summary_registeredPropertyNumber: 'Finca Registral',
    REPORT_valuation_summary_totalValue: 'Valor Total ({{currency}})',
    REPORT_valuation_summary_unitaryValue: 'Valor Unitario',
    REPORT_valuationSummary: 'Resumen de valoraciones',
    REPORT_validationAsset: 'Validación Activo',
    REPORT_validation: 'Validación de Informe',
    REPORT_thereAreNoItems: 'No hay elementos',
    REPORT_checkValidation: 'Revisar Validaciones',
    REPORT_checkReportValidation: 'Revisar Informe',
    REPORT_checkReportsValidation: 'Revisar Informes',
    REPORT_checkAssetValidation: 'Revisa el áctivo',
    reportVersion: 'Versión',
    REQUEST_createdFrom: 'Fecha creación inicio',
    REQUEST_createdTo: 'Fecha creación fin',
    REQUEST_customerInvoiced: 'Valoraciones facturables a cliente',
    REQUEST_notCancelled: 'No anuladas',
    requestCreateInvoiceModalMsg1: 'La provision de importe incluido en la solicitud es',
    requestCreateInvoiceModalMsg2: 'Indique el importe a facturar',
    requestCreateInvoiceModalMsg3: 'Se va a generar la factura para las valoraciones seleccionadas y por el importe indicado. Esta factura no se podrá modificar. ¿Está seguro de que quiere continuar?',
    REQUESTER_COMPANIES_billing_information: 'Datos de Facturación',
    REQUESTER_COMPANIES_code: 'Código',
    REQUESTER_COMPANIES_DEFAILS_USERS_users: 'Usuarios',
    REQUESTER_COMPANIES_name: 'Nombre',
    REQUESTER_COMPANIES_NEW_add_prescriber: 'Añadir prescriptor',
    REQUESTER_COMPANIES_prescribers: 'Prescriptores',
    REQUESTER_COMPANIES_prescriptorType: 'Tipo de prescriptor',
    REQUESTER_COMPANIES_profile: 'Perfil',
    Requester: 'Prescriptor',
    REQUESTS_acceptQuoteManually: 'Aceptar presupuesto manualmente',
    REQUESTS_add_manually: 'Introducir manualmente',
    REQUESTS_add_new_asset: 'Añadir nuevo activo',
    REQUESTS_add_new_request: 'Crear solicitud',
    REQUESTS_addedUserFullName: 'Nombre y Apellidos',
    REQUESTS_address: 'Dirección',
    REQUESTS_addToInvoice: 'Añadir a factura',
    REQUESTS_amounts: 'Importes',
    REQUESTS_feesAndPublicDocuments: 'Tasas, impuestos y documentos oficiales',
    REQUESTS_apply: 'Aplicar',
    REQUESTS_appraisal_owner_data: 'Datos del titular de la tasación',
    REQUESTS_appraisal_retriever_email: 'Email del receptor de la valoración',
    REQUESTS_appraisals: 'Valoraciones',
    REQUESTS_askForConfirmation: 'Pedir confirmación',
    REQUESTS_at_any_moment_of_the_day: 'Cualquier momento del día',
    REQUESTS_basicFilters: 'Filtros básicos',
    REQUESTS_state: 'Estado de la solicitud',
    REQUEST_RETURN: '< Ir a la solicitud',
    REQUESTS_billing_data: 'Datos de facturación',
    REQUESTS_billing: 'Facturación',
    REQUESTS_financial_information: 'Información financiera',
    REQUESTS_budget_number: 'Nº de presupuesto',
    REQUESTS_cadastralReference: 'Referencia Catastral',
    REQUESTS_cancel: 'Cancelar',
    REQUESTS_cancelled: 'Anuladas',
    REQUESTS_communication: 'Comunicación',
    REQUESTS_message_code: 'Código de mensaje',
    REQUESTS_message_board: 'Tablón',
    REQUESTS_state_communication: 'Estado de la comunicación',
    REQUESTS_cancelRequest: 'Cancelar solicitud',
    REQUESTS_copyRequest: 'Copiar solicitud',
    REQUESTS_cancelRequestConfirmation: 'Esta solicitud se cancelará. ¿Quieres continuar?',
    REQUESTS_cif: 'CIF',
    REQUESTS_client_error: 'El cliente no tiene email, este campo es obligatorio en solicitudes de previo pago',
    REQUESTS_code: 'Código',
    REQUESTS_commercial_percentage_fee: '% Honorarios suplidos',
    REQUESTS_copyDataFromClient: 'Copy from client data',
    REQUESTS_copyDataFromOwner: 'Copiar datos del titular',
    REQUESTS_createAssessments: 'Crear valoración',
    REQUESTS_createOrder: 'Crear encargo',
    REQUESTS_createNewInvoice: 'Crear nueva factura',
    REQUESTS_createOk: 'La solicitud ha sido creada correctamente.',
    REQUESTS_creationDate: 'Fecha de creación',
    REQUESTS_customer: 'Cliente',
    REQUESTS_date: 'Fecha',
    REQUESTS_DETAIL_createDate: 'Fecha de creación',
    REQUESTS_DETAIL_paymentAmount: 'Cantidad',
    REQUESTS_DETAIL_paymentDate: 'Fecha y hora',
    REQUESTS_DETAIL_paymentId: 'ID pago',
    REQUESTS_DETAIL_description: 'Descripción',
    REQUESTS_DETAIL_paymentState: 'Estado',
    REQUESTS_DETAIL_redsysPaymentId: 'Redsys ID',
    REQUESTS_DETAIL_redsysResponseState: 'Estado Redsys',
    REQUESTS_detail: 'Detalle',
    REQUESTS_direction: 'Dirección',
    REQUESTS_dni_nie_passport: 'DNI, NIE, Pasaporte',
    REQUESTS_dni: 'NIF / CIF Cliente',
    REQUESTS_do_visit: ' Realizar la visita',
    REQUESTS_do_you_have_code: '¿Tienes código?',
    REQUESTS_document: 'Documento',
    REQUESTS_documents: 'Documentos',
    REQUESTS_eco_805_2003: 'Eco 805/2003',
    REQUESTS_editBillingData: 'Editar datos de facturación',
    REQUESTS_email: 'Email',
    REQUESTS_end: 'Terminar',
    REQUESTS_euros_without_taxes: 'Euros (sin tasas)',
    REQUESTS_execution_percentage_fee: '% Ejecución',
    REQUESTS_expensesProvision: 'Provisión de Ingresos/Gastos',
    REQUESTS_expensesRapel: 'Gastos conexos',
    REQUESTS_externalReference: 'Nº referencia externa',
    REQUESTS_feeOptions: 'Modo tarifa',
    REQUESTS_finishDate: 'Fecha de finalización',
    REQUESTS_finished: 'Terminadas',
    REQUESTS_first_name_last_name_business_name: 'Nombre Apellidos / Razón Social',
    REQUESTS_first_name: 'Nombre',
    REQUESTS_from: 'Desde',
    REQUESTS_hasAppraisals: 'Tiene encargos',
    REQUESTS_id: 'Identificador',
    REQUESTS_internal_amounts: 'Importes internos',
    REQUESTS_invoiceDateTime: 'Fecha de factura',
    REQUESTS_invoiceNumber: 'Nº de factura',
    REQUESTS_invoiceNumberId: 'Número de factura',
    REQUESTS_irpfTotal: 'Total IRPF',
    REQUESTS_is_company: 'Es una empresa',
    REQUESTS_ivaTotal: 'Total impuestos',
    REQUESTS_last_name: 'Apellidos',
    REQUESTS_locality: 'Localidad',
    REQUESTS_manualOrderAcceptConfirmation: 'El presupuesto se marcará como aceptado manualmente. ¿Quieres continuar?',
    REQUESTS_new_appraisal: 'Nueva valoración',
    REQUESTS_new_request: 'Nueva solicitud',
    REQUESTS_new_others: 'Nuevo encargo',
    REQUESTS_noPrescriber: 'No tiene prescriptor',
    REQUESTS_observations: 'Observaciones',
    REQUESTS_order_characteristics: 'Características del encargo',
    REQUESTS_order_type: 'Tipo de encargo / valoración',
    REQUESTS_job_type: 'Tipo de trabajo',
    REQUESTS_otherExpenses: 'Otros gastos asociados',
    REQUESTS_payment_history: 'Historial de pagos',
    REQUESTS_communications: 'Comunicaciones',
    REQUESTS_history: 'Historial',
    REQUESTS_payment_mode: 'Modo de facturación',
    REQUESTS_payment_mode_info: '*Haciendo click en Facturación automática se generará una factura por cada expediente finalizado',
    REQUESTS_phone_number: 'Teléfono',
    REQUESTS_postal_code: 'CP',
    REQUESTS_prescriptorName: 'Prescriptor',
    REQUESTS_property_data: 'Datos de la solicitud',
    REQUESTS_province: 'Provincia',
    REQUESTS_purpose: 'Finalidad',
    REQUESTS_remove_code: 'Eliminar código',
    REQUESTS_requests: 'Solicitudes',
    REQUESTS_resendMailPayment: 'Reenviar el email de pago',
    REQUESTS_select_a_client: 'Seleccionar un cliente',
    REQUESTS_simpleNote: 'Nota simple',
    REQUESTS_status: 'Estado',
    REQUESTS_subtotal: 'Subtotal',
    REQUESTS_to: 'Hasta',
    REQUESTS_total_fees: 'Total honorarios',
    REQUESTS_total_totalAmountWarning: 'Por favor, introduce una tarifa válida',
    REQUESTS_totalWithoutTaxes: 'Total sin impuestos',
    REQUESTS_totalWithTaxes: 'Total con impuestos',
    REQUESTS_visit_contact_user_data: 'Datos del contacto para la visita',
    REQUESTS_visit_type: 'Tipo de visita',
    REQUESTS_with_report: 'Ejecuto el informe',
    requests: 'Solicitudes',
    REQUESTSS_at_any_moment_of_the_day: 'En cualquier momento del día',
    REQUETS_can_be_launched_in_exchange: 'Incluir la solicitud en la bolsa de encargos',
    REQUETS_generalConditions: 'Condiciones generales de venta',
    REQUETS_has_read_and_accepted: 'He leido y acepto las ',
    REQUIRE_PASSWORD_CHANGE_inputEmail: 'Introduzca su correo electrónico, se le enviará un correo con las instruciones para restaurar su contraseña',
    REQUIRE_PASSWORD_CHANGE_restorePassword: 'Recuperar contraseña',
    REQUIRE_PASSWORD_CHANGE_sendEmailSuccessMsg: 'Se le ha enviado un correo electrónico con las instruciones para cambio de contraseña',
    REQUIRE_PASSWORD_CHANGE_sendEmailSuccessTitle: 'Correo electrónico',
    required: 'Este campo es obligatorio',
    ResidentialMultiFamily: 'Residencial plurifamiliar',
    ResidentialSingleFamily: 'Residencial unifamiliar',
    RESIDUAL_DYNAMIC_workPlanDatesWarning: 'Los datos de fecha de inicio y fin de la promoción son editables en la pestaña de Estado de la obra',
    restart: 'Reiniciar',
    RESTORE_PASSWORD_changePassword: 'Cambiar contraseña',
    RESTORE_PASSWORD_changePasswordLabel: 'Introduzca su nueva contraseña para acceder a su cuenta',
    RESTORE_PASSWORD_newPassword: 'Nueva contraseña',
    revisionsResolutionAverageTime: 'Tiempo medio resolución de revisiones',
    roadType: 'Tipo de vía',
    root: 'Root',
    Sanitary: 'Sanitario',
    save: 'Guardar',
    saveAndFinish: 'Guardar y finalizar',
    saveRequiredChangesToContinue: 'Tienes cambios requeridos pendientes de guardar para realizar Residual Dinámico. Cierra y revisa el formulario o guarda para continuar.',
    saving: 'Guardando...',
    schedule: 'Agenda',
    scheduledByTec: 'Agendadas por técnico',
    search: 'Buscar',
    Section: 'Sección',
    select_batch_excel_file: 'Elegir fichero CSV de carga',
    select_currency_warning: 'La moneda seleccionada cambiará la etiqueta, no influirá en el valor.',
    select_currency: 'Selecciona la moneda con la que quieres trabajar',
    select_language_title: 'Seleccionar idioma',
    select_language: 'Seleccionar idioma',
    selectAmountToIncludeInInvoice: 'Seleccione el importe a incluir en la factura',
    selectCustomer: 'Selecciona un cliente',
    selectedAsset: 'Activo seleccionado',
    selectedCluster: 'Agrupación seleccionada',
    selectInvoiceData: 'Selecciona los datos de facturación',
    selectPrescriber: 'Selecciona un prescriptor',
    selectRequest: 'Selecciona una solicitud',
    send: 'Enviar',
    sending: 'Enviando',
    sendToCadastralApi: 'Enviar a la API de catastro',
    sendToClient: 'Enviar a cliente',
    sendToValorate: 'Enviar a valorar',
    SeveralUses: 'Muchos usos',
    show_filters: 'Mostrar filtros',
    signature: 'Firma',
    SIMPLE_NOTE_addedDateTime: 'Fecha de creación',
    SIMPLE_NOTE_attach: 'Adjuntar',
    SIMPLE_NOTE_Block: 'Bloque',
    SIMPLE_NOTE_Book: 'Libro',
    SIMPLE_NOTE_cadastralReference: 'Referencia catastral',
    SIMPLE_NOTE_Country: 'País',
    SIMPLE_NOTE_currentWorkflowStep: 'Estado actual',
    SIMPLE_NOTE_errorMessage: 'Deje un comentario',
    SIMPLE_NOTE_errorType: 'Tipo de error',
    SIMPLE_NOTE_FILTER_Book: 'Libro',
    SIMPLE_NOTE_FILTER_CadastralReference: 'Referencia catastral',
    SIMPLE_NOTE_FILTER_Idufir: 'IDUFIR',
    SIMPLE_NOTE_FILTER_OwnerFirstName: 'Nombre del cliente',
    SIMPLE_NOTE_FILTER_OwnerLastName: 'Apellidos del cliente',
    SIMPLE_NOTE_FILTER_OwnerNationalIdentityDocument: 'DNI del cliente',
    SIMPLE_NOTE_FILTER_Page: 'Folio',
    SIMPLE_NOTE_FILTER_RealEstateNumber: 'Finca',
    SIMPLE_NOTE_FILTER_RegisterName: 'Nombre del registro de la propiedad ',
    SIMPLE_NOTE_FILTER_RegisterNumber: 'Número del registro de la propiedad',
    SIMPLE_NOTE_FILTER_Section: 'Sección',
    SIMPLE_NOTE_FILTER_status: 'Estado',
    SIMPLE_NOTE_Floor: 'Planta',
    SIMPLE_NOTE_Gate: 'Puerta',
    SIMPLE_NOTE_Id: 'ID',
    SIMPLE_NOTE_Idufir: 'C.R.U. (IDUFIR)',
    SIMPLE_NOTE_Inscription: 'Inscripción',
    SIMPLE_NOTE_Locality: 'Localidad',
    SIMPLE_NOTE_mustUploadAFile: 'Se ha de adjuntar un archivo',
    SIMPLE_NOTE_notAvailable: 'Nota simple no disponible',
    SIMPLE_NOTE_OwnerFirstName: 'Nombre del cliente',
    SIMPLE_NOTE_OwnerLastName: 'Apellidos del cliente',
    SIMPLE_NOTE_OwnerNationalIdentityDocument: 'DNI del cliente',
    SIMPLE_NOTE_Page: 'Página',
    SIMPLE_NOTE_Portal: 'Portal',
    SIMPLE_NOTE_PostalCode: 'Código postal',
    SIMPLE_NOTE_PrescriptorRecordId: 'Identificador del prescriptor',
    SIMPLE_NOTE_Province: 'Provincia',
    SIMPLE_NOTE_RealEstateNumber: 'Finca',
    SIMPLE_NOTE_RegisterName: 'Nombre de registro',
    SIMPLE_NOTE_RegisterNumber: 'Número de registro',
    SIMPLE_NOTE_Section: 'Sección',
    SIMPLE_NOTE_sendedDateTime: 'Fecha de envío',
    SIMPLE_NOTE_StreetName: 'Nombre de la calle',
    SIMPLE_NOTE_StreetNumber: 'Número de la calle',
    SIMPLE_NOTE_StreetType: 'Tipo de calle',
    SIMPLE_NOTES_add_new_simple_note: '+ Añadir nota simple',
    SIMPLE_NOTES_DETAIL_recivedFromRegister: '¿Ha recibido la nota simple del registro de propiedades?',
    SIMPLE_NOTES_failedReceivedFromRegister: 'Registro de propiedades no dispone del documento',
    SIMPLE_NOTES_mustUploadAFile: 'Se ha de subir la nota simple',
    SIMPLE_NOTES_recivedFromRegister: 'Recibido del registro de propiedades',
    SIMPLE_NOTES_sendToClient: 'Enviar al cliente',
    SIMPLE_NOTES_sendToPropertyRegister: 'Enviar al registro de propiedades',
    SIMPLE_NOTES_simple_notes: 'Notas simples',
    simpleNote: 'Nota simple',
    smartaValue: 'Valor de Smarta',
    someTranslatesShouldBeAdded: 'Algunas traducciones deben ser añadidas',
    SortingDepot: 'Nave industrial',
    spanish: 'Español',
    Sports: 'Deportivo',
    sportsZone: 'Zona deportiva',
    stairs: 'Escalera',
    Stairs: 'Escalera',
    StartedAppraisals: 'Tasaciones comenzadas',
    State: 'Estado',
    Stays: 'Estancias',
    StayType: 'Tipo de estancia',
    STEPPER_mobileTooltip: 'Este paso se ejecuta desde la App móvil',
    StorageRoom: 'Trastero',
    StreetCategory: 'Categoría de la calle',
    StreetName: 'Nombre calle',
    streetName: 'Nombre vía',
    StreetNumber: 'Número de la calle',
    streetNumber: 'Número vía',
    streetType: 'Tipo de vía',
    StreetType: 'Tipo vía',
    StudentResidence: 'Residencia de estudiantes',
    successAction: 'La acción se ha realizado correctamente',
    studyMarket: 'Estudios de mercado',
    studyMarketList: 'Lista de estudios de mercado',
    studyMarketListValuation: 'Estudio de mercado utilizado en',
    successful: 'Éxito',
    successfully_completed: 'Realizado con éxito',
    SUPPLIER_COMPANIES_add_appraisal_company_title: 'Crear nueva sociedad de tasación',
    SUPPLIER_COMPANIES_add_appraisal_company: '+ Añadir sociedad de tasación',
    SUPPLIER_COMPANIES_appraisal_companies: 'Sociedades de tasación',
    SUPPLIER_COMPANIES_cif: 'CIF',
    SUPPLIER_COMPANIES_contactEmail: 'Email',
    SUPPLIER_COMPANIES_contactPhone: 'Teléfono',
    SUPPLIER_COMPANIES_description: 'Descripción',
    SUPPLIER_COMPANIES_name: 'Nombre',
    SUPPLIER_COMPANIES_observations: 'Observaciones',
    SUPPLIER_COMPANIES_profile: 'Perfil',
    sureAssignProfessionalManual: '¿Está seguro que desea asignar un técnico manualmente? Esta acción retirará el encargo de la bolsa.',
    sureWantToExit: '¿Esta seguro de que quiere salir? Tiene cambios pendientes por guardar.',
    surface: 'Superficie',
    SurfaceArea: 'Superficie',
    surfaceCommons: 'Superficie construida con comunes ({{surfaceUnit}})',
    surfaceFrom: 'Superficie desde',
    surfaceGroundPiece: 'Superficie parcela (ha)',
    surfacePlotLand: 'Superficie parcela',
    surfaces: 'Superficies',
    Surfaces: 'Superficies',
    property_features: 'Características del inmueble',
    surfacesAndTotalValue: 'Superficies y valor de la oferta',
    SurfacesObservations: 'Observaciones',
    surfaceTo: 'Superficie hasta',
    surfaceUseful: 'Superficie útil ({{surfaceUnit}})',
    surfaceWithoutCommons: 'Superficie construida sin comunes ({{surfaceUnit}})',
    Surveillance: 'Vigilancia',
    tab_information_updated_please_reload_tab: 'La información ha sido actualizada. Recargue la pestaña.',
    tax_type: 'Tipo de impuesto',
    tax: 'Impuesto',
    TECHNICAL_MANAGER_USER: 'Gestor de técnicos',
    TECHNICAL_MANAGER_USERS_add_technical_manager_user: 'Añadir gestor de técnicos',
    TECHNICAL_MANAGER_USERS_appraisals_in_process_count: 'Tasaciones en proceso',
    TECHNICAL_MANAGER_USERS_email: 'Email',
    TECHNICAL_MANAGER_USERS_first_name: 'Nombre',
    TECHNICAL_MANAGER_USERS_is_active: 'Usuario activo',
    TECHNICAL_MANAGER_USERS_lastname: 'Apellidos',
    TECHNICAL_MANAGER_USERS_national_identity_document: 'DNI',
    TECHNICAL_MANAGER_USERS_plus_add_user: '+ Añadir usuario',
    TECHNICAL_MANAGER_USERS_position: 'Cargo',
    TECHNICAL_MANAGER_USERS: 'Usuarios Gestores de Técnicos',
    technical_managers: 'Gestores de Técnicos',
    technicalDept: 'Departamento Técnico',
    TechnicalManager: 'Gestores de técnicos',
    TechnicalManagerUser: 'Gestor de técnicos',
    technician: 'Técnico',
    tendingToSchedule: 'Pendientes de agendar',
    TENURES_warningMessage: 'La suma de los porcentajes ha de ser 100%',
    Terrace: 'Terraza',
    TERRAIN_CLUSTER_all: 'Todos',
    TERRAIN_CLUSTER_AppliedPremiumPercent: 'Prima aplicada',
    TERRAIN_CLUSTER_applyToAll: 'Aplicar a todos',
    TERRAIN_CLUSTER_deadlinesAndComercialParamethers: 'Plazos y parámetros económicos',
    TERRAIN_CLUSTER_expenses: 'Gastos',
    TERRAIN_CLUSTER_ExternalFinancingIncreaseJustification: 'Justifica el incremento de la financiación',
    TERRAIN_CLUSTER_ExternalFinancingIncreasePercent: 'Incremento financiación',
    TERRAIN_CLUSTER_filterByIncome: 'Filtrar por ingreso',
    TERRAIN_CLUSTER_filterByExpense: 'Filtrar por gasto',
    TERRAIN_CLUSTER_FreeRateConstantPercent: 'Tasa libre constante',
    TERRAIN_CLUSTER_FreeRateType: 'Tipo tasa libre',
    TERRAIN_CLUSTER_income: 'Ingresos',
    TERRAIN_CLUSTER_IncomeExpenseDiff: 'Ingresos act. - Gastos act.',
    TERRAIN_CLUSTER_ipc: 'IPC',
    TERRAIN_CLUSTER_isActiveFromDateTime: 'Fecha inicio de promoción',
    TERRAIN_CLUSTER_isActiveUntilDateTime: 'Fecha fin de promoción',
    TERRAIN_CLUSTER_isConstructionFromDateTime: 'Fecha de inicio de la construcción',
    TERRAIN_CLUSTER_isConstructionUntilDateTime: 'Fecha de fin de la construcción',
    TERRAIN_CLUSTER_isSalesFromDateTime: 'Fecha de inicio de las ventas',
    TERRAIN_CLUSTER_isSalesUntilDateTime: 'Fecha de fin de las ventas',
    TERRAIN_CLUSTER_Period: 'Periodo',
    TERRAIN_CLUSTER_PeriodTotalNetExpense: 'TOTAL gastos ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalNetExpenseDiff: 'TOTAL ingresos - gastos ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalNetIncome: 'TOTAL ingresos ({{currency}})',
    TERRAIN_CLUSTER_PeriodTotalUpdatedExpense: 'TOTAL gastos act.',
    TERRAIN_CLUSTER_PeriodTotalUpdatedIncome: 'TOTAL ingresos act.',
    TERRAIN_CLUSTER_PeriodTotalUpdatedIncomeExpenseDiff: 'TOTAL ACTUALIZADO ({{currency}})',
    TERRAIN_CLUSTER_PeriodUpdateMonthlyRatePercent: 'Tasa act. mensual',
    TERRAIN_CLUSTER_RD_EX_Period: 'Periodo',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetExpense: 'TOTAL gastos Asignados({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetExpenseDiff: 'TOTAL ingresos - gastos Asignados({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalNetIncome: 'TOTAL ingresos Asignados({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedExpense: 'TOTAL gastos Actualizados',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedIncome: 'TOTAL ingresos Actualizados',
    TERRAIN_CLUSTER_RD_EX_PeriodTotalUpdatedIncomeExpenseDiff: 'TOTAL ACTUALIZADO ({{currency}})',
    TERRAIN_CLUSTER_RD_EX_PeriodUpdateMonthlyRatePercent: 'Tasa act. mensual',
    TERRAIN_CLUSTER_RiskFreePercent: 'Tasa libre de riesgo',
    TERRAIN_CLUSTER_RiskPremiumWeighted: 'Prima de riesgo ponderada',
    TERRAIN_CLUSTER_Totals: 'Totales',
    TERRAIN_CLUSTER_totalToBeAsigned: 'Total por asignar',
    TERRAIN_CLUSTER_UpdateExpense: 'Gastos actualizados',
    TERRAIN_CLUSTER_UpdateIncome: 'Ingresos actualizados',
    TERRAIN_CLUSTER_UpdateMonthlyRatePercent: 'Tasa act. Mensual eq.',
    TERRAIN_CLUSTER_UpdateRatePercent: 'Tipo actualización',
    TerrainCalculationMethod_0: 'Residual estático',
    TerrainCalculationMethod_1: 'Residual dinámico',
    terrainToAddListTitle: 'Listado de terrenos disponibles para añadir',
    testTranslation: 'Test para ver si se hace el merge',
    the_action_was_successful: 'La acción se ha realizado correctamente',
    ThirdAgeResidence: 'Residencia de ancianos',
    toAssign: 'Pendientes de asignar técnico',
    toCreateManualCustomerMessage: 'Para crear un cliente manualmente es necesario desmarcar el cliente seleccionado pulsando la cruz',
    toDate: 'Hasta',
    TotalBuiltSurfaceWithCommonSurfaces: 'Superficie construida con comunes ({{surfaceUnit}})',
    TotalBuiltSurfaceWithoutCommonSurfaces: 'Superficie construida sin comunes ({{surfaceUnit}})',
    TotalFloorsAboveGround: 'Plantas sobre el rasante',
    TotalFloorsBellowGround: 'Plantas bajo rasante',
    TotalUsefulSurface: 'Superficie útil ({{surfaceUnit}})',
    usefulSurface: 'Superficie útil',
    translationWillBeDeleted: 'Se borrará la traducción. ¿Estás seguro?',
    TreeActionsEnum_0: 'Edición masiva',
    TreeActionsEnum_1: 'Valorar terrenos',
    TreeActionsEnum_2: 'Agrupar valoraciones',
    TreeActionsEnum_3: 'Modificar estructura',
    TreeActionsEnum_4: 'Importar activos de solicitudes',
    TreeActionsEnum_5: 'Recalcular métodos',
    type: 'Tipo',
    typeOfAppraisal: 'Tipo de tasación',
    Typologies: 'Tipologias',
    typology: 'Tipología',
    uncompleteRequiredFields: 'Faltan campos requeridos',
    unexpectedError: 'Error inesperado',
    unitValue: 'Valor de oferta ({{currency_per_surfaceUnit}}/mes)',
    Unproductive: 'Inproductivo',
    until: 'Hasta',
    Urban: 'Urbano',
    UrbanisticSituation: 'Situación urbanística',
    use: 'Uso',
    USER_FORM_ConfirmPassword: 'Repite la contraseña',
    USER_FORM_CustomerId: 'Cliente',
    USER_FORM_IsActive: 'Usuario activo',
    USER_FORM_RequesterCompanyId: 'Prescriptores',
    User: 'Usuarios',
    userNotAssigned: 'Usuario sin asignar',
    userNotAvailable: 'Usuario no disponible',
    USERS_add_new_client_or_prescriber_user: 'Añadir nuevo usuario de cliente o prescriptor',
    USERS_add_new_user: 'Añadir nuevo usuario',
    USERS_add: 'Añadir usuario',
    USERS_addWarningModal: 'Recuerde que el precio de su tarifa depende del número de usuarios asociados y las tasaciones realizadas. Si desea añadir uno nuevo se cargará el importe adicional en el método de pago seleccionado en su perfil.',
    USERS_commercial: 'Desempeña el rol de gestor comercial',
    USERS_createIncompatibility: 'Crear incompatibilidad',
    USERS_detail: 'Detalle',
    USERS_edit_user: 'Editar usuario',
    USERS_list: 'Listado',
    USERS_process: 'Desempeña el rol de gestor de procesos',
    USERS_professional: 'Desempeña el rol de profesional',
    USERS_receiveNotificationsByEmail: 'Recibir notificaciones en el correo',
    USERS_roles: 'Roles',
    USERS_supplierCompany: 'Sociedad de tasación',
    USERS_technical: 'Desempeña el rol de gestor de técnicos',
    USERS_user: 'Usuario',
    USERS_roles_incompatibilities: 'Los roles que ha seleccionado son incompatibles entre ellos.',
    users: 'Usuarios',
    USERS: 'Usuarios',
    useType: 'Tipo de uso',
    VALIDATE_APPRAISAL_ASSETS_MODAL_appraisalData: 'Dato tasación',
    VALIDATE_APPRAISAL_ASSETS_MODAL_fieldName: 'Nombre del campo',
    VALIDATE_APPRAISAL_ASSETS_MODAL_originData: 'Dato origen',
    VALIDATE_APPRAISAL_ASSETS_MODAL_saveChange: 'Guardar cambio',
    validated: 'Validadas',
    validatedSing: 'Validada',
    validating: 'En proceso de validación',
    validation: 'Validación',
    ValidationAppraisalStepsWithNumber_1: '1 - Alta',
    ValidationAppraisalStepsWithNumber_2: '2 - Responsable de procesos asignado',
    ValidationAppraisalStepsWithNumber_3: '3 - Responsable de técnicos asignado',
    ValidationAppraisalStepsWithNumber_4: '4 - Técnico asignado',
    ValidationAppraisalStepsWithNumber_5: '5 - Validación empezada',
    ValidationAppraisalStepsWithNumber_6: '6 - Validación finalizada',
    ValidationAppraisalStepsWithNumber_7: '7 - Entregado a cliente',
    ValidationFinishDatetime: 'Fecha de validación',
    validationFinished: 'Validación finalizada',
    validationProcess: 'En proceso de validación',
    valoratedBatches: 'Lotes valorados',
    VALUATION_LIST_residualDynamicDeleteMessage: 'Este método sólo se puede eliminar desde el método de residual dinámico de datos comunes correspondiente en la pestaña Estado de la obra',
    VALUATION_LIST_residualDynamicInfo: 'El método residual dinámico se añadirá desde el capitulo Estado de la obra',
    VALUATIONS_currentValuations: 'Valoración actual',
    VALUATIONS_edit_reversion: 'Editar valor de reversión',
    VALUATIONS_futureValuations: 'Valoración H.E.T.',
    VALUATIONS_reversion_observations: 'Observaciones relativas al valor de reversión',
    VALUATIONS_edit: '¿Estás seguro de que quieres salir? Quedan campos por rellenar que son requeridos',
    valuations: 'Valoraciones',
    visit: 'Visita',
    hourVisit: 'Hora de visita',
    Volume: 'Tomo',
    vpo: 'Vivienda de protección oficial',
    WallType: 'Tipo de paredes',
    Warehouse: 'Almacén',
    warning: 'Advertencia',
    warningModalTitle: 'AVISO!',
    warnings: 'Advertencias',
    withholding: 'Retención de %IRPF',
    year: 'Año',
    yes: 'si',
    yesSaving: 'Sí, guardando',
    yesWithoutSaving: 'Sí, sin guardar',
    your_business: 'Tu empresa',
    yourLicenses: 'Tus licencias',
    AUTHORITIES_canNotAccess: 'No tienes permisos para acceder',
    AUTHORITIES_permissions: 'Permisos',

    APPRAISALS_COMMUNICATIONS_BREADCRUMB_communications: 'Comunicaciones',
    APPRAISALS_COMMUNICATIONS_list_title: 'Comunicaciones',
    APPRAISALS_COMMUNICATIONS_list_tab_communications: 'Comunicaciones',
    APPRAISALS_COMMUNICATIONS_list_tab_records: 'Expedientes',
    APPRAISALS_COMMUNICATIONS_list_id: 'ID',
    APPRAISALS_COMMUNICATIONS_list_appraisal_id: 'ID expediente',
    APPRAISALS_COMMUNICATIONS_list_request_id: 'ID Solicitud',
    APPRAISALS_COMMUNICATIONS_list_prescriptorName: 'Nombre del solicitante',
    APPRAISALS_COMMUNICATIONS_list_status: 'Estado',
    APPRAISALS_COMMUNICATIONS_list_lastEventDate: 'Fecha Último Evento',
    APPRAISALS_COMMUNICATIONS_list_emitter: 'Emisor',

    REQUESTS_COMMUNICATIONS_BREADCRUMB_communications: 'Comunicaciones',
    REQUESTS_COMMUNICATIONS_list_title: 'Comunicaciones',
    REQUESTS_COMMUNICATIONS_list_tab_records: 'Expedientes',
    REQUESTS_COMMUNICATIONS_list_tab_communications: 'Comunicaciones',
    REQUESTS_COMMUNICATIONS_list_id: 'ID',
    REQUESTS_COMMUNICATIONS_list_request_id: 'ID Solicitud',
    REQUESTS_COMMUNICATIONS_list_prescriptorName: 'Nombre del solicitante',
    REQUESTS_COMMUNICATIONS_list_status: 'Estado',
    REQUESTS_COMMUNICATIONS_list_lastEventDate: 'Fecha Último Evento',
    REQUESTS_COMMUNICATIONS_list_emitter: 'Emisor',
    APPRAISALS_COMMUNICATIONS_list_action: 'Acción',
    APPRAISALS_COMMUNICATIONS_info: 'No existe información',

    zoom: 'zoom',
    rotate: 'rotar',
    reflect: 'reflejar',
    straightened: 'enderezar',
    resetImageCropperChanges: 'Borrar cambios',

    communications: 'Comunicaciones',
    REQUESTS_unknown_user: 'Usuario desconocido',

    APPRAISALS_COMMUNICATIONS_internal_id: 'ID Interno',
    REQUESTS_COMMUNICATIONS_internal_id: 'ID Interno',

    APPRAISALS_COMMUNICATIONS_list_workflowItemId: 'ID del Proceso',
    APPRAISALS_COMMUNICATIONS_list_requesterCompany: 'Solicitante',
    APPRAISALS_COMMUNICATIONS_list_state: 'Estado',
    APPRAISALS_COMMUNICATIONS_list_lastUpdateDateTime: 'Última actualización',
    APPRAISALS_COMMUNICATIONS_list_lastUpdatedUser: 'Usuario',

    APPRAISALS_COMMUNICATIONS_LIST_FILTER_id: 'ID',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_workflowItemId: 'ID del proceso',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_state: 'Estado',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_lastUpdateMinDatetime: 'Última actualización',
    APPRAISALS_COMMUNICATIONS_LIST_FILTER_lastUpdateMaxDatetime: 'Última actualización',
    APPRAISAL_HISTORY_COMMUNICATIONS_there_is_no_communications: 'No hay ningun evento de comunicación',

    REQUESTS_COMMUNICATIONS_list_workflowItemId: 'ID del Proceso',
    REQUESTS_COMMUNICATIONS_list_requesterCompany: 'Solicitante',
    REQUESTS_COMMUNICATIONS_list_state: 'Estado',
    REQUESTS_COMMUNICATIONS_list_lastUpdateDateTime: 'Última actualización',
    REQUESTS_COMMUNICATIONS_list_lastUpdatedUser: 'Usuario',

    REQUESTS_COMMUNICATIONS_LIST_FILTER_id: 'ID',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_workflowItemId: 'ID del proceso',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_state: 'Estado',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_lastUpdateMinDatetime: 'Última actualización',
    REQUESTS_COMMUNICATIONS_LIST_FILTER_lastUpdateMaxDatetime: 'Última actualización',
    ASSETS_category: 'Categoría',
    ASSETS_targetType: 'Tipo de destino',
    ASSETS_restaurant: 'Restaurante',
    ASSETS_meetingRoom: 'Sala de conferencias/reuniones',
    ASSETS_sportsFacilities: 'Instalaciones deportivas',
    ASSETS_hotelBuildingCharacteristics: 'Características del hotel',

    APPRAISALS_records_history: 'Historial',
    APPRAISALS_records_history_communications: 'Comunicaciones',
    APPRAISALS_records_history_records: 'Expedientes',

    REQUESTS_records_history_communications: 'Comunicaciones',

    TOUR_button: '¿Necesitas ayuda?',
    TOUR_next: 'Siguiente',
    TOUR_prev: 'Atrás',
    TOUR_done: '¡Ok!',
    TOUR_processManager: '¡Selecciona un <b>Gestor de procesos</b> para poder continuar!',
    TOUR_appraiser: '¡Selecciona un <b>Técnico</b> para poder continuar! Recuerda que los siguientes pasos se realizan desde la <b>app móvil</b>.',
    TOUR_technicalManager: 'Selecciona un <b>Gestor de técnicos</b> ¡y empieza la validación del informe!',
    TOUR_stepMobile: '…si no, ¡podrás realizar el informe desde la <b>app móvil de Tasafy</b>!',
    TOUR_advanceSteps: '¿Prefieres <b>avanzar la visita</b> sin necesidad de pasar por la app  móvil? Completa las fechas y podrás avanzar hasta paso 7...',
    TOUR_startValidation: 'Puedes empezar la validación del infome desde aquí',
    TOUR_finishOkValidation: 'Si la validación es correcta y quieres finalizarla, puedes hacerlo desde aquí...',
    TOUR_finishKoValidation: '...por el contrario, si crees que hay algún error, pulsa aquí para que el técnico lo revise.',
    TOUR_documents: 'Ve a la sección <b>Documentos</b>, ¡ahí encontrarás tu informe en formato <b>PDF</b>!',
    TOUR_appraiserSignature: '¡Busca el <b>profesional</b> que ha realizado el expediente y añade en la parte de <b>documentos</b> la <b>firma</b> para que se incluya en el informe!',
    TOUR_reclamationNotLastStep: '¿Necesitas hacer alguna <b>modificación</b> en el expediente? Pasa el expediente a 11 y abre una <b>reclamación</b>. Al marcarla como <b>procede</b> el expediente volverá al paso 8 para que puedas empezar de nuevo la validación y cambiar lo que necesites.',
    TOUR_reclamationLastStep: '¿Necesitas hacer alguna <b>modificación</b> en el expediente? Abre una <b>reclamación</b>. Al marcarla como <b>procede</b> el expediente volverá al paso 8 para que puedas empezar de nuevo la validación y cambiar lo que necesites.',
    TOUR_firstEntityPost: '¿Quieres realizar tu primer expediente? Asegúrate de haber creado previamente un cliente',
    TOUR_firstAppraisalPost: 'Tras haber creado el cliente puedes empezar a realizar tu primer informe. ¡No olvides marcar el check de ejecución por Tasafy para completar los campos del informe.',
    TOUR_firstVisitToAppraisal: '¿Es tu <b>primer expediente</b>? Puedes clickar en Tafy para que te ayude en los puntos claves para que te indique como continuar.',
    ASSETS_otherFeaturesRusticEstate: 'Otras características de la finca',
    ASSETS_electricalEnergy: 'Energía eléctrica',
    ASSETS_accessToRoadInfrastructure: 'Acceso a infraestructuras viales',
    ASSETS_legalizedIrrigationWater: 'Agua de regadío legalizada',
    ASSETS_expectedLeverage: 'Aprovechamiento esperado (Finca rústica con expectativas urbanísticas)',

    ASSETS_builtSurfaceOfHouseholds: 'Superficie construida de viviendas',
    ASSETS_builtSurfaceForAgriculturalUses: 'Superficie construida destinada a usos agropecuarios',
    ASSETS_coveredTerraceArea: 'Superficie terraza cubierta',
    ASSETS_openTerraceArea: 'Superficie terraza descubierta',

    WELCOME_CONNECTIONS_step1_title: '¡Bienvenido al mundo de las conexiones en Tasafy!',
    WELCOME_CONNECTIONS_step1_paragraph1: 'Sumérgete en el universo <b>Tasafy</b> y conecta con los diferentes <i>players</i> para poder realizar las tasaciones.',
    WELCOME_CONNECTIONS_step1_paragraph2: '¿Qué significa esto?',

    WELCOME_CONNECTIONS_step2_paragraph1: 'En este apartado encontrarás dos códigos diferentes que podrás compartir para conectar con otras licencias.',
    WELCOME_CONNECTIONS_step2_section1_title: 'CÓDIGO DE LICENCIA',
    WELCOME_CONNECTIONS_step2_section1_paragraph1: 'Es el código que comienza por <b>L-</b>. Facilita este código a otros usuarios para que puedan conectar contigo y empezar a trabajar para ti como profesionales',
    WELCOME_CONNECTIONS_step2_section2_title: 'CÓDIGO DE PROFESIONAL',
    WELCOME_CONNECTIONS_step2_section2_paragraph1: 'Es el código que comienza por <b>U-</b>. Facilita este código a otras licencias para que puedan conectar contigo y que tú formes parte de su red para que puedan encargarte trabajo.',

    WELCOME_CONNECTIONS_step3_paragraph1: 'Si dispones de algún código con el que quieras conectar podrás añadir una conexión desde el botón <b>Nueva Conexión</b>. ',
    WELCOME_CONNECTIONS_step3_paragraph2: 'Así de simple, podrás encargar trabajo a todos los profesionales que formen parte de tu red y viceversa, si eres tú quien se conecta a otras licencias te podrán encargar trabajo a ti.',

    WELCOME_CONNECTIONS_step4_paragraph1: 'En el historial podrás ver todas las conexiones que tienes y en cualquier momento podrás cancelar esa conexión si ya no quieres que un usuario forme parte de tu red o tú formar parte de la suya.',
    WELCOME_CONNECTIONS_step4_paragraph2: 'En el caso de que este usuario tuviese expedientes activos los podrás reasignar a cualquier usuario perteneciente a tu licencia.',

    WELCOME_CONNECTIONS_step5_paragraph1: 'Desde tu perfil podrás ver las diferentes cuentas a las que estás conectado, incluso podrás ver desde Mi trabajo los expedientes pendientes que tienes asignados en cada una de ellas.',
    WELCOME_CONNECTIONS_step5_button: '¡A conectar!',
    APPRAISAL_REQUEST_DATA_customer_modal_tooltip_message: 'Puedes crear una solicitud desde el panel de solicitudes',
    CUSTOMER_PRESCRIBER_customer_modal_tooltip_message: "Puedes crear un cliente desde el menu superior 'Entidades'",
    CUSTOMER_PRESCRIBER_prescriber_modal_tooltip_message: "Puedes crear un prescriptor desde el menu superior 'Entidades'",
    QUALITY_CONTROL_customer_modal_tooltip_message: "Puedes crear un cliente desde el menu superior 'Entidades'",
    APPRAISAL_OWNER_DATA_customer_tooltip_message: "Puedes crear un cliente desde el menu superior 'Entidades'",
    REQUESTER_COMPANY_prescriber_tooltip_message: "Puedes crear un prescriptor desde el menu superior 'Entidades'",
    SELECT_CUSTOMER_MODAL_table_no_content_label: 'Todavía no tienes ningún cliente creado',

    COMMUNICATIONS_added: 'Añadido',
    COMMUNICATIONS_information: 'Información',
    COMMUNICATIONS_body: 'Body',
    COMMUNICATIONS_updated: 'Actualizado',
    COMMUNICATIONS_lastUpdated: 'Última actualización',
    COMMUNICATIONS_events: 'Eventos',
    COMMUNICATIONS_of: 'de',
    COMMUNICATIONS_seeAll: '< Ver todas las comunicaciones',
    APPRAISAL_CLAIMS_proceed: 'Procede',
    APPRAISAL_CLAIMS_not_proceed: 'No procede',
    APPRAISAL_CLAIMS_solve_claim: 'Resolver reclamación',

    BOTTOM_BAR_error_dialog_enabled: 'Modal de errores habilitado',

    APPRAISALS_errorInForm: 'Error en algún campo del formulario',

    APPRAISALS_information: 'Información del encargo',
    REQUESTS_information: 'Información del encargo',
    EXTRA_INFORMATION_information: 'Información del encargo',
    EXTRA_INFORMATION_informationProvidedByEntity: 'Datos aportados por la entidad',
    EXTRA_INFORMATION_noExtraInformation: 'No hay datos de referencia',
    EXTRA_INFORMATION_title: 'Título',
    EXTRA_INFORMATION_description: 'Descripción',
    EXTRA_INFORMATION_add: 'Añadido por',
    EXTRA_INFORMATION_date: 'Fecha añadido',

    REQUESTS_reportTypes: 'Tipo de informe',
    APPRAISALS_reportTypes: 'Tipo de informe',

    ASSETS_publicTransportation: 'Transporte públicos',
    ASSETS_equipmentEndowments: 'Dotaciones de equipamientos',
    ASSETS_businessEnvironment: 'Entorno comercial',
    ASSETS_tertiaryEnvironment: 'Entorno terciario',
    ASSETS_industrialEnvironment: 'Entorno industrial',
    ASSETS_marketingPropertyDifficulties: '¿Difícil comercialización del inmueble?',
    ASSETS_numberOfElevators: 'Número de ascensores',
    ASSETS_witnessInternalState: 'Estado interior del testigo',
    ASSETS_buildingNumberOfFloors: 'Número de plantas del edificio',
    ASSETS_buildingNumberOfElevators: 'Número de ascensores del edificio',

    ASSETS_publicParkings: 'Aparcamientos públicos',
    ASSETS_assetInternalState: 'Estado interior del inmueble',
    ASSETS_assetInternalOrExternal: 'Inmueble exterior o interior',
    APPRAISALS_selectDocuments: 'Seleccionar documentos de la tasación',
    APPRAISALS_REPORT_selectDocuments: 'Seleccionar documentos',
    APPRAISALS_downloadDocumentsSuccess: 'Se han descargado los documentos',
    APPRAISALS_downloadDocumentsError: 'Error en la descarga de documentos',

    MULTIPLE_FILE_UPLOAD_chooseLabel: 'Seleccionar',
    MULTIPLE_FILE_UPLOAD_uploadLabel: 'Subir',
    MULTIPLE_FILE_UPLOAD_cancelLabel: 'Eliminar todos',
    MULTIPLE_FILE_UPLOAD_noItemsAdded: 'No hay ninguna elemento añadido.',
    APPRAISALS_REPORT_selectAll: 'Seleccionar todos',

    ASSETS_notStartedStoppedUrbanization: 'Urbanización no iniciada o parada',
    ASSETS_orientation: 'Orientación',

    CLUSTER_ITEMS_LIST_can_not_be_removed_at_least_two_assets: 'Imposible eliminar. Debe haber al menos dos assets por agrupación.',
    REQUESTS_invoicesInformation: 'Información de la valoración',
    REQUESTS_selectResolver: 'Seleccionar resolutor',
    REQUESTS_resolver: 'Sociedad de Tasación',

    REQUESTS_DASHBOARD_recordState: 'Estado actual de las solicitudes',
    REQUESTS_DASHBOARD_totalOrders: 'Total de solicitudes: ',
    records: 'expedientes',

    NETWORK_basicClientCardTitle: '¡Permite a las sociedades, despachos, etc… que puedan trabajar contigo!',
    NETWORK_basicClientCardContent: 'Este código de licencia permite que otras personas puedan buscarte y conectar contigo para que les puedas encargar trabajo.',
    NETWORK_licenseeToBasicClientMessage: 'Quiero unirme a tu licencia para que puedas encargarme solicitudes...',
    NETWORK_basicClientToLicenseeMessage: 'Quiero que te unas a mi licencia para poder encargarte solicitudes...',
    NETWORK_newLicenseeUserConnection: 'Nueva conexión licencia/profesional',
    NETWORK_newLicenseeRelationshipLicenseeConnection: 'Nueva conexión con cliente',
    NETWORK_newLicenseeRelationshipBasicClientConnection: 'Nueva conexión con licencia',
    APPRAISALS_REPORT_expenseType: 'Tipo de gasto',

    ASSET_numberOfCopiesModalTitle: '¿Cuántas veces quieres copiarlo?',
    ASSET_numberOfCopies: 'Nº de copias',
    APPRAISALS_REPORT_copy: 'Copiar',
    APPRAISALS_LIST_professional: 'Profesional',
    NETWORK_myNetwork: 'Mi red',
    NETWORK_customerConnections: 'Conexiones clientes',

    EXTRA_INFO_addNewData: '+ Añadir nuevo dato',
    EXTRA_INFO_addNewDataTitle: 'Añadir nuevo dato',
    EXTRA_INFO_generalDataTitle: 'Datos generales',
    EXTRA_INFO_noDataToAdd: 'No existen datos para añadir',
    EXTRA_INFO_succesRemovingData: 'Dato eliminado correctamente',
    EXTRA_INFO_errorRemovingData: 'Error al eliminar el dato',
    EXTRA_INFO_errorCreatingData: 'Error al crear el dato',
    EXTRA_INFO_succesCreatingData: 'Dato creado correctamente',
    ASSET_numberOfCopiesError: 'Número de copias entre 1 y 20',
    BADGE_visitScheduleDeadlineExpiredLabel: 'Fuera de plazo para agendar',
    BADGE_appraiserAllDataSentDeadlineExpiredLbel: 'Entrega de informe fuera de plazo',
    BADGE_customerDeadlineExpiredLabel: 'Entrega a cliente fuera de plazo',
    BADGE_withIncidentsLabel: 'Con incidencias',
    BADGE_withClaimsLabel: 'Con reclamaciones',
    BADGE_cancelledLabel: 'Anulado',

    APPRAISALS_advance_visit: 'Avanzar visita',
    APPRAISALS_cantAdvanceVisitMessage: 'No tienes permiso para ralizar esta acción. Tienes que tener marcado el rol de profesional.',
    APPRAISALS_visitScheduleDate: 'Fecha visita agendada',
    APPRAISALS_startedVisitDate: 'Fecha visita empezada',
    APPRAISAL_finishVisitDate: 'Fecha visita finalizada',
    APPRAISALS_copyFromVisitScheduleDate: 'Copiar fecha de visita agendada',
    APPRAISALS_confirmImportReport: 'Va a importar los datos del informe seleccionado, al actual, ¿Está seguro?',
    APPRAISALS_ImportedConfirmed: 'Los datos han sido importados con éxito',
    REQUEST_errorSelectReportTypeFirst: 'Es necesario seleccionar previamente el Tipo de informe',
    REPORT_CUSTOMIZATION_includeTextInCertificate: 'Incluir texto certificado',
    REPORT_CUSTOMIZATION_certificateTextLabel: 'Texto certificado (superior)',
    REPORT_CUSTOMIZATION_certificateTextIsAppraisalCompany: '¿Sociedad de tasación?',
    REPORT_CUSTOMIZATION_certificateTextIsAppraisalCompanyMessage: 'NOMBRE, representante legal de SOCIEDAD',
    WITNESS_copy: 'Copiar',
    WITNESS_confirmDeleteMessage: '¿Estás seguro de que quieres eliminar el testigo de tu base de datos?',
    WITNESS_OwnComparables: 'Comparables propios',
    WITNESS_IdealistaComparables: 'Comparables Idealista',
    REQUESTS_payments: 'Pagos',
    REQUESTS_paymentId: 'Id pago',
    REQUESTS_paymentState: 'Estado',
    REQUESTS_paymentRedsysPaymentIdContains: 'Redsys Id',

    APPRAISALS_advanceTo: 'Avanzar a',
    APPRAISALS_chooseStepToAdvance: 'Elige el paso al que quieres avanzar.',
    APPRAISALS_chooseSuggestedDate: '¿Quieres escoger alguna de las fechas propuestas para agendar la visita?',
    APPRAISALS_chooseProposedDate: 'Fecha propuesta',
    APPRAISALS_completeDates: 'Completar fechas',

    IMPORT_ASSETS_REQUEST_message: 'Va a importar los activos de la solicitud',
    ASSETS_ceeEmissions: 'Emisiones (kgCO₂/m² año)',
    ASSETS_ceeEmissionsLetter: 'Letra emisiones',
    ASSETS_ceeDemand: 'Demanda (kWH/m² año)',
    ASSETS_ceeDemandLetter: 'Letra demanda',

    ASSETS_ceeHeating: 'Calefacción',
    ASSETS_ceeRefrigeration: 'Refrigeración',
    ASSETS_ceeLighting: 'Iluminación',
    ASSETS_CEEConsuEner: 'Consumo energía total (Kwh/m² año)',
    ASSETS_CEENormatiAnroConst: 'Normativa que aplica respecto año construcción',
    ASSETS_CEECatAnter: 'Calificación energética anterior',
    ASSETS_CEEFchRecCertif: 'Fecha de visita recogida en el CEE',
    ASSETS_CEEFchLimValid: 'Fecha límite de validez del CEE',
    ASSETS_CEEFchEmisionCertif: 'Fecha de emisión del CEE',
    ASSETS_CEEMejoraCalAntCEE: '¿Mejora la calificación desde CEE anterior?',
    ASSETS_CEEPorceMejora: 'Porcentaje de mejora',
    ASSETS_CEECo2Emisions: 'Emisiones de dióxido de carbono (KgCO2/M2)',
    APPRAISALS_vinculationInternalExternal: 'Interna/Externa',
    APPRAISALS_vinculationFile: 'Expediente en:',
    APPRAISALS_vinculationFileInfo: '* El expediente seleccionado procede de Borsan. Esta valoración tendrá características particulares durante la ejecución del informe.',
    APPRAISALS_notifyToEntity: 'Notificar a la entidad',
    APPRAISALS_entityObservations: 'Observaciones a la entidad',

    COMMUNICATIONS_FILTER_BAR_needToDoSomething: 'Requieren una acción',
    COMMUNICATIONS_FILTER_BAR_inProcess: 'En proceso',
    COMMUNICATIONS_FILTER_BAR_finishedAndReviewed: 'Finalizadas o revisadas',

    COMMUNICATIONS_markAsReviewed: 'Marcar como revisada',
    COMMUNICATIONS_reviewed: 'Revisada',

    statusFilters: 'Estado del encargo',
    REPORT_validation_calculate: 'Actualizar validaciones',
    REPORT_validation_watch: 'Info validaciones',
    REPORT_validation_calculating: 'Calculando...',
    REPORT_validation_succeed: 'Pincha aquí para ver el resultado de la validación',
    APPRAISALS_externalManager: 'Validador básico',

    ACCOUNT_MANAGEMENT_invoiceCustomization: 'Configuración de facturas',
    ACCOUNT_MANAGEMENT_areYouSureDeleteLogo: '¿Estás seguro de que quieres eliminar el logo personalizado para tu nuevas facturas?',
    ACCOUNT_MANAGEMENT_customLogo: 'Logo personalizado',
    REQUESTS_expensesProvisionAllAmountInEuro: 'Provisión de Ingresos/Gastos',
    APPRAISALS_precedentType: 'Tipo de antecedente',
    APPRAISALS_updatePrecedents: 'Actualizar antecedentes',
    APPRAISALS_precedents: 'Antecedentes',
    APPRAISALS_lookingForPrecedents: 'Estamos buscando antecedentes...',
    APPRAISALS_precedentsFounded: 'Se han encontrado antecedentes para esta tasación. Puedes encontrar estos expedientes en el apartado de antecedentes.',
    APPRAISALS_precedentsNotFounded: 'No se han encontrado antecedentes para esta tasación.',
    APPRAISALS_precedentsTitleTooltip: 'Se muestra el listado de tasaciones que existe en el sistema independientemente de la fecha de realización.',
    APPRAISALS_aditionalDataNotCompleted: 'Se deben añadir en Datos Adicionales los datos presentados en información del encargo. De lo contrario no se podrá enviar.',
    LINK_tooltip: 'Utiliza control + clic, o la rueda del ratón para abrir en una nueva pestaña',
    COMPARABLES_environmentFeatures: 'Características del entorno',
    COMPARABLES_situationWithinArea: 'Situación dentro zona',
    COMPARABLES_socioEconomicLevel: 'Nivel socio-económico zona',
    COMPARABLES_fastTrackProximity: 'Cercanía vía rápida',
    COMPARABLES_buildingType: 'Tipo edificio-zona',

    COOKIES_headerTitle: 'Tu configuración de cookies',
    COOKIES_headerDescription: 'Cuidamos bien de ti y de tus datos. Puedes leer más sobre cómo utilizamos las cookies y actualizar tu configuración de cookies aquí.',
    COOKIES_analyticsCookies: 'Cookies analíticas',
    COOKIES_analyticsCookiesDescription: 'Las cookies analíticas hacen posible recoger estadísticas sobre el uso y el tráfico de nuestra página web, de manera que la podamos mejorar.',
    COOKIES_analyticsPurposeCookiesProviders: '¿Qué proveedores de cookies usamos con propósitos analíticos?',
    COOKIES_analyticsPurposeCookiesProvidersDescription: 'Utilizamos estos proveedores para recopilar información con fines estadísticos.',
    COOKIES_googleAnalyticsDescription: 'Google se especializa en servicios y productos relacionados con internet, que incluyen tecnologías de publicidad en línea, un motor de búsqueda, computación en la nube, software y hardware.',
    COOKIES_hotjarDescription: 'Hotjar Ltd. provee herramientas analíticas para la web que analizan el tráfico de datos del cliente',
    COOKIES_urlText: 'Lee la política de privacidad del proveedor de las cookies',
    MULTIPLE_EDITION_title: 'Edición masiva',
    MULTIPLE_EDITION_chapter: 'Capítulo',
    MULTIPLE_EDITION_chooseChapterMessage: 'Elige un capítulo para completar la información de los activos en edición masiva',
    MULTIPLE_EDITION_errorMessage: 'Revisa los campos del formulario',
    MULITPLE_EDITION_chaptersSelector: 'Selector de capítulo',
    COMMUNICATIONS_technicalManager: 'Validador',
    copy_button: 'Copiar',
    paste_button: 'Pegar',
    copy_success: 'La información ha sido copiada.',
    paste_success: 'Información pegada.',
    paste_error: 'No hay información copiada.',
    paste_not_allowed: 'No se puede pegar la información sobre un expediente ya cerrado.',
    copied: '¡Copiado!',
    APPRAISALS_errorRecalculatingValidations: 'No ha sido posible recalcular las validaciones',

    VALIDATION_checkWarnings: '¡Realizado con éxito! Te mostramos las advertencias para verificar que las has tenido en cuenta',
    APPRAISALS_contract: 'Integración',
    emptyValue: 'Sin valor',

    web_reload_app_title: '¡Nueva versión disponible!',
    web_reload_app_message: '<p class="app-version-p">Existe una <b>nueva versión</b> de la herramienta disponible.</p><p class="app-version-p"> Para poder disfrutar de las últimas actualizaciones, recarga esta pesataña pulsando <img class="app-version-image" src="assets/images/icons/reload.PNG"> al lado de la barra de búsqueda del navegador o pulsa las teclas <span class="app-version-key">Ctrl</span> <b>+</b> <span class="app-version-key">F5</span> en tu teclado.</p>',
    mobile_web_reload_app_message: '<p class="app-version-p">Existe una <b>nueva versión</b> de la herramienta disponible.</p><p class="app-version-p"> Para poder disfrutar de las últimas actualizaciones, recarga esta pesataña pulsando <img class="app-version-image" src="assets/img/reload.PNG"> al lado de la barra de búsqueda del navegador o pulsa las teclas <span class="app-version-key">Ctrl</span> <b>+</b> <span class="app-version-key">F5</span> en tu teclado.</p>',
    mobile_android_reload_app_message: '<p class="app-version-p">Existe una <b>nueva versión</b> de la herramienta disponible.</p><p class="app-version-p"> Para poder disfrutar de las últimas actualizaciones, descarga la app desde la <b>Play Store</b>.</p>',
    mobile_ios_reload_app_message: '<p class="app-version-p">Existe una <b>nueva versión</b> de la herramienta disponible.</p><p class="app-version-p"> Para poder disfrutar de las últimas actualizaciones, descarga la app desde la <b>Apple Store</b></p>',
    APPRAISALS_mbWeight: 'Peso',
    APPRAISALS_sizeInMB: 'MB',
    APPRAISALS_editDocument: 'Editar documento',
    DOCUMENTS_obtainedDatetimeNull: '<b>La fecha de emisión es requerida en los documentos de tipo</b> Documentación registral, Nota Simple y Certificación registral.',
    DOCUMENTS_vinculatedToAssets: 'Los documentos de tipo Documentación Catastral deben estar vinculados a un activo.',

    MASSIVE_usefulSurface: 'Útil',
    MASSIVE_surfaceWithCommons: 'Construida con comunes',
    MASSIVE_surfaceWithoutCommons: 'Construida sin comunes',
    MASSIVE_terrainAndRusticEstateSurface: 'Terreno y finca rústica',
    MASSIVE_marketStudy: `Para crear un estudio de mercado, puedes hacerlo desde: •Datos Comunes -> Estudio de Mercado`,
    Massive_marketStudyCopyCell: 'Uso y tipología son diferentes',
    Massive_surfaceCopyCell: 'No existe la superficie para este activo.',
    APPRAISAL_notifiedToEntity: 'Notificación a la entidad',

    cadastralSurfaces: 'catastral',
    checkedSurfaces: 'comprobada',
    registeredSurfaces: 'registral',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalDatetime: 'Fecha de validación anterior',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalWorkProgressPercent: 'Avance obra del informe anterior (%)',
    APPRAISALS_externalIntermediateWorkEvaluationLastAppraisalTotalValue: 'Valor tasación anterior',
    entityDetail: 'Detalle de la entidad',

    ASSETS_rCoefficient: 'R',
    ASSETS_rCoefficientInfo: 'Superficie construida con comunes entre superficie útil',
    ASSETS_existsComparableMarket: '¿Existe un mercado de comparables?',
    ASSETS_defaultComparableMarketText: 'No se ha podido calcular el valor del inmueble por el método de comparación ya que no se cumplen los requisitos descritos en el artículo 21 de la Orden ECO 805/2003 para la utilización de este método.',

    appraisalInfo: 'Info',
    REPORT_LIST_succesDraftDeneration: 'Si el borrador del informe no se ha abierto en una pestaña nueva, revisa que no tengas bloqueadas las ventanas emergentes en el navegador y vuelve a generarlo.',
    APPRAISALS_externalConnectionError: 'Ha habido un error con la conexión',
    ASSETS_socioEconomicLevel: 'Nivel socioeconómico',
    languageReport: 'Idioma del informe',
    SelectAllNodes: 'Marcar/Desmarcar todos',
    CONTACT_US_title_Tasafy: 'Teléfono de soporte de Tasafy',
    CONTACT_US_description: 'Si tienes alguna duda o problema con la herramienta llámanos al teléfono indicado para que podamos ayudarte.',
    CONTACT_US_call: 'Llamar',
    MASSIVE_possessions: 'Posesiones',
    MASSIVE_stays: 'Estancias',
    locationError: 'No se encuentran las coordenadas para la dirección indicada.',

    hour: 'Hora',
    creation: 'Creación',
    edition: 'Edición',
    actual: 'Actual',
    HET: 'HET',

    VALORATION_METHOD_MODAL_ACTION: '¿Qué acción quieres realizar?',
    VALORATION_METHOD_MODAL_TYPE: 'Tipo de valoración',
    PARTNER_MODAL_slide1_title1: 'Programa Colaborador 2023',
    PARTNER_MODAL_slide1_subtitle1: 'TE AYUDAMOS A HACER TUS SUEÑOS MÁS GRANDES',
    PARTNER_MODAL_slide2_header1: 'Aún más cerca de tu crecimiento',
    PARTNER_MODAL_slide2_paragraph1: 'En Valum siempre nos hemos preocupado por ayudarte a <b>sacar el máximo beneficio a tu trabajo</b>.',
    PARTNER_MODAL_slide2_paragraph2: 'Primero lo hicimos ofreciéndote entrar en nuestro <b>programa colaborador</b>: una nueva forma de <b>desarrollo profesional</b> y una fuente de ingresos complementaria.',
    PARTNER_MODAL_slide2_paragraph3: 'Después, lanzamos la herramienta de gestión y ejecución de tasaciones más completa del mercado -<b>Tasafy</b>-, y te dimos acceso al <b>módulo colaborador: un espacio único</b> donde dar de alta tus propios presupuestos, hacer seguimiento a tiempo real de todo tu trabajo y conseguir la máxima satisfacción de tus clientes.',
    PARTNER_MODAL_slide2_paragraphBottom1: '...',
    PARTNER_MODAL_slide3_header1: 'Te presentamos algo revolucionario...',
    PARTNER_MODAL_slide3_paragraph1: 'Ahora llega 2023 y queremos seguir <b>apostando por ti y tu trabajo</b>.',
    PARTNER_MODAL_slide3_paragraph2: '¿Te imaginas ingresar tus honorarios en el mismo momento en el que el cliente recibe su informe de tasación?',
    PARTNER_MODAL_slide3_paragraph3: '¡Lo hacemos posible!',
    PARTNER_MODAL_slide4_header1: '¡Descúbrelo! Tu agilidad marcará la nuestra',
    PARTNER_MODAL_slide4_paragraph1: 'El cliente realiza el pago del presupuesto emitido desde Tasafy.',
    PARTNER_MODAL_slide4_paragraph2: 'Realizas la visita al inmueble y comienzas la ejecución del informe de tasación a través de Tasafy.',
    PARTNER_MODAL_slide4_paragraph3: 'Haces entrega del borrador de informe de tasación a Valum. Este es validado y entregado al cliente.',
    PARTNER_MODAL_slide4_paragraph4: 'Valum y tú aprobáis los honorarios de este expediente y serán abonados inmediantamente en el mismo día*.',
    PARTNER_MODAL_slide4_paragraphTitle0: 'Ejemplo práctico',
    PARTNER_MODAL_slide4_paragraphTitle1: 'Lunes',
    PARTNER_MODAL_slide4_paragraphTitle2: 'Martes',
    PARTNER_MODAL_slide4_paragraphTitle3: 'Miércoles',
    PARTNER_MODAL_slide4_paragraphBottom1: '*Consultar requisitos y condiciones',

    PARTNER_MODAL_slide5_header1: 'Requisitos',
    PARTNER_MODAL_slide5_paragraph1: 'Este formato de pago solo aplicará para presupuestos generados a partir del 1 de enero de 2023 y abonados por TPV: tarjeta de crédito o bizum, así como para pagos por transferencia bancaria únicamente cuando el importe supere los 1.500€ + IVA.',
    PARTNER_MODAL_slide5_paragraph2: 'Igualmente, solo será válido para expedientes en los que aparezcas como prescriptor. Quedan excluídas, por tanto, las asignaciones para toma de datos o montajes.',
    PARTNER_MODAL_slide5_paragraph3: 'Asimismo, deberá ser un informe de tasación realizado por nuestra herramienta de ejecución <b>Tasafy</b>, no pudiendo emplearse otras herramientas externas.',

    PARTNER_MODAL_slide6_header1: 'Condiciones generales',
    PARTNER_MODAL_slide6_paragraph1: 'El sistema de facturación pasa a ser diario. De lunes a viernes, a partir de las 15h, se generará una factura con todos tus expedientes entregados a cliente (estado 11). Desde tu panel de facturación, deberás validarla.',
    PARTNER_MODAL_slide6_paragraph2: 'Tú decides cuando validar tus facturas: diariamente, semanalmente, quincenalmente... Sabrás que una vez que hayas validado tus facturas, recibirás el pago de las mismas a partir de las 17h.',
    PARTNER_MODAL_slide6_paragraph3: 'La fecha de la factura será la fecha de tu validación. Como hasta ahora, deberás rellenar el número de factura.',
    PARTNER_MODAL_slide6_paragraph4: 'Para recibir el pago en el día, deberás aceptar tus facturas antes de las 17h de cada día. Vencido este plazo, recibirás el pago al día siguiente a partir de las 17h.',
    PARTNER_MODAL_slide6_paragraph5: 'En días festivos también se generarán facturas por aquellos expedientes en estado 11. El pago se realizará en el siguiente día laboral. Asimismo, las facturas validadas los viernes serán abonadas junto con las del lunes',

    PARTNER_MODAL_slide7_header1: 'Cómo funciona',
    PARTNER_MODAL_slide7_paragraph1: 'De lunes a viernes, a partir de las 15h, se generará una factura provisional que incluirá todos tus expedientes en estado 11 que se hayan generado en las últimas 24h.',
    PARTNER_MODAL_slide7_paragraph2: 'Recibirás una alerta en tu móvil avisándote de que tienes una factura pendiente de validar.',
    PARTNER_MODAL_slide7_paragraph3: 'Recuerda tener activadas las notificaciones. Deberás validarla e introducir el número de factura que corresponda.',
    PARTNER_MODAL_slide7_paragraph4: 'Tendrás hasta las 17h de ese día para realizar la validación. A posteriori, se procederá a realizar su abono.',
    PARTNER_MODAL_slide7_paragraph5: 'Todo aquello que valides después de las 17h, se incluirá en el pago del siguiente día hábil.',
    PARTNER_MODAL_slide7_paragraph6: 'Si rechazas alguna factura porque no estás de acuerdo con los honorarios de un expediente, ésta entrará en proceso de revisión y se corregirá, si procede, al día siguiente antes de las 17h. Recibirás su pago a posteriori.',

    PARTNER_MODAL_slide8_paragraph1: 'GRACIAS',
    PARTNER_MODAL_slide8_paragraph2: 'Para cualquier duda o ampliar información, ponte en contacto con nosotros',
    PARTNER_MODAL_slide8_button1: 'Finalizar',
    PROFESSIONAL_MODAL_paragraph: '<p>Tienes <b>documentación pendiente</b> de entrega para poder empezar a recibir en cargos.</p> <p>Por favor, revisa el apartado documentos de tu perfil</p>',
    PROFESSIONAL_MODAL_button: 'Ir a documentos',
    IMPORT_ASSETS_message: '¡No olvides importar activos de la solicitud para poder <b>completar tu informe! <div class="js-intro-show-again"></div>',
    IMPORT_ASSETS_dontShowAgain: 'No volver a mostrar',
    IMPORT_ASSETS_title: 'Importar activos',

    COMMUNICATIONS_reviewedBy: 'Revisada por',
    COMMUNICATIONS_theDay: 'el día',

    REQUESTS_fromPartner: '¿Es de colaborador?',

    TECHNICAL_MODAL_slide1_title1: '¡Da la bienvenida a nuestras campanas!',

    TECHNICAL_MODAL_slide2_header1: '¿Para qué sirven?',
    TECHNICAL_MODAL_slide2_paragraphTitle0: 'Existen tres tipos:',
    TECHNICAL_MODAL_slide2_paragraphTitle1: 'ERRORES',
    TECHNICAL_MODAL_slide2_paragraph1: 'La campana te indicará que campos del informe deben ser modificados para su entrega.',
    TECHNICAL_MODAL_slide2_paragraphTitle2: 'ADVERTENCIAS',
    TECHNICAL_MODAL_slide2_paragraph2: 'La campana te indicará información relevante a considerar.',
    TECHNICAL_MODAL_slide2_paragraphTitle3: 'INFORMACIÓN',
    TECHNICAL_MODAL_slide2_paragraph3: 'La campana te sugerirá posibles mejoras en el informe.',

    TECHNICAL_MODAL_slide3_header1: '¿Cómo puedo consultarlas?',
    TECHNICAL_MODAL_slide3_paragraph1: 'Las alertas pueden ser consultadas en cualquier momento accediendo a la última versión del informe y clicando sobre la campana que aparece arriba a la derecha, aquí:',
    TECHNICAL_MODAL_slide3_paragraph2: 'Entonces se mostrará una ventana como la siguiente:',
    TECHNICAL_MODAL_slide3_paragraph3: 'Tras hacer modificaciones no olvide darle al botón de <b>Actualizar validaciones</b>, en la parte de abajo, para así conocer la versión mas actual de las campanas disponible.',

    COMPARABLES_completeComparables: 'Completa tus testigos',
    COMPARABLES_findComparables: 'Encuentra tu testigo',
    COMPARABLES_createComparable: 'Crear testigo',
    COMPARABLES_selectComparable: 'Seleccionar testigo',
    COMPARABLES_chooseComparablesTitle: 'Escoge los testigos que quieras utilizar',
    COMPARABLES_confirmSelection: 'Confirmar selección',
    COMPARABLES_newSearch: 'Nueva búsqueda',
    COMPARABLES_backToSearchResultList: 'Volver al resultado de búsqueda',
    COMPARABLES_completeErrorMessage: 'Completa los datos obligatorios para poder añadir el testigo',
    COMPARABLES_skipConfirmMessage: 'Al realizar esta acción se perderá el resultado de la búsqueda realizada, ¿continuar?',
    COMPARABLES_addComparables: 'Añadir testigos',
    COMPARABLES_backToSelectedComparables: 'Completar testigos',
    COMPARABLES_completeSuccessMessage: 'Completado',
    COMPARABLES_searchInIdealista: 'Buscar en idealista',
    COMPARABLES_yearConstruction: 'Año construcción',
    COMPARABLES_operation: 'Tipo de venta',
    COMPARABLES_numberOfRoomsFromTo: 'Nº habitaciones',
    COMPARABLES_numberOfBathsFromTo: 'Nº baños',
    COMPARABLES_numberOfRooms: 'Nº habitaciones',
    COMPARABLES_numberOfBaths: 'Nº baños',
    COMPARABLES_interiorStateProperty: 'Estado interior inmueble',
    COMPARABLES_top: 'Alta',
    COMPARABLES_middle: 'Media',
    COMPARABLES_ground: 'Baja',
    COMPARABLES_moreFilters: 'Más filtros',
    COMPARABLES_searchResultEmpty: 'No hay resultados para esta búsqueda',
    COMPARABLES_searchResultError: 'Error en la búsqueda',
    COMPARABLES_added: 'Añadido',
    COMPARABLES_withoutAddress: 'Testigo sin dirección',
    COMPARABLES_removeComparableMessage: 'Si eliminas el testigo se perderá la información introducida. ¿Continuar?',
    COMPARABLES_idealistaTermsAndConditionsTitle: 'Términos y condiciones de Idealista',
    COMPARABLES_idealistaRequiredWitnessInfo: '<p>En el siguiente paso se va a realizar la consulta y descarga de testigos de <a href="https://www.idealista.com" target="_blank">Idealista.com</a> . Este servicio tiene un coste asociado de 20 céntimos + IVA y cada consulta devolverá como máximo 30 testigos.</p><p>La aplicación de filtros demasiado restrictivos puede reducir el volumen de testigos encontrados.</p><p>Si tras la consulta no se obtienen testigos, el precio será igualmente de 20 céntimos + IVA.</p>',
    COMPARABLES_idealistaConditionsAccept: 'He leído y acepto las condiciones',
    COMPARABLES_searchResult: 'Resultado búsqueda',
    COMPARABLES_selectedComparables: 'Testigos seleccionados',
    COMPARABLES_noItemsSelected: 'No hay testigos seleccionados',
    COMPARABLES_addComparableManually: 'Introducir testigo manualmente',
    COMPARABLES_chooseIdealistaComparables: 'Escoge los testigos que quieras utilizar',
    COMPARABLES_chooseACadastralReference: 'Escoge una referencia catastral',

    ASSET_marketStudyCreateInValorationMethod: '*Se va a crear un estudio de mercado que podrás seleccionar para otros métodos de este informe',
    COMPARABLES_confirmCloseModal: '¿Estás seguro que quieres cerrar esta ventana? Perderás la búsqueda y los comparables rellenados.',

    MULTIPLE_EDITION_valuationState: 'Estado valoración',
    MULTIPLE_EDITION_addValuation: 'Añadir valoración',
    MULTIPLE_EDITION_cancelAddValuation: 'Cancelar añadir valoración',
    MULTIPLE_EDITION_editingValuation: 'Editando valoración',
    MULTIPLE_EDITION_cancelEditValuation: 'Cancelar edición',
    MULTIPLE_EDITION_createValorationNotAllowed: 'No se puede añadir valoración para este activo',
    RECALCULATION_successMessage: 'Se ha realizado el recálculo con éxito',
    RECALCULATION_errorMessage: 'Error al realizar el recálculo',

    MARKET_STUDY_assetReferenced: 'Activo de referencia',
    MARKET_STUDY_assetReferencedMessage: 'El presente estudio de mercado referencia a las características del inmueble seleccionado siendo aplicable a otros activos con propiedades similares.',
    MARKET_STUDY_assetReferenceInfo: '*Datos extraídos del informe, si desea completarlos o modificarlos debe hacerlo desde el capítulo correspondiente.',
    MARKET_STUDY_height: 'Altura',
    MARKET_STUDY_landValueCalulation: 'Cálculo del valor del suelo dentro del coste',
    MARKET_STUDY_plotArea: 'Superficie de parcela',
    editDocument_error: 'No se puede editar un documento que ha sido importado.',
    APPRAISALS_VALUATION_saveGroundValuation: 'Guardar valor del suelo',
    ground_characteristics: 'Características del suelo',
    info_vinculation: 'Una vez creada la valoración no será editable la vinculación',
    info_order: 'Una vez creado el encargo no será editable la vinculación',
    COST_METHOD_GROUND_comparisonMethod: 'Valoración del suelo - Comparación',
    COST_METHOD_GROUND_staticResidualMethod: 'Valoración del suelo - Residual estático',
    COST_METHOD_GROUND_dynamicResidualMethod: 'Valoración del suelo - Residual dinámico',
    CLUSTER_recalculateUnits: 'Recalcular unidades',
    APPRAISALS_VALUATION_groundValuation: 'Valor de suelo',

    COST_METHOD_observationsProposedValues: 'Justificación del cálculo de la vida útil',
    APPRAISAL_DASHBOARD_FILTER_tabOrdersInfo: 'Información del encargo',
    APPRAISAL_DASHBOARD_FILTER_interveners: 'Intervinientes',
    USER_IS_VERIFIED_APPRAISER: 'Profesional validado',
    USER_IS_VERIFIED_APPRAISER_info: 'Mediante la verificación del profesional se confirma que ha facilitado toda la documentación necesaria para la colaboración y registrado correctamente las incompatibilidades, permitiéndose la asignación de trabajo.',
    USER_internal_observations_tooltip: 'Estos comentarios no serán visualizados por el profesional desde su perfil.',
    USER_userStatus: 'Estado usuario',
    USER_info_text: 'La información reflejada en este apartado corresponde a las preferencias indicadas por el profesional, no siendo posible su edición. La asignación de encargos se realizará conforme a criterios verificados, pudiendo ajustarse en el apartado de capacitación.',
    absences_info_text: 'En este apartado puedes indicar tus periodos de inactividad para bloquear la recepción de encargos durante el mismo. Pulsa el botón "+" para añadir días de ausencia',
    absence: 'Ausencia',
    abstences_add: 'Añadir días de ausencia',
    absences_startDate: 'Inicio ausencia',
    absences_finishDate: 'Fin ausencia',
    COMPARABLES_comparablesTitle: 'Comparables',
    COMPARABLES_infoComparablesTitle: 'Información del comparable',
    COMPARABLES_appraisalFilterTitle: 'Datos del encargo',
    COMPARABLES_appraisalIdFilter: 'ID de la tasación',
    USERS_state_inactive: 'Usuario inactivo',
    USERS_state_checked: 'Usuario validado',
    USERS_state_absent: 'Usuario ausente',
    USERS_state_notValidated: 'Usuario no validado',
    training: 'Capacitación',
    typologies_to_evaluate: 'Tipologías a valorar',
    limit_assignment_check: 'Limitar la asignación de encargos a toma de datos',
    limit_assignment_check_subtitle: 'Si se selecciona esta opción, el profesional solo podrá realizar encargos tipificados como Toma de datos',
    edit_professional_preferences_check: 'Editar preferencias del profesional',
    professional_score: 'Puntuación del profesional',
    APPRAISALS_selectScore: 'Califica el trabajo técnico realizado por el profesional responsable de este expediente.',
    TRAINING_globalScore: 'NOTA GLOBAL',
    TRAINING_deadline: 'PLAZO',
    TRAINING_execution: 'EJECUCIÓN',
    TRAINING_level1: 'NIVEL 1',
    TRAINING_level2: 'NIVEL 2',
    TRAINING_level3: 'NIVEL 3',
    TRAINING_elements: 'ELEMENTOS',
    TRAINING_buildings: 'EDIFICIOS',
    TRAINING_terrains: 'TERRENOS',
    TRAINING_rustic: 'FINCAS RÚSTICAS',
    TRAINING_economicExploitations: 'EXPL.ECONÓMICAS',
    TRAINING_deadline_title: 'Calificaciones totales relativas al plazo',
    TRAINING_level_title: 'Calificaciones totales relativas la ejecución',
    soon: 'PRÓXIMAMENTE',

    REQUESTS_confirmExpenses: 'Confirmar provisión ingresos/gastos o sector del cliente',
    REQUESTS_sectorCustomer: 'Sector del cliente',
    REQUESTS_confirmExpensesIconTooltip: 'Es necesario confirmar provisión de ingresos/gastos o sector del cliente',
    REQUESTS_pendingConfirmExpenses: 'Provisión ingresos/gastos confirmada',
    REQUESTS_pendingExpenses: 'Provisión ingresos/gastos',
  },
};
