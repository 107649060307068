import { Phone } from '@foxeet/utils/classes/contact.class';
import { ContactConfig } from '@foxeet/utils/interfaces';

const PHONE = '682511263';
const COUNTRY_CODE = '34';

export const CONTACT_PHONE = '+34682511263';
export const CONTACT_PHONE_DISPLAY = '+34 682 511 263';
export const CONTACT_NAME = 'Tasafy';
export const CONTACT_EMAIL = '';

export const contactConfig: ContactConfig = {
  name: CONTACT_NAME,
  email: CONTACT_EMAIL,
  phone: new Phone(COUNTRY_CODE, PHONE),
};
