export const ptLang = {
  cultureCodeLanguage: 'pt-PT',
  languageKey: 'pt',
  icon: 'pt',
  translates: {
    noContent: 'There are no items in the list',

    LANG_selectLanguage: 'Selecione um idioma',
    LANG_es: 'Espanhol',
    LANG_en: 'Inglês',
    LANG_pt: 'Português',

    LOGIN_email: 'o email',
    LOGIN_password: 'senha',
    LOGIN_login: 'entrar',
    LOGIN_recoverPassword: 'recuperar senha',
    LOGIN_recoverMessage: 'Digite seu e-mail e enviaremos as instruções para redefinir sua senha.',
    LOGIN_recover: 'recuperar',
    LOGIN_backToLogin: 'retorna',

    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'O formato do email não é válido',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'O formato do email não é válido',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'O formato deve ser: 999999-999999',
    FORM_VALIDATION_MAX_LENGTH: 'O comprimento máximo do campo é:',
    FORM_VALIDATION_MIN_LENGTH: 'O comprimento mínimo do campo é:',
    FORM_VALIDATION_REQUIRED: 'Este campo é obrigatório',
    FORM_VALIDATION_PATTERN: 'O formato está errado',
    FORM_VALIDATION_MIN: 'O valor mínimo é: ',
    FORM_VALIDATION_MAX: 'O valor máximo é: ',
    FORM_VALIDATION_NOT_MATCH: 'Os campos não correspondem',
    FORM_VALIDATION_additionHigherThan: 'Suma superior al 100%',
    FORM_VALIDATION_WRONG_INPUT: 'Sua senha deve ter pelo menos 8 caracteres e conter uma mistura de letras maiúsculas e minúsculas, números e caracteres especiais (por exemplo,! # $).',
    FORM_VALIDATION_NOT_SAME: 'os campos não correspondem',
    FORM_VALIDATION_wrongCredentials: 'Nome de usuário ou senha incorreta',
    FORM_VALIDATION_MAX_ADDITION: 'La suma supera: ',
    FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH: "A sua palavra-passe deve ter pelo menos 8 caracteres de comprimento e conter uma combinação de letras minúsculas e maiúsculas, números e caracteres especiais (por exemplo, ! # $).'",

    MENU_profile: 'meu perfil',
    MENU_comparables: 'comparables',
    MENU_profileData: 'dados pessoais',
    MENU_documents: 'documentos',
    MENU_invoices: 'faturas',
    MENU_orders: 'ordens',
    MENU_exchange: 'oportunidades',
    MENU_requests: 'colaborações',
    MENU_contactUs: 'contate-nos',
    MENU_how_it_works: '¿Como funciona?',
    MENU_logOut: 'fechar sessão',
    MENU_schedule: 'Agenda',
    MENU_debug: 'debug',
    MENU_updateDocumentationAndIncompatibilitiesMessage: 'Recuerda la obligación de mantener actualizada tu documentación y listado de incompatibilidades en cumplimiento del artículo 13 del R.D. 775/1997.',
    MENU_absences: 'disponibilidade / ausências',
    MENU_dashboard: 'Paneil de controle',
    MENU_accounts: 'Cuentas',
    MENU_control_panel: 'Painel de controle',
    MENU_personalData: 'meus dados',
    MENU_connections: 'Conexões',

    APPRAISER_pageTitle: 'dados pessoais',
    APPRAISER_personalData: 'dados pessoais',
    APPRAISER_preferences: ' preferências',
    APPRAISER_incompatibilities: 'incompatibilidades',
    APPRAISER_firstName: 'nome',
    APPRAISER_lastName: 'sobrenomes',
    APPRAISER_nationalIdentityDocument: 'DNI/NIE',
    APPRAISER_phoneNumber: 'telefone',
    APPRAISER_degree: 'título',
    APPRAISER_edit: 'editar',
    APPRAISER_maxDistance: 'distância máxima',
    APPRAISER_receiveNotifications: 'quero receber notificações',
    APPRAISER_notificationPush: 'notificações via push',
    APPRAISER_notificationByEmail: 'notificações por email',
    APPRAISER_savePreferences: 'salvar preferências',
    APPRAISER_preferencesMessage: 'Defina suas preferências para receber as oportunidades mais recentes!',
    APPRAISER_personalDataSection: 'dados',
    APPRAISER_notificationsSection: 'notificações',
    APPRAISER_changePassword: 'mudar senha',
    APPRAISER_password: 'senha',
    APPRAISER_confirmPassword: 'confirmar senha',
    APPRAISER_visits: 'Visitas',
    APPRAISER_deadlineDate: 'Data límite',

    MY_WORK: {
      works: 'Meu Trabalho',
      work_summary: 'Resumo do trabalho',
      assignments: 'Minhas atribuições',
      pending_work: 'Trabalho pendente',
      all: 'Tudo',
      mywork_pending_expedients: 'Meus arquivos pendentes',
      mywork_finalized_expedients: 'Meus arquivos finalizados',
      mywork_total_expedients: 'Meus arquivos totais',
    },
    LOCATION_country: 'país',
    LOCATION_province: 'província',
    LOCATION_locality: 'locality',
    LOCATION_postalCode: 'código postal',
    LOCATION_streetNotExists: 'a estrada não existe na rua',
    LOCATION_streetType: 'tipo de estrada',
    LOCATION_streetName: 'nome da estrada',
    LOCATION_streetNumber: 'número da estrada',
    LOCATION_portal: 'portal',
    LOCATION_stairs: 'escalera',
    LOCATION_floor: 'suelo',
    LOCATION_gate: 'portón',
    LOCATION_locationObservations: 'observações',

    MESSAGES_successPUT: 'Editado corretamente',
    MESSAGES_errorPUT: 'editar erro',
    MESSAGES_successDELETE: 'Removido com sucesso',
    MESSAGES_errorDELETE: 'Deletar al eliminar',
    MESSAGES_successUploadFile: 'Arquivo carregado com sucesso',
    MESSAGES_errorUploadFile: 'Erro ao enviar arquivo',
    MESSAGES_errorPOSTLIST: 'Erro ao exibir a lista',
    MESSAGES_errorPOST: 'Erro ao adicionar',
    MESSAGES_successConfirm: 'Success confirmation',
    MESSAGES_errorConfirm: 'Error confirmation',
    MESSAGES_successReject: 'Success rejection',
    MESSAGES_errorReject: 'Error rejection',
    MESSAGES_requiredField: 'Required fields are missing',
    MESSAGES_the_action_was_successful: 'La acción se ha realizado correctamente',
    MESSAGES_successful: 'Éxito',
    MESSAGES_error: 'error',

    ACTIONS_cancel: 'Cancelar',
    ACTIONS_add: 'Adicionar',
    ACTIONS_edit: 'Editar',
    ACTIONS_delete: 'Remover',
    ACTIONS_download: 'Baixar',
    ACTIONS_back: 'Volver',
    ACTIONS_save: 'Guardar',
    ACTIONS_skip: 'Pular',
    ACTIONS_confirm: 'Confirmar',
    ACTIONS_close: 'Cerrar',
    ACTIONS_reset: 'Reiniciar',
    ACTIONS_next: 'Próxima',
    ACTIONS_save_selection: 'salvar seleção',

    INCOMPATIBILITIES_firstName: 'first name',
    INCOMPATIBILITIES_lastName: 'last name',
    INCOMPATIBILITIES_nationalIdentityDocument: 'DNI/NIE',
    INCOMPATIBILITIES_familyRelationship: 'relationship',

    APPRAISAL_BASIC_FILTERS: {
      recordIdContains: 'Nº expediente',
      executionToolId: 'Nº referência interna',
      externalRecordId: 'Nº referência externa',
    },
    APPRAISAL_STATUS_FILTERS: {
      currentWorkflowSteps: 'Estado',
      purposes: 'Finalidad',
      visitTypes: 'Tipo de visita',
    },
    APPRAISAL_LIMIT_DATES_FILTERS: {
      scheduleVisitLimit: 'Limite agendar visita',
      startVisitLimit: 'Limite iniciar visita',
      finishVisitLimit: 'Limite terminar visita',
      sentReportLimit: 'Limite envio relatório',
    },
    APPRAISAL_INCIDENTS_FILTER: {
      withIncidentsNotResolved: 'Con incidencias sin resolver',
      withIncidents: 'Con incidencias',
    },
    APPRAISAL_INVOICE_FILTERS: {
      isInConfirmedInvoice: 'Tem fatura confirmada',
    },
    FILTERS: {
      basicFilters: 'Filtros básicos',
      exchangeFilters: 'Filtros oportunidades',
      appraisalState: 'Status de avaliação',
      dateLimits: 'Data limite',
      incidents: 'Incidentes',
      invoice: 'Fatura',
      addedDate: 'Data de criação',
      apply: 'Aplicar',
      reset: 'Reiniciar',
      done: 'Hecho',
      requestState: 'Estado solicitud',
      typologyFilters: 'Typology',
      locationFilters: 'Location',
      featuresFilters: 'Features',
    },
    DATES: {
      from: 'Desde',
      to: 'Hasta',
    },

    APPRAISALS: 'encargos',
    APPRAISAL: {
      finished: 'Terminado',
      sentToRevision: 'Enviar encargo',
      lastOrders: 'últimos encargos',
      visitPending: 'visita pendiente',
      sentPending: 'pendientes de enviar',
      finishPending: 'Pendiente de finalizar',
      rejected: 'rechazadas',
      incidents: 'incidencias',
      detail: 'detalle',
      report: 'informe',
      board: 'tablón',
      info: 'info',
      requestElements: 'request elements',
      purpose: 'purpose',
      visitType: 'visit type',
      deadlines: 'deadlines',
      customer: 'customer',
      prescriptor: 'prescriptor',
      customers: 'customers',
      prescriptors: 'prescriptors',
      contact: 'contact',
      incidentResolution: 'resolution',
      revision: 'Revision',
      request: 'Solicitud',
      assets: 'Activos',
      clusters: 'Agrupaciones',
      comment: 'comentário',
      addComment: 'Adicionar comentário',
      addIncident: 'Adicionar incidente',
      incidentDescription: 'Description',
      incidentTypes: 'Tipo de incidente',
      importReport: 'Importar relatório de',
      confirmHeader: 'Tem certeza?',
      confirmationMessage: 'O relatório foi copiado corretamente, exceto para as seções sobre documentos e fotografias. Por favor, reveja e modifique os dados necessários e inclua a documentação atualizada ',
      scheduleDeadLine: 'limite para agendar visita',
      exchangeFinish: 'limite de envio de relatório',
      idufir: 'C. R. U. (IDUFIR) ',
      land: '',
      propertyRegistryName: 'nome do registro',
      volume: 'tome',
      book: 'livro',
      paper: 'folio',
      inscription: 'inscrição',
      fee: 'taxas',
      executionMode: 'modo de execução',
      executedByIvForce: 'ivforce',
      executedByExternalTool: 'ferramenta externa',
      registeredPropertyNumber: 'Farm',
      section: 'Folio',
      appraisalOrderType: 'tipo de pedido',
    },
    APPRAISAL_REPORT: {
      importAssetStructure: 'Importar estructura a activos',
      commonData: 'Datos comunes del informe',
      clusterToValuate: 'Agrupar para valorar',
      completeForm: 'Completar formulario',
      checkAllAsset: 'Seleccionar todas las unidades',
      clusterValuations: 'Agrupar valoraciones',
      valuateTerrains: 'Valorar terrenos',
      // STEPS
      units: 'assets',
      selectStep: 'Select step',
      requiredPercent: 'de requeridos',
      completedPercent: 'de completado',
      seeValuationResume: 'Veja o resumo das avaliações',
      valuationResume: 'Resumo das avaliações',
    },
    ASSET_TREE: {
      addAnnex: 'Añadir anejo',
      duplicate: 'Duplicar',
      delete: 'Eliminar',
      cancel: 'Cancelar',
      makePrincipal: 'Hacer principal',
      extractAsset: 'Extraer de la estructura',

      deleteAssetConfirm: 'Eliminar',
      deleteAssetCancel: 'Cancelar',
      deleteAssetSuccess: 'Activo eliminado correctamente',
      deleteAssetError: 'Error al eliminar activo',
      deleteAsset: 'Eliminar activo',
      deleteAssetMessage: 'Al eliminar el activo, eliminarás también todos los anejos vinculados. ¿Continuar?',

      recalculateValuations: 'Recalcular valoraciones',
      notCompleted: 'No completado',
      unComplete: 'Incompleto',
      complete: 'Completo',
      recalculateWorkPlan: 'Recalcular el estado de la obra',
      hasValuations: 'Você não pode agrupar este ativo, pois ele tem métodos de avaliação associados.',
      onlyClusterSameCategory: 'Não podrás agrupar elementos mezclados, suelos solo se puede agrupar con suelos, elementos terminados, con elementos terminados y explotaciones económicas con explotaciones económicas. No se podrán agrupar elementos No terminados.',
      selectAssetsToCluster: 'Selecione as unidades que deseja agrupar:',
    },
    STEPS: {
      location: 'Localización',
      identification: 'Identificación',
      typology: 'Tipología',
      surfaces: 'Superficies',
      localityAndEnvironmentReport: 'Localidad, entorno y mercado',
      terrainFeatures: 'Características del terreno',
      buildingTerrain: 'Descrição do terreno',
      buildingFeatures: 'Características del edificio',
      assetFeatures: 'Características del inmueble',
      urbanisticSituation: 'Situación urbanística',
      possession: 'Posesión',
      checks: 'Comprobaciones',
      valuations: 'Valoraciones',
      conditioningWarningsObservations: 'Condicionantes, advertencias y observaciones',
      photos: 'Fotos',
      documentation: 'Documentación',
      surfacesAndAssetFeatures: 'Superficies y características del inmueble',
      surface: '',
      LocalityEnvironmentAndBuildingFeatures: 'Características del edificio, localidad, entorno y mercado',
      buildingState: 'Estado de la obra',
    },
    DOCUMENTOS: {
      addDocument: 'adicionar documento',
      documento: 'documento',
      documentos: 'documentos',
      expirationDate: 'data de expiração',
      AddedSuccess: 'O documento foi carregado com sucesso',
      deleteSucces: 'O documento foi deletado com sucesso',
      deleteThisDocument: 'Tem certeza de que deseja excluir este documento?',
      expirationDateTime: 'Data de expiração',
    },

    error: 'Error',

    REPORT_ASSET_TREE_FILTER: {
      registryNumber: 'Finca registral',
      idufir: 'C.R.U. (IDUFIR)',
      cadastralReference: 'Referencia catrastral',
      address: 'Dirección',
      state: 'Estado',
      use: 'Uso',
      typology: 'Tipología',
      subTypology: 'Subtipología',
    },

    INVOICE: {
      invoices: 'invoices',
      rejectInvoice: 'reject invoice',
      reasonRejectInvoice: 'Please, tell us the reason',
      subtotal: 'subtotal',
      total: 'total',
      irpf: 'irpf',
      iva: 'iva',
      taxPercentageText: 'Income tax ',
      invoiceNumberAlertTitle: 'Por favor, introduce el número de factura',
      invoiceNumber: 'Nº de factura',
    },

    REPORT_ASSET: {
      dataSource: 'Origen de datos',
      cadastralReference: 'Referencia catastral',
      cadastralReferenceContainerBuilding: 'Referencia catastral del edificio',
      idufir: 'C.R.U: (IDUFIR)',
      registeredPropertyNumber: 'Finca registral',
      propertyRegistrationName: 'Registro de la propiedad',
      volume: 'Tomo',
      book: 'Libro',
      paper: 'Folio',
      section: 'Sección',
      inscription: 'Inscripción',
      observations: 'Observaciones',
      mainElements_Structure: 'Estructura',
      mainElements_Deck: 'Cubierta',
      mainElements_Enclosure: 'Cerrramientos',
      mainElements_Foundation: 'Cimentación',
      constructionYear: 'Año de construcción',
      lastRehabilitationYear: 'Año de rehabilitación',
      qualityOfBuilding: 'Calidad de las edificaciones',
      conservationState: 'Estadi de conservación',
      buildingUse: 'Uso',
      totalFloorsBellowGround: 'Plantas bajo rasante',
      totalFloorsAboveGround: 'Plantas sobre rasante',
      numberOfBuildingAccesses: 'Accesos del edificio',
      currentStateOfBuilding: '',
      streetCategory: 'Categoría de la calle',
      surveillance: 'Vigilancia',
      hasSwimmingPool: 'Piscina',
      hasSportsZone: 'Zona deportiva',
      hasGreenArea: 'Zonas verdes',
      hasElevator: 'Ascensor',
      totalPlotSurface: 'Superficie de la parcela',
    },

    VALUATIONS: {
      observationsOcupation: 'Observações sobre ocupação',
      observationsNetIncome: 'Observações sobre o lucro líquido / Informações de estimativa de pagamento',
      calculationsInProgressWorkPlanTitle: 'Construction - Costs and expenses',
      constructionCostsTitle: 'Construction costs',
      calculationsInProgressWorkPlanConstructionCostTotal: 'Custo total de construção',
      calculationsInProgressWorkPlanProgressPercentage: '% de progresso do trabalho',
      calculationsInProgressWorkPlanConstructionCostCurrent: 'Custo de construção - real',
      calculationsInProgressWorkPlanNecessaryExpenses: 'Despesas necessárias%',
      calculationsInProgressWorkPlanExpensesProgressPercentage: 'Progresso das despesas%',
      calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Despesas necessárias adotadas%',
      calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Despesas necessárias - Real',
      calculationsInProgressWorkPlanManualEditing: 'Editar porcentagem de progresso nas despesas',
      calculationsInProgressWorkPlanFormMessage: 'Os valores de construção são calculados com base na porcentagem de andamento da obra. O valor do custo vem daquele distribuído na guia Status do trabalho. ',
      calculationsInProgressWorkPlanMessage: '* Os gastos necessários são calculados com relação aos custos totais de construção da unidade (tabela "no estado da obra") considerando o percentual aplicado pelo grau de andamento da obra. Veja os detalhes em "Cálculo com base no andamento do trabalho".',
      necessaryConstructionExpensesPercentageRequired: 'Completar "Calculations according to the progress of the work"',
      calculationsInProgressWorkPlanLink: 'Cálculos de acordo com o andamento da obra',
      costMethodRepercussionCalculateMessage: 'Se você alterar a forma como insere o impacto, perderá os dados incluídos até agora. Quer continuar?',
      summaryTotalValuationsRecalculationMessage: 'As características do ativo foram modificadas. Você deve recalcular as avaliações para continuar',
      futureValuationTotalValue: 'Avaliação total H.C.C.',
      currentValuationTotalValue: 'Avaliação total atual',
      insuranceByRoyalDecree: 'Seguro según R.D.',
      insuranceByEco: 'Seguro según ECO',
      valuationTotalValue: 'Valoración total',

      insuranceValorationTypesDropdown: 'Cálculo do valor do seguro em relação a:',

      proposalValue: 'Valor propuesto',
      documents: 'Documentación',
      totalProposalValue: 'Valor propuesto total',
      observations: 'Observaciones',
      surface: 'Superficie',
      selectSurfaceTocontinue: 'Selecciona una superficie para continuar',
      surfaceCommons: 'Superficie construida con comunes',
      surfaceBuiltWithoutCommons: 'Superficie construida sin comunes',
      surfaceUseful: 'Superficie útil',
      cadastral: 'Catrastral',
      checked: 'Comprobada',
      registered: 'Registral',
      usefulRegistralSurface: 'Superficie útil registral',
      usefulCheckedSurface: 'Superficie útil comprobada',
      usefulQualifiedSurface: 'Superficie útil cédula calif.',

      ground: 'Suelo',
      repercussion: 'Repercusión',
      method: 'Método',
      necessaryExpenses: 'Gastos necesarios',
      totalMarketValue: 'Valor total mercado',
      construction: 'Construcción',
      VRB: 'VRB',
      editPercentages: 'Editar porcentajes',
      physicalBuildingDeprecation: 'Dep. física edificio',
      physicalElementDeprecation: 'Dep. física elemento',
      functionalDeprecation: 'Dep. funcional',
      VRN: 'VRN',

      residualStaticCostTitle: 'Residual estático simplificado',
      residualStaticCostMarketValueUnitPrice: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionCostUnitPrice: 'Coste de construcción ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionExpensesUnitPrice: 'Gastos de construcción ({{currency_per_surfaceUnit}})',
      residualStaticCostFinancialExpensesPercentage: 'Gastos financieros (%)',
      residualStaticCostMarketingExpensesPercentage: 'Gastos de comercialización (%)',
      residualStaticCostDeveloperProfitPercentage: 'Beneficios del promotor (%)',
      residualStaticCostKCoeficient: 'K (Coeficiente para pasar a nuevo)',
      total: 'Total',

      qualifiedSurface: 'Superficie calificada',
      legalMaximumValues: 'Valores de máximo legal',
      legalMaximumValue: 'Valor máximo legal',
      legalMaximumUnitValues: 'Valor unitario máximo legal',
      legalMaximumTotalValue: 'Valor total máximo legal',
      legalMaximumGroundValue: 'Valor máximo legal del suelo',
      groundValueLimit: 'Limitación del valor del suelo',
      REA_legalMaximum: 'REA (máximo legal)',
      REA_costMethod: 'REA (método de coste)',
      valueTypes: 'Tipos de valor',
      moreInfo: 'Más información',
      marketValue: 'Valor de mercado',

      contractualData: 'Datos contractuales',
      contractStartDate: 'Fecha de inicio de contracto',
      contractFinishDate: 'Fecha de extinción de contrato',
      nextChargeDate: 'Fecha del siguiente cobro',
      economicalData: 'Datos económicos',
      monthlyGrossIncome: 'Renta bruta mensual',
      monthlyExpenses: 'Gastos mensuales',
      monthlyNetIncome: 'Renta neta mensual',
      updateType: 'Tipo de actualización',
      updateRatePercent: 'Tasa de actualización',
      monthlyEquivalentUpdateRatePercent: 'Tasa de actualización mensual equivalente',
      reversionValue: 'Valor de reversión',
      capitalGainPercent: 'Plusvalía/minusvalía del suelo',
      updatedValues: 'Valores actualizados',
      updatedIncomes: 'Rentas actualizadas',
      updatedReversion: 'Reversión actualizado',
      updatedTotal: 'Total actualizado',

      marketNetIncome: 'Renta neta mercado',
      euroYearSuffix: '{{currency}}/año',
      euroMonthSuffix: '{{currency}}/mes',
      activeNetIncome: 'Renta neta activa',
      currentIncome: 'Renta actual',
      date: 'Fecha',
      incomes: 'Renta',
      yearly: 'Anual',
      monthly: 'Mensual',
      marketEconomicRentalData: 'Datos económicos de renta del mercado',
      homogeinizedValue: 'Valor homogeneizado',
      deprecatedHomogeinizedValue: 'Valor homogeneizado depreciado',
      comparable: 'Comparable',
      adjustComparison: 'Comparación ajustada',

      witnesses: 'Testemunhas',
      witness: 'Testemunha',
      witnessList: 'Lista de testigos',
      distance: 'Distancia',
      surfaceM2: 'Superficie ({{surfaceUnit}})',
      homogeinizedCoefficient: 'Coef. Homogeinización',
      weight: 'Peso',
      marketValueEuroPerM2: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      marketValueEuroPerM2Month: 'Valor de mercado ({{currency_per_surfaceUnit}}/mes)',
      finalValueEuroPerM2: 'Valor final ({{currency_per_surfaceUnit}})',
      finalValueEuroPerM2Mont: 'Valor final ({{currency_per_surfaceUnit}}/mes)',
      noWitnessAdded: 'No hay testigos añadidos',

      desoccupation: 'Desocupación',
      latePayment: 'Morosidad',
      annualCapex: 'Capex anual',
      netIncome: 'Renta neta',
      unitaryEuroM2Month: 'Unitaria ({{currency_per_surfaceUnit}}/mes)',
      monthlyEuroMonth: 'Mensual ({{currency}}/mes)',
      annualEuroYear: 'Anual ({{currency}}/año)',
      idlenessMonths: 'Meses de desocupación',
      latePaymentMonths: 'Meses de morosidad',
      IPC: 'IPC',

      clusterData: 'Datos de la agrupación',
      name: 'Nombre',
      adoptedSurface: 'Superficie adoptada',
      reformLastYear: 'Año de reforma',
      use: 'Uso',
      typology: 'Tipología',
      subtypology: 'Subtipología',
      censusSection: 'Sección censal',
      buildingInfo: 'Información del edificio',
      mainUse: 'Uso predominante',
      constructionYear: 'Año de construcción',

      totalLegalMaximumValue: 'Valor total de máximo legal',

      createCluster: 'Crear agrupación',
      clustersValuations: 'Valoración de agrupaciones',
      terrainsValuations: 'Valoración de terrenos',
      clusterItems: 'Unidades de la agrupación',
      clusterSurface: 'Superficie de la agrupación',
      clusterDeleteMessage: '¿Está seguro de querer eliminar esta agrupación?',

      errors: {
        title: 'Errores',
        buildingUseRequired: 'Falta el uso del edificio',
        buildingYearRequired: 'Falta el año de construcción del edificio',
        costMethodRequired: 'El método de coste es necesario',
        residualStaticAbr: 'Es necesario rellenar el método residual estático en el método de coste',
        atLeastOneSurfaceWithValue: 'Es necesario al menos una superficie con valor',
        groundRepercussionError: 'Impacto no solo',
        clusterAssetsWithoutSelectedSurfaces: 'É necessário adicionar as superfícies na seção Superfícies de cada ativo e selecioná-las na seção Unidades.',
      },
      recalculateMethod: 'Recalcular métodos',
      recalculate: 'Recalcular',
      current: 'Avaliação atual',
      future: 'Avaliação H.C.C.',

      homogeneousCoefficientType: 'Tipo de homogeneização',
      confirmCoeficientChange: 'Se você modificar o tipo de homogeneização, perderá os dados relacionados a isso incluídos até agora. Deseja continuar?',
      marketPriceUnitValue: 'Valor de mercado ({{currency_per_surfaceUnit}})',
      homogeneousCoefficient: 'Coef. homo. global',
      adoptouUnitValue: 'Valor final ({{currency_per_surfaceUnit}})',
      constructionRehabilitation: 'Ano construção / reabilitação',
      surfaceTotal: 'Superfície ({{surfaceUnit}})',
      elevatorsCount: 'Nº de Elevadores',
      situation: 'Coeff. Situação',
      orientation: 'Coef. Orientação',
      estado: 'Coef. Doença',
      altura: 'Coef. Altura',
      coefSurface: 'Coef. Superfície',
      morfologia: 'Coef. Morfologia',
      polivalência: 'Coef. Polivalência ',
      acessibilidade: 'Coef. Acessibilidade',
      antiguidade: 'Coef. Antiguidade',
      outros: 'Coeff. Outros',
      header: 'Editar coeficientes de homogeneização',

      witnessAddedDate: 'Data de criação da testemunha',
      witnessAddedDateCreation: 'Data de criação',
    },

    TYPOLOGY: {
      hasActiveCultivation: 'tem cultivo ativo?',
      isPlot: 'É um enredo?',
      borrowersHabitualResidence: 'Residência habitual do mutuário',
      use: 'o uso',
      residenceType: '1ª / 2ª residência',
      typology: 'tipologia',
      subTypology: 'subtipologia',
      state: 'estado del activo',
      conservationState: 'estado de conservação',
      legalSituation: 'situação legal',
      hasEnergeticCertificate: 'tem certificado de energia',
      reformLastYear: 'ano de reforma',
      energeticCertificateGrade: 'tipos de certificados de energia',
      expediente: 'expediente',
      protectionTimeLimit: 'termo de proteção',
      provisionalQualificationDateTime: 'data de qualificação provisória',
      definitiveQualificationDateTime: 'data de qualificação definitiva',
      workState: 'Estado de la obra',
      isEconomicExploitation: 'É uma exploração econômica?',
      economicExploitationObservations: 'Descrição da fazenda',
    },

    SURFACES: {
      surface: 'Superfície ({{surfaceUnit}})',
      surfaces: 'superfícies',
      cadastralSurfaces: 'cadastral',
      totalUsefulSurface: 'superfície útil ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'área construída sem comuns ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'área construída com comuns ({{surfaceUnit}})',
      checkedSurfaces: 'verificado',
      registeredSurfaces: 'registro',
      observations: 'observações',
      totalPlotSurface: 'superfície ({{surfaceUnit}})',
      workPlanTotalSurface: 'Superficie total de la obra ({{surfaceUnit}})',
    },

    NEW_ASSET: {
      addAsset: 'Adicionar ativo',
      back: 'Voltar à listagem e ativos',
      byLocation: 'por endereço',
      byCadastralReference: 'Por referência cadastral',
      locateAgain: 'Voltar a localizar',
      locate: 'Localizar',
      addManually: 'Introduzir manualmente',
      cadastralReference: 'Referência cadastral',
      localization: 'localização',
      typology: 'tipologia',
      identification: 'identificação',
      surfaceAndBuildingInformation: 'superfície e informação do ativo',
      surface: 'superfície',
      constructionYear: 'Ano de construção',
      chooseACadastralReference: 'Escolha uma referência cadastral',
      chooseIdealistaComparables: 'Escolha a testemunha que deseja usar',
    },

    LOCATION_ENVIRONMENT_MARKET: {
      dataSource: 'fonte de dados',
      editAutomaticInformation: 'editar informações automáticas',
      environmentPersonalOpinion: 'parecer técnico sobre o meio ambiente da propriedade',
      environment: 'ambiente',
      location: 'localidade',
      market: 'mercado',
      featuresMarket: 'características do mercado imobiliário comparável',
      marketMortgageValueDifferences: 'valor de mercado e diferenças de valor de hipoteca',
      offer: 'oferta',
      demand: 'demanda',
      currentPricSaleRental: 'atuais faixas de preço de venda / aluguel',
      demandRevaluationExpectations: 'oferta-demanda e expectativas de reavaliação',
      jobOccupation: 'job job',
      characteristicAntiquity: 'antiguidade característica',
      architecturalHomogeneity: 'homogeneidade arquitetônica',
      infrastructures: 'infraestruturas',
      equipmentAndServices: 'equipamentos e serviços',
      communications: 'comunicações',
      parkings: 'parques de estacionamento',
      buildingDevelopment: 'building development',
      renovation: 'renovação',
      localityTypeOfCore: 'tipo de núcleo',
      localityPopulation: 'população',
      localityPopulationEvolution: 'evolução populacional recente',
      localityUnemploymentRate: 'desemprego',
      localityAvgAnnualIncome: 'renda média anual disponível per capita',
      censusSectionTypeOfCore: 'tipo de núcleo',
      automaticDataWarning: 'Salvar o formulário irá recuperar os dados com base na localização, perdendo os dados editados manualmente.',
      environmentDescription: 'Descripción del entorno',
      localityMainFeatures: 'Principales características de la localidad',

      environmentSignificance: 'Significado do meio ambiente',
      locationComparedToSameMunicipity: 'Localização da propriedade em comparação com outras no mesmo município',
      locationComparedToSameZone: 'Localização da propriedade em comparação com outras na mesma área',
      locationComparedToSameBuildingElements: 'Localização da propriedade dentro do edifício em comparação com outros elementos no mesmo edifício',
      pendingInfrastructuresPercentage: 'Porcentagem de infraestruturas pendentes',
      environmentConsolidationDegreePercentage: 'Porcentagem do grau de consolidação do ambiente',
    },

    APPRAISAL_REQUESTS: {
      requests: 'solicitações',
      contactUsTitle: 'aumento dos benefícios dos contribuintes',
      contentTitle: 'seus orçamentos e pagamentos com um único clique',
      list1: 'cotar seus pedidos de avaliação em apenas duas etapas',
      list2: 'conhecer a proposta econômica imediatamente',
      list3: 'receba em seu email um link de pagamento e o resumo do seu pedido',
      list4: 'seu cliente terá apenas que acessar o link de pagamento e poderá escolher o método que mais se adequa às suas preferências: cartão de crédito, transferência bancária, etc.',
      list5: 'você não precisa se preocupar com mais nada. Seu pedido já está em andamento!',
      footer: 'nós ajudamos você a otimizar e tornar seu trabalho ainda mais lucrativo.',
      contactUs: 'fale conosco!',
      readAndAcceptTerms: 'Li e aceito',
      generalConditions: 'as condições gerais de venda',
      taxError: 'A taxa deve ser maior ou igual à taxa mínima proposta',
      totalPriceWithoutTaxes: 'preço (sem impostos)',
      withVisit: 'Eu faço uma visita',
      withReport: 'executar relatório',
      observations: 'observações',
      isEnterprise: 'é uma empresa',
      name: 'nome',
      lastName: 'sobrenome',
      nationalIdentityDocument: 'DNI/NIE',
      phone: 'telefone',
      email: 'e-mail',
      orderType: 'tipo de pedido',
      purpose: 'finalidade',
      visitType: 'tipo de visita',
      witECO: 'ECO 805/2003',
      address: 'endereço',
      postalCode: 'código postal',
      province: 'província',
      localidade: 'localidade',
      locality: 'dados de propriedade',
      addAsset: 'adicionar ativo',
      impossibleToMap: 'Não foi possível mapear as informações cadastrais, alguns dados devem ser preenchidos manualmente',
      taxMessage: '*As taxas propostas são editáveis ​​desde que o valor editado seja superior ao proposto.',
      cancelAddAsset: 'cancelar',
      year: 'ano',
      priceRange: 'faixa de valor estimado',
      taxes: 'taxa',
      owner: 'dados do proprietário da avaliação',
      ownerDataMessage: 'Relembramos que, como solicitante da avaliação, você é responsável por informar ao seu cliente que seus dados serão transferidos para o Instituto de Valoraciones S.A. para executar o serviço contratado',
      appraisalRequestFeatures: 'características de atribuição',
      visitProposals: 'Datas de visita',
      invoiceData: 'dados de faturamento',
      copyOwnerData: 'Copiar dados do proprietário',
      saveRequests: 'Salvar solicitação',
      addRequest: 'adicionar solicitação',
      requestDetail: 'Detalhe da solicitação',
      hasAppraisals: 'Tem avaliações',
      showAppraisals: 'Ver Avaliações',
      relatedAppraisalList: 'Lista de avaliações relacionadas',
      cif: 'CIF',
      rateMessage: '* As tarifas são calculadas com base no tipo e valor do imóvel. Verifique se a tipologia identificada corresponde à real. Caso contrário, insira a propriedade manualmente.',
      acceptTipologyCommitmentMessage: 'Aceito que o orçamento seja revisto pelo Valuation Institute para verificar se os imóveis incluídos foram correctamente identificados quanto à sua tipologia. Caso se verifique que não correspondem à tipologia real, este orçamento não será válido e o cliente deverá pagar a diferença para iniciar a encomenda.',
      visitData: 'dados de contato para a visita',
      visitName: 'nome sobrenome / nome da empresa',
      visitPhone: 'telefone',
      visitEmail: 'e-mail',
      visitObservations: 'observações',
      hasRequestElements: 'Faltam elementos para adicionar ao pedido',
      requestElementsArrayIsValid: 'Alguns dos elementos estão faltando dados para preencher',
      proposedDatesFormArrayIsValid: 'Você precisa verificar as possíveis datas de visita',
      finalidadeSNB: 'Propósito',
    },

    REPORT_ASSET_PROPERTY_FEATURES: {
      installations: 'instalações',
      stays: 'permanece',
      hasHotWater: 'água quente',
      hasHeating: 'aquecimento',
      hasAirConditioning: 'ar condicionado',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'encanamento e aparelhos sanitários',
      hasIntercom: 'intercom',
      hasSecurity: 'security',
      hasPhone: 'telefone',
      hasSanitation: 'saneamento',
      externalWoodWork: 'carpintaria exterior',
      facilidadeObservações: 'observações',
      pavementType: 'pavement type',
      wallType: 'tipo de parede',
      roofType: 'roof type',
      internalWoodWork: 'carpintaria interior',
      addStay: 'adicionar estadia',
      editStay: 'edit stay',
      stayType: 'tipo de estadia',
      totalSurface: 'superfície',
      generate: 'gerar',
      distribution: 'distribuição',
      stayResidentialMessage: 'deve haver pelo menos um quarto e um banheiro',
      deleteThisStay: 'Tem certeza que deseja deletar esta sala?',

      propertySize: 'Tamanho da propriedade',
      license: 'Licença',
      polivalentUse: 'Uso polivalente',
      productAnalysis: 'Análise de produto',

      hasEquippedKitchen: 'Cozinha equipada',
      hasBuiltInWardrobes: 'guarda-roupas embutidos',
      hasAlarmSystem: 'Sistema de alarme',
      hasClosedSecurityCircuit: 'Circuito fechado de segurança',
      hotWaterType: 'Água quente',
      heatingType: 'Tipo de aquecimento',

      localFeatures: 'Características locais',
      facade: 'Fachada (m)',
      depth: 'Background (m)',
      clearanceHeight: 'Altura livre (m)',
    },
    WITNESS_DETAIL: {
      supplierInfo: 'Información del proveedor',
      cadastralInformation: 'Información catastral',
      cadastralReference: 'Referencia catastral',
      cadastralSurface: 'Superficie catastral',
      builtSurface: 'Superficie construida',
      plotSurface: 'Superficie de parcela',
      features: 'Características',
      offerValue: 'Valor de oferta',
      reductionNegotiation: 'Reduc. / Negociación',
      offerType: 'Tipo de oferta',
      sourceType: 'Tipo de origen',
      crawledSource: 'Origen del testigo',
      providerUrl: 'URL del proveedor',
      contactInfo: 'Número de contacto',
      constructionYear: 'Año de construcción',
      reformLastYear: 'Año de reforma',
      qualityOfBuilding: 'Calidad de la construcción',
      conservationState: 'Estado de conservación',
      bathroomCount: 'Número de baños',
      bedroomCount: 'Número de habitaciones',
      hasPool: 'Piscina',
      hasSportsZone: 'Zona deportiva',
      hasGreenArea: 'Zonas verdes',
      hasHeating: 'Calefacción',
      hasAirConditioning: 'Climatización',
      hasElevator: 'Ascensor',
      witnessDetail: 'Detalles del testigo',
      hasPaving: 'Paving',
      hasPublicLighting: 'Iluminação pública',
      hasWaterSupply: 'Abastecimento de água',
      hasSanitationNetwork: 'Rede de saneamento',
      hasTelephoneNetwork: 'Rede telefônica',
      hasPipedGasNetwork: 'Rede de gás canalizado',
      buildeableSurface: 'Superfície edificável',
      observations: 'Observações',
      mainUse: 'Uso principal',
      hasFlatTerrain: 'Nível térreo',
      hasWarehouse: 'Armazém',
      hasActiveCrop: 'Cultivo ativo',
      hasRolledAccess: 'Acesso rodoviário',
      hasWaterDistribution: 'Distribuição de água',
    },

    URBAN_SITUATION: {
      dataSource: 'fonte de dados',
      currentPlanning: 'planejamento atual',
      description: 'descrição',
      currentPlanningAdequacy: 'adaptação à abordagem atual?',
      observations: 'observações',
      subjectCorrespondingManagementInitiative: 'Assunto a quem corresponde a iniciativa de gestão',
      modeAndManagementSystem: 'Sistema e modo de gerenciamento',
      urbanBurdens: 'Encargos urbanos (transferências, custos de urbanização, obrigações adquiridas, etc.)',
      pendingProcedures: 'Procedimentos pendentes para terreno finalista',
      executionDeadlinesAndConsequences: 'Prazos de execução previstos para o ordenamento urbano do terreno. Consequências em caso de não conformidade ',
      expropiation: 'Expropriação. Situação do processo de expropriação. Critérios legais aplicáveis ​​à sua avaliação',
      buildability: 'Capacidade de construção',
      characteristicUse: 'Uso característico',
      compatibleUses: 'Usos compatíveis',
      prohibitedUses: 'Usos Proibidos',
      maxHeight: 'Altura máxima',
      occupation: 'Ocupação',
      setBacks: 'Retrocessos',
      minimunPlot: 'Gráfico mínimo',
      exploitation: 'Exploração',
      scopeSurface: 'Scope surface',
      managment: 'Gestão urbana',
      params: 'Parâmetros urbanos',
      urbanisticQualificationAndManagement: 'Qualificação e gestão urbanística',
      urbanisticParameters: 'Urbanistic Parameters',
      isAdequatedToCurrentPlanning: 'Adequação à abordagem atual?',
    },

    POSSESSION: {
      ocupationState: 'estado de ocupação',
      occupationType: 'ocupante',
      hasNonexistenceLeasesDocument: 'documento de inexistência de arengas',
      hasContract: 'Existe um contrato?',
      tenureType: 'tipo de posse',
      name: 'primeiro e último nome',
      nationalIdentityDocument: 'DNI / NIF',
      percentage: 'porcentagem',
      editTenure: 'editar posse',
      addTenure: 'adicionar posse',
      deleteMessage: 'Tem certeza que deseja excluir este mandato?',
      tenurePorcentageMessage: 'A soma das percentagens deve ser 100%',
      requiredField: 'campos obrigatórios para preencher estão faltando',
      tenuresLength: 'necessidade de adicionar mandatos',
      tenureNif: 'DNI/NIF',
    },

    CHECKS: {
      assetChecksDataSource: 'use dados gerais do relatório',
      MinimumChecks: 'verificações mínimas',
      ChecksRelatedToAttachedDocumentation: 'verificações sobre a documentação anexada',
    },

    CONDITIONING_FACTORS: {
      conditions: 'condições',
      warnings: 'avisos',
      observations: 'observações',
      conditionalType: 'Tipo de condicionador',
      warningType: 'Tipo de aviso',
    },

    TERRAIN_DESCRIPTION_AND_SURFACES: {
      terrainSurfaces: 'superfícies de terreno',
      landInfrastructures: 'infraestruturas terrestres',
      cadastralSurfaceTotalPlotSurface: 'registro ({{surfaceUnit}})',
      registeredSurfaceTotalPlotSurface: 'cadastral ({{surfaceUnit}})',
      checkSurfaceTotalPlotSurface: 'verificado ({{surfaceUnit}})',
      terrenoUse: 'grau do solo',
      urbanizationState: 'estado de urbanização',
      morfologia: 'morfologia',
      topografia: 'topografia',
      observations: 'observações',
      withPaving: 'pavimentação',
      withStreetLighting: 'iluminação pública',
      withWaterSupply: 'abastecimento de água',
      withSanitationNetwork: 'rede de saneamento',
      withTelephoneNetwork: 'rede telefônica',
      withPipedGasNetwork: 'rede de gás centralizada',
      infrastructureObservations: 'observações',
      terrainDescription: 'Descripción del terreno',
    },

    TERRAIN_FEATURES: {
      pendingInfraestructure: 'Infraestrutura pendente',
      workDonePercentage: 'Porcentagem de trabalho realizado',
      currentInfraestructure: 'infraestrutura existente',
      urbanizationCostsAndPendingCharges: 'custos de urbanização e encargos pendentes',
      existingEdifications: 'edifícios existentes',
      surfaceToDemolish: 'superfície para demolir ({{surfaceUnit}})',
      acessibilidade: 'acessibilidade',
      groundLevel: 'Ground Level (Normativa ECO / 805/2003)',
      planningDevelopment: 'desenvolvimento de abordagem',
      managementSystem: 'sistema de gerenciamento',
      managementPhase: 'fases de gerenciamento',
      urbanCoreProximity: 'proximidade com o núcleo urbano',
      productToDevelopTypes: 'produto a ser desenvolvido',
      percentOfValuedArea: 'porcentagem do escopo avaliado',
      terrainInformationDataSource: 'fonte de dados',
      ASSET_exploitingAboveGround: 'Use acima do grau ({{surfaceUnit}})',
    },
    EXCHANGES: {
      list: 'Listado de oportunidades',
      assignToMe: '¡Asígnamela!',
    },

    REPORT_VISIT_ASSET_SURFACES_FACILITIES_STAYS: {
      surfaces: 'superfície',
      facilities: 'instalações',
      stays: 'permanece',
      totalUsefulSurface: 'útil ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'construído sem comuns ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'construído com comuns ({{surfaceUnit}})',
    },

    REPORT_VISIT_ASSET_BUILDING_INFO: {
      localityEnvironmentAndMarket: 'locality, environment and market',
      buildingFeatures: 'características de construção',
      environmentPersonalOpinion: 'parecer técnico sobre o meio ambiente da propriedade',
      mainElements_Structure: 'estrutura',
      mainElements_Deck: 'deck',
      mainElements_Enclosure: 'cercos',
      mainElements_Foundation: 'Foundation',
      constructionYear: 'ano de construção',
      lastRehabilitationYear: 'ano de reabilitação',
      totalPlotSurface: 'sup. enredo',
      qualityOfBuilding: 'qualidade de edifícios',
      conservationState: 'estado de conservação',
      buildingUse: 'use',
      totalFloorsBellowGround: 'Não. plantas abaixo do grau',
      totalFloorsAboveGround: 'Não. plantas acima do solo',
      numberOfBuildingAccesses: 'Não. acessos ao edifício',
      streetCategory: 'categoria da rua',
      surveillance: 'vigilância',
      hasSportsZone: 'zona de esportes',
      hasSwimmingPool: 'pool',
      hasGreenArea: 'áreas verdes',
      hasElevator: 'elevador',
      observations: 'observações',
      requiredField: 'campos obrigatórios para preencher estão faltando',
    },

    INPUT_FILE: {
      errorTitle: 'Erro',
      errorMessage: 'Formato não suportado',
    },
    BooleanQuestionValues_false: 'No',
    BooleanQuestionValues_true: 'Sí',
    REQUEST_FILTERS: {
      id: 'Identificador',
      containsOwnerFullName: 'Nombre cliente',
      containsOwnerNationalIdentityDocument: 'NIF/CIF cliente',
    },
    DEBUG: {
      confirm: 'Confirme',
      setDev: 'Definir DEV',
      setQA: 'Definir QA',
      setPROD: 'Set PROD',
      setPreset: 'Ou defina um ponto final predefinido:',
      setEndpoint: 'Definir ponto final',
      setCustomEndpoint: 'Set custom endpoint:',
      enpointAdress: 'Endpoint address:',
      cancel: 'Cancelar',
    },

    COMPARABLES_FILTERS: {
      workflowItemAssetId: 'Record number',
      offerType: 'Offer type',
      addedMinDateTime: 'Added date',
      addedMaxDateTime: 'Added date',
      bathroomCount: 'Bathroom count',
      bedroomCount: 'Room count',

      hasHotWater: 'Hot water',
      hasHeating: 'Heating',
      hasAirConditioning: 'Air conditioning',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'Plumbing and sanitary equipment',
      hasIntercom: 'pintercom',
      hasSecurity: 'security',
      hasPhone: 'phone',
      hasSanitation: 'sanitation',
      hasSwimmingPool: 'Swimming pool',
      hasSportsZone: 'Sports zone',
      hasGreenArea: 'Green area',
      hasElevator: 'Elevator',
      hasPool: 'pool',

      maxValorationTotalAdoptedSurface: 'Max surface',
      minValorationTotalAdoptedSurface: 'Min surface',
    },
    VALUATION_RESUME: {
      version: 'Versão',
      elemento: 'Elemento',
      unitValue: 'Valor unitário',
      totalValue: 'Valor total {{currency}}',
      subtotal: 'Subtotal',
      total: 'Total',
    },
    GROUP_VALUATE_AssetSurfaceIds_6: 'Registral ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_0: 'Cadastral ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_12: 'Verificado ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_13: 'Útil ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_15: 'Com comuns ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_14: 'Sem comuns ({{surfaceUnit}})',
    GROUP_VALUATE_isVPO: 'É VPO',
    GROUP_VALUATE_protected: 'Protegido',
    GROUP_VALUATE_free: 'Grátis',
    CLUSTER_TERRAIN: {
      addBuildingHypothesis: 'Adicionar hipótese',
      editParmaters: 'editar parâmetros',
      riskPremiumPercent: 'risk premium',
      developerProfitPercent: 'lucro do desenvolvedor (%)',
      valorationUnitValue: 'valor de mercado',
      weight: 'peso',
      repercussion: 'repercussão',
      editBuildingHypothesis: 'Editar hipótese',
      editIncome: 'Editar receita',
      editExpense: 'Editar despesas',
      maxPercentage: 'entre 0-100%',
      updatedIncome: 'Lucro atualizados',
      updatedExpenses: 'Despesas atualizados',
      totals: 'Totais',
      buildingHypothesisValuation: 'Avaliação de hipóteses',
      valuationMethod: 'Método de avaliação',
      countBuildingElements: 'Não. elementos para construir ',
      observations: 'Observações',
      calculationMethod: 'Cálculo do método',
      elementSurface: 'Estimativa da superfície do elemento ({{surfaceUnit}})',
      valorationUnitValueProposed: 'Proposta de valor ({{currency_per_surfaceUnit}})',
      terrainMethodChange: 'Alterar o método de avaliação apagará todos os itens criados a partir da avaliação anterior. Prosseguir? ',
      increaseInExternalFinancing: 'Aumentar o financiamento externo (%)',
      updateRate: 'Taxa de atualização (%)',
      yearly: 'Anual',
      biannual: 'Mensal',
      monthly: 'Mensal',
      increaseInExternalFinancingObservations: 'Justificativa de valor',
      groupBy: 'Agrupar por',
      byPeriods: 'por períodos',
      assignValueToAll: 'Atribuir valor a todos',
      netExpensesUpdated: 'Expenses Act.',
      total: 'Total',
      atualizado: 'Atualizado',
      totalUpdated: 'Total Actualizado',
      totalIncomesExpensesDiff: 'TOTAL (I-G)',
      assigned: 'Atribuído',
      assignedTotalValue: 'Atribuído ({{currency}})',
      calculateValorationValue: 'Cálculo do valor de mercado',
    },

    BUILDING_STATE: {
      observations: 'Observaciones',
      adoptedSurface_2: 'Superficie adoptada',
      constructionCostsTotalValue: 'Coste de construcción  {{currency}}',
      recalculateWarning: 'Recuerda que después de recalcular debes revisar el reparto de activos y guardar los cambios.',
      recalculateBeforeContinue: 'Es necesario recalcular antes de poder editar el formulario',
      distribution: 'Distribución',
    },

    surfaceCommons: 'Superficie construida con comunes',
    surfaceUseful: 'Superficie útil',
    surfaceWithoutCommons: 'Superficie construida sin comunes',
    cadastral: 'Catastral',
    checked: 'Comprobada',
    registered: 'Registral',

    HOMOG_COEF_MODAL: {
      requiredFields: 'Deben completarse todos los coeficientes de homogeneización. En caso de no aplicar algún concepto dejar en 1,00',
      requiredCoeficientObservations: 'Las observaciones serán obligatorias si tiene valor el coeficiente “otros”',
      observations: 'Observaciones',
    },

    VALIDATION_MODAL: {
      bodyText: 'Declaro ter lido e compreendido os artigos relativos às obrigações de sigilo e incompatibilidades dos profissionais a que se referem os artigos 11 e 13 do RD 775/1997, bem como as <a href="https://dev-migration.iv.solutions/files/users/IV_Reglamento_Interno_de_Conducta_2020.pdf">normas de conduta internas</a>, aceitando-se ambos para o desempenho de minha atividade profissional no Instituto de Valoraciones SA. <hr> Comprometo-me a informar o Instituto de Valoraciones de qualquer conflito de interesses decorrente da execução do meu trabalho e a manter atualizadas as minhas incompatibilidades.',
      button: 'EU CONCORDO',
    },

    CHANGE_TEMPORAL_PASSWORD_recoverMessage: 'Sua senha atual é temporária, por razões de segurança deve ser alterada',
    ASSETS_totalPlotSurface: 'Superfície de parcela ({{surfaceUnit}})',

    numberAbbreviation: 'Nº',
    currentValuationAbbreviation: 'Actual',
    futureValuationAbbreviation: 'H.E.T.',
    visit: 'Visita',
    incidentAbbreviation: 'INC',

    confirmDeleteTitle: 'Confirmar eliminar',
    confirmDeleteMessage: '¿Está seguro de querer eliminar esta valoración?',
    buildingElementChangeValuationMethodMessage: 'Al cambiar el método de valoración se eliminará el anterior método usado',
    noMoreInfo: 'No hay más información',

    REA: {
      maximumLegalDividedByCostMethodSurface: 'Este valor viene dado de el valor máximo legal dividido entre la superficie adoptada en el método de coste',
      surfaceInCostMethod: 'Superficie del método de coste: ',
    },

    EXCHANGE_TOUR: {
      welcome: '¡Bienvenido!',
      configureMaxDistance: ' Empieza configurando la distancia máxima de la que quieres recibir oportunidades',
      preferencesSaved: 'Preferencias guardadas correctamente',
      assetAddress: 'Dirección del activo',
    },
    EXCHANGE: {
      assignAppraisalInExchangeMessage: 'Con la asignación estás asumiendo la ejecución de la valoración por los honorarios y en el plazo indicado.',
      successfulAssigned: 'Asignada correctamente',
      errorAssigned: 'Ha ocurrido un error, inténtelo de nuevo.',
    },

    invoice: 'Factura',

    TOUR: {
      exitTour: 'Saltar tour',
      canChangePreferences: 'Recuerda que siempre puedes cambiar tus preferencias desde PERFIL',
      canFilterExchanges: 'Puedes filtrar las oportunidades según fecha y tarifa',
      exchangeMoreInfo: '¿Te interesa saber más sobre la oportunidad?',
      clickInCard: 'Haz click sobre la tarjeta',
      hurryUpAndAssign: '... o si todo te encaja, date prisa y ¡Asígnatela!',
      continueWithYourWork: 'Y sigue con el proceso habitual de trabajo',
    },

    CANDEACTIVATE: {
      pendingChanges: 'Existem alterações pendentes',
      pendingChangesMessage: 'Você tem alterações pendentes para confirmar. Tem certeza que deseja sair desta página?',
      yesWithoutSaving: 'Sim, sem salvar',
      yesSaving: 'Sim, salvando',
    },
    WORKPLAN_everyAssetMustHaveAValidSurface: 'Todos os ativos devem ter pelo menos uma superfície com valor',

    language: 'Idioma',
    currency: 'Moeda',
    config: 'configuração',
    select_currency_warning: 'A moeda selecionada vai mudar o rótulo, não vai influenciar o valor.',

    REDE: {
      myConnectionsNetwork: 'Minha rede de conexões',
      confirmAndSend: 'Confirmar e enviar',
      addConnection: 'Adicionar conexão',
      workWith: 'Work for ...',
      code: 'Código',
      message: 'Mensagem',
      codeConnectionError: 'Não é possível conectar-se com este usuário, para isso você deve contratar um de nossos planos.',
      licenseMessage: 'Quero colaborar com você para fazer avaliações em ...',
      codeCopied: 'Código copiado!',
      cancelConnection: 'Cancelar conexão',
      connectionDate: 'Data de conexão',
      connectionRequestDate: 'Data de solicitação',
      all: 'Tudo',
      resendRequest: 'Reenviar solicitação',
      insertConnectionData: 'Insira os dados da pessoa com a qual deseja se conectar',
      requestError: 'Erro ao enviar solicitação',
      invalidSharingIdMessage: 'O código que você digitou não corresponde a nenhum usuário Tasafy, verifique este código e faça a solicitação novamente',
      pendingConnectionRequestMessage: 'Você já tentou se conectar com esta pessoa, mas ela ainda não aceitou o convite. Você pode reenviar a solicitação de conexão novamente ',
      alreadyRelatedMessage: 'Esta pessoa já pertence à sua rede de contatos. Verifique se os dados que você inseriu estão corretos.',
      requestSended: 'Solicitação enviada',
      reviewRequest: 'Pedido de revisão',
      cancelConnectionMessage: 'Tem certeza de que deseja cancelar esta conexão?',
      resendRequestMessage: 'Tem certeza que deseja reenviar a solicitação?',
      requestMessageWorkFor: '{{name}} deseja se conectar com você, entre na rede deles!',
      rejectRequest: 'Rejeitar solicitação',
      acceptRequest: 'Aceitar pedido',
      name: 'Nome',
      newConnection: 'Nova conexão',
      colaborateWithLicenses: 'Colabora con otras licencias como um profissional',
    },
    ConnectionRequested_0: 'Enviado',
    ConnectionRequested_1: 'Recebido',
    EXCHANGE_errorMessage: 'Ocorreu um erro, tente novamente.',
    EXCHANGE_executionMessage: 'Com a atribuição assume a execução da avaliação pelas taxas e no prazo indicado.',
    EXCHANGE_assignedRight: 'Atribuído corretamente',
    COST_METHOD_editFieldsInCurrentValoration: 'O resto dos campos só podem ser editados na guia Avaliação atual',
    VALUATION_LIST_residualDynamicInfo: 'O método residual dinâmico será adicionado a partir do capítulo Estado do trabalho',

    VALUATION_LIST_residualDynamicDeleteMessage: 'Este método só pode ser removido removendo o método residual dinâmico de dados comum correspondente na guia Status do trabalho',

    CLUSTER: {
      clusterData: 'Datos agrupación',
      valuations: 'Valoraciones',
      assets: 'Unidades',
      totalValue: 'Valoración total',
      valuationsSummary: 'Desglose de valoraciones',
      allValuations: 'Todas las valoraciones',
      canNotValuate: 'Este ativo não pode ser avaliado porque pertence a um pool.',
      assetNotHasSurfaces: 'Es necesario añadir las superficies en el apartado de Superficies del activo.',
      surfacesError: 'Faltan superficies en los activos por seleccionar.',
      valuationSurfaceTitle: 'Surface',
      necessarySaveCluster: 'Salvar é necessário para recalcular a superfície de agrupamento.',
      necessarySaveClusterValuationsStep: 'Salvar em unidades é necessário para recalcular a superfície do cluster.',
      addAsset: 'Adicionar ativo',
      removeAssetWithParentOrChildrenConfirm: 'Remover esta unidade irá remover as unidades vinculadas a ela. Retirar?',
      removeAssetConfirm: 'Você vai remover esta unidade. Retirar?',
      selectClusterType: 'Seleccionar tipo de agrupación:',
      selectClusterAssets: 'Selecionar ativos de cluster',
      minClusterAssets: '* O cluster deve consistir em pelo menos dois ativos',
      selectableAssets: 'selectable',
      noSelectableAssets: 'não selecionável',
      noItemsSection: 'Não há itens',
      noItemsToSelect: 'Não há itens que possam fazer parte do agrupamento. Se você considerar que tem um ativo que precisa agrupar, pode verificar se há um erro na parte de ativos não selecionáveis. ',
      isEconomicExploit: 'Exploração econômica',
      isNotEconomicExploit: 'Sem exploração econômica',
    },

    COST_METHOD_isUnfinishedMessage: 'La superficie adoptada solo podrá ser modificada en el capitulo de Estado de la Obra.',
    notHavePermissions: 'Você não tem permissões',
    licenseNotHasPermissions: 'Sua licença não tem permissão para acessar esta avaliação',
    COST_METHOD_recalulateWorkPlan: 'É necessário recalcular o estado da obra e atribuir uma superfície a este ativo.',
    COST_METHOD_totalAdoptedSurface: 'É necessário definir as superfícies adotadas no capítulo Status do Trabalho.',
    CLUSTER_DETAIL_canNotRemoveAssets: 'Incapaz de remover. Deve haver pelo menos duas unidades por agrupamento ',

    REPORT_ASSET_report_structure_hasChildrenWithErrors_error: 'É necessário rever os erros pertencentes aos anexos para poder agrupar.',
    cashflows_income_imputed: 'Os Fluxos de Caixa Atribuídos mostram os valores totais de Receitas e Despesas geradas pela Exploração Econômica sem aplicação do percentual relativo à Receita imputada ao imóvel.',

    ASSET_canNotSelectEconomicExploitation: 'Não é possível selecionar Exploração Econômica com o status do ativo não concluído',
    CLUSTERS_canNotChangeUseToTerrain: 'Este ativo pertence a um cluster. Se você quiser convertê-lo em terreno, é necessário removê-lo desse agrupamento ',

    EX_economicDataInfoModalTitle: 'Informações de dados econômicos',

    last_orders_empty: 'Olá {{user}}! Você não tem novos pedidos, se você perder algum, verifique o resto dos estados: Visita pendente, Envio pendente...',

    WELCOME_CONNECTIONS_slide1_title: 'Bem-vindo ao mundo das conexões Tasafy!',
    WELCOME_CONNECTIONS_slide1_paragraph1: 'Mergulhe no universo <b>Tasafy</b> e conecte-se com os diferentes <i>jogadores</i> para poder fazer as avaliações para eles.',
    WELCOME_CONNECTIONS_slide1_paragraph2: 'O que isso significa?',
    WELCOME_CONNECTIONS_slide2_paragraph1: 'Em seu nome, você encontrará seu <b>código profissional</b> que você pode compartilhar para que eles possam se conectar com você e que você faça parte da rede deles para que eles possam solicitar trabalhos de você.',
    WELCOME_CONNECTIONS_slide2_paragraph2: 'Se você tem um código de licença (aqueles que começam com <b>L-</b>) você pode solicitar a conexão apenas clicando no <b>+</b>.',
    WELCOME_CONNECTIONS_slide3_paragraph1: 'Na página de conexões você pode encontrar todas as solicitações de conexão que você tem em cada estado. Da mesma forma, a qualquer momento você pode solicitar o cancelamento da conexão.',
    WELCOME_CONNECTIONS_slide3_paragraph2: 'No menu lateral, você poderá navegar entre as diferentes contas às quais está conectado.',
    WELCOME_CONNECTIONS_slide4_paragraph1: 'Além disso, você terá um acesso muito mais completo a partir do seu <b>painel de controle</b>, pois poderá ver o resumo dos pedidos que você tem em andamento em cada uma das contas e o trabalho pendente.',
    WELCOME_CONNECTIONS_slide4_button: 'Vamos nos conectar!',

    ACCOUNT_ACTIVATION_noActivationTitle: 'Não se esqueça de ativar sua conta!',
    ACCOUNT_ACTIVATION_noActivationMessage: 'Sua conta ainda não foi ativada. Verifique sua caixa de entrada onde você encontrará o e-mail para fazê-lo. Se você não o recebeu ou ultrapassou o tempo de ativação de um dia, pode solicitá-lo novamente aqui.',
    ACCOUNT_ACTIVATION_resend: 'Reenviar',
    ACCOUNT_ACTIVATION_resending: 'Reenviando...',
    ACCOUNT_ACTIVATION_goToLogin: 'Voltar ao login',
    ACCOUNT_ACTIVATION_defaultError: 'Erro ao ativar a conta. Por favor, entre em contato conosco',
    ACCOUNT_ACTIVATION_resendSuccess: 'E-mail reenviado com sucesso',
    ACCOUNT_ACTIVATION_resendError: 'Erro ao reenviar e-mail',
  },
};
