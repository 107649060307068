export const enLang = {
  cultureCodeLanguage: 'en-GB',
  languageKey: 'en',
  icon: 'gb',
  translates: {
    noContent: 'There are no items in the list',

    LANG_selectLanguage: 'Choose a language',
    LANG_es: 'Spanish',
    LANG_en: 'English',
    LANG_pt: 'Portuguese',

    LOGIN_email: 'email',
    LOGIN_password: 'password',
    LOGIN_login: 'login',
    LOGIN_recoverPassword: 'recover password',
    LOGIN_recoverMessage: 'Enter your email and we will send you the instructions to reset your password.',
    LOGIN_recover: 'recover',
    LOGIN_backToLogin: 'back',

    FORM_VALIDATION_EMAIL_VALIDATION_ERROR: 'The email format is not valid',
    FORM_VALIDATION_EMAIL_VALIDATION_ERROR_ALT: 'The email format is not valid',
    FORM_VALIDATION_BUDGET_NUMBER_MASK_ERROR: 'The format must be: 999999-999999',
    FORM_VALIDATION_MAX_LENGTH: 'The maximum length of the field is: ',
    FORM_VALIDATION_MIN_LENGTH: 'The minimum length of the field is: ',
    FORM_VALIDATION_REQUIRED: 'This field is mandatory',
    FORM_VALIDATION_PATTERN: 'The format is wrong',
    FORM_VALIDATION_MIN: 'The min value is: ',
    FORM_VALIDATION_MAX: 'The max value is: ',
    FORM_VALIDATION_NOT_MATCH: 'fields not match',
    FORM_VALIDATION_additionHigherThan: 'Additin higher than 100%',
    FORM_VALIDATION_WRONG_INPUT: 'Your password must have at least 8 characters and contain a mix of lowercase and uppercase letters, numbers and special characters (e.g.!#$).',
    FORM_VALIDATION_NOT_SAME: 'the fields do not match',
    FORM_VALIDATION_wrongCredentials: 'Incorrect username or password',
    FORM_VALIDATION_MAX_ADDITION: 'Addition is more than: ',
    FORM_VALIDATION_PASSWORD_DOES_NOT_MATCH: 'Your password must have at least 8 characters and contain a combination of lower case and upper case letters, numbers and special characters (for example, ! # $).',

    MENU_profile: 'profile',
    MENU_comparables: 'comparable',
    MENU_profileData: 'personal data',
    MENU_documents: 'documents',
    MENU_invoices: 'invoices',
    MENU_orders: 'orders',
    MENU_exchange: 'opportunities',
    MENU_requests: 'collaborations',
    MENU_contactUs: 'contact us',
    MENU_personalData: 'My data',
    MENU_how_it_works: 'How it works?',
    MENU_logOut: 'log out',
    MENU_schedule: 'Schedule',
    MENU_debug: 'debug',
    MENU_updateDocumentationAndIncompatibilitiesMessage: 'Recuerda la obligación de mantener actualizada tu documentación y listado de incompatibilidades en cumplimiento del artículo 13 del R.D. 775/1997.',
    MENU_absences: 'availability / absences',
    MENU_dashboard: 'Dashboard',
    MENU_accounts: 'Accounts',
    MENU_control_panel: 'Control panel',
    MENU_connections: 'Connections',

    APPRAISER_pageTitle: 'personal data',
    APPRAISER_personalData: 'personal data',
    APPRAISER_preferences: 'preferences',
    APPRAISER_incompatibilities: 'incompatibilities',
    APPRAISER_firstName: 'firstname',
    APPRAISER_lastName: 'lastname',
    APPRAISER_nationalIdentityDocument: 'DNI/NIE',
    APPRAISER_phoneNumber: 'phone number',
    APPRAISER_degree: 'degree',
    APPRAISER_edit: 'edit',
    APPRAISER_maxDistance: 'max distance',
    APPRAISER_receiveNotifications: 'receive notifications',
    APPRAISER_notificationPush: 'push notifications',
    APPRAISER_notificationByEmail: 'email notifications',
    APPRAISER_savePreferences: 'save preferences',
    APPRAISER_preferencesMessage: 'Set your preferences to receive the latest opportunities!',
    APPRAISER_personalDataSection: 'data',
    APPRAISER_notificationsSection: 'notifications',
    APPRAISER_changePassword: 'change password',
    APPRAISER_password: 'password',
    APPRAISER_confirmPassword: 'confirm password',
    APPRAISER_visits: 'Visits',
    APPRAISER_deadlineDate: 'Deadline date',

    MY_WORK: {
      works: 'My Work',
      work_summary: 'Work Summary',
      assignments: 'My Assignments',
      pending_work: 'Pending Work',
      all: 'All',
      mywork_pending_expedients: 'My pending files',
      mywork_finalized_expedients: 'My finalized files',
      mywork_total_expedients: 'My total files',
    },

    LOCATION_country: 'country',
    LOCATION_province: 'province',
    LOCATION_locality: 'locality',
    LOCATION_postalCode: 'postal code',
    LOCATION_streetNotExists: 'The street not exist in the street map',
    LOCATION_streetType: 'street type',
    LOCATION_streetName: 'street name',
    LOCATION_streetNumber: 'street number',
    LOCATION_portal: 'portal',
    LOCATION_stairs: 'staircase',
    LOCATION_floor: 'floor',
    LOCATION_gate: 'door',
    LOCATION_locationObservations: 'observations',

    MESSAGES_successPOST: 'Success adding',
    MESSAGES_successPUT: 'Success editing',
    MESSAGES_errorPUT: 'Error editing',
    MESSAGES_successDELETE: 'Success removing',
    MESSAGES_errorDELETE: 'Error removing',
    MESSAGES_successUploadFile: 'Success uploading file',
    MESSAGES_errorUploadFile: 'Error uploading file',
    MESSAGES_errorPOSTLIST: 'Error displaying the list',
    MESSAGES_errorPOST: 'Error adding',
    MESSAGES_successConfirm: 'Success confirmation',
    MESSAGES_errorConfirm: 'Error confirmation',
    MESSAGES_successReject: 'Success rejection',
    MESSAGES_errorReject: 'Error rejection',
    MESSAGES_requiredField: 'Required fields are missing',
    MESSAGES_the_action_was_successful: 'La acción se ha realizado correctamente',
    MESSAGES_successful: 'Éxito',
    MESSAGES_error: 'error',

    ACTIONS_cancel: 'Cancelar',
    ACTIONS_add: 'Add',
    ACTIONS_edit: 'Edit',
    ACTIONS_delete: 'Remove',
    ACTIONS_download: 'Desargar',
    ACTIONS_done: 'Done',
    ACTIONS_back: 'Back',
    ACTIONS_save: 'Save',
    ACTIONS_skip: 'Skip',
    ACTIONS_confirm: 'Confirm',
    ACTIONS_close: 'Close',
    ACTIONS_reset: 'Reset',
    ACTIONS_next: 'Next',
    ACTIONS_save_selection: 'Save selection',

    INCOMPATIBILITIES_firstName: 'first name',
    INCOMPATIBILITIES_lastName: 'last name',
    INCOMPATIBILITIES_nationalIdentityDocument: 'DNI/NIE',
    INCOMPATIBILITIES_familyRelationship: 'relationship',

    APPRAISAL_BASIC_FILTERS: {
      recordIdContains: 'Record id',
      executionToolId: 'Internal reference id',
      externalRecordId: 'External reference id',
    },
    APPRAISAL_STATUS_FILTERS: {
      currentWorkflowSteps: 'State',
      purposes: 'Purpose',
      visitTypes: 'Visit type',
    },
    APPRAISAL_LIMIT_DATES_FILTERS: {
      scheduleVisitLimit: 'Schedule visit deadline',
      startVisitLimit: 'Start visit deadline',
      finishVisitLimit: 'Finish visit deadline',
      sentReportLimit: 'Sent report deadline',
    },
    APPRAISAL_INCIDENTS_FILTER: {
      withIncidentsNotResolved: 'With incidences unresolved',
      withIncidents: 'With incidences',
    },
    APPRAISAL_INVOICE_FILTERS: {
      isInConfirmedInvoice: 'Has confirmed invoice',
    },
    FILTERS: {
      basicFilters: 'Basic filters',
      exchangeFilters: 'Exchange filters',
      appraisalState: 'Appraisal state',
      dateLimits: 'Deadlines',
      incidents: 'Incidences',
      invoice: 'Invoice',
      addedDate: 'Added date',
      apply: 'Apply',
      reset: 'Reset',
      requestState: 'Request state',
      typologyFilters: 'Typology',
      locationFilters: 'Location',
      featuresFilters: 'Features',
    },
    DATES: {
      from: 'From',
      to: 'To',

      visitScheduleDeadlineDateTime: 'limit to schedule visit',
      visitScheduleDateTime: 'visit date',
      appraiserAllDataSentDeadlineDateTime: 'limit to send report',
      addedDateTime: 'added',
      solvedDateTime: 'solved',
      date: 'date',
      allDay: 'any time of day',
      hour: 'hour',
      chooseADate: 'choose a date',
      chooseOtherDate: 'or confirm other visit date',
      confirmDate: 'confirm date and hour',
    },

    APPRAISALS: 'appraisals',
    APPRAISAL: {
      finished: 'Finished',
      sentToRevision: 'Finish appraisal',
      lastOrders: 'last appraisals',
      visitPending: 'pending visit',
      sentPending: 'pending to send',
      finishPending: 'Pending to finish',
      rejected: 'rejected',
      incidents: 'incidents',
      detail: 'detail',
      reports: 'reports',
      report: 'report',
      board: 'board',
      info: 'info',
      requestElements: 'request elements',
      purpose: 'purpose',
      visitType: 'visit type',
      deadlines: 'deadlines',
      customer: 'customer',
      prescriptor: 'prescriptor',
      customers: 'customers',
      prescriptors: 'prescriptors',
      contact: 'contact',
      incidentResolution: 'resolution',
      revision: 'Revision',
      request: 'Request',
      assets: 'Assets',
      clusters: 'Clusters',
      comment: 'comment',
      addComment: 'Add comment',
      addIncident: 'Add incident',
      incidentDescription: 'Description',
      incidentTypes: 'Incident types',
      scheduleVisit: 'Schedule visit',
      editScheduleVisit: 'Edit visit data',
      startVisit: 'Start visit',
      finishVisit: 'Finish visit',
      sendToRevision: 'Send appraisal',
      visitProposedDates: 'Proposed visit dates',
      scheduleVisitSuccess: 'Visit scheduled',
      scheduleVisitError: 'Error scheduling visit',
      visitDate: 'Visit date',
      limitScheduleDateTime: 'visit date deadline',
      limitSendDataDateTime: 'send data deadline',
      importReport: 'Import report from',
      confirmationHeader: 'Are you sure?',
      confirmationMessage: 'The report has been copied correctly except for the sections on documents and photographs. Please, review and modify the necessary data and include the updated documentation ',
      scheduleDeadLine: 'limit to schedule visit',
      exchangeFinish: 'report send limit',
      idufir: 'C. R. U. (IDUFIR) ',
      land: '',
      propertyRegistryName: 'registry name',
      volume: 'tome',
      book: 'book',
      paper: 'folio',
      inscription: 'inscription',
      fee: 'fees',
      executionMode: 'execution mode',
      executedByIvForce: 'ivforce',
      executedByExternalTool: 'external tool',
      registeredPropertyNumber: 'Farm',
      section: 'Folio',
      appraisalOrderType: 'order type',
    },
    APPRAISAL_REPORT: {
      importAssetStructure: 'Import structure to assetss',
      commonData: 'Common report data',
      clusterToValuate: 'Create cluster to valuate',
      completeForm: 'Complete form',
      checkAllAsset: 'Check all assets',
      clusterValuations: 'Cluster valuations',
      valuateTerrains: 'Valuate lands',
      // STEPS
      units: 'assets',
      selectStep: 'Select step',
      requiredPercent: 'required',
      completedPercent: 'completed',
      seeValuationResume: 'See reviews summary',
      valuationResume: 'Reviews summary',
    },
    ASSET_TREE: {
      addAnnex: 'Add annex',
      duplicate: 'Duplicate',
      delete: 'Removed',
      cancel: 'Cancel',
      makePrincipal: 'Make it principal',
      extractAsset: 'Extract from asset structure',

      deleteAssetConfirm: 'Delete',
      deleteAssetCancel: 'Cancel',
      deleteAssetSuccess: 'Asset deleted',
      deleteAssetError: 'Error deleting asset',
      deleteAsset: 'Delete asset',
      deleteAssetMessage: 'If you delete this asset, you will delete every annexed associated. Do you want to continue?',

      recalculateValuations: 'Recalculate valuations',
      notCompleted: 'Not completed',
      unComplete: 'Uncomplete',
      complete: 'Complete',
      recalculateWorkPlan: 'Recalculate asset building state',
      hasValuations: 'You cannot group this asset as it has associated valuation methods.',
      onlyClusterSameCategory: 'You will not be able to group mixed elements, soils can only be grouped with soils, finished elements, with finished elements and economic exploitations with economic exploitations. Unfinished items cannot be grouped.',
      selectAssetsToCluster: 'Select the units you want to group:',
    },
    STEPS: {
      location: 'Localización',
      identification: 'Identificación',
      typology: 'Tipología',
      surfaces: 'Superficies',
      localityAndEnvironmentReport: 'Localidad, entorno y mercado',
      terrainFeatures: 'Características del terreno',
      buildingTerrain: 'Terrain description',
      buildingFeatures: 'Características del edificio',
      assetFeatures: 'Características del inmueble',
      urbanisticSituation: 'Situación urbanística',
      possession: 'Posesión',
      checks: 'Comprobaciones',
      valuations: 'Valoraciones',
      conditioningWarningsObservations: 'Condicionantes, advertencias y observaciones',
      photos: 'Fotos',
      documentation: 'Documentación',
      surfacesAndAssetFeatures: 'Superficies y características del inmueble',
      surface: '',
      LocalityEnvironmentAndBuildingFeatures: 'Características del edificio, localidad, entorno y mercado',
      buildingState: 'Building state',
      environment: 'environment',
    },
    DOCUMENTS: {
      addDocument: 'add document',
      document: 'document',
      documents: 'documents',
      expirationDate: 'expiration date',
      addedSuccess: 'The document was uploaded successfully',
      deletedSucces: 'The document has been deleted successfully',
      deleteThisDocument: 'Are you sure you want to delete this document?',
      expirationDateTime: 'Expiration Date',
    },

    error: 'Error',

    REPORT_ASSET_TREE_FILTER: {
      registryNumber: 'Registry property',
      idufir: 'C.R.U. (IDUFIR)',
      cadastralReference: 'Cadastral reference',
      address: 'Address',
      state: 'State',
      use: 'Use',
      typology: 'Typology',
      subTypology: 'Subtypology',
    },

    INVOICE: {
      invoices: 'invoices',
      rejectInvoice: 'reject invoice',
      reasonRejectInvoice: 'Please, tell us the reason',
      subtotal: 'subtotal',
      total: 'total',
      irpf: 'irpf',
      iva: 'iva',
      taxPercentageText: 'Income tax ',
      invoiceNumberAlertTitle: 'Please, insert invoice number',
      invoiceNumber: 'Invoice number',
    },

    REPORT_ASSET: {
      dataSource: 'Data source',
      mainElements_Structure: 'Structure',
      mainElements_Deck: 'Deck',
      mainElements_Enclosure: 'Enclosure',
      mainElements_Foundation: 'Foundation',
      constructionYear: 'Construction year',
      lastRehabilitationYear: 'Rehabilitation year',
      qualityOfBuilding: 'Building quality',
      conservationState: 'Conservation state',
      buildingUse: 'Use',
      totalFloorsBellowGround: 'Floors below ground',
      totalFloorsAboveGround: 'Floor above ground',
      numberOfBuildingAccesses: 'Building accesses',
      streetCategory: 'Street category',
      surveillance: 'Surveillance',
      hasSwimmingPool: 'Pool',
      hasSportsZone: 'Sport zone',
      hasGreenArea: 'Green zone',
      hasElevator: 'Elevator',
      totalPlotSurface: 'Plot surface',
    },

    VALUATIONS: {
      observationsOcupation: 'Observations regarding occupation',
      observationsNetIncome: 'Observations regarding net income / Payment estimate information',
      necessaryConstructionExpensesPercentageRequired: 'Complete "Calculations according to the progress of the work"',
      calculationsInProgressWorkPlanTitle: 'Construction - Costs and expenses',
      constructionCostsTitle: 'Construction costs',
      calculationsInProgressWorkPlanConstructionCostTotal: 'Total construction cost',
      calculationsInProgressWorkPlanProgressPercentage: 'Work progress%',
      calculationsInProgressWorkPlanConstructionCostCurrent: 'Construction cost - Actual',
      calculationsInProgressWorkPlanNecessaryExpenses: 'Necessary expenses%',
      calculationsInProgressWorkPlanExpensesProgressPercentage: 'Progress of expenses%',
      calculationsInProgressWorkPlanAdoptedNecessaryExpenses: 'Necessary expenses adopted%',
      calculationsInProgressWorkPlanNecessaryExpensesCurrent: 'Necessary expenses - Actual',
      calculationsInProgressWorkPlanManualEditing: 'Edit progress percentage in expenses',

      calculationsInProgressWorkPlanFormMessage: 'Construction values ​​are calculated based on the percentage of progress of the work. The Cost value comes from the one distributed in the Work Status tab. ',
      calculationsInProgressWorkPlanMessage: '* The necessary expenses are calculated with respect to the total construction costs of the unit (table "in the state of the work") considering the% applied by the degree of progress of the work. See detail in "Calculation based on work progress".',
      calculationsInProgressWorkPlanLink: 'Calculations according to the progress of the work',
      costMethodRepercussionCalculateMessage: 'If you change the way you enter the impact, you will lose the data included so far, do you want to continue?',
      summaryTotalValuationsRecalculationMessage: 'The characteristics of the asset have been modified. You must recalculate the ratings to continue',
      futureValuationTotalValue: 'C.B.H. total valuation',
      currentValuationTotalValue: 'Current total valuation',
      insuranceByEco: 'insurance by ECO',
      insuranceByRoyalDecree: 'insurance by R.D.',
      valuationTotalValue: 'Valuation total value',

      insuranceValorationTypesDropdown: 'Calculation of the insurance value with respect to:',

      proposalValue: 'Proposal value',
      documents: 'Documents',
      totalProposalValue: 'Total proposal value',
      observations: 'Observations',
      surface: 'Surface',
      selectSurfaceTocontinue: 'Choose a surface to continue',
      surfaceCommons: 'Built surface with commons',
      surfaceBuiltWithoutCommons: 'Built surface without commons',
      surfaceUseful: 'Useful surface',
      cadastral: 'Cadastral',
      checked: 'Checked',
      registered: 'Registered',
      usefulRegistralSurface: 'Registered useful surface',
      usefulCheckedSurface: 'Checked useful surface',
      usefulQualifiedSurface: 'Qualified useful surface',

      ground: 'Ground',
      repercussion: 'Repercussion',
      method: 'Method',
      necessaryExpenses: 'Necesary expenses',
      totalMarketValue: 'Total market value',
      construction: 'Construction',
      VRB: 'VRB',
      editPercentages: 'Edit percentages',
      physicalBuildingDeprecation: 'Physical building deprecation',
      physicalElementDeprecation: 'Physical element deprecation',
      functionalDeprecation: 'Functional deprecation',
      VRN: 'VRN',

      residualStaticCostTitle: 'Simplified static residual',
      residualStaticCostMarketValueUnitPrice: 'Market value ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionCostUnitPrice: 'Construction cost ({{currency_per_surfaceUnit}})',
      residualStaticCostConstructionExpensesUnitPrice: 'Construction expenses ({{currency_per_surfaceUnit}})',
      residualStaticCostFinancialExpensesPercentage: 'Financial expenses (%)',
      residualStaticCostMarketingExpensesPercentage: 'Comercialization expenses (%)',
      residualStaticCostDeveloperProfitPercentage: 'Delevoper profits (%)',
      residualStaticCostKCoeficient: 'K (Coefficient to make new)',
      total: 'Total',

      qualifiedSurface: 'Qualified surface ',
      legalMaximumValues: 'Legal maximum values',
      legalMaximumValue: 'Legal maximum value',
      legalMaximumUnitValues: 'Valor unitario máximo legal',
      legalMaximumTotalValue: 'Valor total máximo legal',
      legalMaximumGroundValue: 'Legal maximum ground value',
      groundValueLimit: 'Ground limit value',
      REA_legalMaximum: 'REA (legal maximum)',
      REA_costMethod: 'REA (cost method)',
      valueTypes: 'Value types',
      moreInfo: 'More information',
      marketValue: 'Market value',

      contractualData: 'Contractual data',
      contractStartDate: 'Contract start date',
      contractFinishDate: 'Contract extinction date',
      nextChargeDate: 'Next charge date',
      economicalData: 'Economic data',
      monthlyGrossIncome: 'Monthly gross income',
      monthlyExpenses: 'Monthly expenses',
      monthlyNetIncome: 'Monthly net income',
      updateType: 'Update type',
      updateRatePercent: 'Update rate',
      monthlyEquivalentUpdateRatePercent: 'Monthly equivalent update rate',
      reversionValue: 'Revesion value',
      capitalGainPercent: 'Capital gain',
      updatedValues: 'Updated values',
      updatedIncomes: 'Updated incomes',
      updatedReversion: 'Updated reversion',
      updatedTotal: 'Updated total',

      marketNetIncome: 'Market net income',
      euroYearSuffix: '{{currency}}/year',
      euroMonthSuffix: '{{currency}}/month',
      activeNetIncome: 'Active net income',
      currentIncome: 'Current income',
      date: 'Date',
      incomes: 'Incomes',
      yearly: 'Yearly',
      monthly: 'Monthly',
      marketEconomicRentalData: 'Market rental economic data',
      homogeinizedValue: 'Homogeinized value',
      deprecatedHomogeinizedValue: 'Deprecated homogeinized value',
      comparable: 'Comparable',
      adjustComparison: 'Adjusted comparison',

      witnesses: 'Witnesses',
      witness: 'Witness',
      witnessList: 'Witness list',
      distance: 'Distance',
      surfaceM2: 'Surface ({{surfaceUnit}})',
      homogeinizedCoefficient: 'Homogeinized coef.',
      weight: 'Weight',
      marketValueEuroPerM2: 'Market value ({{currency_per_surfaceUnit}})',
      marketValueEuroPerM2Month: 'Market value ({{currency_per_surfaceUnit}}/month)',
      finalValueEuroPerM2: 'Final value ({{currency_per_surfaceUnit}})',
      finalValueEuroPerM2Mont: 'Valor final ({{currency_per_surfaceUnit}}/month)',
      noWitnessAdded: 'There is not added witnesses',

      desoccupation: 'Idleness',
      latePayment: 'Late payment',
      annualCapex: 'Yearly capex',
      netIncome: 'Net income',
      unitaryEuroM2Month: 'Unitary ({{currency_per_surfaceUnit}}/mes)',
      monthlyEuroMonth: 'Monthly ({{currency}}/mes)',
      annualEuroYear: 'Yearly ({{currency}}/año)',
      idlenessMonths: 'Idleness months',
      latePaymentMonths: 'Late payment months',
      IPC: 'IPC',

      clusterData: 'Cluster data',
      name: 'Name',
      adoptedSurface: 'Adopted surface',
      reformLastYear: 'Reform last year',
      use: 'Use',
      typology: 'Typology',
      subtypology: 'Subtypology',
      censusSection: 'Census section',
      buildingInfo: 'Building information',
      mainUse: 'Main use',
      constructionYear: 'Construction year',

      totalLegalMaximumValue: 'Legal maximum total value',

      createCluster: 'Create cluster',
      clustersValuations: 'Clusters valuations',
      terrainsValuations: 'Terrains valuations',
      clusterItems: 'Cluster items',
      clusterSurface: 'Cluster surface',
      clusterDeleteMessage: 'Are you sure that you want to delete this cluster?',

      errors: {
        title: 'Errors',
        buildingUseRequired: 'Building use is missing',
        buildingYearRequired: 'Building construction year is missing',
        costMethodRequired: 'Cost method is necessary',
        residualStaticAbr: 'Static residual window must be completed in cost method',
        atLeastOneSurfaceWithValue: 'At least one surface with value is needed',
        groundRepercussionError: 'Ground impact',
        clusterAssetsWithoutSelectedSurfaces: 'It is necessary to add the surfaces in the Surfaces section of each asset and select them in the Units section.',
      },
      recalculateMethod: 'Recalculate methods',
      recalculate: 'Recalculate',
      current: 'Current valuation',
      future: 'Valuation C.B.H.',

      homogeneousCoefficientType: 'Type of homogenization',
      confirmCoeficientChange: 'If you modify the homogenization type, you will lose the data related to this included so far. Do you want to continue?',
      marketPriceUnitValue: 'Market value ({{currency_per_surfaceUnit}})',
      homogeneousCoefficient: 'Coef. homo. global',
      adoptedUnitValue: 'Final value ({{currency_per_surfaceUnit}})',
      constructionRehabilitation: 'Construction / rehabilitation year',
      surfaceTotal: 'Surface ({{surfaceUnit}})',
      elevatorsCount: 'Nº of elevators',
      situation: 'Coeff. Situation',
      orientation: 'Coef. Orientation',
      state: 'Coef. Condition',
      height: 'Coef. Height',
      coefSurface: 'Coef. Surface',
      morphology: 'Coef. Morphology',
      polyvalence: 'Coef. Polyvalence',
      accessibility: 'Coef. Accessibility',
      antiquity: 'Coef. Antiquity',
      others: 'Coeff. Others',
      header: 'Edit homogenization coefficients',

      witnessAddedDate: 'Witness creation date',
      witnessAddedDateCreation: 'Creation date',
    },

    TYPOLOGY: {
      hasActiveCultivation: 'Has active cultivation?',
      isPlot: 'Is it a plot?',
      borrowersHabitualResidence: `Borrower's habitual residence`,
      use: 'use',
      residenceType: '1st / 2nd residence',
      typology: 'typology',
      subTypology: 'subtypology',
      state: 'Asset state',
      conservationState: 'conservation status',
      legalSituation: 'legal situation',
      hasEnergeticCertificate: 'has energy certificate',
      reformLastYear: 'reform year',
      energeticCertificateGrade: 'types of energy certificates',
      expedient: 'expedient',
      protectionTimeLimit: 'protection term',
      provisionalQualificationDateTime: 'provisional qualification date',
      definitiveQualificationDateTime: 'definitive qualification date',
      workState: 'State of the work',
      isEconomicExploitation: 'Is it an Economic Exploitation?',
      economicExploitationObservations: 'Description of the Exploitation',
    },

    SURFACES: {
      surface: 'surface ({{surfaceUnit}})',
      surfaces: 'surfaces',
      cadastralSurfaces: 'cadastral',
      totalUsefulSurface: 'useful surface ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'built area without commons ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'built area with commons ({{surfaceUnit}})',
      checkedSurfaces: 'checked',
      registeredSurfaces: 'registry',
      observations: 'observations',
      totalPlotSurface: 'surface ({{surfaceUnit}})',
      workPlanTotalSurface: 'Work plan total surface ({{surfaceUnit}})',
    },

    NEW_ASSET: {
      addAsset: 'Add asset',
      back: 'Back to list of assets',
      byLocation: 'by address',
      byCadastralReference: 'By land registry reference',
      locateAgain: 'Back to detect',
      locate: 'Detect',
      addManually: 'Enter manually',
      cadastralReference: 'Land registry reference',
      localization: 'location',
      typology: 'type',
      identification: 'identification',
      surfaceAndBuildingInformation: 'asset surface area and information',
      surface: 'surface',
      constructionYear: 'Year of construction',
      chooseACadastralReference: 'Choose a cadastral reference',
      chooseIdealistaComparables: 'Choose the witness you want to use',
    },

    IDENTIFICATION: {
      cadastralReference: 'Cadastral reference',
      cadastralReferenceContainerBuilding: 'Building cadastral reference',
      idufir: 'C.R.U: (IDUFIR)',
      registeredPropertyNumber: 'Property number',
      propertyRegistrationName: 'Property registry name',
      volume: 'Volume',
      book: 'Book',
      paper: 'Paper',
      section: 'Section',
      inscription: 'Inscription',
      observations: 'Observations',
    },
    LOCATION_ENVIRONMENT_MARKET: {
      dataSource: 'data source',
      editAutomaticInformation: 'edit automatic information',
      environmentPersonalOpinion: 'technical opinion on the environment of the property',
      environment: 'environment',
      location: 'locality',
      market: 'market',
      featuresMarket: 'characteristics of comparable real estate market',
      marketMortgageValueDifferences: 'market value and mortgage value differences',
      offer: 'offer',
      demand: 'demand',
      currentPricSaleRental: 'current sale / rental price ranges',
      demandRevaluationExpectations: 'supply-demand and revaluation expectations',
      jobOccupation: 'job occupation',
      characteristicAntiquity: 'characteristic antiquity',
      architecturalHomogeneity: 'architectural homogeneity',
      infrastructures: 'infrastructures',
      equipmentAndServices: 'equipment and services',
      communications: 'communications',
      parkings: 'car parks',
      buildingDevelopment: 'building development',
      renovation: 'renovation',
      localityTypeOfCore: 'core type',
      localityPopulation: 'population',
      localityPopulationEvolution: 'recent population evolution',
      localityUnemploymentRate: 'unemployment',
      localityAvgAnnualIncome: 'average annual disposable income per capita',
      censusSectionTypeOfCore: 'core type',
      automaticDataWarning: 'Saving the form will retrieve data based on location, losing manually edited data.',
      environmentDescription: 'Environment description',
      localityMainFeatures: 'Locality main features',

      environmentSignificance: 'Environment significance',
      locationComparedToSameMunicipality: 'Location of the property compared to others in the same municipality',
      locationComparedToSameZone: 'Location of the property compared to others in the same area',
      locationComparedToSameBuildingElements: 'Location of the property within the building compared to other elements in the same building',
      pendingInfrastructuresPercentage: 'Percentage of pending infrastructures',
      environmentConsolidationDegreePercentage: 'Environment consolidation degree percentage',
    },

    APPRAISAL_REQUESTS: {
      requests: 'requests',
      contactUsTitle: 'contributor benefits increase',
      contentTitle: 'your budgets and payments with a single click',
      list1: 'quote your appraisal orders in just two steps',
      list2: 'know the economic proposal immediately',
      list3: 'receive in your email a payment link and the summary of your order',
      list4: 'your client will only have to access the payment link and will be able to choose the method that best suits their preferences: credit card, bank transfer, etc.',
      list5: 'you do not have to worry about anything else. Your order is already underway!',
      footer: 'we help you optimize and make your work even more profitable.',
      contactUs: 'contact us!',
      readAndAcceptTerms: 'I have read and accept',
      generalConditions: 'the general conditions of sale',
      taxError: 'The rate must be greater than or equal to the minimum proposed rate',
      totalPriceWithoutTaxes: 'price (without taxes)',
      withVisit: 'I make a visit',
      withReport: 'run report',
      observations: 'observations',
      isEnterprise: 'is an enterprise',
      name: 'name',
      lastName: 'lastname',
      nationalIdentityDocument: 'DNI/NIE',
      phone: 'phone',
      email: 'email',
      orderType: 'order type',
      purpose: 'purpose',
      visitType: 'type of visit',
      witECO: 'ECO 805/2003',
      address: 'address',
      postalCode: 'postal code',
      province: 'province',
      locality: 'locality',
      assetData: 'property data',
      addAsset: 'add asset',
      impossibleToMap: 'The cadastre information could not be mapped, some data must be filled in manually',
      taxesMessage: '*The proposed rates are editable as long as the edited amount is higher than the proposed one.',
      cancelAddAsset: 'cancel',
      year: 'year',
      priceRange: 'estimated value range',
      taxes: 'rate',
      owner: 'data of the owner of the appraisal',
      ownerDataMessage: 'We remind you that as the applicant for the appraisal, you are responsible for having informed your client that their data will be transferred to Instituto de Valoraciones S.A. in order to execute the contracted service',
      appraisalRequestFeatures: 'assignment characteristics',
      visitProposals: 'Visit Dates',
      invoiceData: 'invoicing data',
      copyOwnerData: 'Copy owner data',
      saveRequests: 'Save Request',
      addRequest: 'add request',
      requestDetail: 'Detail of the request',
      hasAppraisals: 'Has appraisals',
      showAppraisals: 'View Appraisals',
      relatedAppraisalList: 'List of related appraisals',
      cif: 'CIF',
      rateMessage: '* Rates are calculated based on the type and value of the property. Check that the typology identified corresponds to the real one. If not, please enter the property manually.',
      acceptTipologyCommitmentMessage: 'I accept that the budget will be reviewed by the Valuation Institute to verify that the properties included have been correctly identified in terms of their typology. If it is verified that they do not correspond to the real typology, this budget will not be valid and the client must pay the difference to start the order.',
      visitData: 'contact data for the visit',
      visitName: 'first name last name / business name',
      visitPhone: 'phone',
      visitEmail: 'email',
      visitObservations: 'observations',
      hasRequestElements: 'There are missing elements to add to the request',
      requestElementsArrayIsValid: 'Some of the elements are missing data to fill',
      proposedDatesFormArrayIsValid: 'You need to check the possible visit dates',
      purposeSNB: 'Purpose',
    },

    REPORT_ASSET_PROPERTY_FEATURES: {
      installations: 'installations',
      stays: 'stays',
      hasHotWater: 'hot water',
      hasHeating: 'heating',
      hasAirConditioning: 'air conditioning',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'plumbing and sanitary appliances',
      hasIntercom: 'intercom',
      hasSecurity: 'security',
      hasPhone: 'phone',
      hasSanitation: 'sanitation',
      externalWoodWork: 'exterior carpentry',
      facilityObservations: 'observations',
      pavementType: 'pavement type',
      wallType: 'wall type',
      ceilingType: 'ceiling type',
      internalWoodWork: 'interior carpentry',
      addStay: 'add stay',
      editStay: 'edit stay',
      stayType: 'type of stay',
      totalSurface: 'surface',
      generate: 'generate',
      distribution: 'distribution',
      staysResidentialMessage: 'there must be at least one bedroom and one bathroom',
      deleteThisStay: 'Are you sure you want to delete this room?',

      propertySize: 'Property size',
      license: 'License',
      polivalentUse: 'Multipurpose use',
      productAnalysis: 'Product analysis',

      hasEquippedKitchen: 'Equipped kitchen',
      hasBuiltInWardrobes: 'Built-in wardrobes',
      hasAlarmSystem: 'Alarm system',
      hasClosedSecurityCircuit: 'Security closed circuit',
      hotWaterType: 'Hot water',
      heatingType: 'Heating type',

      localFeatures: 'Local characteristics',
      facade: 'Façade (m)',
      depth: 'Background (m)',
      clearanceHeight: 'Free height (m)',
    },

    WITNESS_DETAIL: {
      supplierInfo: 'Supplier information',
      cadastralInformation: 'Cadastral information',
      cadastralReference: 'Cadastral reference',
      cadastralSurface: 'Cadastral surface',
      builtSurface: 'Built surface',
      plotSurface: 'Plot surface',
      features: 'Features',
      offerValue: 'Offer value',
      reductionNegotiation: 'Reduc. / Negotiation',
      offerType: 'Offer type',
      sourceType: 'Source type',
      crawledSource: 'Witness source',
      providerUrl: 'Provider URL',
      contactInfo: 'Contact phone',
      constructionYear: 'Construction year',
      reformLastYear: 'Reform last year',
      qualityOfBuilding: 'Building quality',
      conservationState: 'Conservation state',
      bathroomCount: 'Bathroom count',
      bedroomCount: 'Bedroom count',
      hasPool: 'Pool',
      hasSportsZone: 'Sport zone',
      hasGreenArea: 'Green area',
      hasHeating: 'Heating',
      hasAirConditioning: 'Air conditioning',
      hasElevator: 'Elevator',
      witnessDetail: 'Witness detail',
      hasPaving: 'Paving',
      hasPublicLighting: 'Public lighting',
      hasWaterSupply: 'Water supply',
      hasSanitationNetwork: 'Sanitation Network',
      hasTelephoneNetwork: 'Telephone network',
      hasPipedGasNetwork: 'Piped gas network',
      buildeableSurface: 'Buildable surface',
      observations: 'Observations',
      mainUse: 'Main use',
      hasFlatTerrain: 'Level ground',
      hasWarehouse: 'Warehouse',
      hasActiveCrop: 'Active cultivation',
      hasRolledAccess: 'Road access',
      hasWaterDistribution: 'Water distribution',
    },
    URBAN_SITUATION: {
      dataSource: 'data source',
      currentPlanning: 'current planning',
      description: 'description',
      currentPlanningAdequacy: 'adaptation to current approach?',
      observations: 'observations',
      subjectCorrespondingManagementInitiative: 'Subject to whom the management initiative corresponds',
      modeAndManagementSystem: 'System and management mode',
      urbanBurdens: 'Urban charges (transfers, urbanization costs, obligations acquired, etc.)',
      pendingProcedures: 'Pending procedures for finalist land',
      executionDeadlinesAndConsequences: 'Execution deadlines foreseen for the urban development of the land. Consequences in case of non-compliance ',
      expropiation: 'Expropriation. Status of the expropriation procedure. Legal criteria applicable to its valuation',
      buildability: 'Buildability',
      characteristicUse: 'Characteristic use',
      compatibleUses: 'Compatible uses',
      prohibitedUses: 'Prohibited uses',
      maxHeight: 'Maximum height',
      occupation: 'Occupation',
      setBacks: 'Setbacks',
      minimunPlot: 'Minimum plot',
      exploitation: 'Exploitation',
      scopeSurface: 'Scope surface',
      managment: 'Urban management',
      params: 'Urban parameters',
      urbanisticQualificationAndManagement: 'Urbanistic qualification and management',
      urbanisticParameters: 'Urbanistic Parameters',
      isAdequatedToCurrentPlanning: 'Adequacy to current approach?',
    },

    POSSESSION: {
      occupationState: 'occupation state',
      occupationType: 'occupant',
      hasNonexistenceLeasesDocument: 'document of nonexistence of harangues',
      hasContract: 'Is there a contract?',
      tenureType: 'tenure type',
      name: 'first and last name',
      nationalIdentityDocument: 'DNI / NIF',
      percentage: 'percentage',
      editTenure: 'edit tenure',
      addTenure: 'add tenure',
      deleteMessage: 'Are you sure you want to delete this tenure?',
      tenurePorcentageMessage: 'The sum of the percentages must be 100%',
      requiredField: 'required fields to fill are missing',
      tenuresLength: 'need to add tenures',
      tenureNif: 'DNI/NIF',
    },

    CHECKS: {
      assetChecksDataSource: 'use general data from report',
      MinimumChecks: 'minimum checks',
      ChecksRelatedToAttachedDocumentation: 'Checks regarding attached documentation',
    },

    CONDITIONING_FACTORS: {
      conditions: 'conditions',
      warnings: 'warnings',
      observations: 'observations',
      conditionalType: 'Type of conditioner',
      warningType: 'Warning type',
    },

    TERRAIN_DESCRIPTION_AND_SURFACES: {
      terrainSurfaces: 'terrain surfaces',
      landInfrastructures: 'land infrastructures',
      cadastralSurfaceTotalPlotSurface: 'registry ({{surfaceUnit}})',
      registeredSurfaceTotalPlotSurface: 'cadastral ({{surfaceUnit}})',
      checkedSurfaceTotalPlotSurface: 'checked ({{surfaceUnit}})',
      terrainUse: 'soil grade',
      urbanizationState: 'state of urbanization',
      morphology: 'morphology',
      topography: 'topography',
      observations: 'observations',
      withPaving: 'paving',
      withStreetLighting: 'public lighting',
      withWaterSupply: 'water supply',
      withSanitationNetwork: 'sanitation network',
      withTelephoneNetwork: 'telephone network',
      withPipedGasNetwork: 'centralized gas network',
      infrastructureObservations: 'observations',
      terrainDescription: 'Terrain description',
    },

    TERRAIN_FEATURES: {
      pendingInfraestructure: 'Pending infrastructure',
      workDonePercentage: 'Percentage of work done',
      currentInfraestructure: 'existing infrastructure',
      urbanizationCostsAndPendingCharges: 'urbanization costs and pending charges',
      existingEdifications: 'existing buildings',
      surfaceToDemolish: 'surface to demolish ({{surfaceUnit}})',
      accessibility: 'accessibility',
      groundLevel: 'ground level (Normative ECO / 805/2003)',
      planningDevelopment: 'development of approach',
      managementSystem: 'management system',
      managementPhase: 'management phases',
      urbanCoreProximity: 'proximity to the urban core',
      productToDevelopTypes: 'product to be developed',
      percentageOfValuedArea: 'percentage of the valued scope',
      terrainInformationDataSource: 'data source',
      exploitingAboveGround: 'Exploitation above grade ({{surfaceUnit}})',
    },

    EXCHANGES: {
      list: 'Exchange list',
      assignToMe: 'Asign it to me!',
    },

    REPORT_VISIT_ASSET_SURFACES_FACILITIES_STAYS: {
      surfaces: 'surface',
      facilities: 'facilities',
      stays: 'stays',
      totalUsefulSurface: 'useful ({{surfaceUnit}})',
      totalBuiltSurfaceWithoutCommonSurfaces: 'built without commons ({{surfaceUnit}})',
      totalBuiltSurfaceWithCommonSurfaces: 'built with commons ({{surfaceUnit}})',
    },

    REPORT_VISIT_ASSET_BUILDING_INFO: {
      localityEnvironmentAndMarket: 'locality, environment and market',
      buildingFeatures: 'building characteristics',
      environmentPersonalOpinion: 'technical opinion on the environment of the property',
      mainElements_Structure: 'structure',
      mainElements_Deck: 'deck',
      mainElements_Enclosure: 'enclosures',
      mainElements_Foundation: 'foundation',
      constructionYear: 'year of construction',
      lastRehabilitationYear: 'year of rehabilitation',
      totalPlotSurface: 'sup. plot',
      qualityOfBuilding: 'quality of buildings',
      conservationState: 'conservation status',
      buildingUse: 'use',
      totalFloorsBellowGround: 'No. plants below grade',
      totalFloorsAboveGround: 'No. plants above ground',
      numberOfBuildingAccesses: 'No. accesses to the building',
      streetCategory: 'street category',
      surveillance: 'surveillance',
      hasSportsZone: 'sports zone',
      hasSwimmingPool: 'pool',
      hasGreenArea: 'green areas',
      hasElevator: 'elevator',
      observations: 'observations',
      requiredField: 'required fields to fill are missing',
    },

    INPUT_FILE: {
      errorTitle: 'Error',
      errorMessage: 'Format not supported',
    },
    BooleanQuestionValues_false: 'No',
    BooleanQuestionValues_true: 'Yes',
    REQUEST_FILTERS: {
      id: 'ID',
      containsOwnerFullName: 'Customer name',
      containsOwnerNationalIdentityDocument: 'Customer ID',
    },
    DEBUG: {
      confirm: 'Confirm',
      setDev: 'Set DEV',
      setQA: 'Set QA',
      setPROD: 'Set PROD',
      setPreset: 'Or set a preset endpoint:',
      setEndpoint: 'Set endpoint',
      setCustomEndpoint: 'Set custom endpoint:',
      enpointAdress: 'Endpoint address:',
      cancel: 'Cancel',
    },
    VALUATION_RESUME: {
      version: 'Version',
      element: 'Element',
      unitValue: 'Unit value',
      totalValue: 'Total value {{currency}}',
      subtotal: 'Subtotal',
      total: 'Total',
    },
    GROUP_VALUATE_AssetSurfaceIds_6: 'Registral ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_0: 'Cadastral ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_12: 'Checked ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_13: 'Useful ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_15: 'With commons ({{surfaceUnit}})',
    GROUP_VALUATE_AssetSurfaceIds_14: 'No commons ({{surfaceUnit}})',
    GROUP_VALUATE_isVPO: 'It is VPO',
    GROUP_VALUATE_protected: 'Protected',
    GROUP_VALUATE_free: 'Free',
    CLUSTER_TERRAIN: {
      addBuildingHypothesis: 'Add hypothesis',
      editParmaters: 'edit parameters',
      riskPremiumPercent: 'risk premium',
      developerProfitPercent: 'developer profit (%)',
      valorationUnitValue: 'market value',
      weight: 'weight',
      repercussion: 'repercussion',
      editBuildingHypothesis: 'Edit hypothesis',
      editIncome: 'Edit income',
      editExpense: 'Edit expense',
      maxPercentage: 'between 0-100%',
      updatedIncome: 'Updated income',
      updatedExpenses: 'Updated expenses',
      totals: 'Totals',
      buildingHypothesisValuation: 'Hypothesis valuation',
      valuationMethod: 'Valuation method',
      countBuildingElements: 'No. elements to build',
      observations: 'Observations',
      calculationMethod: 'Calculation of method',
      elementSurface: 'Estimated element surface ({{surfaceUnit}})',
      valorrationUnitValueProposed: 'Value proposition ({{currency_per_surfaceUnit}})',
      terrainMethodChange: 'Changing the valuation method will erase all items created from the previous valuation. Continue? ',
      increaseInExternalFinancing: 'Increase external financing (%)',
      updateRate: 'Update rate (%)',
      yearly: 'Annual',
      biannual: 'Semiannual',
      monthly: 'Monthly',
      increaseInExternalFinancingObservations: 'Value justification',
      groupBy: 'Group by',
      byPeriods: 'by periods',
      assignValueToAll: 'Assign value to all',
      netExpensesUpdated: 'Expenses Act.',
      total: 'Total',
      updated: 'Updated',
      totalUpdated: 'Total Updated',
      totalIncomesExpensesDiff: 'TOTAL (I-G)',
      cashflows_observations: '* It will only be necessary to distribute the Income / Expenses that have been added with the Non-Periodic Typology.',
      assigned: 'Assigned',
      calculateValorationValue: 'Market value calculation',
    },
    BUILDING_STATE: {
      observations: 'Observations',
      adoptedSurface_2: 'Superficie adoptada',
      constructionCostsTotalValue: 'Construction cost {{currency}}',
      recalculateWarning: 'Remember after recalculate you must check assets repartition and save your changes.',
      recalculateBeforeContinue: 'Recalculation is needed before you can edit this form',
      distribution: 'Distribution',
    },

    surfaceCommons: 'Built surface with commons',
    surfaceUseful: 'Useful surface',
    surfaceWithoutCommons: 'Built surface without commons',
    cadastral: 'Cadastral',
    checked: 'Checked',
    registered: 'Registered',

    VALIDATION_MODAL: {
      bodyText: 'I declare that I have read and understood the articles relating to the obligations of secrecy and incompatibilities of the professionals referred to in articles 11 and 13 of RD 775/1997, as well as the <a href="https://dev-migration.iv.solutions/files/users/IV_Reglamento_Interno_de_Conducta_2020.pdf">internal rules of conduct</a>, accepting both for the performance of my professional activity at Instituto de Valoraciones SA. <hr> I undertake to inform to Instituto de Valoraciones of any conflict of interest arising from the performance of my work and to keep my incompatibilities updated.',
      button: 'I ACCEPT',
    },

    CHANGE_TEMPORAL_PASSWORD_recoverMessage: 'Your current password is temporary, for security reasons it must be changed',

    COMPARABLES_FILTERS: {
      workflowItemAssetId: 'Record number',
      offerType: 'Offer type',
      addedMinDateTime: 'Added date',
      addedMaxDateTime: 'Added date',
      bathroomCount: 'Bathroom count',
      bedroomCount: 'Room count',

      hasHotWater: 'Hot water',
      hasHeating: 'Heating',
      hasAirConditioning: 'Air conditioning',
      hasGas: 'gas',
      hasPlumbingAndSanitaryEquipment: 'Plumbing and sanitary equipment',
      hasIntercom: 'pintercom',
      hasSecurity: 'security',
      hasPhone: 'phone',
      hasSanitation: 'sanitation',
      hasSwimmingPool: 'Swimming pool',
      hasSportsZone: 'Sports zone',
      hasGreenArea: 'Green area',
      hasElevator: 'Elevator',
      hasPool: 'pool',

      maxValorationTotalAdoptedSurface: 'Max surface',
      minValorationTotalAdoptedSurface: 'Min surface',
    },

    ASSETS_totalPlotSurface: 'Plot surface ({{surfaceUnit}})',

    numberAbbreviation: 'Nº',
    currentValuationAbbreviation: 'Actual',
    futureValuationAbbreviation: 'H.E.T.',
    visit: 'Visita',
    incidentAbbreviation: 'INC',

    confirmDeleteTitle: 'Confirmar eliminar',
    confirmDeleteMessage: '¿Está seguro de querer eliminar esta valoración?',
    buildingElementChangeValuationMethodMessage: 'Al cambiar el método de valoración se eliminará el anterior método usado',
    noMoreInfo: 'No hay más información',

    REA: {
      maximumLegalDividedByCostMethodSurface: 'Este valor viene dado de el valor máximo legal dividido entre la superficie adoptada en el método de coste',
      surfaceInCostMethod: 'Superficie del método de coste: ',
    },

    EXCHANGE_TOUR: {
      welcome: '¡Bienvenido!',
      configureMaxDistance: ' Empieza configurando la distancia máxima de la que quieres recibir oportunidades',
      preferencesSaved: 'Preferencias guardadas correctamente',
      assetAddress: 'Dirección del activo',
    },
    EXCHANGE: {
      assignAppraisalInExchangeMessage: 'Con la asignación estás asumiendo la ejecución de la valoración por los honorarios y en el plazo indicado.',
      successfulAssigned: 'Asignada correctamente',
      errorAssigned: 'Ha ocurrido un error, inténtelo de nuevo.',
    },

    invoice: 'Factura',

    TOUR: {
      exitTour: 'Saltar tour',
      canChangePreferences: 'Recuerda que siempre puedes cambiar tus preferencias desde PERFIL',
      canFilterExchanges: 'Puedes filtrar las oportunidades según fecha y tarifa',
      exchangeMoreInfo: '¿Te interesa saber más sobre la oportunidad?',
      clickInCard: 'Haz click sobre la tarjeta',
      hurryUpAndAssign: '... o si todo te encaja, date prisa y ¡Asígnatela!',
      continueWithYourWork: 'Y sigue con el proceso habitual de trabajo',
    },
    CANDEACTIVATE: {
      pendingChanges: 'There are pending changes',
      pendingChangesMessage: 'You have changes pending to confirm. Are you sure you want to leave this page?',
      yesWithoutSaving: 'Yes, no saving',
      yesSaving: 'Yes, saving',
    },
    WORKPLAN_everyAssetMustHaveAValidSurface: 'All assets must have at least one surface with value',

    language: 'Language',
    currency: 'Currency',
    config: 'configuration',
    select_currency_warning: 'The selected currency will change the label, it will not influence the value.',

    NETWORK: {
      myConnectionsNetwork: 'My network of connections',
      confirmAndSend: 'Confirm and send',
      addConnection: 'Add connection',
      workWith: 'Work for ...',
      code: 'Code',
      message: 'Message',
      codeConnectionError: 'It is not possible to connect with this user, for this you must contract one of our plans.',
      licenseMessage: 'I want to collaborate with you to make appraisals in ...',
      codeCopied: 'Code copied!',
      cancelConnection: 'Cancel connection',
      connectionDate: 'Connection date',
      connectionRequestDate: 'Request date',
      all: 'All',
      resendRequest: 'Resend request',
      insertConnectionData: 'Enter the data of the person you want to connect with',
      requestError: 'Error sending request',
      invalidSharingIdMessage: 'The code you have entered does not match any Tasafy user, check this code and make the request again',
      pendingConnectionRequestMessage: 'You have already tried to connect with this person but they have not yet accepted the invitation. You can resend the connection request again ',
      alreadyRelatedMessage: 'This person already belongs to your contact network. Check that the data you have entered is correct.',
      requestSended: 'Request sent',
      reviewRequest: 'Review request',
      cancelConnectionMessage: 'Are you sure you want to cancel this connection?',
      resendRequestMessage: 'Are you sure you want to resend the request?',
      requestMessageWorkFor: '{{name}} wants to connect with you, join their network!',
      rejectRequest: 'Reject request',
      acceptRequest: 'Accept request',
      name: 'Name',
      newConnection: 'New connection',
      colaborateWithLicenses: 'Collaborate with other licenses as professional',
    },
    ConnectionRequested_0: 'Sent',
    ConnectionRequested_1: 'Received',
    EXCHANGE_errorMessage: 'An error has occurred, please try again.',
    EXCHANGE_executionMessage: 'With the assignment you are assuming the execution of the valuation for the fees and within the indicated period.',
    EXCHANGE_assignedRight: 'Assigned correctly',
    COST_METHOD_editFieldsInCurrentValoration: 'The rest of the fields can only be edited from the current Valuation tab',
    VALUATION_LIST_residualDynamicInfo: 'The dynamic residual method will be added from the State of the work chapter',
    VALUATION_LIST_residualDynamicDeleteMessage: 'This method can only be removed by removing the corresponding common data dynamic residual method in the Work status tab',

    CLUSTER: {
      clusterData: 'cluster detail',
      valuations: 'Valuations',
      totalValue: 'Current valuation',
      valuationsSummary: 'valuations summary',
      allValuations: 'All valuations',
      canNotValuate: 'This asset cannot be valued as it belongs to a pool.',
      assetNotHasSurfaces: 'Es necesario añadir las superficies en el apartado de Superficies del activo.',
      surfacesError: 'Faltan superficies en los activos por seleccionar.',
      valuationSurfaceTitle: 'Surface',
      necessarySaveCluster: 'Save is necessary to recalculate the grouping surface.',
      necessarySaveClusterValuationsStep: 'Save in Units is required to recalculate the surface of the cluster.',
      addAsset: 'Add units',
      removeAssetWithParentOrChildrenConfirm: 'Removing this drive will remove the drives linked to it. Remove?',
      removeAssetConfirm: 'You are going to remove this drive. Remove?',
      selectClusterType: 'Select grouping type:',
      selectClusterAssets: 'Select cluster assets',
      minClusterAssets: '* Cluster must consist of at least two assets',
      selectableAssets: 'selectable',
      noSelectableAssets: 'not selectable',
      noItemsSection: 'There are no items',
      noItemsToSelect: 'There are no items that can be part of the grouping. If you consider that you have an asset that you need to group together, you can check if there is an error in the Non-selectable assets part. ',
      isEconomicExploit: 'Economic exploitation',
      isNotEconomicExploit: 'No economic exploitation',
    },

    COST_METHOD_isUnfinishedMessage: 'The adopted surface can only be modified in the Work Status chapter.',
    COST_METHOD_recalulateWorkPlan: 'It is necessary to recalculate the state of the work and assign a surface to this asset.',
    COST_METHOD_totalAdoptedSurface: 'It is necessary to define the surfaces adopted in the Work Status chapter.',

    notHavePermissions: "You don't have permissions",
    licenseNotHasPermissions: 'Your license does not have permissions to access this appraisal',
    CLUSTER_DETAIL_canNotRemoveAssets: 'Unable to remove. There must be at least two units per grouping ',

    REPORT_ASSET_report_structure_hasChildrenWithErrors_error: 'It is necessary to review the errors belonging to the annexes to be able to group.',

    ASSET_canNotSelectEconomicExploitation: 'It is not possible to select Economic Exploitation with the status of the asset not finished',
    CLUSTERS_canNotChangeUseToTerrain: 'This asset belongs to a cluster. If you want to convert it into land, it is necessary to remove it from that grouping ',

    EX_economicDataInfoModalTitle: 'Economic Data Information',

    last_orders_empty: 'Hello {{user}}! You do not have any new orders, if you miss any, check the rest of the states: Visit pending, Pending sending...',

    WELCOME_CONNECTIONS_slide1_title: 'Welcome to the world of Tasafy connections!',
    WELCOME_CONNECTIONS_slide1_paragraph1: 'Immerse yourself in the <b>Tasafy</b> universe and connect with the different <i>players</i> to be able to do the appraisals for them.',
    WELCOME_CONNECTIONS_slide1_paragraph2: 'What does this mean?',
    WELCOME_CONNECTIONS_slide2_paragraph1: 'Under your name you will find your <b>professional code</b> that you can share so that they can connect with you and that you are part of their network so they can order work from you.',
    WELCOME_CONNECTIONS_slide2_paragraph2: 'If you have a license code (those that start with <b>L-</b>) you can request the connection just by clicking on the <b>+</b>.',
    WELCOME_CONNECTIONS_slide3_paragraph1: 'On the connections page you can find all the connection requests you have in each state. Likewise, at any time you can request the cancellation of the connection.',
    WELCOME_CONNECTIONS_slide3_paragraph2: 'From the side menu you will be able to navigate between the different accounts you are connected to.',
    WELCOME_CONNECTIONS_slide4_paragraph1: 'In addition, you will have a much more complete access from your <b>control panel</b>, since you will be able to see the summary of the orders that you have in progress in each of the accounts and the pending work.',
    WELCOME_CONNECTIONS_slide4_button: 'Lets connect!',

    ACCOUNT_ACTIVATION_noActivationTitle: "Don't forget to activate your account!",
    ACCOUNT_ACTIVATION_noActivationMessage: 'Your account has not been activated yet. Check your inbox where you will find the email to do so. If you have not received it or you have exceeded the activation time of one day, you can request it again from here.',
    ACCOUNT_ACTIVATION_resend: 'Resend',
    ACCOUNT_ACTIVATION_resending: 'Resending...',
    ACCOUNT_ACTIVATION_goToLogin: 'Go back to login',
    ACCOUNT_ACTIVATION_defaultError: 'Error activating account. Please get in touch with us',
    ACCOUNT_ACTIVATION_resendSuccess: 'Email resend successfully',
    ACCOUNT_ACTIVATION_resendError: 'Error resending email',
  },
};
